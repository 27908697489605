import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import Select from 'react-select';
import WebCamera from 'webcamjs';
import { getManagers } from '../../../models/UserModel';
import { getSiteVisitProjects } from '../../../models/ProjectModel';
import { loginAttendance, saveAttendanceToStorage } from '../../../models/AttendanceModel';

const AttendanceLoginModal = ({showModal, setShowModal, fetchAttendances, limit, page}) => {

  const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);

    const [imageUrl, setImageUrl] = useState('assets/images/user-placeholder.png');
    
    const [attendance_type, setAttendanceType] = useState('');
    const [attendance_manager_id, setAttendanceManagerId] = useState('');
    const [attendance_site_visit_project_id, setAttendanceSiteVisitProjectId] = useState('');
    const [attendance_in_selfie, setAttendanceInSelfie] = useState('');
    const [attendance_in_location, setAttendanceInLocation] = useState('');

    const [cameraStatus, setCameraStatus] = useState(false);

    const startCamera = () => {
        if(cameraStatus===false){ // Start the camera !
            setCameraStatus(true);
            document.getElementById('camdemo').style.display = 'block';

            WebCamera.set({
                width: 250,
                height: 250,
                image_format: 'jpeg',
                jpeg_quality: 90
            });

            WebCamera.attach('#camdemo');
            console.log("The camera has been started");
        }else{ // Disable the camera !
            setCameraStatus(false);
            document.getElementById('camdemo').style.display = 'none';
            WebCamera.reset();
            console.log("The camera has been disabled");
        }
    }

    const captureSelfie = () => {
        WebCamera.snap( function(data_uri) {
            setImageUrl(data_uri);

            setAttendanceInSelfie(data_uri);

            setCameraStatus(false);
            document.getElementById('camdemo').style.display = 'none';
            WebCamera.reset();
        });
    }
    
    const [errors, setErrors] = useState({attendance_type:  '',  attendance_in_selfie: '', attendance_in_location: ''});
    

    const closeModal = () =>{
        setAttendanceType('');
        setAttendanceManagerId('');
        setAttendanceSiteVisitProjectId('');
        setAttendanceInSelfie('');
        setImageUrl('assets/images/user-placeholder.png');
        setAttendanceInLocation('');

        if(cameraStatus===true){
            setCameraStatus(false);
            document.getElementById('camdemo').style.display = 'none';
            WebCamera.reset();
            console.log("The camera has been disabled");
        }    

        setErrors({});
    
        setShowModal(false);
    }

    const handleTypeChange = (type) => {
        setAttendanceType(type);

        if (type==='wfh') {
            setAttendanceSiteVisitProjectId('');
        }
        else if(type==='site'){
            setAttendanceManagerId('');
        }
        else{
            setAttendanceManagerId('');
            setAttendanceSiteVisitProjectId('');
        }
    }

    const [attendance_types, setAttendanceTypes] = useState([]);

    const [managers, setManagers] = useState([]);
    const fetchManagers = async () => {
        try {
          const result = await getManagers({user_token: user_data.user_token, page:1, limit:200});
          
          //console.log(result);

          const managerOptions = result.managers.map(manager => ({
            label: manager.user_first_name+' '+manager.user_last_name,
            value: manager._user_id
          }));


          setManagers(managerOptions);
        } catch (error) {
          console.error(error);
        }
    };

    const [projects, setProjects] = useState([]);
    const fetchProjects = async () => {
        try {
          const result = await getSiteVisitProjects(user_data.user_token);
          
          //console.log(result);

          const projectOptions = result.site_visit_projects.map(project => ({
            label: project.project_title,
            value: project._project_id
          }));


          setProjects(projectOptions);
        } catch (error) {
          console.error(error);
        }
    };

    useEffect(() => {
        fetchManagers();
        fetchProjects();
        const typeOptions = 
        [
            {label: 'Office', value: 'office'},
            {label: 'WFH', value: 'wfh'},
            {label: 'Site', value: 'site'}
        ];

        setAttendanceTypes(typeOptions);
    }, []); 

    const getGeoLocation = async() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(show_location, show_location_error);
        } 
        else { 
            toast.error("Geolocation is not supported by this browser.");
        }
    }

    function show_location(position) {

        console.log(position);

        setAttendanceInLocation(position.coords.latitude+','+position.coords.longitude);
    }
      
    function show_location_error(error) {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            toast.error("User denied the request for Geolocation.");
            break;
        case error.POSITION_UNAVAILABLE:
            toast.error("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            toast.error("The request to get user location timed out.");
            break;
        case error.UNKNOWN_ERROR:
            toast.error("An unknown error occurred.");
            break;
    }
    }


    
    
    const doAttendanceLogin = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { attendance_type: attendance_type.value ? attendance_type.value : '', 
                             attendance_manager_id: attendance_manager_id.value ? attendance_manager_id.value : 0,
                             attendance_site_visit_project_id: attendance_site_visit_project_id.value ? attendance_site_visit_project_id.value : 0,
                             attendance_in_selfie, attendance_in_location };
    
            const response = await loginAttendance(user_data.user_token, formData);

            if (response.status) {
                toast.success(response.message);
        
                closeModal();
        
                saveAttendanceToStorage(response.data.attendances, response.data.pagination, response.data.attendance_is_logged_in);
        
                fetchAttendances(limit, page); 
              }
              else{
                toast.error(response.message);

                if (response.data.attendance_is_logged_in==true) {
                    saveAttendanceToStorage(response.data.attendances, response.data.pagination, response.data.attendance_is_logged_in);

                    fetchAttendances(limit, page); 

                    closeModal();
                }
                else{
                    setErrors(response.error);
                }
                
              }
    
        } catch (error) {
          apiError(error);

        //   if (error.response.data.invalid_token==1) {
        //     navigate('/re-login');
        //   }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='sm'>
        <Modal.Header closeButton>
            <Modal.Title>Attendance Login</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                
                <div className="col-xl-12">
                    <label className="form-label">Login Type <span className="text-danger">*</span></label>
                    <Select
                        value={attendance_type}
                        onChange={handleTypeChange}
                        options={attendance_types}
                        placeholder="- Type -"
                        isSearchable="true"
                        noOptionsMessage={() => "No type found"} />

                    {errors.attendance_type && <p style={{ color: 'red' }}>{errors.attendance_type}</p>}  
                </div>

                <div className="col-xl-12" style={{display: attendance_type.value && attendance_type.value==='wfh' ? 'block' : 'none'}}>
                    <label className="form-label">Manager <span className="text-danger">*</span></label>
                    <Select
                        value={attendance_manager_id}
                        onChange={setAttendanceManagerId}
                        options={managers}
                        placeholder="- Manager -"
                        isSearchable="true"
                        noOptionsMessage={() => "No manager found"} />

                    {errors.attendance_manager_id && <p style={{ color: 'red' }}>{errors.attendance_manager_id}</p>}  
                </div>


                <div className="col-xl-12" style={{display: attendance_type.value && attendance_type.value==='site' ? 'block' : 'none'}}>
                    <label className="form-label">Project <span className="text-danger">*</span></label>
                    <Select
                        value={attendance_site_visit_project_id}
                        onChange={setAttendanceSiteVisitProjectId}
                        options={projects}
                        placeholder="- Project -"
                        isSearchable="true"
                        noOptionsMessage={() => "No project found"} />

                    {errors.attendance_site_visit_project_id && <p style={{ color: 'red' }}>{errors.attendance_site_visit_project_id}</p>}  
                </div>

                <div className="col-xl-12">
                    <label className="form-label">Location <span className="text-danger">*</span></label>

                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Location Co-ordinates" value={attendance_in_location} disabled="true" />

                        <button className="btn btn-light" type="button"  title="Get Location" onClick={getGeoLocation} style={{zIndex: '0'}}>
                            <i className="bi bi-geo-alt-fill align-middle"></i>
                        </button>
                    </div>

                    {errors.attendance_in_location && <p style={{ color: 'red' }}>{errors.attendance_in_location}</p>} 
                </div>

                <div className="col-xl-12">
                    <label className="form-label">Selfie <span className="text-danger">*</span></label>

                    <img className='card-img-top' src={imageUrl} style={{width: "250px", height: "250px",  margin: "0 auto", display: cameraStatus===false ? 'block' : 'none'}}/>

                    <div id="camdemo" style={{width: "250px", height: "250px", textAlign: "center", margin: "0 auto", display: cameraStatus===true ? 'block' : 'none'}}></div>

                    <button  class="btn btn-light" onClick={startCamera} style={{display: cameraStatus===false ? 'block' : 'none'}}>
                        <i class="bi bi-camera-video"></i> Open Camera
                    </button>

                    <button  class="btn btn-light" onClick={captureSelfie} style={{display: cameraStatus===true ? 'block' : 'none'}}>
                        <i class="bi bi-camera"></i> Capture Selfie
                    </button>

                    {errors.attendance_in_selfie && <p style={{ color: 'red' }}>{errors.attendance_in_selfie}</p>} 
                </div>
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={doAttendanceLogin}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Submit'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default AttendanceLoginModal;