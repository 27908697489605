import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { removeInvoiceShippingAddress, saveInvoicesToStorage } from '../../../models/InvoiceModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const DeleteShippingAddressModal = ({showModal, setShowModal, modalKey, invoiceData, setInvoiceData, setShippingAddress, setInvoiceItems, setCompanySettings}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);
    
    const [invoice_unique_id, setInvoiceUniqueId] = useState('');

    const closeModal = () =>{
        setInvoiceUniqueId('');
        
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (invoiceData) {
            setInvoiceUniqueId(invoiceData.invoice_unique_id);
        }
      }, [invoiceData, modalKey]);


    const deleteShippingAddress = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
          const response = await removeInvoiceShippingAddress(user_data.user_token, invoice_unique_id);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            setInvoiceData(response.data.invoice_data);
            setShippingAddress(response.data.invoice_shipping_address);
            setInvoiceItems(response.data.invoice_items);
            setCompanySettings(response.data.company_settings);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Shipping Address</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this address ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteShippingAddress}>Delete</button>
            </Modal.Footer>
        </Modal>
    );  

}; 


export default DeleteShippingAddressModal;