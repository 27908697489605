import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";

export const createNote = async(user_token, formData) => {

    const endPoint = "/notes/add-note";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}  


export const editNote = async(user_token, noteId, formData) => {

    const endPoint = `/notes/update-note/${noteId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}  


export const removeNote = async(user_token, noteId) => {

    const endPoint = `/notes/delete-note/${noteId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const saveNotesToStorage = (notes, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        notes: notes,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("notes", encryptData(dataToStore));
} 


export const getNotes = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('notes')) {
        const saved_notes_obj = decryptData(localStorage.getItem('notes'));

        return saved_notes_obj;
    }
    else{
        try {
            const endPoint = '/notes/note-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveNotesToStorage(response.data.notes, response.data.pagination);

                    const saved_notes_obj = decryptData(localStorage.getItem('notes'));

                    return saved_notes_obj;
                }
                else{
                    //localStorage.removeItem("notes");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);

            //console.log(error);
        }
    }
};

   
