import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'react-toastify';

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};

initializeApp(firebaseConfig);
export const messaging = getMessaging();

export const generateToken = async() => {
    const permission = await Notification.requestPermission();
    //console.log(permission);

    if (permission === 'granted') {
        const fcmToken = await getToken(messaging, {
            vapidKey: `${process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY}`
        });

        //console.log('FCM Token: ' + fcmToken);

        return fcmToken;

    } else {
        toast.warning("Allow notification permission to get notification");
    }

}