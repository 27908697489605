import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../../context/AuthContext';
import { utcToLocal } from '../../../helpers/DateHelper';
import dateFormat from "dateformat";

const ViewTimesheetModal = ({showModal, setShowModal, timesheetData, modalKey, fetchTimesheets, limit, page}) => {

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [_timesheet_id, setTimesheetId] = useState('');
    const [timesheet_other_task, setTimesheetOtherTask] = useState('');
    const [timesheet_task_detail, setTimesheetTaskDetail] = useState('');
    const [timesheet_date, setTimesheetDate] = useState('');
    const [timesheet_start_time, setTimesheetStartTime] = useState('');
    const [timesheet_end_time, setTimesheetEndTime] = useState('');
    const [timesheet_idle_time, setTimesheetIdleTime] = useState('');
    const [timesheet_working_hours, setTimesheetWorkingHours] = useState('');
    const [timesheet_is_completed, setTimesheetIsCompleted] = useState('');
    const [client_name, setClientName] = useState('');
    const [project_title, setProjectTitle] = useState('');
    const [_task_id, setTaskId] = useState('');
    const [task_title, setTaskTitle] = useState('');

    const closeModal = () =>{
        setTimesheetId('');
        setTimesheetOtherTask('');
        setTimesheetTaskDetail('');
        setTimesheetDate('');
        setTimesheetStartTime('');
        setTimesheetEndTime('');
        setTimesheetIdleTime('');
        setTimesheetWorkingHours('');
        setTimesheetIsCompleted('');
        setClientName('');
        setProjectTitle('');
        setTaskId('');
        setTaskTitle('');
    
        setShowModal(false);
    }


    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (timesheetData) {
            setTimesheetId(timesheetData._timesheet_id);
            setTimesheetOtherTask(timesheetData.timesheet_other_task);
            setTimesheetTaskDetail(timesheetData.timesheet_task_detail);
            setTimesheetDate(timesheetData.timesheet_date);
            setTimesheetStartTime(timesheetData.timesheet_start_time);
            setTimesheetEndTime(timesheetData.timesheet_end_time);
            setTimesheetIdleTime(timesheetData.timesheet_idle_time);
            setTimesheetWorkingHours(timesheetData.timesheet_working_hours);
            setTimesheetIsCompleted(timesheetData.timesheet_is_completed);
            setClientName(timesheetData.client_name);
            setProjectTitle(timesheetData.project_title);
            setTaskId(timesheetData._task_id);
            setTaskTitle(timesheetData.task_title);
          
        }
    }, [timesheetData, modalKey]);


    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Timesheet Details</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    

                    <div className="col-lg-6">
                        <label className="form-label">Date </label>
                        <div className='form-control'>{dateFormat(timesheet_date, "dd mmm yyyy")}</div>
                    </div>

                    <div className="col-lg-6">
                        <label className="form-label">Project </label>
                        <div className='form-control'>{project_title}</div>
                    </div>

                    <div className="col-lg-6">
                        <label className="form-label">Task </label>
                        <div className='form-control'>{_task_id ? task_title : timesheet_other_task}</div>
                    </div>

                    <div className="col-lg-6">
                        <label className="form-label">Description </label>
                        <div className='form-control'>{timesheet_task_detail}</div>
                    </div>

                    <div className="col-lg-6">
                        <label  className="form-label">Start Time </label>
                        <div className='form-control'>{dateFormat(timesheet_start_time, "hh:MM:ss TT")}</div>
                    </div>

                    <div className="col-lg-6">
                        <label  className="form-label">End Time </label>
                        <div className='form-control'>{timesheet_end_time ? dateFormat(timesheet_end_time, "hh:MM:ss TT") : '--:--:--'}</div>
                    </div> 
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            </Modal.Footer>
        </Modal>
    );

};    


export default ViewTimesheetModal;