import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { getProjectFiles } from '../../../models/ProjectModel';
import ProjectFiles from '../../skeletons/ProjectFiles';
import DataTable, { createTheme } from 'react-data-table-component';
import AddProjectFileModal from '../../modals/Project/AddProjectFileModal';
import DeleteProjectFileModal from '../../modals/Project/DeleteProjectFileModal';

const ProjectFilesTables = ({
    project_unique_id,
    callProjectFiles,
    projectData,
    task_unique_id
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const [modalKey, setModalKey] = useState(0);

    const [fileData, setFileData] = useState({});

    //Add Project File Start
    const [showAddProjectFileModal, setShowAddProjectFileModal] = useState(false);

    const openAddProjectFileModal = () =>{
        setModalKey(prevKey => prevKey + 1); 
        setShowAddProjectFileModal(true);
    } 
    //Add Project File End


    //Delete Project File Start
    const [showDeleteProjectFileModal, setShowDeleteProjectFileModal] = useState(false);

    const openDeleteProjectFileModal = (file_data) =>{
        setFileData(file_data);
        setModalKey(prevKey => prevKey + 1); 
        setShowDeleteProjectFileModal(true);
    } 
    //Delete Project File End


    // Get Project Files Start
    const [projectFiles, setProjectFiles] = useState([]);
    const [projectFilesTotalRows, setProjectFilesTotalRows] = useState(0);
    const [projectFilesLoading, setProjectFilesLoading] = useState(false);
    const [projectFilesPage, setProjectFilesPage] = useState(1);
    const [projectFilesLimit, setProjectFilesLimit] = useState(10);

    const fetchProjectFiles = async (limit, page) => {

        if (callProjectFiles===true) {

            setProjectFilesLoading(true);
            
            try {
                let apiParam = {page: page, limit: limit};

                const response = await getProjectFiles(user_data.user_token, project_unique_id, task_unique_id, apiParam);

                //console.log(response);

                if (typeof response != typeof undefined) {
                    setProjectFiles(response.files);
                    setProjectFilesTotalRows(response.pagination.total_rows);
                    setProjectFilesLimit(limit);
                    setProjectFilesPage(page);

                    setNotificationCount(localStorage.getItem('unread_notification_count'));
                }  
            
                setProjectFilesLoading(false);

            } catch (error) {
                //console.error(error);
            }

        }
    };

    const ProjectFilesCustomLoader = () => (
        <ProjectFiles/>
    );

    const ProjectFilesPageChange = async (page) => {
        fetchProjectFiles(projectFilesLimit, page);  
    };

    const ProjectFilesLimitChange = async (limit) => {
        fetchProjectFiles(limit, projectFilesPage); 
    };  

    useEffect(()=>{
        fetchProjectFiles(projectFilesLimit, projectFilesPage); 
    }, [project_unique_id, callProjectFiles, projectData, task_unique_id]);
    // Get Project Files End

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    const ProjectFilesColumns = [
        {
            name: "#",
            selector: (row, index) => <div>{((projectFilesPage-1)*projectFilesLimit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Name",
            selector: (row) => row.file_original_name
        },
        {
            name: "Type",
            selector: (row) => row.file_mime_type
        },
        {
            name: "Size",
            selector: (row) => row.file_size+' KB'
        },
        {
            name: "Added On",
            selector: (row) => dateFormat(row.file_upload_time, "dd mmm yyyy HH:MM")  
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">    
                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteProjectFileModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    return (
        <div className="card custom-card shadow-none">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">
                    
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-primary btn-sm" onClick={() => openAddProjectFileModal()}>
                        <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add File
                    </button>
                </div>
            </div>
            <div className="card-body">

                <DataTable 
                    title={`Manage Files`} 
                    columns={ProjectFilesColumns} 
                    data={projectFiles}
                    pagination
                    paginationServer
                    paginationTotalRows={projectFilesTotalRows} 
                onChangeRowsPerPage={ProjectFilesLimitChange} 
                onChangePage={ProjectFilesPageChange} 
                progressPending={projectFilesLoading} 
                progressComponent={<ProjectFilesCustomLoader />}
                    fixedHeader 
                    fixedHeaderScrollHeight='550px'
                    highlightOnHover
                    theme={`${theme==='light' ? 'light' : 'dark'}`} />
            </div>


            {/* <!--Add Project File Modal Start--> */}
            <AddProjectFileModal
                showModal={showAddProjectFileModal}
                setShowModal={setShowAddProjectFileModal}
                project_unique_id={project_unique_id}
                projectData={projectData}
                modalKey={modalKey}
                fetchProjectFiles={fetchProjectFiles}
                limit={projectFilesLimit}
                page={projectFilesPage}
                task_unique_id={task_unique_id} />
            {/* <!--Add Project File Modal End--> */}


            {/* <!--Delete Project File Modal Start--> */}
            <DeleteProjectFileModal
                showModal={showDeleteProjectFileModal}
                setShowModal={setShowDeleteProjectFileModal}
                project_unique_id={project_unique_id}
                projectData={projectData}
                fileData={fileData}
                modalKey={modalKey}
                fetchProjectFiles={fetchProjectFiles}
                limit={projectFilesLimit}
                page={projectFilesPage}
                task_unique_id={task_unique_id} />
            {/* <!--Delete Project File Modal End--> */}


        </div> 
    );

};    

export default ProjectFilesTables;    