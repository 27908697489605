import {  GET, POST, PUT, UploadPost, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";



export const saveTimesheetsToStorage = (timesheets, pagination, timesheet_is_running) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        timesheets: timesheets,
        pagination: pagination,
        timesheet_is_running: timesheet_is_running,
        time:currentTime
    };

    localStorage.setItem("timesheets", encryptData(dataToStore));
} 


export const getTimesheets = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;


    if (page==1 && limit==10 && localStorage.getItem('timesheets')) {
        const saved_timesheets_obj = decryptData(localStorage.getItem('timesheets'));

        return saved_timesheets_obj;
    }
    else{
        try {
            const endPoint = '/timesheets/timesheet-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveTimesheetsToStorage(response.data.timesheets, response.data.pagination, response.data.timesheet_is_running);

                    const saved_timesheets_obj = decryptData(localStorage.getItem('timesheets'));

                    return saved_timesheets_obj;
                }
                else{
                    //localStorage.removeItem("timesheets");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};



export const saveActivitiesToStorage = (activities, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        activities: activities,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("activities", encryptData(dataToStore));
} 



export const getActivities = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 12;

    let _user_id = param._user_id ? param._user_id : '';


    if (page==1 && limit==12 && _user_id=='' && localStorage.getItem('activities')) {
        const saved_activities_obj = decryptData(localStorage.getItem('activities'));

        return saved_activities_obj;
    }
    else{
        try {
            const endPoint = '/timesheets/activity-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&_user_id=${_user_id}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==12 && _user_id=='') {
                    saveActivitiesToStorage(response.data.activities, response.data.pagination);

                    const saved_activities_obj = decryptData(localStorage.getItem('activities'));

                    return saved_activities_obj;
                }
                else{
                    //localStorage.removeItem("activities");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};