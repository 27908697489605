import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { invoiceAmount, invoiceStatus } from '../../helpers/InvoiceHelper';
import { Link } from 'react-router-dom';
import InvoiceList from '../skeletons/InvoiceList';
import { useFinancialYear } from '../../context/FinancialYearContext';


const InvoiceTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchInvoices,
    openDeleteInvoiceModal
}) => {

    const { financialYear } = useFinancialYear();

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
        <InvoiceList/>
    );

    const handlePageChange = async (page) => {
        fetchInvoices(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchInvoices(newLimit, page); 
        }
        else if (localStorage.getItem('invoices')) {
            fetchInvoices(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchInvoices(limit, page);  
    }, [financialYear]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Client",
            selector: (row) => row.client_name
        },
        {
            name: "Invoice ID",
            selector: (row) => <Link to={`/edit-invoice/${row.invoice_unique_id}`} style={{color: '#845adf'}}>{row.invoice_number}</Link> 
        },
        {
            name: "Issued Date",
            selector: (row) => dateFormat(row.invoice_bill_date, "dd mmm yyyy") 
        },
        {
            name: "Amount",
            selector: (row) => invoiceAmount(row.invoice_type, row.invoice_amount),
            width: '120px'
        },
        {
            name: "Status",
            selector: (row) => invoiceStatus(row.invoice_status),
            width: '100px'
        },
        {
            name: "Due Date",
            selector: (row) => dateFormat(row.invoice_due_date, "dd mmm yyyy")  
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`/edit-invoice/${row.invoice_unique_id}`} className="btn btn-icon btn-sm btn-info" title="Edit">
                                <i className='bx bxs-edit'></i>
                            </Link>
                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteInvoiceModal(row)}>
                                <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
      ];



    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Invoices`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />} 
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );

};    

export default InvoiceTables;    