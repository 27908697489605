import React, { useEffect, useState } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { apiError } from '../../../helpers/ErrorHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { getProjectOverView } from '../../../models/ProjectModel';
import { utcToLocal } from '../../../helpers/DateHelper';
import { profilePic } from '../../../helpers/GeneralHelper';
import { Line } from 'react-chartjs-2';
import { getTaskOverView } from '../../../models/TaskModel';

const TaskOverView = ({
    task_unique_id,
    callTaskOverView,
    taskData,
    setTaskData,
    taskOverViewLoading,
    setTaskOverViewLoading,
    setProjectUniqueId,
    setProjectData
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const loader = document.getElementById('loading');

    const options = {
        scales: {
          x: {
            type: 'category', // Define the x-axis as categorical
          },
        },
        maintainAspectRatio: false
    };


    const [taskActivities, setTaskActivities] = useState([]);

    const [taskTimesheets, setTaskTimesheets] = useState({
        labels: [],
        datasets: [
          {
            label: 'Work Time',
            data: [],
            backgroundColor: '#5f76e8',
            tension: 0.1,
          }
        ],
    });


    // Get Project Overview Start
    const fetchTaskOverView = async() => {

        if (callTaskOverView===true && Object.keys(taskData).length == 0) {
            setTaskOverViewLoading(true); 

            try {
                const response = await getTaskOverView(user_data.user_token, task_unique_id);

                if (response.status) {

                    if (response.data.task_data) {
                        //console.log(response.data.task_data);
                        setTaskData(response.data.task_data);

                        setProjectUniqueId(response.data.task_data.project_unique_id);
                    }

                    if (response.data.project_data) {
                        setProjectData(response.data.project_data);
                    }

                    if (response.data.activities) {
                        setTaskActivities(response.data.activities);
                    }

                    if (response.data.timesheets) {
                        setTaskTimesheets({
                            labels: response.data.timesheets.map(item => {
                                return `${utcToLocal(item.timesheet_date, "dd mmm yyyy")}`;
                            }),
                            datasets: [
                            {
                                label: 'Work Time',
                                data: response.data.timesheets.map(item => (parseInt(item.work_seconds))),
                                backgroundColor: '#5f76e8',
                                tension: 0.1,
                            }
                            ],
                        });
                    }
                    
                }
                else{
                    if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                        navigate(`/re-login?redirect=${currentRoute}`);
                    }

                    toast.error(response.message);
                }  
                
                setTaskOverViewLoading(false);    
                
            } catch (error) {
                //console.log(error);
            }
        }
    }

    useEffect(() => {  
        fetchTaskOverView();
    }, [callTaskOverView]);
    // Get Project Overview End



    return (
        <>
            
            <div className="row">
                <div className="col-xl-8">
                    <div className="card custom-card shadow">
                        <div className="card-header">
                            <div className="card-title">Work Hour Chart</div>
                        </div>
                        <div className="card-body" style={{height: '400px'}}>
                            <Line data={taskTimesheets} options={options}  />
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card custom-card shadow">
                        <div className="card-header justify-content-between">
                            <div className="card-title">
                                Timeline
                            </div>
                            <div>
                                <a className="btn btn-sm btn-primary-light btn-wave waves-effect waves-light">View All</a>
                            </div>
                        </div>
                        <div className="card-body"  style={{height: '400px'}}>
                            {taskOverViewLoading===true ? (
                                <div className='d-flex justify-content-center my-3'>
                                    <span class="loader-new"></span>
                                </div>
                            ) : taskActivities.length > 0 ? (
                            <ul className="list-unstyled timeline-widget mb-0 my-3">
                                {taskActivities.map((activity) => (
                                <li className="timeline-widget-list">
                                    
                                    <div className="d-flex align-items-top">
                                        <div className="me-5 text-center">
                                            <span className="d-block fs-20 fw-semibold">{utcToLocal(activity.activity_create_time, 'dd')}</span>
                                            <span className="d-block fs-12 text-muted">{utcToLocal(activity.activity_create_time, 'mmm')}</span>
                                        </div>

                                        <div className="lh-1">
                                            <span className="avatar avatar-md online me-3 avatar-rounded">
                                                {activity._user_id !== '' ? (
                                                    profilePic(activity.user_first_name, activity.user_last_name, activity.user_image)
                                                ) : (
                                                    <i className="ti ti-user-check fs-18"></i>
                                                )}
                                            </span>
                                        </div>

                                        <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between">
                                            <div>
                                                <p className="mb-1 text-truncate timeline-widget-content text-wrap">{activity.activity_title}</p>
                                                <p className="mb-0 fs-12 lh-1 text-muted">{utcToLocal(activity.activity_create_time, 'hh:MM TT')}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </li>
                                )) }  
                            </ul>
                            ) : 
                            <div className="p-5 empty-item1 d-nonea">
                                <div className="text-center">
                                    <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                        <i className="ri-notification-off-line fs-2"></i>
                                    </span>
                                    <h6 className="fw-semibold mt-3">No New Activies</h6>
                                </div>
                            </div>

                            } 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};    

export default TaskOverView;    