import { GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const getNotifications = async(user_token, param={}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    try {
        const endPoint = '/notifications';

        let url =  `${endPoint}?page=${page}&limit=${limit}`;
        
        if (param.is_read==0 || param.is_read==1) {
            url = `${endPoint}?page=${page}&limit=${limit}&is_read=${param.is_read}`;
        }
        

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}

export const updateReadNotification = async(user_token, _notification_id) => {
    const endPoint = `/notifications/read`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, {_notification_id});

    return response;
}