import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createClient = async(user_token, formData) => {

    const endPoint = "/clients/add-client";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editClient = async(user_token, clientId, formData) => {

    const endPoint = `/clients/update-client/${clientId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}  


export const removeClient = async(user_token, clientId) => {

    const endPoint = `/clients/delete-client/${clientId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}  


export const saveClientsToStorage = (clients, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        clients: clients,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("clients", encryptData(dataToStore));
} 


export const getClients = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('clients')) {
        const saved_clients_obj = decryptData(localStorage.getItem('clients'));

        return saved_clients_obj;
    }
    else{
        try {
            const endPoint = '/clients/client-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveClientsToStorage(response.data.clients, response.data.pagination);

                    const saved_clients_obj = decryptData(localStorage.getItem('clients'));

                    return saved_clients_obj;
                }
                else{
                    //localStorage.removeItem("clients");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};