import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { createProjectLocation, createProjectMember, getProjectNonMembers } from '../../../models/ProjectModel';

const AddProjectLocationModal = ({showModal, setShowModal, project_unique_id, projectData, modalKey, fetchProjectLocations, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);


    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [location_name, setLocationName] = useState('');
    const [location_point, setLocationPoint] = useState('');
    const [location_radius, setLocationRadius] = useState('');


    const [errors, setErrors] = useState({location_name: '', location_point: '', location_radius: ''});

    const closeModal = () =>{
        setLocationName('');
        setLocationPoint('');
        setLocationRadius('');

        setErrors({});
    
        setShowModal(false);
    }





    const addProjectLocation = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});

          const formData = {location_name, location_point, location_radius};

          const response = await createProjectLocation(user_data.user_token, project_unique_id, formData);

          if (response.status) {
            toast.success(response.message);

            fetchProjectLocations(limit, page);
    
            closeModal();

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add Location</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">

                    <div className="col-lg-12">
                        <label className="form-label">Location <span className="text-danger">*</span></label>
                        
                        

                        {errors.location_point && <p style={{ color: 'red' }}>{errors.location_point}</p>}
                    </div>

                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={addProjectLocation}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};

export default AddProjectLocationModal;    