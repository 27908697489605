import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { getProjects } from '../../../models/ProjectModel';
import dateFormat from "dateformat";
import { projectPriority } from '../../../helpers/ProjectHelper';
import { profilePic } from '../../../helpers/GeneralHelper';
import { tableCustomLoader } from '../../../helpers/StyleHelper';
import { getTasks } from '../../../models/TaskModel';
import { useTheme } from '../../../context/ThemeContext';
import TaskTables from '../../tables/TaskTables';
import AddTaskModal from '../../modals/Project/AddTaskModal';
import DeleteTaskModal from '../../modals/Project/DeleteTaskModal';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import ProjectTasksTables from '../../tables/ProjectDetails/ProjectTasksTables';


function Tasks() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme } = useTheme();  

  const { state } = useAuth(); 

  let user_data = state.user_data;
  
  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading'); 
  
  const [modalKey, setModalKey] = useState(0);

  //Add Note Start
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  //Add Note End

  //Flush Task Start
  const flushTask = async() => {
    localStorage.removeItem("tasks");
    fetchTasks(limit, page); 
  }  
  //Flush Task End


  //Delete Task Start
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

  const [taskData, setTaskData] = useState({});

  const openDeleteTaskModal = (task_data) =>{
    setTaskData(task_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteTaskModal(true);
  }
  //Delete Task End

  
  //Task List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);



  const fetchTasks = async(newLimit, page) => {
    setLoading(true);

    try {
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getTasks(apiParam);

        if (typeof response != typeof undefined) {
            setData(response.tasks);
            setTotalRows(response.pagination.total_rows);
            setLimit(newLimit);
            setPage(page);

            if (response.time) {
                setCacheTime(response.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            navigate('/re-login');
        }    
        

        setLoading(false);
    } catch (error) {
        //console.error(error);
    }

    
  }; 
  //Task List End
     
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Tasks - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Tasks</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Projects</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Tasks</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <ProjectTasksTables project_unique_id='' callProjectTasks={true}/> 
                        </div>


                    </div>
                    {/* <!--End::row-1 --> */}

                </div>


            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Tasks
