import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNote, saveNotesToStorage } from '../../../models/NoteModel';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createInvoice, saveInvoicesToStorage } from '../../../models/InvoiceModel';
import { getClients } from '../../../models/ClientModel';
import { getProjects } from '../../../models/ProjectModel';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const CreateInvoiceModal = ({showModal, setShowModal, fetchInvoices, limit, page, setShowAddClientModal, setShowAddProjectModal, _client_id}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    
    const [invoice_client_id, setInvoiceClientID]   = useState('');
    const [invoice_project_id, setInvoiceProjectID] = useState('');
    const [invoice_bill_date, setInvoiceBillDate]   = useState('');
    const [invoice_due_date, setInvoiceDueDate]     = useState('');

    const [errors, setErrors] = useState({invoice_client_id:  '',  invoice_project_id: '', invoice_bill_date: '', invoice_due_date: ''});

    const closeModal = () =>{
        setInvoiceClientID('');
        setInvoiceProjectID('');
        setInvoiceBillDate('');
        setInvoiceDueDate('');

        setErrors({});
    
        setShowModal(false);
    }

    const [clients, setClients] = useState([]);
    const fetchClients = async () => {
        try {
          const result = await getClients({user_token: user_data.user_token, page: 1, limit: 500});

          const clientOptions = result.clients.map(client => ({
            label: client.client_name,
            value: client._client_id
          }));

          console.log('_client_id: '+_client_id);

          const selectedClient = result.clients.find(client => client._client_id === _client_id);
          if (selectedClient) {
            setInvoiceClientID({ label: selectedClient.client_name, value: selectedClient._client_id });
          }

          // Append "Add Client" option to the clientOptions array
          //clientOptions.push({ label: "Add Client", value: "add-client" });

          setClients(clientOptions);

          

          setNotificationCount(localStorage.getItem('unread_notification_count'));
        } catch (error) {
          //console.error(error);
        }
    };

    const [projects, setProjects] = useState([]);
    const fetchProjects = async (_client_id) => {
        // Start the loading state
        loader.style.display = 'block';

        try {
          const result = await getProjects({user_token: user_data.user_token, page: 1, limit: 500, _client_id});

          const projectOptions = result.projects.map(project => ({
            label: project.project_title,
            value: project._project_id
          }));

          setProjects(projectOptions);

          setNotificationCount(localStorage.getItem('unread_notification_count'));
        } catch (error) {
          // Handle errors if necessary
          //console.error(error);
        }

        // Stop the loading state
        loader.style.display = 'none';
    };

    const clientChange = async(clientObj) => {
        setInvoiceClientID(clientObj);

        if (clientObj.value) {
            fetchProjects(clientObj.value);
        }

        
    }

    useEffect(() => {
        fetchClients();
    }, [showModal]);


    const addInvoice = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});
    
          const formData = { invoice_client_id: invoice_client_id.value ? invoice_client_id.value : '', 
          invoice_project_id: invoice_project_id.value ? invoice_project_id.value : '', 
          invoice_bill_date: invoice_bill_date ? dateFormat(invoice_bill_date, "dd-mm-yyyy") : '', 
          invoice_due_date: invoice_due_date ? dateFormat(invoice_due_date, "dd-mm-yyyy") : '' };
    
          const response = await createInvoice(user_data.user_token, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveInvoicesToStorage(response.data.invoices, response.data.pagination);
    
            fetchInvoices(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
            
        } catch (error) {
            apiError(error);

            //console.log(error);

            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }
    
    const openAddClientModal = ()=>{
        closeModal();
        setShowAddClientModal(true)
    }


    const openAddProjectModal = ()=>{
        closeModal();
        setShowAddProjectModal(true)
    }
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create Invoice</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-6">
                        <label className="form-label">Client <span className="text-danger">*</span></label>

                            <div className="d-flex gap-1">
                                <Select
                                className='form-control custom-select'
                                value={invoice_client_id}
                                onChange={clientChange}
                                options={clients}
                                placeholder="- Client -"
                                isSearchable="true"
                                noOptionsMessage={() => "No client found"}
                                styles={selectBoxTheme}
                                theme={(selectBoxTheme) => ({
                                ...selectBoxTheme,
                                borderRadius: 0,
                                })} />
                                <button className="btn btn-dark" style={{height: "38px"}} type="button" title='Add Client' onClick={() => openAddClientModal()}>
                                    <i className="bi bi-plus-circle align-middle"></i>
                                </button>
                            </div>    

                        
                        {errors.invoice_client_id && <p style={{ color: 'red' }}>{errors.invoice_client_id}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Project <span className="text-danger">*</span></label>
                            
                        <div className="d-flex gap-1">
                                
                            <Select
                                className='form-control custom-select'
                                value={invoice_project_id}
                                onChange={setInvoiceProjectID}
                                options={projects}
                                placeholder="- Project -"
                                isSearchable="true"
                                noOptionsMessage={() => "No project found"}
                                styles={selectBoxTheme}
                                theme={(selectBoxTheme) => ({
                                ...selectBoxTheme,
                                borderRadius: 0,
                                })} />

                                <button className={`btn btn-dark ${!invoice_client_id ? 'd-none' : ''}`} style={{height: "38px"}} type="button" title='Add Project' onClick={() => openAddProjectModal()}>
                                    <i className="bi bi-plus-circle align-middle"></i>
                                </button>    

                        </div>    

                        
                        {errors.invoice_project_id && <p style={{ color: 'red' }}>{errors.invoice_project_id}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Bill Date <span className="text-danger">*</span></label><br/>

                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" minDate={new Date} maxDate={invoice_due_date}
                            selected={invoice_bill_date} onSelect={setInvoiceBillDate} onChange={setInvoiceBillDate} dateFormat="dd-MM-yyyy"/>

                        {errors.invoice_bill_date && <p style={{ color: 'red' }}>{errors.invoice_bill_date}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Due Date <span className="text-danger">*</span></label><br/>

                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" minDate={invoice_bill_date ? invoice_bill_date : new Date} disabled={!invoice_bill_date} 
                            selected={invoice_due_date} onSelect={setInvoiceDueDate} onChange={setInvoiceDueDate} dateFormat="dd-MM-yyyy"/>

                        {errors.invoice_due_date && <p style={{ color: 'red' }}>{errors.invoice_due_date}</p>}
                    </div>
                                            
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={addInvoice}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal> 
    ); 

};

export default CreateInvoiceModal;