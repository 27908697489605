import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createInvoice = async(user_token, formData) => {

    const endPoint = "/invoices/add-invoice";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const sendInvoice = async(user_token, invoice_unique_id, formData) => {

    const endPoint = `/invoices/send-invoice-email/${invoice_unique_id}`;
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const createInvoiceItem = async(user_token, invoice_unique_id, formData) => {

    const endPoint = `/invoices/add-item/${invoice_unique_id}`;
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const editInvoice = async(user_token, invoiceId, formData) => {

    const endPoint = `/invoices/update-invoice/${invoiceId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 


export const editInvoiceItem = async(user_token, invoiceId, itemId, formData) => {

    const endPoint = `/invoices/update-item/${invoiceId}/${itemId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 


export const editInvoiceShippingAddress = async(user_token, invoiceId, formData) => {

    const endPoint = `/invoices/update-shipping-address/${invoiceId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 


export const removeInvoice = async(user_token, invoiceId) => {

    const endPoint = `/invoices/delete-invoice/${invoiceId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}


export const removeInvoiceItem = async(user_token, invoiceId, itemId) => {

    const endPoint = `/invoices/delete-item/${invoiceId}/${itemId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const removeInvoiceShippingAddress = async(user_token, invoiceId) => {

    const endPoint = `/invoices/delete-shipping-address/${invoiceId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const saveInvoicesToStorage = (invoices, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        invoices: invoices,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("invoices", encryptData(dataToStore));
}


export const getInvoices = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('invoices')) {
        const saved_invoices_obj = decryptData(localStorage.getItem('invoices'));

        return saved_invoices_obj;
    }
    else{
        try {
            const endPoint = '/invoices/invoice-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveInvoicesToStorage(response.data.invoices, response.data.pagination);

                    const saved_invoices_obj = decryptData(localStorage.getItem('invoices'));

                    return saved_invoices_obj;
                }
                else{
                    //localStorage.removeItem("invoices");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};


export const getInvoiceDetails = async(param = {}) => {

    let user_token        = param.user_token;
    let invoice_unique_id = param.invoice_unique_id;

    try {
        const endPoint = `/invoices/invoice-details/${invoice_unique_id}`;
        const url = `${endPoint}`;
    
        setAuthToken(user_token);
        
        const response = await GET(url);

        if (response.status===false) {
            toast.error(response.message);
        }    

        return response;
        
    } catch (error) {
        apiError(error);
    }
}



export const getInvoiceStats = async(user_token) => {

    const endPoint = `/invoices/invoice-stats`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}


export const getInvoiceMonthWiseChart = async(user_token) => {

    const endPoint = `/invoices/month-wise-chart`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}