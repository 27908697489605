import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { profilePic } from '../../helpers/GeneralHelper';
import { formatMonthYear, secondsToTime } from '../../helpers/DateHelper';
import LeaveReportList from '../skeletons/LeaveReportList';

const LeaveReportTables = ({
    data,
    setData,
    loading,
    setLoading,
    fetchLeaveReport,
    _user_id
}) => {

    const [months, setMonths] = useState([]);

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
        <LeaveReportList/>
    );

    useEffect(() => {
        const monthsData = data.length > 0 ? data[0].months.map(month => month.month) : [];
        setMonths(monthsData);
    }, [data]);

    
    useEffect(()=>{
        fetchLeaveReport();  
    }, [_user_id]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.user_first_name, row.user_last_name, row.user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.user_first_name} {row.user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Leave Allocated",
            selector: (row) => row.lb_leave_allocated,
            width: '130px'
        },
        ...months.map(month => ({
            name: formatMonthYear(month), // You can format the month here if needed
            selector: row => {
              const monthData = row.months.find(m => m.month === month);
              return monthData ? monthData.value : ''; // Return the value if available, otherwise empty string
            }
          }))
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            columns={columns} 
            data={data} 
            progressPending={loading} 
            progressComponent={<CustomLoader />} 
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default LeaveReportTables;