import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { apiError } from '../../../helpers/ErrorHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { getUsers } from '../../../models/UserModel';
import { getProjects } from '../../../models/ProjectModel';
import { editTask, saveTasksToStorage } from '../../../models/TaskModel';
import { checkPermission } from '../../../helpers/GeneralHelper';


const TaskDetailsForm = ({
    task_unique_id,
    callTaskDetails,
    taskData,
    setTaskData,
    taskLoading, 
    setTaskLoading
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const editor = useReducer(null);

    const loader = document.getElementById('loading');

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300,
            placeholder: 'Enter description...'
        }),
        []
    );


    // Get Task Details Start
    const setTaskForm = () => {
        if (Object.keys(taskData).length > 0) {
            setTaskTitle(taskData.task_title);
            setProjectTitle(taskData.project_title);
            setTaskDescription(taskData.task_description);

            setTaskStartDate(new Date(taskData.task_start_date));
            setTaskDeadline(new Date(taskData.task_deadline));

            setTaskAssigned({'label': taskData._assign_user_first_name+' '+taskData._assign_user_last_name, 'value': taskData._assign_user_id});
        }
    }  

    useEffect(()=>{
        if (taskLoading===false) {
            setTaskForm(); 
        }
         
    }, [task_unique_id, taskData, taskLoading]);
    // Get Task Details End


    
    const [task_title, setTaskTitle] = useState('');
    const [project_title, setProjectTitle] = useState('');
    const [task_description, setTaskDescription] = useState('');
    const [task_start_date, setTaskStartDate] = useState('');
    const [task_deadline, setTaskDeadline] = useState('');
    const [task_assigned, setTaskAssigned] = useState('');
    const [task_collaborators, setTaskCollaborators] = useState([]);

    

    const [errors, setErrors] = useState({task_title: '', task_description: '', task_project_id: '', task_start_date: '', task_deadline: '',
    task_assigned: '', task_collaborators: ''});



    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page:1, limit:500});

          const userOptions = result.users.map(user => ({
            label: user.user_first_name+' '+user.user_last_name,
            value: user._user_id
          }));
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setUsers(userOptions);
        } catch (error) {
          //console.error(error);
        }
    };

    useEffect(() => {
        if (callTaskDetails) {
            fetchUsers();
        }
        
    }, [callTaskDetails]); 


    // Update Task Start
    const updateTask = async() => {
        // Start the loading state
        loader.style.display = 'block';

        try {
            // Clear previous errors
            setErrors({});

            const selected_collaborators_ids = task_collaborators.map(_user_id => _user_id.value).join(',');

            const formData = {task_title, task_description,
            task_start_date: task_start_date ? dateFormat(task_start_date, "dd-mm-yyyy") : '', 
            task_deadline: task_deadline ? dateFormat(task_deadline, "dd-mm-yyyy") : '',
            task_assigned: task_assigned.value ? task_assigned.value : '', task_collaborators: selected_collaborators_ids  };
  
            const response = await editTask(user_data.user_token, task_unique_id, formData);

          if (response.status) {
            toast.success(response.message);

            setTaskData(response.data.task_data);
    
            saveTasksToStorage(response.data.tasks, response.data.pagination);
    
            setNotificationCount(localStorage.getItem('unread_notification_count'));

          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);

           // console.log(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }


        // Stop the loading state
        loader.style.display = 'none';

    }    
    // Update Task End

    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card custom-card shadow-none">
                    <div className="card-header">
                        <div className="card-title">
                            Task Details
                        </div>

                        <div className="card-body">
                            <div className="row gy-2">
                                <div className="col-xl-6">
                                    <label className="form-label">Task Name <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Task Name" 
                                    value={task_title} onChange={(e) => setTaskTitle(e.target.value)} />

                                    {errors.task_title && <p style={{ color: 'red' }}>{errors.task_title}</p>}
                                </div>

                                <div className="col-xl-6">
                                    <label className="form-label">Project <span className="text-danger">*</span></label>
                                    <input type='text' className="form-control" value={project_title} disabled={true}/>
                                </div>

                                <div className="col-xl-12">
                                    <label className="form-label">Description</label>

                                    <JoditEditor
                                        ref={editor}
                                        value={task_description}
                                        config={config}
                                        onChange={newContent => setTaskDescription(newContent)}/>

                                    {errors.task_description && <p style={{ color: 'red' }}>{errors.task_description}</p>}
                                </div>

                                <div className="col-xl-4">
                                    <label className="form-label">Start Date <span className="text-danger">*</span></label><br/>
                                    <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                                        selected={task_start_date} onSelect={setTaskStartDate} onChange={setTaskStartDate} dateFormat="dd-MM-yyyy"/>

                                    {errors.task_start_date && <p style={{ color: 'red' }}>{errors.task_start_date}</p>}
                                </div>

                                <div className="col-xl-4">
                                    <label className="form-label">Deadline <span className="text-danger">*</span></label><br/>
                                    <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                                        selected={task_deadline} onSelect={setTaskDeadline} onChange={setTaskDeadline} dateFormat="dd-MM-yyyy"/>

                                    {errors.task_deadline && <p style={{ color: 'red' }}>{errors.task_deadline}</p>}
                                </div>

                                <div className="col-xl-4">
                                    <label className="form-label">Assigned To <span className="text-danger">*</span></label>
                                    <Select
                                        value={task_assigned}
                                        onChange={setTaskAssigned}
                                        options={users}
                                        placeholder="- Member -"
                                        isSearchable="true"
                                        noOptionsMessage={() => "No member found"}
                                        styles={{control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            borderColor: state.isFocused ? 'grey' : '#ccc',
                                            backgroundColor: theme == "light" ? '#fff' : '#333',
                                            color: theme == "light" ? '#333' : '#fff',
                                          }),
                                          selectBoxTheme}}
                                        theme={(selectBoxTheme) => ({
                                        ...selectBoxTheme,
                                        borderRadius: 0,
                                        })} />

                                    {errors.task_assigned && <p style={{ color: 'red' }}>{errors.task_assigned}</p>}
                                </div>

                                <div className="col-xl-6 d-none">
                                    <label className="form-label">Collaborators <span className="text-danger">*</span></label>
                                    <Select
                                        className="form-control"
                                        value={task_collaborators}
                                        onChange={setTaskCollaborators}
                                        options={users}
                                        placeholder="- Member -"
                                        isSearchable="true"
                                        isMulti="true"
                                        noOptionsMessage={() => "No member found"} />

                                    {errors.task_collaborators && <p style={{ color: 'red' }}>{errors.task_collaborators}</p>}
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <button className={`btn btn-primary-light btn-wave ms-auto float-end ${checkPermission('project_edit')===false ? 'd-none' : ''}`} onClick={updateTask}>Update</button>
                        </div> 
                    </div>
                </div>
            </div>

            
        </div>
    );

};    

export default TaskDetailsForm;    