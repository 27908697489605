import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createPayment = async(user_token, invoice_unique_id, formData) => {

    const endPoint = `/payments/add-payment/${invoice_unique_id}`;
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editPayment = async(user_token, _payment_id, formData) => {

    const endPoint = `/payments/update-payment/${_payment_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}  


export const removePayment = async(user_token, _payment_id) => {

    const endPoint = `/payments/delete-payment/${_payment_id}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 



export const getPayments = async(invoice_unique_id='', param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    try {
        const endPoint = '/payments/payment-list';
        const url = `${endPoint}/${invoice_unique_id}?page=${page}&limit=${limit}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;   
        
    } catch (error) {
        apiError(error);
    }
};