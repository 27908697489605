import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { checkPermission, profilePic } from '../../../helpers/GeneralHelper';
import { formatMonthYear, utcToLocal } from '../../../helpers/DateHelper';
import { getUsers } from '../../../models/UserModel';
import Select from 'react-select';
import { Line } from 'react-chartjs-2';
import { getPerformanceGraphs } from '../../../models/ReportModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';

function PerformanceGraphs() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const currentRoute =  useLocation().pathname; 

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);


  //Fetch Users Start
  const [_user_id, setUserId] = useState('');
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

      const userOptions = result.users.map(user => ({
        label: user.user_first_name+' '+user.user_last_name,
        value: user._user_id
      }));

      setNotificationCount(localStorage.getItem('unread_notification_count'));

      setUsers(userOptions);
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
        fetchUsers();
  }, []); 
  //Fetch Users End


  const options = {
    scales: {
      x: {
        type: 'category', // Define the x-axis as categorical
      },
    },
    maintainAspectRatio: false
  };

  //Fetch Login Times & Work Times Start
  const [loginTimes, setLoginTimes] = useState({
    labels: [],
    datasets: [
      {
        label: 'Login Time',
        data: [],
        borderColor: '#5f76e8',
        tension: 0.1,
      },
    ],
  });

  const [workTimes, setWorkTimes] = useState({
    labels: [],
    datasets: [
      {
        label: 'Work Time',
        data: [],
        borderColor: '#01caf1',
        tension: 0.1,
      },
    ],
  });

  const fetchPerformanceGraphs = async() => {
    try {
        const selected_user_id = _user_id.value ? _user_id.value : '';

        const response = await getPerformanceGraphs(user_data.user_token, selected_user_id);

        if (response.status===true) {

            setNotificationCount(localStorage.getItem('unread_notification_count'));

            if (response.data.login_time) {
                const chartData = {
                    labels: response.data.login_time.map(item => {
                        // Format the date string into desired format
                        return `${formatMonthYear(item.month)}`;
                    }),
                    datasets: [
                        {
                            label: 'Login Time',
                            data: response.data.login_time.map(item => (parseInt(item.value)/3600)),
                            backgroundColor: '#5f76e8',
                            tension: 0.1,
                        }
                    ]
                };
                setLoginTimes(chartData);
            } 
            
            if (response.data.work_time) {
                const chartData = {
                    labels: response.data.work_time.map(item => {
                        // Format the date string into desired format
                        return `${formatMonthYear(item.month)}`;
                    }),
                    datasets: [
                        {
                            label: 'Work Time',
                            data: response.data.work_time.map(item => (parseInt(item.value)/3600)),
                            backgroundColor: '#01caf1',
                            tension: 0.1,
                        }
                    ]
                };
                setWorkTimes(chartData);
            } 

            

        } 
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
    
            toast.error(response.message);
        }   

    } catch (error) {
        //console.error(error);
    }
  }
  //Fetch Login Times & Work Times End

  useEffect(() => {
    if (checkPermission('reports_performance_graphs')===true) {
        fetchPerformanceGraphs();
    }
    
  }, [_user_id]);

  return (
    <>
        {checkPermission('reports_performance_graphs')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Performance Graphs - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Performance Graphs</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Performance Graphs</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className={`row ${user_data.urole_permission!='*' ? 'd-none' : ''}`}>
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body p-3">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                        <div className="card-title">
                                            <div className='row'>
                                                <div className={`col-xl-12 ${user_data.urole_permission!='*' ? 'd-none' : ''}`}>
                                                    
                                                    <div className="input-group">
                                                        <Select
                                                            value={_user_id}
                                                            onChange={setUserId}
                                                            options={users}
                                                            placeholder="- Select Member -"
                                                            isSearchable="true"
                                                            noOptionsMessage={() => "No member found"}
                                                            styles={selectBoxTheme}
                                                            theme={(selectBoxTheme) => ({
                                                            ...selectBoxTheme,
                                                            borderRadius: 0
                                                            })} /> 
                                                        <button className="btn btn-light" type="button" onClick={() => setUserId('')}
                                                            style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: _user_id !== '' ? 'block' : 'none'}}>
                                                            x
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}



                    {/* <!-- Start::row-2 --> */}
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Login Time
                                    </div>
                                </div>
                                <div className="card-body p-3" style={{height: '250px'}}>
                                    <Line data={loginTimes} options={options}  />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-2 --> */}



                    {/* <!-- Start::row-3 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Work Time
                                    </div>
                                </div>
                                <div className="card-body p-3" style={{height: '250px'}}>
                                    <Line data={workTimes} options={options}  />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-3 --> */}

                    


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default PerformanceGraphs
