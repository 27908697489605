import React, { useEffect } from 'react';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { getNotes } from '../../models/NoteModel';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { profilePic } from '../../helpers/GeneralHelper';
import LeaveBalanceList from '../skeletons/LeaveBalanceList';
import { useFinancialYear } from '../../context/FinancialYearContext';


const LeaveBalanceTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchBalances,
    user_unique_id,
    openViewBalanceModal
}) => {

    const { financialYear } = useFinancialYear();

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;

    const CustomLoader = () => (
        <LeaveBalanceList/>
    );

    const handlePageChange = async (page) => {
        fetchBalances(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchBalances(newLimit, page); 
        }
        else if (localStorage.getItem('leave_balances')) {
            fetchBalances(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchBalances(limit, page);  
    }, [user_unique_id, financialYear]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.leave_user_first_name, row.leave_user_last_name, row.leave_user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.leave_user_first_name} {row.leave_user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.leave_user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Joining Date",
            selector: (row) =>  dateFormat(row.leave_user_date_of_joining, "dd mmm yyyy") 
        },
        {
            name: "Allocated",
            selector: (row) => row.lb_leave_allocated ? row.lb_leave_allocated : 0 
        },

        {
            name: "Balance",
            selector: (row) => row.lb_leave_balance ? row.lb_leave_balance : 0
        },
    
        {
            name: "Taken",
            selector: (row) => row.lb_leave_allocated-row.lb_leave_balance
        },

        {
            name: "Pending",
            selector: (row) => row.pending_applications 
        },
        
        {
            name: "Allocated On",
            selector: (row) => dateFormat(row.lb_create_time, "dd mmm yyyy HH:MM")  
        },
    
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit" onClick={() => openViewBalanceModal(row)}>
                                <i className='bx bxs-edit'></i>
                            </button>
                        </div>
        }
    ];
    
    // Conditionally unset a specific column
    const filteredColumns = user_data.urole_permission!=='*'
    ? columns.filter(column => !["Team Member", "Action"].includes(column.name) )
    : columns;


    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Leave Balances`} 
            columns={filteredColumns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );


};    

export default LeaveBalanceTables;    