import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import AdminLayout from '../layout/AdminLayout'
import { Helmet } from 'react-helmet'
import { useNotificationCount } from '../../context/NotificationCountContext';
import { useAuth } from '../../context/AuthContext';
import { getNotifications, updateReadNotification } from '../../models/NotificationModel';
import { toast } from 'react-toastify';
import { profilePic } from '../../helpers/GeneralHelper';
import { timeAgo, utcToLocal } from '../../helpers/DateHelper';

const Notifications = () => {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const currentRoute = useLocation().pathname; 

  const navigate = useNavigate(); 

  const { state } = useAuth(); 

  let user_data = state.user_data;

  //Fetch Notifications Start
  const [notificationLoading, setNotificationLoading] = useState(true);
  const [notifications, setNotifications] = useState([]); 
  const [page, setPage] = useState(1); // State to track the current page
  const [limit, setLimit] = useState(10); 

  const [nextPage, setNextPage] = useState(2); 
  const [totalPages, setTotalPages] = useState(-1); 

  const fetchNotifications = async() => {
    if (page==totalPages) {
        //console.log('page: '+page);
        //console.log('totalPages: '+totalPages);
        return;
    }

    try {
        let apiParam = {page, limit};

        const response = await getNotifications(user_data.user_token, apiParam);

        if (response.status===true) {

            if (response.data.notifications) {
                const newNotifications = response.data.notifications || [];

                if (page > 1) {
                    setNotifications((prevNotifications) => [...prevNotifications, ...newNotifications]);
                } else {
                    setNotifications(newNotifications);
                }
            }

            if (response.data.pagination) {
                setNextPage(response.data.pagination.next_page);

                setTotalPages(response.data.pagination.total_pages);
            }    

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
    
            toast.error(response.message);
        }
        
        setNotificationLoading(false);
        
    } catch (error) {
        //console.log(error);
    }
  }

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
      setPage((prevPage) => prevPage + 1); // Increment the page number when reaching the bottom
    }
  };

  useEffect(()=>{
    fetchNotifications(); 
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);
  //Fetch Notifications End

  //Read Notification Start
  const readNotification = async(notificationObj) => {
    try {
        const response = await updateReadNotification(user_data.user_token, notificationObj._notification_id);

        if (response.status===true) {

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }
    
            toast.error(response.message);
        }
        
    } catch (error) {
        //console.log(error);
    }
  }
  //Read Notification End

  return (
    <>
    <AdminLayout>
        
        <Helmet>
            <title>Notifications - Worktracker Anciletech</title>
            
        </Helmet>
        <div className="main-content app-content">
            <div className="container-fluid">
            <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Notifications</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Notifications</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        {notificationLoading===true ? (
                            <div className='d-flex justify-content-center my-3'>
                                <span class="loader-new"></span>
                            </div>
                        ) : notifications ? (
                            <ul className="list-unstyled mb-0 notification-container">
                                {notifications.map((notification) => (
                                    <li>
                                        <div className="card custom-card un-read">
                                            <div className="card-body p-3">
                                                <a href="javascript:void(0);">
                                                    <div className="d-flex align-items-top mt-0 flex-wrap">
                                                        <div className="lh-1">
                                                            <span className="avatar avatar-md online me-3 avatar-rounded">
                                                                {notification.from_user_id !== '' ? (
                                                                    profilePic(notification.from_first_name, notification.from_last_name, notification.from_user_image)
                                                                ) : (
                                                                    <i className="ti ti-user-check fs-18"></i>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="flex-fill" onClick={() => readNotification(notification)}>
                                                            <div className="d-flex align-items-center">
                                                                <div className="mt-sm-0 mt-2">
                                                                    <p className="mb-0 fs-14 fw-semibold">{notification.from_first_name} {notification.from_last_name}</p>
                                                                    <p className="mb-0 text-muted">{notification.notification_title}</p>
                                                                    <span className="mb-0 d-block text-muted fs-12">{timeAgo(notification.notification_create_time)}</span>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span className="float-end badge bg-light text-muted">
                                                                        {utcToLocal(notification.notification_create_time, 'd mmm yyyy')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>    
                                            </div>
                                        </div>
                                    </li>
                                )) }
                            </ul>
                            ) : 

                            <div className="p-5 empty-item1 d-nonea">
                                <div className="text-center">
                                    <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                        <i className="ri-notification-off-line fs-2"></i>
                                    </span>
                                    <h6 className="fw-semibold mt-3">No New Notifications</h6>
                                </div>
                            </div>

                        }    
                        <div className={`text-center ${page==totalPages ? 'd-none' : ''}`}>
                                <button className="btn btn-info-transparent btn-loader my-3 mx-auto">
                                    <span className="me-2">Loading</span>
                                    <span className="loading"><i className="ri-loader-4-line fs-16"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Helmet>
                
        </Helmet>

    </AdminLayout>
    </>
  )
}

export default Notifications