import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { getProjects } from '../../../models/ProjectModel';
import { getUsers } from '../../../models/UserModel';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";
import { createTask, saveTasksToStorage } from '../../../models/TaskModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const AddTaskModal = ({showModal, setShowModal, project_unique_id, modalKey, fetchTasks, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300,
            placeholder: 'Enter description...'
        }),
        []
    );

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [task_title, setTaskTitle] = useState('');
    const [task_description, setTaskDescription] = useState('');
    const [task_project_id, setTaskProjectID] = useState('');
    const [task_start_date, setTaskStartDate] = useState('');
    const [task_deadline, setTaskDeadline] = useState('');
    const [task_assigned, setTaskAssigned] = useState('');
    const [task_collaborators, setTaskCollaborators] = useState([]);

    const [errors, setErrors] = useState({task_title: '', task_description: '', task_project_id: '', task_start_date: '', task_deadline: '',
    task_assigned: '', task_collaborators: ''});

    const closeModal = () =>{
        setTaskTitle('');
        setTaskDescription('');
        setTaskProjectID('');
        setTaskStartDate('');
        setTaskDeadline('');
        setTaskAssigned('');
        setTaskCollaborators([]);

        setErrors({});
    
        setShowModal(false);
    }


    const [projects, setProjects] = useState([]);
    const fetchProjects = async () => {
        try {
          const result = await getProjects({user_token: user_data.user_token, page:1, limit:500});

          const projectOptions = result.projects.map(project => ({
            label: project.project_title,
            value: project._project_id
          }));

          result.projects.forEach(project => {
            if (project.project_unique_id === project_unique_id) {
                setTaskProjectID({
                    label: project.project_title,
                    value: project._project_id
                });
            }
          });

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setProjects(projectOptions);
        } catch (error) {
          //console.error(error);
        }
    };

    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page:1, limit:500});

          const userOptions = result.users.map(user => ({
            label: user.user_first_name+' '+user.user_last_name,
            value: user._user_id
          }));
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setUsers(userOptions);
        } catch (error) {
          //console.error(error);
        }
    };


    useEffect(() => {
        if (showModal) {
            fetchProjects();
            fetchUsers();
        }
        
    }, [modalKey, showModal]); 

    const addTask = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});

          const selected_collaborators_ids = task_collaborators.map(_user_id => _user_id.value).join(',');

          const formData = {task_title, task_description, task_project_id: task_project_id.value ? task_project_id.value : '',
          task_start_date: task_start_date ? dateFormat(task_start_date, "dd-mm-yyyy") : '', 
          task_deadline: task_deadline ? dateFormat(task_deadline, "dd-mm-yyyy") : '',
          task_assigned: task_assigned.value ? task_assigned.value : '', task_collaborators: selected_collaborators_ids  };

          const response = await createTask(user_data.user_token, formData);

          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveTasksToStorage(response.data.tasks, response.data.pagination);
    
            fetchTasks(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }


        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add Task</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-6">
                        <label className="form-label">Task Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Task Name" 
                        value={task_title} onChange={(e) => setTaskTitle(e.target.value)} />

                        {errors.task_title && <p style={{ color: 'red' }}>{errors.task_title}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Project <span className="text-danger">*</span></label>
                        <Select
                            value={task_project_id}
                            onChange={setTaskProjectID}
                            options={projects}
                            placeholder="- Project -"
                            isSearchable="true"
                            noOptionsMessage={() => "No project found"} />

                        {errors.task_project_id && <p style={{ color: 'red' }}>{errors.task_project_id}</p>}
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Description</label>

                        <JoditEditor
                            ref={editor}
                            value={task_description}
                            config={config}
                            onChange={newContent => setTaskDescription(newContent)}/>

                        {errors.task_description && <p style={{ color: 'red' }}>{errors.task_description}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Start Date <span className="text-danger">*</span></label><br/>
                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                            selected={task_start_date} onSelect={setTaskStartDate} onChange={setTaskStartDate} dateFormat="dd-MM-yyyy"/>

                        {errors.task_start_date && <p style={{ color: 'red' }}>{errors.task_start_date}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Deadline <span className="text-danger">*</span></label><br/>
                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                            selected={task_deadline} onSelect={setTaskDeadline} onChange={setTaskDeadline} dateFormat="dd-MM-yyyy"/>

                        {errors.task_deadline && <p style={{ color: 'red' }}>{errors.task_deadline}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Assigned To <span className="text-danger">*</span></label>
                        <Select
                            value={task_assigned}
                            onChange={setTaskAssigned}
                            options={users}
                            placeholder="- Member -"
                            isSearchable="true"
                            noOptionsMessage={() => "No member found"} />

                        {errors.task_assigned && <p style={{ color: 'red' }}>{errors.task_assigned}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Collaborators <span className="text-danger">*</span></label>
                        <Select
                            value={task_collaborators}
                            onChange={setTaskCollaborators}
                            options={users}
                            placeholder="- Member -"
                            isSearchable="true"
                            isMulti="true"
                            noOptionsMessage={() => "No member found"} />

                        {errors.task_collaborators && <p style={{ color: 'red' }}>{errors.task_collaborators}</p>}
                    </div>
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={addTask}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};

export default AddTaskModal;    