import React, { useEffect,  useRef,  useState }  from 'react'

function InvoiceDetails() { 

    return (

        <>
            <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card placeholder-glow">
                                <div className="card-header d-md-flex d-block">
                                    <div className="h5 mb-0 d-sm-flex d-block align-items-center">
                                        
                                        <div className="ms-sm-2 ms-0 mt-sm-0 mt-2 col-5">
                                            <input type="text" className="form-control form-control-light form-control-sm placeholder col-12" disabled="true" /> 
                                        </div> 
                                        <div className="ms-sm-2 ms-0 mt-sm-0 mt-2 col-auto">
                                            <input type="text" className="form-control form-control-light form-control-sm placeholder col-12" disabled="true" /> 
                                        </div> 
                                        <div className="ms-sm-2 ms-0 mt-sm-0 mt-2 col-auto">
                                            <input type="text" className="form-control form-control-light form-control-sm placeholder col-12" disabled="true" /> 
                                        </div> 
                                        
                                    </div>
                                    <div className="ms-auto mt-md-0 mt-2 col-3 justify-content-end d-flex">
                                        <button className="btn btn-sm btn-primary me-2 placeholder col-4"></button>
                                        <button className="btn btn-sm btn-icon btn-secondary-light me-2 placeholder col-2"></button>
                                        <button className="btn btn-sm btn-icon btn-success-light me-2 placeholder col-2"></button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row gy-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <p className="dw-semibold mb-2 placeholder col-2">
                                                        
                                                    </p>
                                                    <div className="row gy-2">
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <textarea className="form-control form-control-light placeholder col-2" rows="3"></textarea>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <textarea className="form-control form-control-light placeholder col-2" rows="4"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <p className="dw-semibold mb-2">
                                                        <a href="" className='text-primary placeholder col-2'></a>
                                                    </p>
                                                    <div className="row gy-2">
                                                        <div className="col-xl-12">
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <textarea className="form-control form-control-light placeholder col-2" rows="3"></textarea>
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </div>
                                                            <div className="col-xl-12">
                                                                <textarea className="form-control form-control-light placeholder col-2" rows="4"></textarea>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 ms-auto mt-sm-0 mt-3">
                                                    <p className="dw-semibold mb-2 placeholder col-2">
                                                        
                                                    </p>
                                                    <div className="row gy-2">
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-2" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <textarea className="form-control form-control-light placeholder col-2" rows="3"></textarea>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-2" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-2" />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-2"/>
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <input type="text" className="form-control form-control-light placeholder col-2" />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3"> 
                                            <label htmlFor="invoice-number" className="form-label placeholder col-2"></label>
                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="invoice-date-issued" className="form-label placeholder col-2"></label>
                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="invoice-date-due" className="form-label placeholder col-2"></label>
                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                        </div>
                                        <div className="col-xl-3"> 
                                            <label htmlFor="invoice-due-amount" className="form-label placeholder col-2"></label>
                                            <input type="text" className="form-control form-control-light placeholder col-12" />
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="table-responsive"> 
                                                <table className="table nowrap text-nowrap border mt-3"> 
                                                    <thead>
                                                        <tr>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                            <td>
                                                                <label className="form-label placeholder col-4"></label>
                                                            </td>
                                                        </tr> 
                                                    </thead> 
                                                    <tbody>
                                                        <tr> 
                                                            <td>
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td className="invoice-quantity-container">
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td><input type="text" className="form-control form-control-light placeholder col-12" /></td>
                                                            <td><input type="text" className="form-control form-control-light placeholder col-12" /></td>
                                                            <td>
                                                                <button className="btn btn-sm btn-icon btn-danger-light placeholder col-12"></button>
                                                            </td>
                                                        </tr> 
                                                        <tr> 
                                                            <td>
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td className="invoice-quantity-container">
                                                                <input type="text" className="form-control form-control-light placeholder col-12" />
                                                            </td>
                                                            <td><input type="text" className="form-control form-control-light placeholder col-12" /></td>
                                                            <td><input type="text" className="form-control form-control-light placeholder col-12" /></td>
                                                            <td>
                                                                <button className="btn btn-sm btn-icon btn-danger-light placeholder col-12"></button>
                                                            </td>
                                                        </tr> 
                                                        <tr>
                                                            <td colspan="6" className="border-bottom-0 placeholder col-12"><a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4"></td>
                                                            <td colspan="2">
                                                                <table className="table table-sm text-nowrap mb-0 table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className='col-6'>
                                                                                <label className="form-label placeholder col-6"></label>
                                                                            </td>
                                                                            <td className='col-6'>
                                                                                <a className="btn btn-light px-5 py-3" href="javascript:void(0);"></a>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div>
                                                <label htmlFor="invoice-note" className="form-label placeholder col-1"></label>
                                                <textarea className="form-control form-control-light placeholder col-12" rows="3"></textarea>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button className="btn btn-light me-1 placeholder col-1"></button>
                                    <button className="btn btn-primary placeholder col-1"></button>
                                </div>
                            </div>
                        </div>
                        

                    </div>
        </>
    );    

}    

export default InvoiceDetails