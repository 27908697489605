import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import FullCalendar from '@fullcalendar/react'; // include this line
import dayGridPlugin from '@fullcalendar/daygrid';
import { getEvents } from '../../models/EventModel';
import { useTheme } from '../../context/ThemeContext';
import { useAuth } from '../../context/AuthContext';
import dateFormat from "dateformat";
import AddEventModal from '../modals/Events/AddEventModal';
import UpdateEventModal from '../modals/Events/UpdateEventModal';
import DeleteEventModal from '../modals/Events/DeleteEventModal';
import EventCalendar from '../skeletons/EventCalendar';
import { useFinancialYear } from '../../context/FinancialYearContext';
import { decryptData } from '../../helpers/EncryptHelper';
import { useNotificationCount } from '../../context/NotificationCountContext';
import { checkPermission } from '../../helpers/GeneralHelper';

function Events() {

  const { financialYear } = useFinancialYear();

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);


  //Add Event Start
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  //Add Event End


  //Update Event Start
  const [showUpdateEventModal, setShowUpdateEventModal] = useState(false);

  const [eventData, setEventData] = useState({});

  const openUpdateEventModal = (event_data) =>{
    setEventData(event_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateEventModal(true);
  }  
  //Update Event End


  //Delete Event Start
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);

  const openDeleteEventModal = (event_data) =>{
    setEventData(event_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteEventModal(true);
  } 
  //Delete Event End


  //Flush Event Start
  const flushEvent = async() => {
    localStorage.removeItem("events");
    fetchEvents(); 
  }  
  //Flush Event End


  //Event List Start
  const [events, setEvents] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEvents = async() => {
    setLoading(true);

    try {
      let apiParam = {user_token: user_data.user_token};

      const response = await getEvents(apiParam);

      if (typeof response != typeof undefined) {
        setEvents(response.events);

        if (response.time) {
            setCacheTime(response.time);
        }

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      }
      else{
          navigate('/re-login');
      }        

      setLoading(false);

    } catch (error) {
      //console.error(error);
    }

  } 
  
  
  const [calendarOptions, setCalendarOptions] = useState({
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    events: events,
    // Other calendar options
  });

  useEffect(() => {  
    fetchEvents();

    if (financialYear) {

      const currentFinYearObj = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year")) : {};
      
      const fin_year = currentFinYearObj.fin_year;

      const [startYear, endYear] = fin_year.split('-');
      const startOfMonth = `${startYear}-04-01`;
      const endOfMonth = `${endYear}-03-31`;
      setCalendarOptions(prevOptions => ({
        ...prevOptions,
        validRange: {
          start: startOfMonth,
          end: endOfMonth
        }
      }));
    }


  }, [financialYear]);
  //Event List End

  function renderEventContent(event) {
    return (
      <>
        <b>{event.title}</b>
        <i>{event.desc}</i>
      </>
    )
  }
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Events - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
              <div className="container-fluid">

                {/* <!-- Page Header --> */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Events</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Events</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* <!-- Page Header Close --> */}

                {/* <!-- Start::row-1 --> */}
                {loading ? (
                  <EventCalendar />
                ) : (  
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card custom-card">
                            <div className="card-header d-grid">
                                <button className={`btn btn-primary-light btn-wave ${checkPermission('event_add')===false ? 'd-none' : ''}`} onClick={() => setShowAddEventModal(true)}>
                                  <i className="ri-add-line align-middle me-1 fw-semibold d-inline-block"></i>Create New Event
                                </button>
                            </div>
                            <div className="card-body p-0">
                                
                                <div className="p-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="fw-semibold">
                                      Holidays :
                                    </h6>
                                    <button className="btn btn-success-light btn-sm btn-wave" onClick={() => flushEvent()}>
                                      <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                    </button>
                                  </div>
                                </div>
                                <div className="p-3 border-bottom" id="full-calendar-activity">
                                  <ul className="list-unstyled mb-0 fullcalendar-events-activity">
                                  {events.map((event) => (
                                    <li>
                                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <p className="mb-1 fw-semibold" style={{maxWidth: "10rem", textOverflow: "ellipsis", whiteSpace:"nowrap", overflow:"hidden"}}>
                                          {dateFormat(event.start, "ddd, dd mmm yyyy")}{event.days>1 ? ' - '+dateFormat(event.end, "ddd, dd mmm yyyy") : ''}
                                        </p>
                                        <span className="badge bg-light text-default mb-1">{event.days} day (s)</span>
                                        <div className="hstack gap-2 fs-15">
                                        <button type="button" className={`btn btn-icon btn-sm btn-info btn-link p-0 `} title={`${new Date(event.start) < new Date() || checkPermission('event_update')===false ? 'View' : 'Edit'}`} onClick={() => openUpdateEventModal(event)}>
                                          <i className={`${new Date(event.start) < new Date() || checkPermission('event_update')===false ? 'bi bi-eye' : 'bx bxs-edit'}`}></i>
                                        </button>
    
                                        <button type="button" className={`btn btn-icon btn-sm btn-danger btn-link p-0 ${new Date(event.start) < new Date() ? 'd-none' : ''} ${checkPermission('event_delete')===false ? 'd-none' : ''}`} title="Delete" onClick={() => openDeleteEventModal(event)}>
                                          <i className='bx bxs-trash'></i>
                                        </button>
                                        </div>
                                      </div>

                                      
                                      <p className="mb-0 text-muted fs-12">
                                        {event.title}
                                      </p>
                                    </li>
                                    ))}

                                    
                                  </ul>
                                </div>
                                {/* <div className="p-3">
                                  <img src="assets/images/media/media-83.svg" alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card custom-card">
                            <div className="card-header">
                                <div className="card-title">Event Calendar (Last Synced On {cacheTime})</div>
                            </div>
                            <div className="card-body">
                            <FullCalendar
                              plugins={[dayGridPlugin]}
                              initialView='dayGridMonth'
                              events={events}
                              displayEventTime={false}
                            />
                            {/* <FullCalendar
                              {...calendarOptions}
                            /> */}

                            {/* <FullCalendar
                              events={events}
                            /> */}
                            </div>
                        </div>
                    </div>
                </div>
                )} 
                {/* <!--End::row-1 --> */}


                {/* <!--Add Event Modal Start--> */}
                <AddEventModal
                  showModal={showAddEventModal}
                  setShowModal={setShowAddEventModal}
                  fetchEvents={fetchEvents} />
                {/* <!--Add Event Modal End--> */}

                {/* <!--Update Event Modal Start--> */}
                <UpdateEventModal
                  showModal={showUpdateEventModal}
                  setShowModal={setShowUpdateEventModal}
                  eventData={eventData}
                  modalKey={modalKey}
                  fetchEvents={fetchEvents} />
                {/* <!--Update Event Modal End--> */}


                {/* <!--Delete Event Modal Start--> */}
                <DeleteEventModal
                  showModal={showDeleteEventModal}
                  setShowModal={setShowDeleteEventModal}
                  eventData={eventData}
                  modalKey={modalKey}
                  fetchEvents={fetchEvents} />
                  {/* <!--Delete Event Modal End--> */}

              </div>


            </div>  

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Events
