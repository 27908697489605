import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editProject, getProjectDetails, saveProjectsDetailsToStorage, saveProjectsToStorage } from '../../../models/ProjectModel';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { apiError } from '../../../helpers/ErrorHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { checkPermission, decodeHtmlEntities } from '../../../helpers/GeneralHelper';


const ProjectDetailsForm = ({
    project_unique_id,
    projectData,
    setProjectData,
    projectLoading, 
    setProjectLoading
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const editor = useReducer(null);

    const loader = document.getElementById('loading');

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300
        }),
        []
    );

    //Set Priority List Start
    const [priorities, setPriorities] = useState([]);
    useEffect(() => {
        const priorityOptions = 
        [
            {label: 'Low', value: 'low'},
            {label: 'Medium', value: 'medium'},
            {label: 'High', value: 'high'}
        ];

        setPriorities(priorityOptions);
    }, []); 
    //Set Priority List End


    //Set Status List Start
    const [status, setStatus] = useState([]);
    useEffect(() => {
        const statusOptions = 
        [
            {label: 'Open', value: '1'},
            {label: 'Completed', value: '2'},
            {label: 'Cancelled', value: '3'}
        ];

        setStatus(statusOptions);
    }, []); 
    //Set Status List End


    // Get Project Details Start
    const setProjectForm = () => {
        setProjectData(projectData);

        setProjectID(projectData._project_id);
        setProjectTitle(projectData.project_title);
        setProjectDescription(decodeHtmlEntities(projectData.project_description));
        setProjectDate(new Date(projectData.project_start_date));
        setProjectDeadline(new Date(projectData.project_deadline));
        setProjectPrice(projectData.project_price);
        setProjectLogoUrl(projectData.project_logo);
        let statusLabel = '';

        if (projectData.project_status==='1') {
            statusLabel = 'Open';
        }
        else if (projectData.project_status==='2') {
            statusLabel = 'Completed';
        }
        else{
            statusLabel = 'Cancelled';
        }
        setProjectStatus({'label': statusLabel, 'value': projectData.project_status});

        setClientName(projectData.client_name);

        let priorityLabel = '';

        if (projectData.project_priority==='low') {
            priorityLabel = 'Low';
        }
        else if (projectData.project_priority==='medium') {
            priorityLabel = 'Medium';
        }
        else{
            priorityLabel = 'High';
        }
        setProjectPriority({'label': priorityLabel, 'value': projectData.project_priority});

        setProjectSiteVisit(projectData.project_site_visit);

        setNotificationCount(localStorage.getItem('unread_notification_count'));
    }

    useEffect(()=>{
        if (projectLoading===false) {
            setProjectForm(); 
        }
         
    }, [project_unique_id, projectData, projectLoading]);
    // Get Project Details End


    // Update Project Start
    const [_project_id, setProjectID] = useState('');
    const [project_title, setProjectTitle] = useState('');
    const [project_description, setProjectDescription] = useState('');
    const [project_start_date, setProjectDate] = useState('');
    const [project_deadline, setProjectDeadline] = useState('');
    const [project_price, setProjectPrice] = useState('');
    const [project_status, setProjectStatus] = useState('');
    const [client_name, setClientName] = useState('');
    const [project_priority, setProjectPriority] = useState('');
    const [project_logo, setProjectLogo] = useState('');
    const [project_logo_url, setProjectLogoUrl] = useState('');
    const [project_site_visit, setProjectSiteVisit] = useState('');

    const inputFileRef = useRef(null);
    const handleImageClick = () => {
        inputFileRef.current.click();
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        //console.log(file);

        setProjectLogo(file);

        //console.log(URL.createObjectURL(file));
    }

    const [errors, setErrors] = useState({project_title: '', project_description: '', project_start_date: '', project_deadline: '',
    project_price: '', project_status: '', _client_id: '', _user_ids: '', project_priority: '', project_logo: ''});

    const updateProject = async() => {
        // Start the loading state
        loader.style.display = 'block';

        try {
            // Clear previous errors
            setErrors({});

            const formData = { project_title, project_description, project_start_date: project_start_date ? dateFormat(project_start_date, "dd-mm-yyyy") : '', 
          project_deadline: project_deadline ? dateFormat(project_deadline, "dd-mm-yyyy") : '', project_price,  
          project_priority:  project_priority.value ? project_priority.value : '', project_status:  project_status.value ? project_status.value : '', project_site_visit, project_logo};

          const response = await editProject(user_data.user_token, _project_id, formData);

          if (response.status) {
            toast.success(response.message);
    
            saveProjectsToStorage(response.data.projects, response.data.pagination);

            const newResponseData = { ...response.data };
            delete newResponseData.projects;
            delete newResponseData.pagination;

            //saveProjectsDetailsToStorage(project_unique_id, newResponseData);

            setProjectData(response.data.project_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));

          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);

            //console.log(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }


        // Stop the loading state
        loader.style.display = 'none';

    }    
    // Update Project End

    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="card custom-card shadow-none">
                    <div className="card-header">
                        <div className="card-title">
                            Project Details
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row gy-2">
                            <div className="col-xl-9">
                                <label htmlFor="task-name" className="form-label">Project Title <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder="Project Title"
                                value={project_title} onChange={(e) => setProjectTitle(e.target.value)} />

                                {errors.project_title && <p style={{ color: 'red' }}>{errors.project_title}</p>}
                            </div>

                            <div className="col-xl-3">
                                <label htmlFor="task-id" className="form-label">Client <span className="text-danger">*</span></label>
                                
                                <input type="text" className="form-control" value={client_name} disabled="true" />    
                            </div>

                            <div className="col-xl-9">
                                <label className="form-label">Description <span className="text-danger">*</span></label>

                                <JoditEditor
                                    ref={editor}
                                    value={project_description}
                                    config={config}
                                    onChange={newContent => setProjectDescription(newContent)}/>

                                {errors.project_description && <p style={{ color: 'red' }}>{errors.project_description}</p>}
                            </div>

                            <div className="col-xl-3">
                                <label className="form-label">Logo </label><br/>
                                    <div className='d-flex justify-content-end' >
                                        <button type='button' className='btn-close' title='Remove Logo' style={{display: project_logo!=='' ? 'block' : 'none'}}
                                        onClick={(e) => setProjectLogo('')}></button>
                                    </div>
                                
                                    <div onClick={handleImageClick} style={{cursor: 'pointer'}}>
                                        {project_logo_url ? (
                                            <img className='card-img-top' src={process.env.REACT_APP_ASSETS_URL+`/`+project_logo_url}/>
                                        ) : project_logo ? (
                                            <img className='card-img-top' src={URL.createObjectURL(project_logo)}/>
                                        ) : (
                                            <img className='card-img-top' src={process.env.REACT_APP_BASE_URL+`/assets/images/upload-icon.png`}/>
                                        )}
                                        <input className='d-none' type='file' ref={inputFileRef} onChange={handleImageChange} 
                                        accept="image/png, image/jpeg, image/jpg" />
                                    </div>
                                    
                                    {errors.project_logo && <p style={{ color: 'red' }}>{errors.project_logo}</p>}
                            </div>

                            <div className="col-xl-4">
                                <label className="form-label">Start Date <span className="text-danger">*</span></label><br/>
                                <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                                    selected={project_start_date} onSelect={setProjectDate} onChange={setProjectDate} dateFormat="dd-MM-yyyy"/>

                                {errors.project_start_date && <p style={{ color: 'red' }}>{errors.project_start_date}</p>}
                            </div>

                            <div className="col-xl-4">
                                <label className="form-label">Deadline <span className="text-danger">*</span></label><br/>
                                <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                                    selected={project_deadline} onSelect={setProjectDeadline} onChange={setProjectDeadline} dateFormat="dd-MM-yyyy"/>

                                {errors.project_deadline && <p style={{ color: 'red' }}>{errors.project_deadline}</p>}
                            </div>

                            <div className="col-xl-4">
                                <label htmlFor="task-name" className="form-label">Price <span className="text-danger">*</span></label><br/>

                                <CurrencyInput
                                    className="form-control"
                                    placeholder="Enter Price"
                                    prefix="₹"
                                    value={project_price}
                                    decimalsLimit={2}
                                    allowNegativeValue="false"
                                    onValueChange={(value, name, values) => setProjectPrice(value)}
                                />;

                                {errors.project_price && <p style={{ color: 'red' }}>{errors.project_price}</p>}
                            </div>

                            <div className="col-xl-4">
                                <label className="form-label">Priority <span className="text-danger">*</span></label>
                                <Select
                                    value={project_priority}
                                    onChange={setProjectPriority}
                                    options={priorities}
                                    placeholder="- Priority -"
                                    isSearchable="true"
                                    noOptionsMessage={() => "No priority found"}
                                    styles={selectBoxTheme}
                                    theme={(selectBoxTheme) => ({
                                    ...selectBoxTheme,
                                    borderRadius: 0,
                                    })} />

                                {errors.project_priority && <p style={{ color: 'red' }}>{errors.project_priority}</p>}  
                            </div>

                            <div className="col-xl-4">
                                <label className="form-label">Status <span className="text-danger">*</span></label>
                                <Select
                                    value={project_status}
                                    onChange={setProjectStatus}
                                    options={status}
                                    placeholder="- Status -"
                                    isSearchable="true"
                                    noOptionsMessage={() => "No status found"}
                                    styles={selectBoxTheme}
                                    theme={(selectBoxTheme) => ({
                                    ...selectBoxTheme,
                                    borderRadius: 0,
                                    })} />

                                {errors.project_status && <p style={{ color: 'red' }}>{errors.project_status}</p>}  
                            </div>

                            <div className="col-xl-4">
                                <label className="form-label">Site Visit <span className="text-danger">*</span></label>
                                <br/>

                                <label className="radio-inline">
                                    <input type="radio" value="1" checked={project_site_visit==='1' ? 'checked' : ''}
                                    onChange={(e) => setProjectSiteVisit(e.target.value)}/>&nbsp;Yes&nbsp;&nbsp;
                                </label>

                                <label className="radio-inline">
                                    <input type="radio" value="0" checked={project_site_visit==='0' ? 'checked' : ''}
                                    onChange={(e) => setProjectSiteVisit(e.target.value)} />&nbsp;No&nbsp;&nbsp;
                                </label>

                                {errors.project_site_visit && <p style={{ color: 'red' }}>{errors.project_site_visit}</p>}  

                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button className={`btn btn-primary-light btn-wave ms-auto float-end ${checkPermission('project_edit')===false ? 'd-none' : ''}`} onClick={updateProject}>Update</button>
                    </div> 
                </div>
            </div>
        </div>
    );

};    

export default ProjectDetailsForm;    