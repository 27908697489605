import React, { useEffect,  useRef,  useState }  from 'react'

function ProfileTabs() { 

    return (
        <>
            <div className="row">
                <div className="col-xxl-4 col-xl-12">
                    <div className="card custom-card overflow-hidden placeholder-glow">
                        <div className="card-body p-0">
                            <div className="d-sm-flex align-items-top p-4 border-bottom-0 main-profile-cover placeholder col-12">
                                <div>
                                    <span className="avatar avatar-xxl avatar-rounded online me-3 placeholder col-3">
                                        
                                    </span>
                                </div>
                                <div className="flex-fill main-profile-info">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="fw-semibold mb-1 text-fixed-white placeholder col-6"></h6>
                                    </div>
                                    <p className="mb-1 text-muted text-fixed-white op-7 placeholder col-3"></p>
                                    
                                    <span className="me-3 d-block placeholder col-1"></span> 
                                    
                                    <div className="d-flex mb-0 mt-2">
                                        <div className="col-3 me-4">
                                            <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0 placeholder col-10"></p>
                                            <p className="mb-0 fs-11 op-5 text-fixed-white placeholder col-10"></p>
                                        </div>
                                        <div className="col-3 me-4">
                                            <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0 placeholder col-10"></p>
                                            <p className="mb-0 fs-11 op-5 text-fixed-white placeholder col-10"></p>
                                        </div>
                                        <div className="col-3 me-4">
                                            <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0 placeholder col-10"></p>
                                            <p className="mb-0 fs-11 op-5 text-fixed-white placeholder col-10"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="p-4 border-bottom border-block-end-dashed">
                                <p className="fs-15 mb-2 me-4 fw-semibold placeholder col-6"></p>
                                <div className="text-muted">
                                    <p className="mb-2">
                                        <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted placeholder col-3">
                                            
                                        </span>
                                        <span className="placeholder col-3"></span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted placeholder col-3">
                                            
                                        </span>
                                        <span className="placeholder col-3"></span>
                                    </p>
                                    <p className="mb-2">
                                        <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted placeholder col-3">
                                            
                                        </span>
                                        <span className="placeholder col-3"></span>
                                    </p>
                                </div>
                            </div>
                            <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-center">
                                <p className="fs-15 mb-2 me-4 fw-semibold placeholder col-4"></p>
                                <div className="btn-list mb-0">
                                    <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                                    <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                                    <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                                    <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                                    <button className="btn btn-sm btn-icon btn-light btn-wave waves-effect waves-light placeholder col-2"></button>
                                </div>
                            </div>
                            <div className="p-4 border-bottom border-block-end-dashed">
                                <p className="fs-15 mb-2 me-4 fw-semibold placeholder col-6"></p>
                                <div className="placeholder col-12">
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-xxl-8 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card placeholder-glow">
                                <div className="card-body p-0">
                                    <div className="p-3 border-bottom border-block-end-dashed d-flex align-items-center justify-content-between">
                                        <div className="col-8">
                                            <ul className="nav nav-tabs mb-0 tab-style-6 justify-content-start" id="myTab" role="tablist">
                                                <li className="nav-item placeholder col-2" role="presentation">
                                                    
                                                </li>
                                                <li className="nav-item placeholder col-2" role="presentation">
                                                    
                                                </li>
                                                <li className="nav-item placeholder col-2" role="presentation">
                                                    
                                                </li>
                                                <li className="nav-item placeholder col-2" role="presentation">
                                                    
                                                </li>
                                               
                                            </ul>
                                        </div>   
                                        <div className='col-4'>
                                            <p className="fw-semibold mb-2 placeholder col-12"> <a href="javascript:void(0);" className="text-primary fs-12"></a></p>
                                            <div className="progress progress-xs progress-animate placeholder col-12">
                                                
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="p-3">
                                        <div className="tab-content" id="myTabContent">

                                            {/* <!--Profile Tab Content Start---> */}
                                            <div className="tab-pane show active fade p-0 border-0" id="profile-tab-pane" role="tabpanel" 
                                                aria-labelledby="profile-tab" tabindex="0">

                                                <h4 className='placeholder col-2'></h4>

                                                {/* <!--General Info Card Start---> */}
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title placeholder col-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-3">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                        </div>

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                        </div>   

                                                        <div className="row gy-3">

                                                            <div className="col-xl-6">
                                                                <label for="input-label" className="form-label placeholder col-8"></label>
                                                                <textarea className="form-control placeholder col-12" placeholder="Permanent Address" rows="5"></textarea>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <label for="input-label" className="form-label placeholder col-8"></label>
                                                                <textarea className="form-control placeholder col-12" placeholder="Permanent Address" rows="5"></textarea>
                                                            </div>
                                                        </div>

                                                    </div>  
                                                    
                                                    <div className="card-footer">
                                                        <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                                    </div>  
                                                   
                                                </div>
                                                {/* <!--General Info Card End---> */}


                                                {/* <!--JOB Info Card Start---> */}
                                                
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title placeholder col-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                        </div>    

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                        </div>    
                                                    </div> 
                                                    
                                                    
                                                    <div className="card-footer">
                                                        <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                                    </div> 
                                                    
                                                </div>
                                               
                                                {/* <!--JOB Info Card End---> */}


                                                {/* <!--Social Networks Card Start---> */}
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title placeholder col-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                        </div>    

                                                        <div className="row gy-3 mb-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                        </div>    
                                                    </div> 

                                                    <div className="card-footer">
                                                        <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                                    </div>   
                                                </div>
                                                
                                                {/* <!--Social Networks Card End---> */}


                                                {/* <!--Account Info Card Start---> */}
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title placeholder col-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className="row gy-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>

                                                        </div>    

                                                    </div>  
                                                                      
                                                    <div className="card-footer">
                                                        <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                                    </div>   
                                                    
                                                </div> 
                                             
                                                {/* <!--Account Info Card End---> */}


                                                {/* <!--Change Password Card Start---> */}
                                                <div className="card custom-card">
                                                    <div className="card-header">
                                                        <div className="card-title placeholder col-2">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="card-body">

                                                        <div className="row gy-3">

                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <label className="form-label placeholder col-8"> </label>
                                                                <input type="text" className="form-control placeholder col-12"/>
                                                            </div>
                                                        </div>    

                                                    </div>  

                                                    <div className="card-footer">
                                                        <button className="btn btn-primary-light btn-wave ms-auto float-end placeholder col-2"></button>
                                                    </div>   
                                                </div> 
                                                
                                                {/* <!--Change Password Card End--->    */}
                                                
                                            </div>
                                            {/* <!--Profile Tab Content End---> */}

                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>

    );    

}    


export default ProfileTabs