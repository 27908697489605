import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";



export const saveBanksToStorage = (banks, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        banks: banks,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("banks", encryptData(dataToStore));
} 



export const getBanks = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('banks')) {
        const saved_banks_obj = decryptData(localStorage.getItem('banks'));

        return saved_banks_obj;
    }
    else{
        try {
            const endPoint = '/banks/bank-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveBanksToStorage(response.data.banks, response.data.pagination);

                    const saved_banks_obj = decryptData(localStorage.getItem('banks'));

                    return saved_banks_obj;
                }
                else{
                    //localStorage.removeItem("notes");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);

            //console.log(error);
        }
    }
};