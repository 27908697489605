import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import dateFormat from "dateformat";
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { editProjectMember } from '../../../models/ProjectModel';

const UpdateProjectMemberModal = ({showModal, setShowModal, project_unique_id, memberData, modalKey, fetchProjectMembers, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);


    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [user_name, setUserName] = useState('');
    const [member_site_visit_allowed, setMemberSiteVisitAllowed] = useState(0);


    const [errors, setErrors] = useState({user_id: ''});

    const closeModal = () =>{
        setUserName('');
        setMemberSiteVisitAllowed(0);

        setErrors({});
    
        setShowModal(false);
    }


    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (memberData) {
            setUserName(memberData.user_first_name+' '+memberData.user_last_name);
            setMemberSiteVisitAllowed(memberData.member_site_visit_allowed);
        }
    }, [project_unique_id, memberData, modalKey]);


    const updateProjectMember = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});

          const formData = {member_site_visit_allowed};

          const response = await editProjectMember(user_data.user_token, project_unique_id, formData);

          if (response.status) {
            toast.success(response.message);

            fetchProjectMembers(limit, page);
    
            closeModal();

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Update Member</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">

                    <div className="col-lg-12">
                        <label className="form-label">Member</label>
                        <div className='form-control'>{user_name}</div>
                    </div>

                    <div className={`col-lg-12}`}>
                        <label className="form-label">Site Visit Allowed</label>

                        <div className="form-check form-check-lg form-switch">
                            <input className="form-check-input" type="checkbox" role="switch"
                            value={member_site_visit_allowed} onChange={(e) => setMemberSiteVisitAllowed(e.target.value)}   />
                        </div>
                    </div>
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateProjectMember}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};

export default UpdateProjectMemberModal;    