import React, { useEffect, useState } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import DataTable, { createTheme } from 'react-data-table-component';
import { profilePic } from '../../helpers/GeneralHelper';
import { utcToLocal } from '../../helpers/DateHelper';
import TimesheetList from '../skeletons/TimesheetList';

const TimesheetTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchTimesheets,
    openViewWorkModal
}) => {

    const { theme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;

    const CustomLoader = () => (
        <TimesheetList/>
    );

    const handlePageChange = async (page) => {
        fetchTimesheets(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchTimesheets(newLimit, page); 
        }
        else if (localStorage.getItem('timesheets')) {
            fetchTimesheets(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchTimesheets(limit, page);  
    }, []);

    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.user_first_name, row.user_last_name, row.user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.user_first_name} {row.user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Project",
            selector: (row) =>  row.project_title,
            width: '200px'
        },
        {
            name: "Task",
            selector: (row) =>  row.timesheet_other_task ? row.timesheet_other_task : row.task_title,
            width: '300px'
        },
        {
            name: "Date",
            selector: (row) => utcToLocal(row.timesheet_date, "dd mmm yyyy") ,
            width: '150px' 
        },
        
        {
            name: "Start Time",
            selector: (row) => utcToLocal(row.timesheet_start_time, "HH:MM:ss") ,
            width: '150px'
        },
        {
            name: "Stop Time",
            selector: (row) => utcToLocal(row.timesheet_end_time, "HH:MM:ss", '--:--:--'),
            width: '150px'
        },
        {
            name: "Duration",
            selector: (row) => row.timesheet_working_hours ,
            width: '100px' 
        },
        {
            name: "Activity",
            selector: (row) => '--' ,
            width: '100px'
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="View" onClick={() => openViewWorkModal(row)}>
                                <i className='bi bi-eye'></i>
                            </button>
                        </div>,
            width: '100px'
        }
    ];

    // Conditionally unset a specific column
    const filteredColumns = user_data.urole_permission!=='*'
        ? columns.filter(column => column.name !== "Team Member")
        : columns;

    createTheme('dark', {
        text: {
            primary: '#a2a3a4',
            secondary: '#2aa198',
        },
        background: {
            default: '#252729',
        },
        context: {
            background: '#cb4b16',
            text: '#a2a3a4',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Timesheet`} 
            columns={filteredColumns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );


};    

export default TimesheetTables;      