import React, { useEffect,  useRef,  useState }  from 'react'

function ProjectTabs() { 

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card placeholder-glow">
                        <div className="card-body">
                            <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                <li className="nav-item placeholder col-1" role="presentation">
                                    <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#overview-center" aria-selected="true"></a>
                                </li>
                                <li className="nav-item placeholder col-1" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#details-center" aria-selected="false" tabindex="-1"></a>
                                </li>
                                <li className="nav-item placeholder col-1" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#files-center" aria-selected="false" tabindex="-1"></a>
                                </li>
                                <li className="nav-item placeholder col-1" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#members-center" aria-selected="false" tabindex="-1"></a>
                                </li>
                                <li className="nav-item placeholder col-1" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#tasks-center" aria-selected="false" tabindex="-1"></a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane show active text-muted" id="overview-center" role="tabpanel">
                                    <div className="row mb-3">
                                        <div className="col-xl-3">
                                            <div className="card custom-card card-bg-dark placeholder col-2">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="me-2">
                                                            <span className="avatar avatar-rounded placeholder col-2">
                                                                
                                                            </span>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="fs-15 fw-semibold placeholder col-8"><span className='placeholder col-6 mb-1'></span></div>
                                                            <p className="mb-0 text-fixed-white op-7 fs-12 placeholder col-8"></p>
                                                        </div>
                                                        <div className="col-4">
                                                            <a href="javascript:void(0);" className="text-fixed-white placeholder col-2"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3">
                                            <div className="card custom-card card-bg-dark placeholder col-2">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="me-2">
                                                            <span className="avatar avatar-rounded placeholder col-2">
                                                                
                                                            </span>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="fs-15 fw-semibold placeholder col-8"><span className='placeholder col-6 mb-1'></span></div>
                                                            <p className="mb-0 text-fixed-white op-7 fs-12 placeholder col-8"></p>
                                                        </div>
                                                        <div className="col-4">
                                                            <a href="javascript:void(0);" className="text-fixed-white placeholder col-2"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3">
                                            <div className="card custom-card card-bg-dark placeholder col-2">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="me-2">
                                                            <span className="avatar avatar-rounded placeholder col-2">
                                                                
                                                            </span>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="fs-15 fw-semibold placeholder col-8"><span className='placeholder col-6 mb-1'></span></div>
                                                            <p className="mb-0 text-fixed-white op-7 fs-12 placeholder col-8"></p>
                                                        </div>
                                                        <div className="col-4">
                                                            <a href="javascript:void(0);" className="text-fixed-white placeholder col-2"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3">
                                            <div className="card custom-card card-bg-dark placeholder col-2">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center w-100">
                                                        <div className="me-2">
                                                            <span className="avatar avatar-rounded placeholder col-2">
                                                                
                                                            </span>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="fs-15 fw-semibold placeholder col-8"><span className='placeholder col-6 mb-1'></span></div>
                                                            <p className="mb-0 text-fixed-white op-7 fs-12 placeholder col-8"></p>
                                                        </div>
                                                        <div className="col-4">
                                                            <a href="javascript:void(0);" className="text-fixed-white placeholder col-2"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-8">
                                            <div className="card custom-card shadow">
                                                <div className="card-header">
                                                    <div className="card-title placeholder col-2"></div>
                                                </div>
                                                <div className="card-body">
                                                    Chart
                                                </div>
                                                <div className="card-footer text-muted">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4">
                                            <div className="card custom-card shadow">
                                                <div className="card-header justify-content-between">
                                                    <div className="card-title placeholder col-2">
                                                        
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-sm btn-primary-light btn-wave waves-effect waves-light placeholder col-12 px-5"></button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled timeline-widget mb-0 my-3">
                                                        <li className="timeline-widget-list">
                                                            <div className="d-flex align-items-top">
                                                                <div className="me-5 text-center col-1">
                                                                    <span className="d-block fs-20 fw-semibold placeholder col-12 mb-1"></span>
                                                                    <span className="d-block fs-12 text-muted placeholder col-12"></span>
                                                                </div>
                                                                <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between col-11">
                                                                    <div className='col-10'>
                                                                        <p className="mb-1 text-truncate timeline-widget-content text-wrap placeholder col-12 mb-1"></p>
                                                                        <p className="mb-0 fs-12 lh-1 text-muted placeholder col-6"></p>
                                                                    </div>
                                                                    <div className="dropdown">
                                                                        <a href="javascript:void(0);" className="p-2 fs-16 text-muted placeholder col-2" data-bs-toggle="dropdown">
                                                                            
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="timeline-widget-list">
                                                            <div className="d-flex align-items-top">
                                                                <div className="me-5 text-center col-1">
                                                                    <span className="d-block fs-20 fw-semibold placeholder col-12 mb-1"></span>
                                                                    <span className="d-block fs-12 text-muted placeholder col-12"></span>
                                                                </div>
                                                                <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between col-11">
                                                                    <div className='col-10'>
                                                                        <p className="mb-1 text-truncate timeline-widget-content text-wrap placeholder col-12 mb-1"></p>
                                                                        <p className="mb-0 fs-12 lh-1 text-muted placeholder col-6"></p>
                                                                    </div>
                                                                    <div className="dropdown">
                                                                        <a href="javascript:void(0);" className="p-2 fs-16 text-muted placeholder col-2" data-bs-toggle="dropdown">
                                                                            
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="timeline-widget-list">
                                                            <div className="d-flex align-items-top">
                                                                <div className="me-5 text-center col-1">
                                                                    <span className="d-block fs-20 fw-semibold placeholder col-12 mb-1"></span>
                                                                    <span className="d-block fs-12 text-muted placeholder col-12"></span>
                                                                </div>
                                                                <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between col-11">
                                                                    <div className='col-10'>
                                                                        <p className="mb-1 text-truncate timeline-widget-content text-wrap placeholder col-12 mb-1"></p>
                                                                        <p className="mb-0 fs-12 lh-1 text-muted placeholder col-6"></p>
                                                                    </div>
                                                                    <div className="dropdown">
                                                                        <a href="javascript:void(0);" className="p-2 fs-16 text-muted placeholder col-2" data-bs-toggle="dropdown">
                                                                            
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );    

}    


export default ProjectTabs