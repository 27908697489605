import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { getProjects } from '../../../models/ProjectModel';
import dateFormat from "dateformat";
import { projectPriority } from '../../../helpers/ProjectHelper';
import { checkPermission, decodeHtmlEntities, profilePic } from '../../../helpers/GeneralHelper';
import ProjectCards from '../../skeletons/ProjectCards';
import AddProjectModal from '../../modals/Project/AddProjectModal';
import DeleteProjectModal from '../../modals/Project/DeleteProjectModal';
import { useNotificationCount } from '../../../context/NotificationCountContext';


function Projects() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { state } = useAuth(); 

  let user_data = state.user_data;
  
  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading'); 
  
  const [modalKey, setModalKey] = useState(0);

  //Add Project Start
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  //Add Project End


  //Flush Project Start
  const flushProject = async() => {
    localStorage.removeItem("projects");
    fetchProjects(); 
  }  
  //Flush Project End


  //Delete Project Start
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  const [projectData, setProjectData] = useState({});

  const openDeleteProjectModal = (project_data) =>{
    setProjectData(project_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteProjectModal(true);
  }
  //Delete Project End
  

  //Project List Start
  const [projects, setProjects] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [prevPage, setPrevPage] = useState('');
  const [nextPage, setNextPage] = useState('');

  const fetchProjects = async (param = {}) => {
    setLoading(true);
    try {
      let page  = param.page ? param.page : 1;
      let limit = param.limit ? param.limit : 9;

      let apiParam = {page: page, limit: limit, user_token: user_data.user_token};
      const response = await getProjects(apiParam);

      if (typeof response != typeof undefined) {
        setProjects(response.projects);
        setTotalPages(response.pagination.total_pages);
        setPage(response.pagination.current_page);
        setTotalRows(response.pagination.total_rows);
        setPrevPage(response.pagination.prev_page);
        setNextPage(response.pagination.next_page);

        if (response.time) {
            setCacheTime(response.time);
        }

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      }
      else{
        navigate('/re-login');
      }  

      setLoading(false);
    } catch (error) {
     //console.error(error);
    }
    
  };

  useEffect(() => {  
    fetchProjects();
  }, []);  
  //Project List End  
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Projects - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Projects List</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Projects</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Projects List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body p-3">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                        <div className="card-title me-auto">
                                            Last Synced On {cacheTime}
                                        </div>

                                        <button className={`btn btn-primary btn-sm me-1 ${checkPermission('project_add')===false ? 'd-none' : ''}`} onClick={() => setShowAddProjectModal(true)}>
                                                <i className="ri-add-line me-1 fw-semibold align-middle"></i>New Project
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushProject()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}

                    {/* <!-- Project Card List Row Start --> */}
                    <div className="row" style={{minHeight: '1000px'}}>

                    {loading ? (

                    [1,2,3,4,5,6,7,8,9].map((n) => (
                        <ProjectCards />
                    ))

                    ) : (  
                    projects.map((project) => (

                        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div className="card custom-card">
                                <div className="card-header align-items-center">
                                    <div className="me-2">
                                        <span className="avatar avatar-rounded p-1 bg-danger-transparent">
                                            <img src={project.project_logo ? process.env.REACT_APP_ASSETS_URL+`/`+project.project_logo : process.env.REACT_APP_BASE_URL+`/assets/images/no-pic.jpeg`} alt="" />
                                        </span>
                                    </div>
                                    <div className="flex-fill">
                                        <Link to={`/project-details/${project.project_unique_id}`} className="fw-semibold fs-14 d-block text-truncate project-list-title" style={{maxWidth: "15rem"}}>{project.project_title}</Link>
                                        <span className="text-muted d-block fs-12">
                                            {
                                            project.project_total_tasks!=0 ? (
                                                <span>
                                                Total{' '}
                                                <strong className="text-default">
                                                    {project.project_total_completed_tasks}/{project.project_total_tasks}
                                                </strong>{' '}
                                                tasks completed
                                                </span>
                                            ) : (
                                                <span>No Task added</span>
                                            )
                                            }
                                            
                                        </span>
                                    </div>
                                    <div className="dropdown">
                                        <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-sm btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fe fe-more-vertical"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to={`/project-details/${project.project_unique_id}`}><i className="ri-eye-line align-middle me-1 d-inline-block"></i>View</Link>
                                            </li>
                                            <li>
                                                <button className={`dropdown-item ${checkPermission('project_delete')===false ? 'd-none' : ''}`} onClick={() => openDeleteProjectModal(project)}>
                                                    <i className="ri-delete-bin-line me-1 align-middle d-inline-block"></i>Delete
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body" style={{height: "240px", overflowY: "auto" }}>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <div>
                                            <div className="fw-semibold mb-1">Team :</div>
                                            <div className="avatar-list-stacked">
                                                {project.members.map((member) => (
                                                <Link to={`/profile/${member.user_unique_id}`} className="avatar avatar-sm me-2 avatar-rounded" title={`${member.user_first_name} ${member.user_last_name}`}>
                                                    {profilePic(member.user_first_name, member.user_last_name, member.user_image)}
                                                </Link>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <div className="fw-semibold mb-1">Priority :</div>
                                                {projectPriority(project.project_priority)}
                                            </div>
                                    </div>
                                    <div className="fw-semibold mb-1">Description :</div>
                                    <p className="text-muted mb-3"><div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(project.project_description) }} /></p>
                                    <div className="fw-semibold mb-1">Status :</div>
                                    <div>
                                        <div></div>
                                        <div className="progress progress-xs progress-animate" role="progressbar" aria-valuenow={project.status_percentage} aria-valuemin="0" aria-valuemax="100">
                                            <div className="progress-bar bg-primary" style={{width: `${project.status_percentage}%`}}></div>
                                        </div>
                                        <div className="mt-1"><span className="text-primary fw-semibold">{project.status_percentage}%</span> Completed</div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <div>
                                        <span className="text-muted fs-11 d-block">Assigned Date :</span>
                                        <span className="fw-semibold d-block">{dateFormat(project.project_start_date, "dd mmm yyyy")}</span>
                                    </div>
                                    <div className="text-end">
                                        <span className="text-muted fs-11 d-block">Due Date :</span>
                                        <span className="fw-semibold d-block">{dateFormat(project.project_deadline, "dd mmm yyyy")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    ))
                    )}
                    </div>
                    {/* <!-- Project Card List Row End --> */}
                    {projects ? (
                    <ul className="pagination justify-content-end">

                        <li className={`page-item ${prevPage==='' ? 'disabled' : ''}`}>
                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchProjects({page:Number(prevPage)})}>Previous</a>
                        </li>

                        {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index} className={`page-item ${page===Number(index+1) ? 'active' : ''}`} title={`Page ${index+1}`}>
                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchProjects({page:Number(index + 1)})}>{index + 1}</a>
                        </li>
                        ))}

                        <li className={`page-item ${nextPage==='' ? 'disabled' : ''}`}>
                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchProjects({page:Number(nextPage)})}>Next</a>
                        </li>
                    </ul>
                    ) : ('' )}

                    {/* <!--Add Project Modal Start--> */}
                    <AddProjectModal
                    showModal={showAddProjectModal}
                    setShowModal={setShowAddProjectModal}
                    fetchProjects={fetchProjects}
                    limit={limit}
                    page={page} />
                    {/* <!--Add Project Modal End--> */}

                    {/* <!--Delete Project Modal Start--> */}
                    <DeleteProjectModal
                    showModal={showDeleteProjectModal}
                    setShowModal={setShowDeleteProjectModal}
                    projectData={projectData}
                    modalKey={modalKey}
                    fetchProjects={fetchProjects}
                    limit={limit}
                    page={page}  />
                    {/* <!--Delete Project Modal End--> */}

                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Projects
