import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import JoditEditor from 'jodit-react';
import { editEvent, saveEventsToStorage } from '../../../models/EventModel';
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { checkPermission } from '../../../helpers/GeneralHelper';

const UpdateEventModal = ({showModal, setShowModal, eventData, modalKey, fetchEvents}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_event_id, setEventID] = useState('');
    const [event_title, setEventTitle] = useState('');
    const [event_desc, setEventDesc] = useState('');

    const [dateRange, setDateRange] = useState([null, null]);
    const [event_start_date, event_end_date] = dateRange;

    const [errors, setErrors] = useState({event_title:  '',  event_desc: '', event_start_date: '', event_end_date: ''});

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300,
            placeholder: 'Enter description...'
        }),
        []
    );
    
    

    
    const closeModal = () =>{
        setEventID('');
        setEventTitle('');
        setEventDesc('');

        setDateRange([null, null]);
        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (eventData) {
            //console.log(eventData);
          setEventID(eventData._event_id);
          setEventTitle(eventData.title);
          setEventDesc(eventData.desc);

          setDateRange([eventData.start ? new Date(eventData.start) : null, eventData.end ? new Date(eventData.end) : null]);

          setErrors({});
        }
      }, [eventData, modalKey]);

    
      const handleDateChange = (update) =>{
        //console.log(update);
        setDateRange(update);
      }


    const updateEvent = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { event_title, event_desc, event_start_date: event_start_date ? dateFormat(event_start_date, "dd-mm-yyyy") : '', 
          event_end_date: event_end_date ? dateFormat(event_end_date, "dd-mm-yyyy") : '' };
    
          const response = await editEvent(user_data.user_token, _event_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveEventsToStorage(response.data.events);
    
            fetchEvents(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Update Event</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">

                    <div className="col-xl-12">
                        <label className="form-label">Title <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Event Title" 
                        value={event_title} onChange={(e) => setEventTitle(e.target.value)}/>
    
                        
                        {errors.event_title && <p style={{ color: 'red' }}>{errors.event_title}</p>}
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Description <span className="text-danger">*</span></label>

                        <JoditEditor
                            ref={editor}
                            value={event_desc}
                            config={config}
                            onChange={newContent => setEventDesc(newContent)}/>
        
                            {errors.event_desc && <p style={{ color: 'red' }}>{errors.event_desc}</p>}
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Start Date - End Date <span className="text-danger">*</span></label><br/>

                        <DatePicker
                            className="form-control"
                            selectsRange={true}
                            startDate={event_start_date}
                            endDate={event_end_date}
                            onChange={(update) => {setDateRange(update);}}
                            isClearable={true}
                            dateFormat="dd-MM-yyyy" />

                        {errors.event_start_date && <p style={{ color: 'red' }}>{errors.event_start_date}</p>}
                        {errors.event_end_date && <p style={{ color: 'red' }}>{errors.event_end_date}</p>}
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className={`btn btn-primary ${new Date(eventData.start) < new Date() ? 'd-none' : ''} ${checkPermission('event_update')===false ? 'd-none' : ''}`} onClick={updateEvent}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UpdateEventModal;