import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { checkPermission, profilePic } from '../../../helpers/GeneralHelper';
import { utcToLocal } from '../../../helpers/DateHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';

function ProjectReports() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);



  return (
    <>
        {checkPermission('reports_project')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Project Reports - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Project Reports</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Project Reports</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body p-3">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                        <div className="card-title">
                                            
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}

                    


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default ProjectReports
