import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editLeaveApplication, saveLeaveApplicationToStorage, saveLeaveBalanceToStorage } from '../../../models/LeaveModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const ViewLeaveApplicationModal = ({showModal, setShowModal, applicationData, modalKey, fetchApplications, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_leave_id, setLeaveID] = useState('');
    const [user_first_name, setUserFirstName] = useState('');
    const [user_last_name, setUserLastName] = useState('');
    const [leave_type, setLeaveType] = useState('');
    const [leave_reason, setLeaveReason] = useState('');
    const [leave_duration, setLeaveDuration] = useState('');
    const [leave_half_day, setLeaveHalfDay] = useState('');
    const [leave_start_date, setLeaveStartDate] = useState('');
    const [leave_end_date, setLeaveEndDate] = useState('');
    const [leave_date, setLeaveDate] = useState('');
    const [leave_no_of_days, setLeaveNoOfDays] = useState('');
    const [leave_status, setLeaveStatus] = useState('');

    const [errors, setErrors] = useState({leave_status: ''});

    const closeModal = () =>{
        setLeaveID('');
        setUserFirstName('');
        setUserLastName('');
        setLeaveType('');
        setLeaveReason('');
        setLeaveDuration('');
        setLeaveHalfDay('');
        setLeaveStartDate('');
        setLeaveEndDate('');
        setLeaveDate('');
        setLeaveNoOfDays('');
        setLeaveStatus('');

        setErrors({});
    
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (applicationData) {
          setLeaveID(applicationData._leave_id);
          setUserFirstName(applicationData.leave_user_first_name);
          setUserLastName(applicationData.leave_user_last_name);
          setLeaveType(applicationData.leave_type);
          setLeaveReason(applicationData.leave_reason);
          setLeaveDuration(applicationData.leave_duration);
          setLeaveHalfDay(applicationData.leave_half_day);
          setLeaveStartDate(applicationData.leave_start_date);
          setLeaveEndDate(applicationData.leave_end_date);
          setLeaveDate(applicationData.leave_start_date);
          setLeaveNoOfDays(applicationData.leave_no_of_days);
          setLeaveStatus(applicationData.leave_status);
          
          setErrors({});
        }
    }, [applicationData, modalKey]);


    const updateApplication = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { leave_status: leave_status!='pending' ? leave_status : '' };
    
          const response = await editLeaveApplication(user_data.user_token, _leave_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveLeaveApplicationToStorage(response.data.applications, response.data.pagination);

            saveLeaveBalanceToStorage(response.data.balances, response.data.pagination);
    
            fetchApplications(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Leave Application Details</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-4" style={{display: user_data.urole_permission=='*' ? 'block' : 'none'}}>
                        <label htmlFor="client_pincode" className="form-label">Member </label>
                        <div className='form-control'>{user_first_name} {user_last_name}</div>
                    </div> 

                    <div className={`col-xl-${user_data.urole_permission=='*' ? '4' : '6'}`}>
                        <label htmlFor="client_pincode" className="form-label">Leave Type <span className="text-danger">*</span></label>
                        <select className="form-control selectpicker" disabled="true">
                            <option value=''>- Type -</option>
                            <option value='casual' selected={leave_type === 'casual' ? true : false} disabled="true">Casual</option>
                            <option value='planned' selected={leave_type === 'planned' ? true : false} disabled="true">Planned</option>
                        </select>
                    </div>  

                    <div className={`col-xl-${user_data.urole_permission=='*' ? '4' : '6'}`}>
                        <label className="form-label">Duration</label>
                        
                        <br/>

                        <label className="radio-inline">
                            <input type="radio" value="half" checked={leave_duration=='half' ? 'checked' : ''} disabled="true" />&nbsp;Half&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                            <input type="radio" value="single" checked={leave_duration=='single' ? 'checked' : ''} disabled="true" />&nbsp;Single&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                            <input type="radio" value="multiple" checked={leave_duration=='multiple' ? 'checked' : ''} disabled="true" />&nbsp;Multiple
                        </label>
                    </div>

                    <div className="col-xl-6" style={{display: leave_duration=='half' ? 'block' : 'none'}}>
                        <label className="form-label">Which Half ?</label>
                        <br/>

                        <label className="radio-inline">
                        <input type="radio" value="1" checked={leave_half_day=='1' ? 'checked' : ''} disabled="true" />&nbsp;1st Half&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" value="2" checked={leave_half_day=='2' ? 'checked' : ''} disabled="true" />&nbsp;2nd Half&nbsp;&nbsp;
                        </label>
                    </div>

                    <div className="col-xl-6" style={{display: leave_duration=='half' || leave_duration=='single' ? 'block' : 'none'}}>
                        <label className="form-label">Date</label><br/>

                        <div className='form-control'>{dateFormat(leave_start_date, "dd-mm-yyyy")}</div>
                    </div>

                    <div className="col-xl-12" style={{display: leave_duration=='multiple' ? 'block' : 'none'}}>
                        <label className="form-label">Start Date - End Date</label><br/>

                        <div className='form-control'>{dateFormat(leave_start_date, "dd-mm-yyyy")} - {dateFormat(leave_end_date, "dd-mm-yyyy")}</div>
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Reason <span className="text-danger">*</span></label> 

                        <textarea className="form-control" placeholder="Reason" rows="3" value={leave_reason} disabled="true"></textarea> 
                    </div>

                    <div className={`col-xl-4 ${user_data.urole_permission==='*' ? '' : 'd-none'}`}>
                        <label className="form-label">Status <span className="text-danger">*</span></label>
                        <select className="form-control selectpicker" onChange={(e) => setLeaveStatus(e.target.value)} disabled={applicationData.leave_status !== 'pending' ? true : false}>
                            <option value='' hidden>- Status -</option>
                            <option value='approved' selected={leave_status === 'approved' ? true : false} >Approved</option>
                            <option value='rejected' selected={leave_status === 'rejected' ? true : false} >Rejected</option>
                            <option value='cancelled' selected={leave_status === 'cancelled' ? true : false} >Cancelled</option>
                        </select>

                        {errors.leave_status && <p style={{ color: 'red' }}>{errors.leave_status}</p>}
                    </div> 
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>

                {applicationData.leave_status == 'pending' ? (
                <button type="button" className={`btn btn-primary ${user_data.urole_permission==='*' ? '' : 'd-none'}`} onClick={updateApplication}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
                ):('')}
            </Modal.Footer>
        </Modal>
    );

};    

export default ViewLeaveApplicationModal;