import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { editRoleMembers, getUsers, saveRolesToStorage } from '../../../models/UserModel';
import { useTheme } from '../../../context/ThemeContext';
import Select from 'react-select';

const UserRoleMembersModal = ({showModal, setShowModal, roleData, modalKey, fetchRoles}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_urole_id, setRoleID] = useState('');
    const [_user_ids, setUserIds] = useState([]);
    
    const [errors, setErrors] = useState({_user_ids:  ''});


    
    const closeModal = () =>{
        setRoleID('');
        setUserIds([]);

        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (roleData) {
          //console.log(roleData);
          setRoleID(roleData._urole_id);

          if (roleData.users && roleData.users.length > 0) {
            const selectedOptions = roleData.users.map(user => ({
              value: user._user_id,
              label: `${user.user_first_name} ${user.user_last_name}`
            }));

            setUserIds(selectedOptions);
          }
            
          setErrors({});
        }
    }, [roleData, modalKey]);


    //Fetch Users Start
    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

          //console.log(result);

          const userOptions = result.users.map(user => ({
            label: user.user_first_name+' '+user.user_last_name,
            value: user._user_id
          }));
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setUsers(userOptions);
        } catch (error) {
          console.error(error);
        }
    };

    useEffect(() => {
      if (showModal) {
        fetchUsers();
      }
      
    }, [showModal]);
    //Fetch Users End


    const updateRoleMember = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});

          const selected_user_ids = _user_ids.map(_user_id => _user_id.value).join(',');

          const formData = { _user_ids: selected_user_ids};

          const response = await editRoleMembers(user_data.user_token, _urole_id, formData);

          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveRolesToStorage(response.data.user_roles);
    
            fetchRoles(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '15px'}}>Update User List of {roleData.urole_title}</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-12">
                        <label className="form-label">Members <span className="text-danger">*</span></label>
                        <Select
                            value={_user_ids}
                            onChange={setUserIds}
                            options={users}
                            placeholder="- Members -"
                            isSearchable="true"
                            isMulti="true"
                            noOptionsMessage={() => "No member found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })} />

                        {errors._user_ids && <p style={{ color: 'red' }}>{errors._user_ids}</p>}  
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateRoleMember}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UserRoleMembersModal;