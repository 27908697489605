import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createEvent = async(user_token, formData) => {

    const endPoint = "/events/add-event";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editEvent = async(user_token, eventId, formData) => {

    const endPoint = `/events/edit-event/${eventId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 


export const removeEvent = async(user_token, eventId) => {

    const endPoint = `/events/delete-event/${eventId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const saveEventsToStorage = (events) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        events: events,
        time:currentTime
    };

    localStorage.setItem("events", encryptData(dataToStore));
}


export const getEvents = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    if (localStorage.getItem('events')) {
        const saved_events_obj = decryptData(localStorage.getItem('events'));

        return saved_events_obj;
    }
    else{
        try {
            const endPoint = '/events/event-list';
            const url = `${endPoint}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                saveEventsToStorage(response.data.events);

                const saved_notes_obj = decryptData(localStorage.getItem('events'));

                return saved_notes_obj;
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);

            //console.log(error);
        }
    }
};