import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createInvoiceItem, saveInvoicesToStorage } from '../../../models/InvoiceModel';
import CurrencyInput from 'react-currency-input-field';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { createPayment } from '../../../models/PaymentModel';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';
import { getBanks } from '../../../models/BankModel';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";

const AddPaymentModal = ({showModal, setShowModal, modalKey, invoice_unique_id, invoiceData, setInvoiceData, setPayments}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [payment_mode, setPaymentMode] = useState('');
    const [payment_amount, setPaymentAmount] = useState('');
    const [_bank_id, setBankId] = useState('');
    const [payment_date, setPaymentDate] = useState('');
    const [payment_tax_amount, setPaymentTaxAmount] = useState('');
    const [payment_bank_deduction, setPaymentBankDeduction] = useState('');
    const [payment_other_deduction, setPaymentOtherDeduction] = useState('');
    const [payment_bank_name, setPaymentBankName] = useState('');
    const [payment_account_no, setPaymentAccountNo] = useState('');
    const [payment_bank_ifsc, setPaymentBankIfsc] = useState('');
    const [payment_branch, setPaymentBranch] = useState('');
    const [payment_branch_code, setPaymentBranchCode] = useState('');
    const [payment_swift_code, setPaymentSwiftCode] = useState('');
    const [payment_cheque_no, setPaymentChequeNo] = useState('');

    const [payment_note, setPaymentNote] = useState('');


    const [errors, setErrors] = useState({payment_mode:  '',  payment_amount: '', _bank_id: '', payment_date: '', payment_tax_amount: '', 
    payment_bank_deduction: '', payment_other_deduction: '', payment_bank_name: '', payment_account_no: '', payment_bank_ifsc: '',
    payment_branch: '', payment_branch_code: '', payment_swift_code: '', payment_cheque_no: '', payment_note: ''
    });
    

    const closeModal = () =>{
        setPaymentMode('');
        setPaymentAmount('');
        setBankId('');
        setPaymentDate('');
        setPaymentTaxAmount('');
        setPaymentBankDeduction('');
        setPaymentOtherDeduction('');
        setPaymentBankName('');
        setPaymentAccountNo('');
        setPaymentBankIfsc('');
        setPaymentBranch('');
        setPaymentBranchCode('');
        setPaymentSwiftCode('');
        setPaymentChequeNo('');
        setPaymentNote('');

        setErrors({});
    
        setShowModal(false);
    }

    
    
    
    const addPayment = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { payment_mode: payment_mode.value ? payment_mode.value : '', payment_amount, _bank_id: _bank_id.value ? _bank_id.value : '', payment_date: payment_date ? dateFormat(payment_date, "dd-mm-yyyy") : '', 
           payment_tax_amount, payment_bank_deduction,
            payment_other_deduction, payment_bank_name, payment_account_no, payment_bank_ifsc, payment_branch, payment_branch_code,
            payment_swift_code, payment_cheque_no, payment_note };
    
          const response = await createPayment(user_data.user_token, invoice_unique_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            if (response.data.payments) {
                setPayments(response.data.payments);
            }

            if (response.data.invoices && response.data.pagination_invoices) {
                saveInvoicesToStorage(response.data.invoices, response.data.pagination_invoices);
            }
            

            if (response.data.invoice_data) {
                setInvoiceData(response.data.invoice_data);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          //console.log(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
        
    } 


    const [banks, setBanks] = useState([]);
    const fetchBanks = async () => {
        try {
          const result = await getBanks({user_token: user_data.user_token, page:1, limit:50});

          //console.log(result);

          const bankOptions = result.banks.map(bank => ({
            label: bank.bank_name+' ( A/c No : '+bank.bank_account_no+' )',
            value: bank._bank_id
          }));
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setBanks(bankOptions);
        } catch (error) {
          console.error(error);
        }
    };
    
    const [paymentModes, setPaymentModes] = useState([]);
    useEffect(() => {

        if (showModal) {
            fetchBanks();
        
            const paymentModeOptions = 
            [
                {label: 'Bank Transfer', value: 'bank-transfer'},
                {label: 'Cash', value: 'cash'},
                {label: 'Cheque', value: 'cheque'}
            ];
    
            setPaymentModes(paymentModeOptions);
        }

    }, [showModal]);   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
            <Modal.Title>Add Payment</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                <div className="col-xl-6">
                    <label className="form-label">Payment Mode <span className="text-danger">*</span></label>
                    
                    <Select
                        value={payment_mode}
                        onChange={setPaymentMode}
                        options={paymentModes}
                        placeholder="- Payment Mode -"
                        isSearchable="true"
                        noOptionsMessage={() => "No payment mode found"}
                        styles={selectBoxTheme}
                        theme={(selectBoxTheme) => ({
                        ...selectBoxTheme,
                        borderRadius: 0,
                        })} />
                    
                    {errors.payment_mode && <p style={{ color: 'red' }}>{errors.payment_mode}</p>}
                </div>

                <div className="col-xl-6">
                    <label className="form-label">Payment Amount <span className="text-danger">*</span></label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="Enter Amount"
                        prefix={invoiceData.invoice_type==='domestic' ? '₹ ' : '$ '}
                        defaultValue={payment_amount}
                        decimalsLimit={2}
                        allowNegativeValue="false"
                        onValueChange={(value, name, values) => setPaymentAmount(value)}
                    />

                    
                    {errors.payment_amount && <p style={{ color: 'red' }}>{errors.payment_amount}</p>}
                </div>


                <div className="col-xl-6">
                    <label className="form-label">Payment On </label>
                    
                    <Select
                        value={_bank_id}
                        onChange={setBankId}
                        options={banks}
                        placeholder="- Banks -"
                        isSearchable="true"
                        noOptionsMessage={() => "No bank found"}
                        styles={selectBoxTheme}
                        theme={(selectBoxTheme) => ({
                        ...selectBoxTheme,
                        borderRadius: 0,
                        })} />
                    
                    {errors._bank_id && <p style={{ color: 'red' }}>{errors._bank_id}</p>}
                </div>


                <div className="col-xl-6">
                    <label className="form-label">Payment Date <span className="text-danger">*</span></label>
                    
                    <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                            selected={payment_date} onSelect={setPaymentDate} onChange={setPaymentDate} dateFormat="dd-MM-yyyy"/>
                    
                    {errors.payment_date && <p style={{ color: 'red' }}>{errors.payment_date}</p>}
                </div>

                
                <div className="col-xl-4">
                    <label className="form-label">GST Amount </label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="Enter GST Amount"
                        prefix={invoiceData.invoice_type==='domestic' ? '₹ ' : '$ '}
                        defaultValue={payment_tax_amount}
                        decimalsLimit={2}
                        allowNegativeValue="false"
                    />

                    {errors.payment_tax_amount && <p style={{ color: 'red' }}>{errors.payment_tax_amount}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Bank Deduction </label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="Enter Bank Deduction"
                        prefix={invoiceData.invoice_type==='domestic' ? '₹ ' : '$ '}
                        defaultValue={payment_bank_deduction}
                        decimalsLimit={2}
                        allowNegativeValue="false"
                    />

                    {errors.payment_bank_deduction && <p style={{ color: 'red' }}>{errors.payment_bank_deduction}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Other Deduction </label>
                    <CurrencyInput
                        className="form-control"
                        placeholder="Enter Other Deduction"
                        prefix={invoiceData.invoice_type==='domestic' ? '₹ ' : '$ '}
                        defaultValue={payment_other_deduction}
                        decimalsLimit={2}
                        allowNegativeValue="false"
                    />

                    {errors.payment_other_deduction && <p style={{ color: 'red' }}>{errors.payment_other_deduction}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Bank Name </label>
                    <input className='form-control' value={payment_bank_name} onChange={(e) => setPaymentBankName(e.target.value)} placeholder='Bank Name' />

                    {errors.payment_bank_name && <p style={{ color: 'red' }}>{errors.payment_bank_name}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Account No </label>
                    <input className='form-control' value={payment_account_no} onChange={(e) => setPaymentAccountNo(e.target.value)} placeholder='Account No' />

                    {errors.payment_account_no && <p style={{ color: 'red' }}>{errors.payment_account_no}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">IFSC Code </label>
                    <input className='form-control' value={payment_bank_ifsc} onChange={(e) => setPaymentBankIfsc(e.target.value)} placeholder='IFSC Code' />

                    {errors.payment_bank_ifsc && <p style={{ color: 'red' }}>{errors.payment_bank_ifsc}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Swift Code </label>
                    <input className='form-control' value={payment_swift_code} onChange={(e) => setPaymentSwiftCode(e.target.value)} placeholder='Swift Code' />

                    {errors.payment_swift_code && <p style={{ color: 'red' }}>{errors.payment_swift_code}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Branch Name </label>
                    <input className='form-control' value={payment_branch} onChange={(e) => setPaymentBranch(e.target.value)} placeholder='Branch Name' />

                    {errors.payment_branch && <p style={{ color: 'red' }}>{errors.payment_branch}</p>}
                </div>

                <div className="col-xl-4">
                    <label className="form-label">Branch Code </label>
                    <input className='form-control' value={payment_branch_code} onChange={(e) => setPaymentBranchCode(e.target.value)} placeholder='Branch Code' />

                    {errors.payment_branch_code && <p style={{ color: 'red' }}>{errors.payment_branch_code}</p>}
                </div>

                <div className={`col-xl-6 ${payment_mode.value!='cheque' ? 'd-none' : ''}`}>
                    <label className="form-label">Cheque No</label>
                    <input className='form-control' value={payment_cheque_no} onChange={(e) => setPaymentChequeNo(e.target.value)} placeholder='Cheque No' />

                    {errors.payment_cheque_no && <p style={{ color: 'red' }}>{errors.payment_cheque_no}</p>}
                </div>

                <div className="col-xl-6">
                    <label className="form-label">Note</label>
                    <textarea className='form-control' value={payment_note} onChange={(e) => setPaymentNote(e.target.value)} placeholder='Note'></textarea>

                    {errors.payment_note && <p style={{ color: 'red' }}>{errors.payment_note}</p>}
                </div>
               
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={addPayment}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Submit'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default AddPaymentModal;
