import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { getActivities } from '../../models/TimesheetModel';
import ActivityCards from '../skeletons/ActivityCards';
import ViewActivityModal from '../modals/Timesheet/ViewActivityModal';
import { checkPermission, profilePic } from '../../helpers/GeneralHelper';
import { utcToLocal } from '../../helpers/DateHelper';
import { useNotificationCount } from '../../context/NotificationCountContext';
import Error403 from './Error403';
import DatePicker from "react-datepicker";
import { getUsers } from '../../models/UserModel';
import Select from 'react-select';

function Activities() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  const [workDate, setWorkDate] = useState('');


  //Fetch Users Start
  const [_user_id, setUserId] = useState('');
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

      const userOptions = result.users.map(user => ({
        label: user.user_first_name+' '+user.user_last_name,
        value: user._user_id
      }));

      setNotificationCount(localStorage.getItem('unread_notification_count'));

      setUsers(userOptions);
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();  
  }, []); 
  //Fetch Users End

  //Flush Project Start
  const flushActivity = async() => {
      localStorage.removeItem("activities");
      fetchActivities(); 
   }  
  //Flush Project End


  //View Activity Start
  const [showViewActivityModal, setShowViewActivityModal] = useState(false);

  const [activityData, setActivityData] = useState({});

  const openViewActivityModal = (activity_data) =>{
    setActivityData(activity_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowViewActivityModal(true);
  }
  //View Activity End

  //Activity List Start
  const [activities, setActivities] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(12);
  const [prevPage, setPrevPage] = useState('');
  const [nextPage, setNextPage] = useState('');


  const fetchActivities = async (param = {}) => {
    setLoading(true);

    try {
        let page  = param.page ? param.page : 1;
        let limit = param.limit ? param.limit : 12;

        let selected_user_id = _user_id.value ? _user_id.value : '';
  
        let apiParam = {page: page, limit: limit, _user_id: selected_user_id, user_token: user_data.user_token};
        const response = await getActivities(apiParam);

        if (typeof response != typeof undefined) {
            setActivities(response.activities);
            setTotalPages(response.pagination.total_pages);
            setPage(response.pagination.current_page);
            setTotalRows(response.pagination.total_rows);
            setPrevPage(response.pagination.prev_page);
            setNextPage(response.pagination.next_page);
    
            if (response.time) {
                setCacheTime(response.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            navigate('/re-login');
          }  
    
          setLoading(false);
        
    } catch (error) {
        console.error(error);
    }

  }   

  useEffect(() => {  
    if (checkPermission('reports_activities')===true) {
        fetchActivities();
    }
    
  }, [_user_id]); 
  //Activity List End

  return (
    <>
        {checkPermission('reports_activities')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Activities - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Activities</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body p-3">
                                    
                                    <div className='row align-items-center'>

                                    
                                    <div className="col-lg-7">
                                        <p> Last Synced On {cacheTime}</p> 
                                    </div>

                                    <div className={`col-lg-2 ${user_data.urole_permission!='*' ? 'd-none' : ''}`}>

                                                <div className="input-group">
                                                    <Select
                                                        value={_user_id}
                                                        onChange={setUserId}
                                                        options={users}
                                                        placeholder="- Select Member -"
                                                        isSearchable="true"
                                                        noOptionsMessage={() => "No member found"}
                                                        styles={selectBoxTheme}
                                                        theme={(selectBoxTheme) => ({
                                                        ...selectBoxTheme,
                                                        borderRadius: 0
                                                        })} /> 

                                                    <button className="btn btn-light" type="button" onClick={() => setUserId('')}
                                                        style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: _user_id !== '' ? 'block' : 'none'}}>
                                                        x
                                                    </button>    
                                                </div>
                                                
                                    </div>

                                    <div className="col-lg-2 d-none">
                                    <DatePicker className="form-control" placeholderText="DD-MM-YYYY" dateFormat="dd-MM-yyyy"
                                    selected={workDate} onSelect={setWorkDate} onChange={setWorkDate} maxDate={new Date()} />

                                    </div>

                                    
                                    <div className="col-lg-auto ms-auto">
                                    <button className="btn btn-success-light btn-sm" onClick={() => flushActivity()}>
                                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                    </button>
                                    </div>

                                    </div>
                                        
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}

                    {/* <!-- Activity List Start --> */}
                    <div class="row g-3">

                    {loading ? (

                        [1,2,3,4,5,6,7,8,9,10,11,12].map((n) => (
                            <ActivityCards />
                        ))

                    ) : ( 

                        activities.map((activity) => (

                        <div class="col-lg-3 col-md-3 col-sm-6 col-12">

                            <div className="d-flex justify-content-between align-items-center mb-1">
                                <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(activity.user_first_name, activity.user_last_name, activity.user_image)}
                                    </div>

                                    <div>
                                        <div className="lh-1">
                                            <span>{activity.user_first_name} {activity.user_last_name}</span>
                                        </div>
                                    </div>
                                </div>

                                <p className='mb-0'>{utcToLocal(activity.details_create_time, "dd mmm yyyy HH:MM")} </p>
                            </div>

                            <a style={{cursor: 'pointer'}}  className="glightbox card mb-1" onClick={() => openViewActivityModal(activity)}>
                                <img src={`${process.env.REACT_APP_S3_URL}/${activity.details_screenshot_path}`} alt="image" />
                            </a>

                            
                            <p className='mb-1'>{activity.task_title ? activity.task_title : activity.timesheet_other_task}</p>

                        </div>
                        ))
                    )}
                    </div>
                    {/* <!-- Activity List End --> */}



                    {/* <!---Pagination Start---> */}
                    {activities ? (

                    <nav aria-label="Page navigation" className="pagination-style-4 mb-4 mt-4">
                    <ul className="pagination mb-0 flex-wrap">
                        <li className={`page-item ${prevPage==='' ? 'disabled' : ''}`}>
                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:Number(prevPage)})}>
                                Prev
                            </a>
                        </li>

                        {totalPages < 8 ? 
                        ( Array.from({ length: totalPages }).map((_, index) => (
                                <li className={`page-item ${page===Number(index+1) ? 'active' : ''}`} title={`Page ${index+1}`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:Number(index + 1)})}>{index + 1}</a>
                                </li>
                            ))
                        ) : (   
                            <>
                                <li className={`page-item ${page===1 ? 'active' : ''}`} title={`Page 1`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:1})}>1</a>
                                </li>
                                <li className={`page-item ${page===2 ? 'active' : ''}`} title={`Page 2`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:2})}>2</a>
                                </li>
                                <li className={`page-item ${page > 3 ? '' : 'd-none'}`}>
                                    <a className="page-link" href="javascript:void(0);">
                                        <i className="bi bi-three-dots"></i>
                                    </a>
                                </li>
                                <li className={`page-item ${page > 2 && page < totalPages-1  ? 'active' : 'd-none'}`} title={`Page ${page}`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:page})}>{page}</a>
                                </li>
                                <li className={`page-item ${page < totalPages-2 ? '' : 'd-none'}`}>
                                    <a className="page-link" href="javascript:void(0);">
                                        <i className="bi bi-three-dots"></i>
                                    </a>
                                </li>
                                <li className={`page-item ${page===totalPages-1 ? 'active' : ''}`} title={`Page ${totalPages-1}`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:totalPages-1})}>{totalPages-1}</a>
                                </li>
                                <li className={`page-item ${page===totalPages ? 'active' : ''}`} title={`Page ${totalPages}`}>
                                    <a className="page-link" href="javascript:void(0);" onClick={() => fetchActivities({page:totalPages})}>{totalPages}</a>
                                </li>
                            </>    
                                
                        )}


                        <li className={`page-item ${nextPage==='' ? 'disabled' : ''}`}>
                            <a className="page-link text-primary" href="javascript:void(0);" onClick={() => fetchActivities({page:Number(nextPage)})}>
                                next
                            </a>
                        </li>
                    </ul>
                    </nav>
                    ) : ('' )}
                    {/* <!---Pagination End---> */}


                    {/* <!--View Activity Modal Start--> */}
                    <ViewActivityModal
                    showModal={showViewActivityModal}
                    setShowModal={setShowViewActivityModal}
                    activityData={activityData}
                    modalKey={modalKey}
                    fetchActivities={fetchActivities}
                    limit={limit}
                    page={page}  />
                    {/* <!--View Activity Modal End--> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default Activities
