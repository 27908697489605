import React, { createContext, useContext, useReducer, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
import { decryptData } from '../helpers/EncryptHelper';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const initialState = {
    user_data: localStorage.getItem('user_data') ? decryptData(localStorage.getItem('user_data')) : {},
    isAuthenticated: localStorage.getItem('worktracker_login'),
  };

  const authReducer = (state, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          user_data: action.payload.user_data,
          isAuthenticated: true,
        };
      case 'LOGOUT':
        return {
          ...state,
          user_data: {},
          isAuthenticated: false,
      };
      case 'UPDATE_USER_DATA':
      return {
        ...state,
        user_data: action.payload.user_data,
      };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(authReducer, initialState);
  //const navigate = useNavigate();

  const checkAuthAndRedirect = () => {
    if (!state.isAuthenticated) {
      // Redirect to login page or perform other authentication handling
      // For simplicity, let's assume you have a function to handle redirection
      //navigate('/login');
    }
  };

  useEffect(() => {
    // Perform any initial authentication check here, e.g., check stored tokens
    // For simplicity, let's assume you have a function to handle this
    const initialAuthCheck = () => {
      // Check authentication status and update state accordingly
      const isAuthenticated = localStorage.getItem('worktracker_login');
      if (isAuthenticated) {
        const user_data = decryptData(localStorage.getItem('user_data'));
        dispatch({ type: 'LOGIN', payload: { user_data } });
      }
      else{
        //navigate('/login');
      }
    };

    initialAuthCheck();
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch, checkAuthAndRedirect }}>
      {children}
    </AuthContext.Provider>
  );
};
