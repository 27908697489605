import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { profilePic } from '../../../helpers/GeneralHelper';
import { utcToLocal } from '../../../helpers/DateHelper';
import { getLoginVsWorkReport } from '../../../models/ReportModel';
import LoginVsWorkTables from '../../tables/LoginVsWorkTables';
import { useNotificationCount } from '../../../context/NotificationCountContext';

function LoginVsWork() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const location = useLocation();
  const currentRoute = location.pathname;  

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  const [loginDate, setLoginDate] = useState('');

  useEffect(()=>{
    setLoginDate(new Date());
  }, []);


  //Flush Report Start
  const flushLoginVsWorkReport = async() => {
    fetchLoginVsWorkReport(); 
  }  
  //Flush Report End


  //Fetch Report Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const fetchLoginVsWorkReport = async() => {
    setLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token};

        const response = await getLoginVsWorkReport(user_data.user_token, loginDate);

        if (response.status===true) {
        
            if (response.data.login_hours) {
                setData(response.data.login_hours);
            }
            
            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }

            toast.error(response.message);
        }      

        setLoading(false);

    } catch (error) {
    
    //console.log(error);
    }
    
  };

  useEffect(()=>{
    fetchLoginVsWorkReport();
  }, [loginDate]);
  //Fetch Report End

  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Login Hours vs Work Hours Report - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Login Hours vs Work Hours</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Activities</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    

                                    <div className="d-flex flex-wrap gap-2">

                                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" dateFormat="dd-MM-yyyy"
                                        selected={loginDate} onSelect={setLoginDate} onChange={setLoginDate} maxDate={new Date()} />

                                       
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <LoginVsWorkTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          fetchLoginVsWorkReport={fetchLoginVsWorkReport} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default LoginVsWork
