import dateFormat from "dateformat";
import { numberToOrdinal } from "./GeneralHelper";

export const leaveApplicationStatus = (status) => {
    if (status=='pending') {
        return <span className="badge bg-warning-transparent">Pending</span>;
    }
    else if (status=='approved') {
        return <span className="badge bg-success-transparent">Approved</span>;
    }
    else if (status=='rejected') {
        return <span className="badge bg-danger-transparent">Rejected</span>;
    }
    else if (status=='cancelled') {
        return <span className="badge bg-secondary-transparent">Cancelled</span>;
    }
    else if (status=='request') {
        return <span className="badge bg-warning-transparent">Request</span>;
    }
    else if (status=='allowed') {
        return <span className="badge bg-success-transparent">Allowed</span>;
    }
};


export const leaveDates = (application_data) => {

    let leave_dates = dateFormat(application_data.leave_start_date, "dd mmm yyyy");

    if (application_data.leave_duration==="half") {
        leave_dates = <span>
                        {numberToOrdinal(application_data.leave_half_day)} half
                        <br/> of<br />
                        {dateFormat(application_data.leave_start_date, "dd mmm yyyy")}
                      </span>;
    }
    else if (application_data.leave_duration=="multiple") {
        leave_dates = <span>{dateFormat(application_data.leave_start_date, "dd mmm yyyy")}
                      <br/> to<br />
                      {dateFormat(application_data.leave_end_date, "dd mmm yyyy")}
                      </span>;
                      
    }

    return leave_dates;
}    