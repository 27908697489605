import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../config/ApiConfig';
import { useAuth } from '../../context/AuthContext';
import { profilePic } from '../../helpers/GeneralHelper';
import { getTimesheets } from '../../models/TimesheetModel';
import TimesheetTables from '../tables/TimesheetTables';
import { useNotificationCount } from '../../context/NotificationCountContext';
import ViewTimesheetModal from '../modals/Timesheets/ViewTimesheetModal';



function Timesheets() {

    const { notificationCount, setNotificationCount } = useNotificationCount();
    
    const { state } = useAuth(); 

    let user_data = state.user_data;
  
    const navigate = useNavigate();  
  
    const loader = document.getElementById('loading');

    const [modalKey, setModalKey] = useState(0);

    const [timesheetWorkStatus, setTimesheetWorkStatus] = useState('');

    //View Work Start
    const [showViewWorkModal, setShowViewWorkModal] = useState(false);

    const [workData, setWorkData] = useState({});

    const openViewWorkModal = (timesheet_data) =>{
        setWorkData(timesheet_data);
        setModalKey(prevKey => prevKey + 1);
        setShowViewWorkModal(true);
    }  
    //View Work End


    //Flush Timesheet Start
    const flushTimesheets = async() => {
        localStorage.removeItem("timesheets");
        fetchTimesheets(limit, page); 
    }  
    //Flush Timesheet End
    
    //Timesheet List Start
    const [data, setData] = useState([]);
    const [cacheTime, setCacheTime] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    

    const fetchTimesheets = async(newLimit, page) => {
        setLoading(true);
    
        try {            
            let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

            const response = await getTimesheets(apiParam);

            if (typeof response != typeof undefined) {
                setData(response.timesheets);
                setTotalRows(response.pagination.total_rows);
                setLimit(newLimit);
                setPage(page);

                setTimesheetWorkStatus(response.timesheet_is_running);

                if (response.time) {
                    setCacheTime(response.time);
                }

                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                navigate('/re-login');
            }    
            

            setLoading(false);
    
        } catch (error) {
            //console.error(error);
        }
    
        
    };
    //Timesheet List End

     
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Timesheet - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Timesheet</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Timesheet</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-success-light btn-sm" onClick={() => flushTimesheets()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <TimesheetTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchTimesheets={fetchTimesheets}
                                          openViewWorkModal={openViewWorkModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}

                    {/* <!--View Timesheet Modal Start--> */}
                    <ViewTimesheetModal
                    showModal={showViewWorkModal}
                    setShowModal={setShowViewWorkModal}
                    timesheetData={workData}
                    modalKey={modalKey}
                    fetchTimesheets={fetchTimesheets}
                    limit={limit}
                    page={page} />
                    {/* <!--View Timesheet Modal End--> */}



                </div>


            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Timesheets
