import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import RoleList from '../skeletons/RoleList';

const UserRoleTables = ({
    data,
    setData,
    loading,
    setLoading,
    fetchRoles,
    openUpdateRoleModal, 
    openDeleteRoleModal,
    openUpdateMembersModal,
    openUpdatePermissionsModal
}) => {

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
        <RoleList/>
    );

    useEffect(()=>{
        fetchRoles();  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{index+1}.</div>,
            width: '50px'
        },
        {
            name: "Role",
            selector: (row) => row.urole_title
        },
        {
          name: "All Permission",
          selector: (row) => 
          {
            if (row.urole_permission==='*') {
                return (
                    <>
                      <span className="badge bg-success-transparent">Yes</span>
                    </>
                  );
            }
            else{
                return (
                    <>
                      <span className="badge bg-primary-transparent">No</span>
                    </>
                  );
            }    
          }
        },
        {
            name: "No of User",
            selector: (row) => row.user_count
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit" onClick={() => openUpdateRoleModal(row)}>
                              <i className='bx bxs-edit'></i>
                            </button>

                            <button type="button" className="btn btn-icon btn-sm btn-primary" title="Members" onClick={() => openUpdateMembersModal(row)}>
                              <i className='bi bi-people'></i>
                            </button>

                            <button type="button" className={`btn btn-icon btn-sm btn-warning ${row.urole_permission==='*' ? 'd-none' : ''}`} title="Permission" onClick={() => openUpdatePermissionsModal(row)}>
                              <i className='bi bi-unlock'></i>
                            </button>
    
                            <button type="button" className={`btn btn-icon btn-sm btn-danger ${row.urole_permission==='*' ? 'd-none' : ''}`} title="Delete" onClick={() => openDeleteRoleModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage User Roles`} 
            columns={columns} 
            data={data} 
            progressPending={loading} 
            progressComponent={<CustomLoader />} 
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default UserRoleTables;