import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { checkPermission, profilePic } from '../../../helpers/GeneralHelper';
import { utcToLocal } from '../../../helpers/DateHelper';
import { getUsers } from '../../../models/UserModel';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useFinancialYear } from '../../../context/FinancialYearContext';
import { decryptData } from '../../../helpers/EncryptHelper';
import { getPerformanceReports } from '../../../models/ReportModel';
import PerformanceReportLoginTimeTables from '../../tables/PerformanceReportLoginTimeTables';
import PerformanceReportWorkTimeTables from '../../tables/PerformanceReportWorkTimeTables';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';

function PerformanceReports() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const location = useLocation();
  const currentRoute = location.pathname;   

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { financialYear } = useFinancialYear();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');


  //Fetch Users Start
  const [_user_id, setUserId] = useState('');
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

      const userOptions = result.users.map(user => ({
        label: user.user_first_name+' '+user.user_last_name,
        value: user._user_id
      }));

      setNotificationCount(localStorage.getItem('unread_notification_count'));

      setUsers(userOptions);
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();  
  }, []); 
  //Fetch Users End


  //Fetch Performance Reports Start
  const [loginTimes, setLoginTimes] = useState([]);
  const [workTimes, setWorkTimes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPerformanceReports = async() => {
    try {
        const selected_user_id = _user_id.value ? _user_id.value : '';

        const response = await getPerformanceReports(user_data.user_token, selected_user_id);

        if (response.status===true) {

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        
            if (response.data.login_time) {
                setLoginTimes(response.data.login_time);
            }

            if (response.data.work_time) {
                setWorkTimes(response.data.work_time);
            }
        
        }
        else{
            if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                navigate(`/re-login?redirect=${currentRoute}`);
            }

            toast.error(response.message);
        }      

       setLoading(false);
        
    } catch (error) {
        //console.error(error);
    }
  }
  //Fetch Performance Reports End



  return (
    <>
        {checkPermission('reports_performance')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Performance Reports - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Performance Reports</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Performance Reports</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className={`row ${user_data.urole_permission!='*' ? 'd-none' : ''}`}>
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-body p-3">
                                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                        <div className="card-title">
                                            <div className='row'>
                                                <div className={`col-xl-12 ${user_data.urole_permission!='*' ? 'd-none' : ''}`}>

                                                    <div className="input-group">
                                                        <Select
                                                            value={_user_id}
                                                            onChange={setUserId}
                                                            options={users}
                                                            placeholder="- Select Member -"
                                                            isSearchable="true"
                                                            noOptionsMessage={() => "No member found"}
                                                            styles={selectBoxTheme}
                                                            theme={(selectBoxTheme) => ({
                                                            ...selectBoxTheme,
                                                            borderRadius: 0
                                                            })} /> 

                                                        <button className="btn btn-light" type="button" onClick={() => setUserId('')}
                                                            style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: _user_id !== '' ? 'block' : 'none'}}>
                                                            x
                                                        </button>    
                                                    </div>
                                                    
                                                </div>

                                                
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}

                    
                    {/* <!-- Start::row-2 --> */}
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Login Time
                                    </div>
                                </div>
                                <div className="card-body p-3" style={{minHeight: '250px'}}>
                                    <PerformanceReportLoginTimeTables 
                                        loginTimes={loginTimes}
                                        setLoginTimes={setLoginTimes}
                                        loading={loading}
                                        setLoading={setLoading}
                                        fetchPerformanceReports={fetchPerformanceReports}
                                        _user_id={_user_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-2 --> */}


                    {/* <!-- Start::row-3 --> */}
                    <div className="row mb-3">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Work Time
                                    </div>
                                </div>
                                <div className="card-body p-3" style={{minHeight: '250px'}}>
                                    <PerformanceReportWorkTimeTables 
                                        workTimes={workTimes}
                                        setWorkTimes={setWorkTimes}
                                        loading={loading}
                                        setLoading={setLoading}
                                        fetchPerformanceReports={fetchPerformanceReports}
                                        _user_id={_user_id} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-3 --> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default PerformanceReports
