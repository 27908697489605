import { GET, POST, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";



export const getActivities = async(user_token, param={}) => {

    if (!user_token) {
        return [];
    }

    const queryString = new URLSearchParams(param).toString();

    try {
        const endPoint = '/activities';

        const url =  `${endPoint}?${queryString}`;
        
        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}