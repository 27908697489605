import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { apiError } from '../../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../../config/ApiConfig';
import { tableCustomLoader } from '../../../helpers/StyleHelper';
import { useAuth } from '../../../context/AuthContext';
import { checkPermission, profilePic } from '../../../helpers/GeneralHelper';
import { useTheme } from '../../../context/ThemeContext';
import { getLeaveBalances } from '../../../models/LeaveModel';
import LeaveBalanceTables from '../../tables/LeaveBalanceTables';
import ViewLeaveBalanceModal from '../../modals/Leave/ViewLeaveBalanceModal';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';



function LeaveBalances() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();  

  const { state } = useAuth(); 

  let user_data = state.user_data;
  
  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading');  

  const [modalKey, setModalKey] = useState(0);

  //View Balance Start
  const [showViewBalanceModal, setShowViewBalanceModal] = useState(false);

  const [balanceData, setBalanceData] = useState({});

  const openViewBalanceModal = (leave_data) =>{
    setBalanceData(leave_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowViewBalanceModal(true);
  }  
  //View Balance End

  //Flush Balance Start
  const flushBalance = async() => {
    localStorage.removeItem("leave_balances");
    fetchBalances(limit, page); 
  }  
  //Flush Balance End


  //Balance List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchBalances = async(newLimit, page) => {
    setLoading(true);

    try {
      let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

      const response = await getLeaveBalances(apiParam);

      if (typeof response != typeof undefined) {
        setData(response.balances);
        setTotalRows(response.pagination.total_rows);
        setLimit(newLimit);
        setPage(page);

        if (response.time) {
            setCacheTime(response.time);
        }

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      }
      else{
        navigate('/re-login');
      }  
      
      setLoading(false);
    } catch (error) {
      //console.error(error);
    }

    
  };
  //Balance List End

  
    
  return (
    <>
        {checkPermission('menu_leave')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Leave Balances - Worktracker Anciletech</title>
                
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Leave Balances</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Leave Balances</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                      Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-success-light btn-sm" onClick={() => flushBalance()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                      <LeaveBalanceTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchBalances={fetchBalances}
                                          openViewBalanceModal={openViewBalanceModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!--View Leave Balance Modal Start--> */}
                    <ViewLeaveBalanceModal
                    showModal={showViewBalanceModal}
                    setShowModal={setShowViewBalanceModal}
                    balanceData={balanceData}
                    modalKey={modalKey}
                    fetchBalances={fetchBalances}
                    limit={limit}
                    page={page} />
                    {/* <!--View Leave Balance Modal End--> */}


                </div>


            </div>  

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default LeaveBalances
