import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { checkPermission, profilePic } from '../../../helpers/GeneralHelper';
import { formatDate, secondsToTime, utcToLocal } from '../../../helpers/DateHelper';
import DatePicker from "react-datepicker";
import { getCompareReport } from '../../../models/ReportModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';

function CompareReport() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const location = useLocation();
  const currentRoute = location.pathname;  

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

  const [dateRange, setDateRange] = useState([null, null]);
  const [date_from, date_to] = dateRange;

  const [errors, setErrors] = useState({date_from:  '',  date_to: ''});

  
  //Fetch Compare Report Start
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState('');

  const fetchCompareReport = async() => {

    if (date_from && date_to) {
        setLoading(true);

        try {

            const response = await getCompareReport(user_data.user_token, date_from ? dateFormat(date_from, "dd-mm-yyyy") : '', date_to ? dateFormat(date_to, "dd-mm-yyyy") : '');

            if (response.status===true) {
                if (response.data.compare_report) {
                    setReports(response.data.compare_report);
                }

                setNotificationCount(localStorage.getItem('unread_notification_count'));
            } 
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }

                toast.error(response.message);
            }  
            
            setLoading(false);
            
        } catch (error) {
            //console.log(error);
        }
    }
    else{
        setReports([]);
        setLoading('');
    }
    
  }

  useEffect(()=>{
    if (checkPermission('reports_compare')===true) {
        fetchCompareReport();
    }
    
  }, [date_from, date_to]);
  //Fetch Compare Report End

  return (
    <>
        {checkPermission('reports_compare')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Compare Report - Worktracker Anciletech </title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Compare Report</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Compare Report</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}


                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    

                                    <div className="d-flex flex-wrap gap-2">

                                        <DatePicker
                                            className="form-control"
                                            selectsRange={true}
                                            startDate={date_from}
                                            endDate={date_to}
                                            onChange={(update) => {setDateRange(update);}}
                                            isClearable={true}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="From Date - To Date"
                                            maxDate={new Date()} />

                                        {errors.date_from && <p style={{ color: 'red' }}>{errors.date_from}</p>}
                                        {errors.date_to && <p style={{ color: 'red' }}>{errors.date_to}</p>}
                                    </div>
                                </div>

                                <div className="card-body p-3">
                                    
                                    {reports.length > 0 ? (
                                        
                                        <div className="table-responsive">
                                            <table className="table text-nowrap table-default table-striped">
                                                
                                                <tbody>
                                                    <tr>
                                                        <th scope="col" rowSpan={2} style={{backgroundColor: '#f0f1f7', textAlign: 'center',position: 'sticky', left: 0, zIndex: 1}}>#</th>
                                                        <th scope="col" rowSpan={2} style={{backgroundColor: '#f0f1f7', textAlign: 'center',position: 'sticky', left: 40, zIndex: 1}}>Team Member</th>

                                                        {reports[0].dates.map((date, index) => (
                                                            <th colSpan={4} style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>
                                                                {formatDate(date.date, "dd-mm-yyyy")}
                                                            </th>
                                                        ))}   

                                                    </tr>
                                                    <tr>

                                                        {reports[0].dates.map((date, index) => (
                                                            <>
                                                            
                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>Login Time</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>Work Time</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>Idle Time</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>Activity %</th>

                                                            </>
                                                        ))} 

                                                    </tr>
                                                    {reports.map((report, index) => (
                                                    <tr>
                                                        <td scope="row" style={{position: 'sticky', left: 0, zIndex: 1}}>{index+1}</td>
                                                        <td style={{position: 'sticky', left: 40, zIndex: 1}}>
                                                            <div className="d-flex align-items-center">
                                                                <div className="avatar avatar-sm me-2 avatar-rounded">
                                                                    {profilePic(report.user_first_name, report.user_last_name, report.user_image)}
                                                                </div>
                                                                <div>
                                                                    <div className="lh-1">
                                                                        <span>{report.user_first_name} {report.user_last_name}</span>
                                                                    </div>
                                                                    <div className="lh-1">
                                                                        <span className="fs-11 text-muted">{report.user_email}</span>
                                                                    </div>
                                                                    <div className="lh-1">
                                                                        <span className="fs-11 text-muted">{report.user_mobile}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        {report.dates.map((date, index) => (
                                                            <>
                                                            
                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>{secondsToTime(date.login_seconds)}</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>{secondsToTime(date.work_seconds)}</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>{secondsToTime(date.idle_time)}</th>

                                                                <th style={{backgroundColor: index%2==0 ? '#DCE6F1' : '#95D3D7', textAlign: 'center'}}>{date.activity} %</th>

                                                            </>
                                                        ))}
                                                         
                                                    </tr>
                                                    ))}
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    ) : (
                                        <div className="alert alert-danger" role="alert">
                                            Enter date range to get report
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-1 --> */}

                    


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default CompareReport
