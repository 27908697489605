import React, { useEffect,  useRef,  useState }  from 'react'

function EventCalendar() { 

    return (
        <>
            <div className="row">
                <div className="col-xl-3">
                    <div className="card custom-card placeholder-glow">
                        <div className="card-header d-grid">
                            <button className="btn btn-light btn-wave placeholder col-12"></button>
                        </div>
                        <div className="card-body p-0">
                            
                            <div className="p-3">
                                <div className="d-flex align-items-center justify-content-between">
                                <h6 className="fw-semibold placeholder col-2">
                                    
                                </h6>
                                <button className="btn btn-success-light btn-sm btn-wave placeholder col-4">
                                    
                                </button>
                                </div>
                            </div>
                            <div className="p-3 border-bottom" id="full-calendar-activity">
                                <ul className="list-unstyled mb-0 fullcalendar-events-activity">
                                
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <p className="mb-1 fw-semibold placeholder col-6 me-1"></p>
                                            <span className="badge bg-light text-default mb-1 placeholder col-4"></span>
                                        </div>

                                        <div className="hstack gap-2 fs-15">
                                            <button type="button" className="btn btn-icon btn-sm btn-light placeholder col-2"></button>

                                            <button type="button" className='btn btn-icon btn-sm btn-light placeholder col-2'></button>
                                        </div>
                                        <p className="mb-0 text-muted fs-12 placeholder col-6"></p>
                                    </li>
                                
                                
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                </div>
                <div className="col-xl-9">
                    <div className="card custom-card placeholder-glow">
                        <div className="card-header">
                            <div className="card-title placeholder col-2"></div>
                        </div>
                        <div className="card-body calendar-skeleton">
                            <h4 className='placeholder col-3 mb-2'></h4>
                            <ul className='ps-0'>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                                <li className='placeholder col-12' style={{height:'100px', width:'auto'}}></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}  

export default EventCalendar