import { GET, POST, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const getLocationMap = async(user_token, date, _user_ids='') => {

    if (!user_token) {
        return [];
    }

    if (!date) {
        const currentDate = new Date();
        
        date = new Date();
    }

    // Format date in DD-MM-YYYY format
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).replace(/\//g, '-');
    date = formattedDate;

    try {
        const endPoint = '/dashboard/user-working-location-map';
        const url = `${endPoint}?date=${date}&_user_ids=${_user_ids}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }

}   



export const getLoginVsWorkLineGraph = async(user_token, _user_id='') => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/dashboard/login-vs-work-hours';
        const url = `${endPoint}?_user_id=${_user_id}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }

}    


export const getInvoiceVsPaymentLineGraph = async(user_token) => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/dashboard/invoice-vs-payment';
        const url = `${endPoint}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }

}  


export const getIncomeVsExpensePieGraph = async(user_token, month='') => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/dashboard/invoice-vs-expense';
        const url = `${endPoint}?month=${month}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }

}  