import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import FinYearList from '../skeletons/FinYearList';


const FinYearTables = ({
    finYears,
    setFinYears,
    finYearLoading,
    setFinYearLoading,
    fetchFinancialYears,
    openUpdateFinYearModal
}) => {

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
        <FinYearList/>
    );


    useEffect(()=>{
        fetchFinancialYears();  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{index+1}.</div>,
            width: '50px'
        },
        {
            name: "Year",
            selector: (row) => row.fin_year
        },
        {
            name: "Is Current",
            selector: (row) => 
            {
                if (row.fin_year_is_current==='1') {
                    return (
                        <>
                          <span className="badge bg-success-transparent">Yes</span>
                        </>
                      );
                }
                else{
                    return (
                        <>
                          <span className="badge bg-danger-transparent">No</span>
                        </>
                      );
                }    
              }
        },
        {
            name: "Action",
            cell: row => <button type="button" className={`btn btn-icon btn-sm btn-info ${row.fin_year_is_current=='1' ? 'd-none' : ''}`} title="Edit" onClick={() => openUpdateFinYearModal(row)}>
                            <i className='bx bxs-edit'></i>
                         </button>
                        
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Financial Year`} 
            columns={columns} 
            data={finYears} 
            progressPending={finYearLoading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default FinYearTables;