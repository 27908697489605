// LoginLayout.js
import React from 'react';
import { Helmet } from 'react-helmet';

const LoginLayout = ({ children }) => {
  return (
    <div>
      <Helmet>
      
        <link id="style" href={`${process.env.REACT_APP_BASE_URL}/assets/libs/bootstrap/css/bootstrap.min.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/css/icons.css`} rel="stylesheet" />

        <link href={`${process.env.REACT_APP_BASE_URL}/assets/css/styles.css`} rel="stylesheet" />
      </Helmet>

      {/* Render the page content */}
      {children}

      <Helmet>
        <script src={`${process.env.REACT_APP_BASE_URL}/assets/libs/bootstrap/js/bootstrap.bundle.min.js`}></script>
      </Helmet>
    </div>
  );
};

export default LoginLayout;
