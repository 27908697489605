import { GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createLeaveApplication = async(user_token, formData) => {

    const endPoint = "/leave/apply-leave";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editLeaveApplication = async(user_token, leaveId, formData) => {

    const endPoint = `/leave/update-leave-application-status/${leaveId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 


export const saveLeaveApplicationToStorage = (applications, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        applications: applications,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("leave_applications", encryptData(dataToStore));
};



export const getLeaveApplications = async(param = {}) => {
    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    let user_unique_id = param.user_unique_id ? param.user_unique_id : '';

    //For Own data which will be cached start
    if (user_unique_id=='') {
        if (page==1 && limit==10 && localStorage.getItem('leave_applications')) {
            const saved_leave_applications_obj = decryptData(localStorage.getItem('leave_applications'));
    
            return saved_leave_applications_obj;
        }
        else{
            try {
                const endPoint = '/leave/application-list';
                const url = `${endPoint}?page=${page}&limit=${limit}`;
        
                setAuthToken(user_token);
                
                const response = await GET(url);
        
                if (response.status) {
                    if (page==1 && limit==10) {
                        saveLeaveApplicationToStorage(response.data.applications, response.data.pagination);
    
                        const saved_leave_applications_obj = decryptData(localStorage.getItem('leave_applications'));
    
                        return saved_leave_applications_obj;
                    }
                    else{
                        localStorage.removeItem("leave_applications");
                        return response.data;
                    }
                }
                else{
                    toast.error(response.message);
                }    
                
            } catch (error) {
                apiError(error);
            }
        }
    }
    //For Own data which will be cached end

    //For Other data which will not be cached start
    else{
        try {
            const endPoint = '/leave/application-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&user_unique_id=${user_unique_id}`;
        
            setAuthToken(user_token);
                
            const response = await GET(url);

            if (response.status) {
                return response.data;
            }    
            else{
                toast.error(response.message);
            }


        } catch (error) {
            apiError(error);
        }
    }
    //For Other data which will not be cached end

    
};




export const saveLeaveBalanceToStorage = (balances, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        balances: balances,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("leave_balances", encryptData(dataToStore));
};



export const getLeaveBalances = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;
    
    let user_unique_id = param.user_unique_id ? param.user_unique_id : '';

    //For Own data which will be cached start
    if (user_unique_id=='') {
        if (page==1 && limit==10 && localStorage.getItem('leave_balances')) {
            const saved_leave_balances_obj = decryptData(localStorage.getItem('leave_balances'));
    
            return saved_leave_balances_obj;
        }
        else{
            try {
                const endPoint = '/leave/balance-list';
                const url = `${endPoint}?page=${page}&limit=${limit}`;
        
                setAuthToken(user_token);
                
                const response = await GET(url);
        
                if (response.status) {
                    if (page==1 && limit==10) {
                        saveLeaveBalanceToStorage(response.data.balances, response.data.pagination);
    
                        const saved_leave_balances_obj = decryptData(localStorage.getItem('leave_balances'));
    
                        return saved_leave_balances_obj;
                    }
                    else{
                        //localStorage.removeItem("leave_balances");
                        return response.data;
                    }
                }
                else{
                    toast.error(response.message);
                }    
                
            } catch (error) {
                apiError(error);
            }
        }
    }    
    //For Own data which will be cached end

    //For Other data which will not be cached start
    else{
        try {
            const endPoint = '/leave/balance-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&user_unique_id=${user_unique_id}`;
        
            setAuthToken(user_token);
                
            const response = await GET(url);

            if (response.status) {
                return response.data;
            }    
            else{
                toast.error(response.message);
            }


        } catch (error) {
            apiError(error);
        }
    }
    //For Other data which will not be cached end

    
};


export const editLeaveBalance = async(user_token, user_unique_id, formData) => {

    const endPoint = `/leave/update-leave-balance/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 