import React from 'react'
import { Link } from 'react-router-dom';
import config from '../../config/config'

function Footer() {


  return (
    <>
      <footer className="footer mt-auto py-3 bg-white text-center">
        <div className="container">
            <span className="text-muted"> © 2024 AncileTech. All Rights Reserved | Designed & Developed by <Link to="https://anciletech.com" target="_blank">Ancile Technologies Pvt. Ltd.</Link> - <strong>v{config.version}</strong> 
            </span>
        </div>
      </footer>

      {/* <!-- SCROLL-TO-TOP --> */}
      <div className="scrollToTop">
              <span className="arrow"><i className="ri-arrow-up-s-fill fs-20"></i></span>
      </div>
      <div id="responsive-overlay"></div>
    </>
  )
}

export default Footer
