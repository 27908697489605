import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editRolePermissions, saveRolesToStorage } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Module from '../../../components/Module';


const UpdateUserRolePermissionModal = ({showModal, setShowModal, roleData, modalKey, fetchRoles}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_urole_id, setRoleID] = useState('');
    const [urole_permission, setUrolePermission] = useState('');

    const [preSelectedModules, setPreSelectedModules] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    
    const [errors, setErrors] = useState({urole_permission:  ''});


    
    const closeModal = () =>{
        setRoleID('');
        setUrolePermission('');

        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (roleData) {
            setRoleID(roleData._urole_id);

            if (roleData.urole_permission) {
                setUrolePermission(roleData.urole_permission);

                const modules = roleData.urole_permission.split(',');

                const selectedOptions = modules.map(module => ({ value: module, label: module }));

                //console.log(modules);

                setSelectedModules(modules);

                //setPreSelectedModules(modules);
            }

            setErrors({});
        }
      }, [roleData, modalKey]);


    const updateRolePermission = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { urole_permission: selectedModules.map(module => module).join(',')};
    
          const response = await editRolePermissions(user_data.user_token, _urole_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveRolesToStorage(response.data.user_roles);
    
            fetchRoles(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '15px'}}>Update Role Permission of {roleData.urole_title}</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">

                    <h4 style={{textAlign: 'center'}}>Modules & Sub-modules</h4>

                    <Module
                        value="menu_events"
                        label="Events"
                        submodules={[
                            { value: "event_add", label: "Add Event" },
                            { value: "event_update", label: "Update Event" },
                            { value: "event_delete", label: "Delete Event" }
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />

                    <Module
                        value="menu_clients"
                        label="Clients"
                        submodules={[
                            { value: "clients_add", label: "Add Client" },
                            { value: "clients_edit", label: "Update Client" },
                            { value: "clients_delete", label: "Delete Client" }
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />


                    <Module
                        value="menu_projects"
                        label="Projects"
                        submodules={[
                            { value: "project_add", label: "Add Project" },
                            { value: "project_edit", label: "Update Project" },
                            { value: "project_delete", label: "Delete Project" }
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />


                    <Module
                        value="menu_invoice"
                        label="Invoices"
                        submodules={[
                            { value: "invoice_add", label: "Add Invoice" },
                            { value: "invoice_update", label: "Update Invoice" },
                            { value: "invoice_delete", label: "Delete Invoice" },
                            { value: "invoice_payment_add", label: "Add Payment" },
                            { value: "invoice_payment_update", label: "Update Payment" },
                            { value: "invoice_payment_delete", label: "Delete Payment" },
                            { value: "invoice_item_add", label: "Add Item" },
                            { value: "invoice_item_update", label: "Update Item" },
                            { value: "invoice_item_delete", label: "Delete Item" },
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />


                    <Module
                        value="menu_leave"
                        label="Leave"
                        submodules={[
                            
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />

                    <Module
                        value="menu_reports"
                        label="Reports"
                        submodules={[
                            { value: "reports_compare", label: "Compare Report" },
                            { value: "reports_activities", label: "Activities" },
                            { value: "reports_work", label: "Work Report" },
                            { value: "reports_leave", label: "Leave Report" },
                            { value: "reports_performance", label: "Performace Reports" },
                            { value: "reports_performance_graphs", label: "Performace Graphs" },
                            { value: "reports_project", label: "Project Reports" },
                            { value: "reports_task", label: "Task Reports" },
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />


                    <Module
                        value="menu_logs"
                        label="Logs"
                        submodules={[
                            { value: "logs_ws", label: "Web Service" },
                            { value: "logs_mail", label: "Mail" }
                        ]}
                        selectedModules={selectedModules}
                        setSelectedModules={setSelectedModules}
                    />

                    <div className='d-none'>
                        <h6>Selected Modules</h6>
                        <ul>
                            {selectedModules.map(module => (
                            <li key={module}>{module}</li>
                            ))}
                        </ul>
                        </div>    

                    </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateRolePermission}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UpdateUserRolePermissionModal;