import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createInvoiceItem, saveInvoicesToStorage, sendInvoice } from '../../../models/InvoiceModel';
import CurrencyInput from 'react-currency-input-field';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const SendInvoiceModal = ({showModal, setShowModal, modalKey, invoice_unique_id, invoiceData}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);
    
    const [user_name, setUserName] = useState('');
    const [client_email, setClientEmail] = useState('');


    const [errors, setErrors] = useState({user_name:  '',  client_email: ''});
    

    const closeModal = () =>{
        setUserName('');
        setClientEmail('');
        
        setErrors({});
    
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (invoiceData) {
            setUserName(invoiceData.client_name);
            setClientEmail(invoiceData.client_email);
        }
      }, [invoiceData, modalKey]);
    
    
    const sendInvoiceEmail = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { user_name, client_email };
    
          const response = await sendInvoice(user_data.user_token, invoice_unique_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          //console.log(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
            <Modal.Title>Send Invoice</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                <div className="col-xl-12">
                    <label className="form-label">Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Name" 
                    value={user_name} onChange={(e) => setUserName(e.target.value)}/>

                    
                    {errors.user_name && <p style={{ color: 'red' }}>{errors.user_name}</p>}
                </div>

                <div className="col-xl-12">
                    <label className="form-label">Email <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Email" 
                    value={client_email} onChange={(e) => setClientEmail(e.target.value)}/>

                    
                    {errors.client_email && <p style={{ color: 'red' }}>{errors.client_email}</p>}
                </div>

                
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={sendInvoiceEmail}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Submit'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default SendInvoiceModal;
