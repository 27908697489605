import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { editFinancialYear, saveFinancialYearsToStorage } from '../../../models/MasterModel';
import DatePicker from "react-datepicker";
import dateFormat from "dateformat";

const UpdateFinYearModal = ({showModal, setShowModal, finYearData, modalKey, fetchFinancialYears}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_fin_year_id, setFinYearId] = useState('');
    const [yearRange, setYearRange] = useState([null, null]);
    const [fin_year_start, fin_year_end] = yearRange;
    
    const [fin_year, setFinYear] = useState('');
    const [fin_year_is_current, setFinYearIsCurrent] = useState(0);
    
    const [errors, setErrors] = useState({fin_year:  ''});

    const handleIsCurrent = () =>{
        if (fin_year_is_current==0) {
            setFinYearIsCurrent(1);
        }
        else{
            setFinYearIsCurrent(0);
        }
    }

    
    const closeModal = () =>{
        setFinYearId('');
        setFinYear('');
        setFinYearIsCurrent(0);
        setYearRange([null, null]);

        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (finYearData) {
            
            setFinYearId(finYearData._fin_year_id);
            setFinYear(finYearData.fin_year);
            setFinYearIsCurrent(finYearData.fin_year_is_current);

            if (typeof finYearData.fin_year != typeof undefined) {
                const [startYear, endYear] = finYearData.fin_year.split('-');

                const startDate = new Date(parseInt(startYear), 0); // January 1st of start year
                const endDate = new Date(parseInt(endYear), 11, 31);

                setYearRange([startDate, endDate]);
            }
            

            setErrors({});
        }
      }, [finYearData, modalKey]);


    const updateFinYear = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          let finYear = (fin_year_start ? dateFormat(fin_year_start, "yyyy") : '')+'-'+(fin_year_end ? dateFormat(fin_year_end, "yyyy") : '');
          setFinYear(finYear); 
    
          const formData = { fin_year: finYear, fin_year_is_current };

          const response = await editFinancialYear(user_data.user_token, _fin_year_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveFinancialYearsToStorage(response.data.financial_years);
    
            fetchFinancialYears(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: '20px'}}>Update Financial Year</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-12">
                        <label className="form-label">Year <span className="text-danger">*</span></label>
                            <DatePicker
                                className="form-control"
                                selectsRange={true}
                                startDate={fin_year_start}
                                endDate={fin_year_end}
                                onChange={(update) => {setYearRange(update);}}
                                isClearable={true}
                                dateFormat="yyyy"
                                showYearPicker
                                placeholderText='YYYY-YYYY' />

                        
                            {errors.fin_year && <p style={{ color: 'red' }}>{errors.fin_year}</p>}
                    </div>
                    <div className="col-xl-12">
                        <label htmlFor="note_desc" className="form-label">Is Current <span className="text-danger">*</span></label>

                        <div className="form-check form-check-lg form-switch">
                            <input className="form-check-input" type="checkbox" role="switch"
                            value={fin_year_is_current} onChange={(e) => handleIsCurrent(e.target.value)}   />
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateFinYear}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UpdateFinYearModal;