export const projectPriority = (priority) => {
    if (priority=='low') {
        return <span className="badge bg-success-transparent">Low</span>;
    }
    else if (priority=='medium') {
        return <span className="badge bg-info-transparent">Medium</span>;
    }
    else{
        return <span className="badge bg-danger-transparent">High</span>; 
    }
};