import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { getTeamWithMembers, getUsers } from '../../models/UserModel';
import { profilePic } from '../../helpers/GeneralHelper';
import AddNewMemberModal from '../modals/Users/AddNewMemberModal';
import DeleteMemberModal from '../modals/Users/DeleteMemberModal';
import TeamMemberCards from '../skeletons/TeamMemberCards';
import TeamWithMemberList from '../skeletons/TeamWithMemberList';
import { useNotificationCount } from '../../context/NotificationCountContext';



function TeamMembers() {

  const { state } = useAuth(); 

  let user_data = state.user_data;  

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading'); 


  //Add User Start
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  //Add User End

  //Delete User Start
  const [userData, setUserData] = useState({});
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const openDeleteUserModal = (user_data) =>{
    setUserData(user_data);
    setShowDeleteUserModal(true);
  } 
  //Delete User End

  //Flush Users and Teams Start
  const flushUserAndTeam = async() => {
    localStorage.removeItem("users");
    localStorage.removeItem("users_with_teams");
    fetchUsers();
    fetchTeamWithMembers();
  }  
  //Flush Users and Teams End
 
  // Get Users Start
  const [users, setUsers] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [prevPage, setPrevPage] = useState('');
  const [nextPage, setNextPage] = useState('');

  const fetchUsers = async (param = {}) => {
    setLoading(true);
    try {
        let page  = param.page ? param.page : 1;
        let limit = param.limit ? param.limit : 10;
  
        let apiParam = {page: page, limit: limit, user_token: user_data.user_token};
        const response = await getUsers(apiParam);

        if (typeof response != typeof undefined) {
            setUsers(response.users);
            setTotalPages(response.pagination.total_pages);
            setPage(response.pagination.current_page);
            setTotalRows(response.pagination.total_rows);
            setPrevPage(response.pagination.prev_page);
            setNextPage(response.pagination.next_page);

            if (response.time) {
                setCacheTime(response.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            navigate('/re-login');
        }    

        
        setLoading(false);
    } catch (error) {
        //console.error(error);
    }

    
  };
  // Get Users End



  // Get Team with Members Start
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading2, setLoading2] = useState(true);

  const fetchTeamWithMembers = async (param = {}) => {
    setLoading2(true);
    try {
        let apiParam = {user_token: user_data.user_token};
        const response= await getTeamWithMembers(apiParam);

        if (typeof response != typeof undefined) {
            setTeamMembers(response.teams);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }    
        else{
            navigate('/re-login');
        }
        
    } catch (error) {
        //console.error(error);
    }

    setLoading2(false);
  };  
  // Get Team with Members End
  
  useEffect(() => {  
    fetchUsers();
    fetchTeamWithMembers();
  }, []);
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Team Members - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- ROW OPEN --> */}
                    <div className="row mt-4">
                        <div className="col-xl-12">
                            <div className="team-members" id="team-members">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card custom-card">
                                            <div className="card-body">
                                                 

                                                <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
                                        
                                                    <div className="card-title me-auto">
                                                        Last Synced On {cacheTime}
                                                    </div>

                                                    <button className="btn btn-primary btn-sm me-1" onClick={() => setShowAddUserModal(true)}>
                                                            <i className="ri-add-line me-1 fw-semibold align-middle"></i>New Member
                                                    </button>

                                                    <button className="btn btn-success-light btn-sm" onClick={() => flushUserAndTeam()}>
                                                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <!--Add User Modal Start--> */}
                                    <AddNewMemberModal
                                        showModal={showAddUserModal}
                                        setShowModal={setShowAddUserModal}
                                        fetchUsers={fetchUsers}
                                        limit={limit}
                                        page={page} />
                                    {/* <!--Add User Modal End--> */}


                                    {/* <!--Delete User Modal Start--> */}
                                    <DeleteMemberModal
                                        showModal={showDeleteUserModal}
                                        setShowModal={setShowDeleteUserModal}
                                        userData={userData}
                                        fetchUsers={fetchUsers}
                                        limit={limit}
                                        page={page}  />
                                    {/* <!--Delete User Modal End--> */}

                                    {/* <!-- User Card List Row Start --> */}
                                    <div className="row" style={{minHeight: '1000px'}}>
                                    {loading ? (
                                        [1,2,3,4,5,6,7,8,9].map((n) => (
                                            <TeamMemberCards />
                                        ))
                                    ) : (  
                                    users.map((user) => (
                                        <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12"> 
                                            <div className="card custom-card team-member-card">
                                                <div className="teammember-cover-image">
                                                    <img src="assets/images/media/team-covers/1.jpg" className="card-img-top" alt="..." />
                                                    <span className="avatar avatar-xl avatar-rounded">
                                                        {profilePic(user.user_first_name, user.user_last_name, user.user_image, '67px', '25px')}
                                                    </span>
                                                </div>
                                                <div className="card-body p-0"> 
                                                    <div className="d-flex flex-wrap align-item-center mt-sm-0 mt-5 justify-content-between border-bottom border-block-end-dashed p-3">
                                                        <div className="team-member-details flex-fill" style={{maxWidth:"20rem"}}>
                                                            <p className="mb-0 fw-semibold fs-16 text-truncate">
                                                                <Link to={`/profile/${user.user_unique_id}`}>{user.user_first_name} {user.user_last_name}</Link>
                                                            </p>
                                                            <p className="mb-0 fs-12 text-muted text-break">
                                                                <Link to={`mailto:${user.user_email}`}>
                                                                    {user.user_email}
                                                                </Link>
                                                            </p>
                                                        </div>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-icon btn-light btn-wave" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ti ti-dots-vertical"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><Link className="dropdown-item" to={`/profile/${user.user_unique_id}`}>Edit</Link></li>

                                                                {user_data.user_unique_id!=user.user_unique_id && user_data.urole_permission=='*' ? (
                                                                <li>
                                                                    <button className="dropdown-item" onClick={() => openDeleteUserModal(user)}>Delete</button>
                                                                </li>
                                                                ):('')}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="team-member-stats d-sm-flex justify-content-evenly my-2">
                                                        <div className="text-center p-1" style={{flex:"0 0 50%", maxWidth:"50%"}}>
                                                            <p className="fw-semibold mb-0">Member Since</p>
                                                            <span className="text-muted fs-12">{user.member_since}</span>
                                                        </div>
                                                        
                                                        <div className="text-center p-1 text-truncate" style={{flex:"0 0 50%", maxWidth:"50%"}}>
                                                            <p className="fw-semibold mb-0">Position</p>
                                                            <span className="text-muted fs-12" style={{maxWidth:"4rem", overflow:"hidden", textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{user.user_job_title}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer border-block-start-dashed text-center" style={{minHeight:"70px"}}>
                                                    <div className="btn-list">
                                                        <div className="btn-list">

                                                            <a href={user.user_team_link} target='_blank' className={`btn btn-sm btn-icon btn-primary-light btn-wave waves-effect waves-light ${!user.user_team_link ? 'd-none' : ''}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-microsoft-teams" viewBox="0 0 12 12">
                                                                <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0m-2.238 9.488-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z"/>
                                                                <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z"/>
                                                                </svg>
                                                            </a>

                                                            <a href={user.user_linkedin_link} target='_blank' className={`btn btn-sm btn-icon btn-secondary-light btn-wave waves-effect waves-light ${!user.user_linkedin_link ? 'd-none' : ''}`}>
                                                                <i class="ri-linkedin-fill"></i>
                                                            </a>

                                                            <a href={user.user_stackoverflow_link} target='_blank' className={`btn btn-sm btn-icon btn-warning-light btn-wave waves-effect waves-light ${!user.user_stackoverflow_link ? 'd-none' : ''}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-stack-overflow" viewBox="0 0 12 12">
                                                                <path d="M12.412 14.572V10.29h1.428V16H1v-5.71h1.428v4.282z"/>
                                                                <path d="M3.857 13.145h7.137v-1.428H3.857zM10.254 0 9.108.852l4.26 5.727 1.146-.852zm-3.54 3.377 5.484 4.567.913-1.097L7.627 2.28l-.914 1.097zM4.922 6.55l6.47 3.013.603-1.294-6.47-3.013zm-.925 3.344 6.985 1.469.294-1.398-6.985-1.468z"/>
                                                                </svg>
                                                            </a>

                                                            <a href={`https://wa.me/${user.user_whatsapp_number}`} target='_blank' className={`btn btn-sm btn-icon btn-success-light btn-wave waves-effect waves-light ${!user.user_whatsapp_number ? 'd-none' : ''}`}>
                                                                <i class="ri-whatsapp-line"></i>
                                                            </a>
                                                            
                                                            <a href={user.user_github_link} target='_blank' className={`btn btn-sm btn-icon btn-danger-light btn-wave waves-effect waves-light ${!user.user_github_link ? 'd-none' : ''}`}>
                                                                <i class="ri-github-line"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    )}
                                    </div>
                                    {/* <!-- User Card List Row End --> */}

                                    
                                    
                                    {/* <!---Pagination Start---> */}
                                    {users ? (
                                    <nav aria-label="...">
                                        <ul className="pagination justify-content-end">
                                        <li className={`page-item ${prevPage==='' ? 'disabled' : ''}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(prevPage)})}>Previous</a>
                                        </li>

                                        {Array.from({ length: totalPages }).map((_, index) => (
                                        <li key={index} className={`page-item ${page===Number(index+1) ? 'active' : ''}`} title={`Page ${index+1}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(index + 1)})}>{index + 1}</a>
                                        </li>
                                        ))}

                                        <li className={`page-item ${nextPage==='' ? 'disabled' : ''}`}>
                                            <a className="page-link" href="javascript:void(0);" onClick={() => fetchUsers({page:Number(nextPage)})}>Next</a>
                                        </li>
                                        </ul>
                                    </nav>
                                    ) : ('' )}
                                    {/* <!---Pagination End---> */}

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 d-none">
                            <div className="team-groups">
                                <div className="card custom-card">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <h6 className="fw-semibold mb-0">All Teams</h6> 
                                        <div>
                                            <button className="btn btn-sm btn-primary-light" data-bs-toggle="modal" data-bs-target="#create-team">Create Team<i className="ri-add-line ms-1 align-middle"></i></button>
                                            
                                            <div className="modal fade" id="create-team" tabindex="-1" aria-labelledby="create-teamLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h6 className="modal-title" id="create-teamLabel">Create Team</h6>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body px-4">
                                                            <div className="row gy-3">
                                                                <div className="col-xl-12">
                                                                    <label htmlFor="team-name" className="form-label">Team Name</label>
                                                                    <input type="text" className="form-control" id="team-name" placeholder="Enter Name" />
                                                                </div>
                                                                <div className="col-xl-12">
                                                                    <label className="form-label">Maximum Team Limit</label>
                                                                    <select className="form-control" data-trigger="" name="choices-single-default" id="choices-single-default">
                                                                        <option value="10" selected="">10</option>
                                                                        <option value="20">20</option>
                                                                        <option value="30">30</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
                                                            <button type="button" className="btn btn-sm btn-primary">Create</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>

                                        
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="teams-nav overflow-y-auto" id="teams-nav">
                                            <ul className="list-unstyled mb-0 mt-2">
                                                {loading2 ? (

                                                [1,2,3,4].map((n) => (
                                                    <TeamWithMemberList />
                                                ))
                                                
                                                
                                                ) : (  
                                                    teamMembers.map((teamMember) => (
                                                        <>
                                                        <li className="pb-0">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="fs-10 fw-semibold mb-2 text-muted">{teamMember.team_name}</div>
                                                                <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Add Member">
                                                                    <button className="btn btn-light btn-wave waves-effect waves-light px-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="ti ti-dots-vertical fs-18"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" href="javascript:void(0);">Delete</a></li>
                                                                        <li><a className="dropdown-item" href="javascript:void(0);">Edit</a></li>
                                                                        <li><a className="dropdown-item" href="javascript:void(0);">Add Member</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                            {teamMember.members.map((member) => (
                                                            <li>
                                                                <Link to={`/profile/${member.user_unique_id}`}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="me-2 d-flex align-items-center">
                                                                            <span className="avatar avatar-sm avatar-rounded online">
                                                                                {profilePic(member.user_first_name, member.user_last_name, member.user_image)}
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-fill">
                                                                            <span>{member.user_first_name} {member.user_last_name}</span>
                                                                        </div>
                                                                        <div>
                                                                            <span className="fs-10 fw-semibold text-muted">
                                                                                
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            ))}
                                                        </>
                                                    ))
                                                )}    

                                                
                                                
                                                
                                               
                                                
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- ROW CLOSE --> */}


                </div>


            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default TeamMembers
