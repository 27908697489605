import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import dateFormat from "dateformat";
import { projectPriority } from '../../../helpers/ProjectHelper';
import { profilePic } from '../../../helpers/GeneralHelper';
import ProjectTabs from '../../skeletons/ProjectTabs';
import { editProject, getProjectDetails, getProjectFiles, getProjectLocations, getProjectLogs, getProjectMembers, getProjectOverView, getProjectTasks, saveProjectsDetailsToStorage, saveProjectsToStorage } from '../../../models/ProjectModel';
import { apiError } from '../../../helpers/ErrorHelper';
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../../context/ThemeContext';
import ProjectFiles from '../../skeletons/ProjectFiles';
import ProjectMembers from '../../skeletons/ProjectMembers';
import ProjectTasks from '../../skeletons/ProjectTasks';
import ProjectLocations from '../../skeletons/ProjectLocations';
import ProjectLogs from '../../skeletons/ProjectLogs';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { utcToLocal } from '../../../helpers/DateHelper';
import ProjectDetailsForm from '../../widgets/projects/ProjectDetailsForm';
import ProjectFilesTables from '../../tables/ProjectDetails/ProjectFilesTables';
import ProjectMembersTables from '../../tables/ProjectDetails/ProjectMembersTables';
import ProjectTasksTables from '../../tables/ProjectDetails/ProjectTasksTables';
import ProjectOverView from '../../widgets/projects/ProjectOverView';
import ProjectLocationsTables from '../../tables/ProjectDetails/ProjectLocationsTables';
import ProjectLogsTables from '../../tables/ProjectDetails/ProjectLogsTables';

function ProjectDetails() {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const location = useLocation();
    const currentRoute = location.pathname; 

    const { state, dispatch } = useAuth();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate();

    let user_data = state.user_data;

    const loader = document.getElementById('loading');

    const [modalKey, setModalKey] = useState(0);

    let { project_unique_id } = useParams();


    // Get Project Overview Start
    

    const [projectData, setProjectData] = useState({});

    const [projectOverViewLoading, setProjectOverViewLoading] = useState(false);

    const [callProjectOverView, setCallProjectOverView] = useState(true);
    // Get Project Overview End


    // Get Project Details Start
    const [callProjectDetails, setCallProjectDetails] = useState(false);
    // Get Project Details End


    // Get Project Files Start
    const [callProjectFiles, setCallProjectFiles] = useState(false);
    // Get Project Files End


    // Get Project Member List on Page & Limit Change Start
    const [callProjectMembers, setCallProjectMembers] = useState(false);
    // Get Project Member List on Page & Limit Change End
    

    // Get Project Task List on Page & Limit Change Start
    const [callProjectTasks, setCallProjectTasks] = useState(false);
    // Get Project Task List on Page & Limit Change End

    
    // Get Project Location List on Page & Limit Change Start
    const [callProjectLocations, setCallProjectLocations] = useState(false);
    // Get Project Location List on Page & Limit Change End
    


    // Get Project Log List on Page & Limit Change Start
    const [callProjectLogs, setCallProjectLogs] = useState(false);
    // Get Project Log List on Page & Limit Change End


    return (
        <>
        
    <AdminLayout>
    
        <Helmet>
            <title> Project Details - Worktracker Anciletech</title>
        </Helmet>

        <div className="main-content app-content">

            <div className="container-fluid">

                {/* <!-- Page Header --> */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    
                    <h1 className="page-title fw-semibold fs-18 mb-0 d-flex align-items-center"> 
                        <span className="avatar avatar-md me-2">
                            {Object.keys(projectData).length > 0 ? (
                            <img src={`${projectData.project_logo ? process.env.REACT_APP_ASSETS_URL+'/'+projectData.project_logo : process.env.REACT_APP_BASE_URL+'/assets/images/no-pic.jpeg'}`} alt="img" />
                            ) : ''}
                        </span>{projectData.project_title}
                        
                    </h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Project Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* <!-- Page Header Close --> */}
    
 
        
        <>
        {projectOverViewLoading ? (
            <ProjectTabs />
        ) : (     
            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#overview-center" aria-selected="true" onClick={() => setCallProjectOverView(true)}>Overview</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#details-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectDetails(true)}>Details</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#files-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectFiles(true)}>Files</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#members-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectMembers(true)}>Members</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#tasks-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectTasks(true)}>Tasks</a>
                                </li>

                                {Object.keys(projectData).length ? (
                                    projectData.project_site_visit==='1' ? (
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#locations-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectLocations(true)}>Locations</a>
                                </li>
                                 ) : null
                                 ) : ''
                                }

                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#logs-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectLogs(true)}>Logs</a>
                                </li>
                            </ul>
                            <div className="tab-content">

                                {/* <!---Overview Tab Content Start---> */}
                                <div className="tab-pane show active text-muted" id="overview-center" role="tabpanel">
                                    <ProjectOverView 
                                        project_unique_id={project_unique_id} 
                                        callProjectOverView={callProjectOverView} 
                                        projectData={projectData}
                                        setProjectData={setProjectData}
                                        projectOverViewLoading={projectOverViewLoading} 
                                        setProjectOverViewLoading={setProjectOverViewLoading} />
                                </div>
                                {/* <!---Overview Tab Content End---> */}


                                {/* <!---Details Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="details-center" role="tabpanel">
                                    <ProjectDetailsForm 
                                          project_unique_id={project_unique_id} 
                                          projectData={projectData} 
                                          setProjectData={setProjectData} 
                                          projectLoading={projectOverViewLoading} 
                                          setProjectLoading={setProjectOverViewLoading}/>
                                </div>
                                {/* <!---Details Tab Content End---> */}


                                {/* <!---Files Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="files-center" role="tabpanel">
                                    <ProjectFilesTables project_unique_id={project_unique_id} callProjectFiles={callProjectFiles} projectData={projectData}/>
                                </div>
                                {/* <!---Files Tab Content End---> */}


                                {/* <!---Members Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="members-center" role="tabpanel">
                                    <ProjectMembersTables project_unique_id={project_unique_id} callProjectMembers={callProjectMembers}  projectData={projectData}/>   
                                </div>
                                {/* <!---Members Tab Content End---> */}


                                {/* <!---Tasks Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="tasks-center" role="tabpanel">
                                    <ProjectTasksTables project_unique_id={project_unique_id} callProjectTasks={callProjectTasks}/> 
                                </div>
                                {/* <!---Tasks Tab Content End---> */}

                                
                                
                                {/* <!---Locations Tab Content Start---> */}
                                 {Object.keys(projectData).length ? ( 
                                    projectData.project_site_visit==='1' ? ( 
                                <div className="tab-pane text-muted" id="locations-center" role="tabpanel">
                                    <ProjectLocationsTables project_unique_id={project_unique_id} callProjectLocations={callProjectLocations} projectData={projectData}/> 
                                </div>
                                 ) : null ) : ''}
                                {/* <!---Locations Tab Content End---> */}


                                 {/* <!---Logs Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="logs-center" role="tabpanel">
                                    <ProjectLogsTables project_unique_id={project_unique_id} callProjectLogs={callProjectLogs} task_unique_id=''/> 
                                </div>
                                {/* <!---Logs Tab Content End---> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )}
        </>
        
        
    
            </div>
    
    
        </div>  
    
                <Helmet>
                    
                </Helmet>
            
            </AdminLayout>
        </>
    )

}

export default ProjectDetails