import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams  } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { getUserProfile, getUserRoles, saveUserDataToStorage, saveUsersToStorage, updateMyPassword, updateMyPicture, updateUserAccountInfo, updateUserBankDetails, updateUserGeneralInfo, updateUserJobInfo, updateUserSocialNetworks } from '../../models/UserModel';
import { profilePic } from '../../helpers/GeneralHelper';
import dateFormat from "dateformat";
import LeaveApplicationTables from '../tables/LeaveApplicationTables';
import { getLeaveApplications, getLeaveBalances } from '../../models/LeaveModel';
import LeaveBalanceTables from '../tables/LeaveBalanceTables';
import { getAttendance } from '../../models/AttendanceModel';
import AttendanceTables from '../tables/AttendanceTables';
import { apiError } from '../../helpers/ErrorHelper';
import DatePicker from "react-datepicker";
import ViewLeaveApplicationModal from '../modals/Leave/ViewLeaveApplicationModal';
import ViewLeaveBalanceModal from '../modals/Leave/ViewLeaveBalanceModal';
import { getSkills } from '../../models/MasterModel';
import Select from 'react-select';
import ProfileTabs from '../skeletons/ProfileTabs';
import { decryptData, encryptData } from '../../helpers/EncryptHelper';
import ViewAttendanceModal from '../modals/Attendance/ViewAttendanceModal';
import { useTheme } from '../../context/ThemeContext';
import { useNotificationCount } from '../../context/NotificationCountContext';

function Profile() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { state, dispatch } = useAuth();

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const navigate = useNavigate();

  let user_data = state.user_data;

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);
  

  //Update Profile Picture Start
  const [user_image, setUserImage] = useState('');
    
  const userImageFileRef = useRef(null);

  const handleUserImageClick = () => {
    userImageFileRef.current.click();
  }

  const handleUserImageChange = (event) => {
    const file = event.target.files[0];

    //console.log(file);

    setUserImage(file);

    //console.log(URL.createObjectURL(file));
  }

  const uploadProfilePicture = async() => {
    // Start the loading state
    loader.style.display = 'block';
    
    try {
        const formData = new FormData();
        formData.append('user_image', user_image);

        const response = await updateMyPicture(user_data.user_token, formData);

        if (response.status) {
            toast.success(response.message);

            setUserImage('');

            setProfileData(response.data.user_data);

            saveUserDataToStorage(response.data.user_data);

            saveUsersToStorage(response.data.users, response.data.pagination);

            dispatch({ type: 'UPDATE_USER_DATA', payload: { user_data: response.data.user_data } });

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            toast.error(response.message);
        }   
        
    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  }
  //Update Profile Picture End


  // Get User Profile Start
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);

  let { user_unique_id } = useParams();

  if (!user_unique_id) {
    user_unique_id = user_data.user_unique_id;
  }

  const fetchUserProfile = async () => {
    // Start the loading state
    //loader.style.display = 'block';
    setProfileLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token, user_unique_id};
        const response = await getUserProfile(apiParam);

        if (typeof response != typeof undefined) {
            const user_skills_options = response.user_data.user_skills.map(user_skill => ({
                label: user_skill.skill_name,
                value: user_skill._skill_id
            })); 
            
            setNotificationCount(localStorage.getItem('unread_notification_count'));
    
            setUserFirstName(response.user_data.user_first_name);
            setUserLastName(response.user_data.user_last_name);
            setUserDob(new Date(response.user_data.user_dob));
            setUserGender(response.user_data.user_gender);
            setUserEmail(response.user_data.user_email);
            setUserMobile(response.user_data.user_mobile);
            setUserAlternatePhone(response.user_data.user_alternate_phone);
            setUserAddress(response.user_data.user_address);
            setUserAlternateAddress(response.user_data.user_alternate_address);
    
            setUserJobTitle(response.user_data.user_job_title);
    
            const userSkillsOptions = response.user_data.user_skills.map(user_skill => ({
                label: user_skill.skill_name,
                value: user_skill._skill_id
            }));
    
            setUserSkills(userSkillsOptions);
    
            response.user_data.user_skills = user_skills_options;
    
            setProfileData(response.user_data);
    
            //console.log(response.user_data);
    
            setUserDateOfJoining(new Date(response.user_data.user_date_of_joining));
            setUserEmpCode(response.user_data.user_emp_code);
            setUserPan(response.user_data.user_pan);
    
            setUserWhatsappNo(response.user_data.user_whatsapp_number);
            setUserLinkedinLink(response.user_data.user_linkedin_link);
            setUserTeamLink(response.user_data.user_team_link);
            setUserGithubLink(response.user_data.user_github_link);
            setUserStackoverflowLink(response.user_data.user_stackoverflow_link);
    
            setUserRoleId(response.user_data.user_role_id);
    
            setUserBankName(response.user_data.user_bank_name);
            setUserBankBranchName(response.user_data.user_bank_branch_name);
            setUserBankAcNo(response.user_data.user_bank_ac_no);
            setUserBankIfsc(response.user_data.user_bank_ifsc);

            setProfileLoading(false);

        }
        else{
            //navigate('/re-login');
        }    
        
    } catch (error) {
        console.error(error);
    }

    // Stop the loading state
    //loader.style.display = 'none';
    
  };  

  
  useEffect(() => {  
    fetchUserProfile();
  }, [user_unique_id]);
  // Get User Profile End

    //Get Skill list start
    const [skills, setSkills] = useState([]);
    const fetchSkills = async () => {
        try {
        const result = await getSkills();

        const skillsOptions = result.skills.map(skill => ({
            label: skill.skill_name,
            value: skill._skill_id
        }));

        setSkills(skillsOptions);
        } catch (error) {
        // Handle errors if necessary
        console.error(error);
        }
    };

    useEffect(() => {
        fetchSkills();
    }, []);
    //Get Skill list end


  // Get User Roles Start
  const [userRoles, setUserRoles] = useState([]);

  const fetchUserRoles = async() => {
    try {
        let apiParam = {user_token: user_data.user_token};
        const response = await getUserRoles(apiParam);

        if (typeof response != typeof undefined) {
            setUserRoles(response.user_roles);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }    
        
    } catch (error) {
        console.error(error);
    }
  }

  useEffect(() => {  
    fetchUserRoles();
  }, []);
  // Get User Roles End


  // Update General Info Start
  const [user_first_name, setUserFirstName] = useState('');
  const [user_last_name, setUserLastName] = useState('');
  const [user_dob, setUserDob] = useState('');
  const [user_gender, setUserGender] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [user_mobile, setUserMobile] = useState('');
  const [user_alternate_phone, setUserAlternatePhone] = useState('');
  const [user_address, setUserAddress] = useState('');
  const [user_alternate_address, setUserAlternateAddress] = useState('');

  const [genInfoerrors, setGenInfoErrors] = useState({user_first_name:  '',  user_last_name: '', user_dob: '', user_gender: '', user_email: '',
  user_mobile: '', user_alternate_phone: '', user_address: '', user_alternate_address: ''});

  const updateGeneralInfo = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setGenInfoErrors({});
    
        const formData = { user_first_name, user_last_name, user_dob : dateFormat(user_dob, "dd-mm-yyyy"), 
            user_gender, user_email, user_mobile, user_alternate_phone, user_address, user_alternate_address };
  
        const response = await updateUserGeneralInfo(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setGenInfoErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update General Info End


  // Update JOB Info Start
  const [user_job_title, setUserJobTitle] = useState('');
  const [user_skills, setUserSkills] = useState([]);
  const [user_date_of_joining, setUserDateOfJoining] = useState('');
  const [user_emp_code, setUserEmpCode] = useState('');
  const [user_pan, setUserPan] = useState('');

  const [jobInfoerrors, setJobInfoErrors] = useState({user_job_title:  '',  user_skills: '', user_date_of_joining: '', user_emp_code: '', user_pan: ''});

  const updateJobInfo = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setJobInfoErrors({});

        const user_skills_ids = user_skills.map(user_skill => user_skill.value).join(',');
    
        const formData = { user_job_title, user_skills: user_skills_ids, user_date_of_joining: dateFormat(user_date_of_joining, "dd-mm-yyyy"), user_emp_code, user_pan};
  
        const response = await updateUserJobInfo(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            const user_skills_options = response.data.user_data.user_skills.map(user_skill => ({
                label: user_skill.skill_name,
                value: user_skill._skill_id
            }));

            setNotificationCount(localStorage.getItem('unread_notification_count'));
    
            response.data.user_data.user_skills = user_skills_options;

            setProfileData(response.data.user_data);
        }
        else{
            setJobInfoErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
        //console.log(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update JOB Info End


  // Update Social Networks Start
  const [user_whatsapp_number, setUserWhatsappNo] = useState('');
  const [user_linkedin_link, setUserLinkedinLink] = useState('');
  const [user_team_link, setUserTeamLink] = useState('');
  const [user_github_link, setUserGithubLink] = useState('');
  const [user_stackoverflow_link, setUserStackoverflowLink] = useState('');

  const [socialNetworksErrors, setSocialNetworksErrors] = useState({user_whatsapp_number:  '',  user_linkedin_link: '', user_team_link: '', user_github_link: '', user_stackoverflow_link: ''});

  const updateSocialNetworks = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setSocialNetworksErrors({});
    
        const formData = { user_whatsapp_number, user_linkedin_link, user_team_link, user_github_link, user_stackoverflow_link};
  
        const response = await updateUserSocialNetworks(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setSocialNetworksErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update Social Networks End


  // Update Account Info Start
  const [user_role_id, setUserRoleId] = useState('');

  const [accInfoErrors, setAccInfoErrors] = useState({user_role_id:  ''});

  const updateAccountInfo = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setAccInfoErrors({});
    
        const formData = { user_role_id };
  
        const response = await updateUserAccountInfo(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setAccInfoErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update Account Info End


  // Change Password Start
  const [current_password, setCurrentPassword] = useState('');
  const [new_password, setNewPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');

  const [changePasswordErrors, setChangePasswordErrors] = useState({current_password:  '', new_password: '', confirm_password: ''});

  const changePassword = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setChangePasswordErrors({});
    
        const formData = { current_password, new_password, confirm_password};
  
        const response = await updateMyPassword(user_data.user_token, formData);

        if (response.status) {
            toast.success(response.message);

            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setChangePasswordErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Change Password End


  // Update Bank Details Start
  const [user_bank_name, setUserBankName] = useState('');
  const [user_bank_branch_name, setUserBankBranchName] = useState('');
  const [user_bank_ac_no, setUserBankAcNo] = useState('');
  const [user_bank_ifsc, setUserBankIfsc] = useState('');

  const [bankDetailsErrors, setBankDetailsErrors] = useState({user_bank_name:  '', user_bank_branch_name: '', user_bank_ac_no: '', user_bank_ifsc: ''});

  const updateBankDetails = async() => {
    // Start the loading state
    loader.style.display = 'block';

    try {
        // Clear previous errors
        setBankDetailsErrors({});
    
        const formData = { user_bank_name, user_bank_branch_name, user_bank_ac_no, user_bank_ifsc};
  
        const response = await updateUserBankDetails(user_data.user_token, user_unique_id, formData);

        if (response.status) {
            toast.success(response.message);

            setProfileData(response.data.user_data);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            setBankDetailsErrors(response.error);
            toast.error(response.message);
        }     

    } catch (error) {
        apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  };
  // Update Bank Details End


  //Leave Application List Start 
  const [leaveApplicationData, setLeaveApplicationData] = useState([]);
  const [leaveApplicationLoading, setLeaveApplicationLoading] = useState(true);
  const [leaveApplicationTotalRows, setLeaveApplicationTotalRows] = useState(0);
  const [leaveApplicationPage, setLeaveApplicationPage] = useState(1);
  const [leaveApplicationLimit, setLeaveApplicationLimit] = useState(10);

  const fetchApplications = async(newLimit, page) => {
    setLeaveApplicationLoading(true);

    try {
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token, user_unique_id: user_unique_id};

        const response = await getLeaveApplications(apiParam);
        setLeaveApplicationData(response.applications);
        setLeaveApplicationTotalRows(response.pagination.total_rows);
        setLeaveApplicationLimit(newLimit);
        setLeaveApplicationPage(page);

        setNotificationCount(localStorage.getItem('unread_notification_count'));

    } catch (error) {
        console.error(error);
    }

    setLeaveApplicationLoading(false);
  };
  //Leave Application List End 


  //View Leave Start
  const [showViewLeaveModal, setShowViewLeaveModal] = useState(false);

  const [applicationData, setApplicationData] = useState({});

  const openViewApplicationModal = (leave_data) =>{
      setApplicationData(leave_data);
      setModalKey(prevKey => prevKey + 1);
      setShowViewLeaveModal(true);
  }  
  //View Leave End


  //Leave Balance List Start 
  const [leaveBalanceData, setLeaveBalanceData] = useState([]);
  const [leaveBalanceLoading, setLeaveBalanceLoading] = useState(true);
  const [leaveBalanceTotalRows, setLeaveBalanceTotalRows] = useState(0);
  const [leaveBalancePage, setLeaveBalancePage] = useState(1);
  const [leaveBalanceLimit, setLeaveBalanceLimit] = useState(10);

  const fetchBalances = async(newLimit, page) => {
    setLeaveBalanceLoading(true);

    try {
      let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token,  user_unique_id: user_unique_id};

      const response = await getLeaveBalances(apiParam);
      setLeaveBalanceData(response.balances);
      setLeaveBalanceTotalRows(response.pagination.total_rows);
      setLeaveBalanceLimit(newLimit);
      setLeaveBalancePage(page);

      setNotificationCount(localStorage.getItem('unread_notification_count'));

    } catch (error) {
      console.error(error);
    }

    setLeaveBalanceLoading(false);
  };
  //Leave Balance List End 


  //View Balance Start
  const [showViewBalanceModal, setShowViewBalanceModal] = useState(false);

  const [balanceData, setBalanceData] = useState({});

  const openViewBalanceModal = (leave_data) =>{
    setBalanceData(leave_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowViewBalanceModal(true);
  }  
  //View Balance End


  //View Attendance Start
  const [showViewAttendanceModal, setShowViewAttendanceModal] = useState(false);

  const [attendanceData, setAttendanceData] = useState({});

  const openViewAttendanceModal = (attendance_data) =>{
      setAttendanceData(attendance_data);
      setModalKey(prevKey => prevKey + 1);
      setShowViewAttendanceModal(true);
  }  
  //View Attendance End


  //Attendance List Start 
  const [attendances, setAttendances] = useState([]);
  const [attendanceLoading, setAttendanceLoading] = useState(true);
  const [attendanceTotalRows, setAttendanceTotalRows] = useState(0);
  const [attendancePage, setAttendancePage] = useState(1);
  const [attendanceLimit, setAttendanceLimit] = useState(10);

  const fetchAttendances = async(newLimit, page) => {
    setAttendanceLoading(true);

    try {            
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token, user_unique_id: user_unique_id};

        const response = await getAttendance(apiParam);
        setAttendances(response.attendances);
        setAttendanceTotalRows(response.pagination.total_rows);
        setAttendanceLimit(newLimit);
        setAttendancePage(page);

        setNotificationCount(localStorage.getItem('unread_notification_count'));

    } catch (error) {
        console.error(error);
    }

    setAttendanceLoading(false);
  };
  //Attendance List End 


  
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Profile - Worktracker Anciletech</title>
                
            </Helmet>

            <div className="main-content app-content">

                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Profile</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

{profileLoading ? (


<ProfileTabs />

) : (  
    
    <div className="row">
        <div className="col-xxl-4 col-xl-12">
            <div className="card custom-card overflow-hidden">
                <div className="card-body p-0">
                    <div className="d-sm-flex align-items-top p-4 border-bottom-0 main-profile-cover">
                        <div>
                            <span className="avatar avatar-xxl avatar-rounded online me-3">
                                {profilePic(profileData.user_first_name, profileData.user_last_name, profileData.user_image, '67px', '25px', user_image!=='' ? 'none' : 'block')}

                                <div className='d-flex justify-content-end' >
                                    <button type='button' className='btn-close profile-pic-remove' title='Remove Pic' 
                                    style={{display: user_image!=='' ? 'block' : 'none'}} onClick={(e) => setUserImage('')}
                                    ></button>
                                </div>
                                <img src={user_image ? URL.createObjectURL(user_image) : ``} alt="img"
                                style={{display: user_image!=='' ? 'block' : 'none'}} />

                                <button className="btn btn-link camera-btn" onClick={handleUserImageClick}
                                    style={{display: user_data.user_unique_id===user_unique_id ? 'block' : 'none'}}>
                                    <i class="bi bi-camera-fill"></i>
                                </button>

                                <input className='d-none' type='file' ref={userImageFileRef} onChange={handleUserImageChange} 
                                accept="image/png, image/jpeg, image/jpg" />
                            </span>
                            <div className='text-center me-3 mt-2'>
                                <button className='btn btn-link upload-btn' style={{display: user_image!=='' ? 'block' : 'none'}} title="Upload"
                                onClick={uploadProfilePicture}>
                                    <i class="bi bi-cloud-arrow-up"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex-fill main-profile-info">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className="fw-semibold mb-1 text-fixed-white">{profileData.user_first_name} {profileData.user_last_name}</h6>
                            </div>
                            <p className="mb-1 text-muted text-fixed-white op-7">{profileData.user_job_title}</p>
                            <p className="fs-12 text-fixed-white mb-4 op-5">  
                                <span className="me-3">
                                    <i className="ri-cake-2-line me-1 align-middle"></i>{dateFormat(profileData.user_dob, "dd mmm")}
                                </span> 
                            </p>
                            <div className="d-flex mb-0 d-none">
                                <div className="me-4">
                                    <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">113</p>
                                    <p className="mb-0 fs-11 op-5 text-fixed-white">Projects</p>
                                </div>
                                <div className="me-4">
                                    <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">12.2k</p>
                                    <p className="mb-0 fs-11 op-5 text-fixed-white">Followers</p>
                                </div>
                                <div className="me-4">
                                    <p className="fw-bold fs-20 text-fixed-white text-shadow mb-0">128</p>
                                    <p className="mb-0 fs-11 op-5 text-fixed-white">Following</p>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="p-4 border-bottom border-block-end-dashed">
                        <p className="fs-15 mb-2 me-4 fw-semibold">Contact Information :</p>
                        <div className="text-muted">
                            <p className="mb-2">
                                <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted">
                                    <i className="ri-mail-line align-middle fs-14"></i>
                                </span>
                                {profileData.user_email}
                            </p>
                            <p className="mb-2">
                                <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted">
                                    <i className="ri-phone-line align-middle fs-14"></i>
                                </span>
                                {profileData.user_mobile}
                            </p>
                            <p className="mb-0">
                                <span className="avatar avatar-sm avatar-rounded me-2 bg-light text-muted">
                                    <i className="ri-map-pin-line align-middle fs-14"></i>
                                </span>
                                {profileData.user_address}
                            </p>
                        </div>
                    </div>
                    <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-center">
                        <p className="fs-15 mb-2 me-4 fw-semibold">Social Networks :</p>
                        <div className="btn-list mb-0">

                            <a href={profileData.user_team_link} target='_blank' className={`btn btn-sm btn-icon btn-primary-light btn-wave waves-effect waves-light ${!profileData.user_team_link ? 'd-none' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-microsoft-teams" viewBox="0 0 12 12">
                                <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0m-2.238 9.488-.12-.002a5.2 5.2 0 0 0 .381-2.07V6.306a1.7 1.7 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.6 2.6 0 0 1-2.598 2.598z"/>
                                <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.68.68 0 0 1-.682.682H.682A.68.68 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945z"/>
                                </svg>
                            </a>

                            <a href={profileData.user_linkedin_link} target='_blank' className={`btn btn-sm btn-icon btn-secondary-light btn-wave waves-effect waves-light ${!profileData.user_linkedin_link ? 'd-none' : ''}`}>
                                <i class="ri-linkedin-fill"></i>
                            </a>
                            <a href={profileData.user_stackoverflow_link} target='_blank' className={`btn btn-sm btn-icon btn-warning-light btn-wave waves-effect waves-light ${!profileData.user_stackoverflow_link ? 'd-none' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-stack-overflow" viewBox="0 0 12 12">
                                <path d="M12.412 14.572V10.29h1.428V16H1v-5.71h1.428v4.282z"/>
                                <path d="M3.857 13.145h7.137v-1.428H3.857zM10.254 0 9.108.852l4.26 5.727 1.146-.852zm-3.54 3.377 5.484 4.567.913-1.097L7.627 2.28l-.914 1.097zM4.922 6.55l6.47 3.013.603-1.294-6.47-3.013zm-.925 3.344 6.985 1.469.294-1.398-6.985-1.468z"/>
                                </svg>
                            </a>
                            <a href={`https://wa.me/${profileData.user_whatsapp_number}`} target='_blank' className={`btn btn-sm btn-icon btn-success-light btn-wave waves-effect waves-light ${!profileData.user_whatsapp_number ? 'd-none' : ''}`}>
                                <i class="ri-whatsapp-line"></i>
                            </a>
                            <a href={profileData.user_github_link} target='_blank' className={`btn btn-sm btn-icon btn-danger-light btn-wave waves-effect waves-light ${!profileData.user_github_link ? 'd-none' : ''}`}>
                                <i class="ri-github-line"></i>
                            </a>
                        </div>
                    </div>
                    <div className="p-4 border-bottom border-block-end-dashed">
                        <p className="fs-15 mb-2 me-4 fw-semibold">Skills :</p>
                        <div>
                            {Array.isArray(profileData.user_skills) && profileData.user_skills.map((user_skill) => (
                                <span className="badge bg-light text-muted m-1" key={user_skill.value}>
                                    {user_skill.label}
                                </span>
                            ))}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="col-xxl-8 col-xl-12">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body p-0">
                            <div className="p-3 border-bottom border-block-end-dashed d-flex align-items-center justify-content-between">
                                <div>
                                    <ul className="nav nav-tabs mb-0 tab-style-6 justify-content-start" id="myTab" role="tablist">

                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" 
                                                data-bs-target="#profile-tab-pane" type="button" role="tab" 
                                                aria-controls="profile-tab-pane" aria-selected="true">
                                                <i className="bi bi-person-circle me-1 align-middle d-inline-block"></i>Profile
                                            </button>
                                        </li>
                                        ) : ('')}

                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="bank-details-tab" data-bs-toggle="tab" 
                                                data-bs-target="#bank-details-tab-pane" type="button" role="tab" 
                                                aria-controls="bank-details-tab-pane" aria-selected="false">
                                                <i className="bi bi-bank me-1 align-middle d-inline-block"></i>Bank Details
                                            </button>
                                        </li>
                                        ) : ('')}

                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="leave-tab" data-bs-toggle="tab" data-bs-target="#leave-tab-pane" 
                                                type="button" role="tab" aria-controls="leave-tab-pane" aria-selected="false">
                                                <i className="bi bi-calendar2-date me-1 align-middle d-inline-block"></i>Leave
                                            </button>
                                        </li>
                                        ) : ('')}

                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="attendance-tab" data-bs-toggle="tab" 
                                                data-bs-target="#attendance-tab-pane" type="button" role="tab" 
                                                aria-controls="attendance-tab-pane" aria-selected="false">
                                                <i className="bi bi-alarm-fill me-1 align-middle d-inline-block"></i>Attendance
                                            </button>
                                        </li>
                                        ) : ('')}
                                    </ul>
                                </div>   
                                
                            </div>
                            <div className="p-3">
                                <div className="tab-content" id="myTabContent">

                                    {/* <!--Profile Tab Content Start---> */}
                                    {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                    <div className="tab-pane show active fade p-0 border-0" id="profile-tab-pane" role="tabpanel" 
                                        aria-labelledby="profile-tab" tabindex="0">

                                        <h4>Profile</h4>

                                        {/* <!--General Info Card Start---> */}
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    General Info
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-3">
                                                        <label className="form-label">First Name : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="First Name"  readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_first_name} onChange={(e) => setUserFirstName(e.target.value)} />

                                                        {genInfoerrors.user_first_name && <p style={{ color: 'red' }}>{genInfoerrors.user_first_name}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Last Name : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Last Name" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_last_name} onChange={(e) => setUserLastName(e.target.value)} />

                                                        {genInfoerrors.user_last_name && <p style={{ color: 'red' }}>{genInfoerrors.user_last_name}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Date of Birth : <span className="text-danger">*</span></label>

                                                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                            selected={user_dob} onSelect={setUserDob} onChange={setUserDob} dateFormat="dd-MM-yyyy"/>

                                                        {genInfoerrors.user_dob && <p style={{ color: 'red' }}>{genInfoerrors.user_dob}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Gender : <span className="text-danger">*</span></label>
                                                        <br/>

                                                      <label className="radio-inline">
                                                        <input type="radio" name="user_gender" value="male" checked={user_gender==='male' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserGender(e.target.value)}/>&nbsp;Male&nbsp;&nbsp;
                                                      </label>

                                                      <label className="radio-inline">
                                                        <input type="radio" name="user_gender" value="female" checked={user_gender==='female' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserGender(e.target.value)} />&nbsp;Female&nbsp;&nbsp;
                                                      </label>

                                                      <label className="radio-inline">
                                                        <input type="radio" name="user_gender" value="other" checked={user_gender==='other' ? 'checked' : ''} disabled={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserGender(e.target.value)} />&nbsp;Other
                                                      </label>

                                                      {genInfoerrors.user_gender && <p style={{ color: 'red' }}>{genInfoerrors.user_gender}</p>}
                                                    </div>

                                                </div>

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Email : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Email address" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_email} onChange={(e) => setUserEmail(e.target.value)}/>

                                                        {genInfoerrors.user_email && <p style={{ color: 'red' }}>{genInfoerrors.user_email}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Mobile : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Mobile number" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_mobile} onChange={(e) => setUserMobile(e.target.value)}/>

                                                        {genInfoerrors.user_mobile && <p style={{ color: 'red' }}>{genInfoerrors.user_mobile}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Alternate Mobile :</label>
                                                        <input type="text" className="form-control" placeholder="Alternate Mobile number" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_alternate_phone} onChange={(e) => setUserAlternatePhone(e.target.value)} />

                                                        {genInfoerrors.user_alternate_phone && <p style={{ color: 'red' }}>{genInfoerrors.user_alternate_phone}</p>}
                                                    </div>

                                                </div>   

                                                <div className="row gy-3">

                                                    <div className="col-xl-6">
                                                        <label for="input-label" className="form-label">Permanent Address : <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" placeholder="Permanent Address" rows="5" value={user_address} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserAddress(e.target.value)}></textarea>

                                                        {genInfoerrors.user_address && <p style={{ color: 'red' }}>{genInfoerrors.user_address}</p>}
                                                    </div>

                                                    <div className="col-xl-6">
                                                        <label for="input-label" className="form-label">Current Address : </label>
                                                        <textarea className="form-control" placeholder="Current Address" rows="5" value={user_alternate_address} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserAlternateAddress(e.target.value)}></textarea>

                                                        {genInfoerrors.user_alternate_address && <p style={{ color: 'red' }}>{genInfoerrors.user_alternate_address}</p>}
                                                    </div>

                                                </div>

                                            </div>  
                                            {user_data.urole_permission==='*' ? ( 
                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateGeneralInfo}>Update</button>
                                            </div>  
                                            ):('')}
                                        </div>
                                        {/* <!--General Info Card End---> */}


                                        {/* <!--JOB Info Card Start---> */}
                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    JOB Info
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">JOB Title : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="JOB Title" value={user_job_title} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserJobTitle(e.target.value)}/>

                                                        {jobInfoerrors.user_job_title && <p style={{ color: 'red' }}>{jobInfoerrors.user_job_title}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Skills :</label>
                                                        
                                                        <Select
                                                            value={user_skills}
                                                            options={skills}
                                                            onChange={setUserSkills}
                                                            placeholder="- Skill -"
                                                            isSearchable="true"
                                                            isMulti="true"
                                                            noOptionsMessage={() => "No skill found"} 
                                                            isDisabled={user_data.urole_permission !== '*' ? true : false}
                                                            styles={selectBoxTheme}
                                                            theme={(selectBoxTheme) => ({
                                                            ...selectBoxTheme,
                                                            borderRadius: 0,
                                                            })} />

                                                        {jobInfoerrors.user_skills && <p style={{ color: 'red' }}>{jobInfoerrors.user_skills}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Date of Joining : <span className="text-danger">*</span></label>
                                                        {/* <input type="text" className="form-control" placeholder="DD-MM-YYYY" value={user_date_of_joining} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserDateOfJoining(e.target.value)} /> */}

                                                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                            selected={user_date_of_joining} onSelect={setUserDateOfJoining} onChange={setUserDateOfJoining} dateFormat="dd-MM-yyyy"/>

                                                        {jobInfoerrors.user_date_of_joining && <p style={{ color: 'red' }}>{jobInfoerrors.user_date_of_joining}</p>}
                                                    </div>

                                                </div>    

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Employee Code :</label>
                                                        <input type="text" className="form-control" placeholder="Employee Code" value={user_emp_code} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserEmpCode(e.target.value)} />

                                                        {jobInfoerrors.user_emp_code && <p style={{ color: 'red' }}>{jobInfoerrors.user_emp_code}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">PAN :</label>
                                                        <input type="text" className="form-control" placeholder="PAN" value={user_pan} readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        onChange={(e) => setUserPan(e.target.value)}/>

                                                        {jobInfoerrors.user_pan && <p style={{ color: 'red' }}>{jobInfoerrors.user_pan}</p>}
                                                    </div>

                                                </div>    
                                            </div> 
                                            
                                            {user_data.urole_permission==='*' ? ( 
                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateJobInfo}>Update</button>
                                            </div> 
                                            ):('')}  
                                        </div>
                                        ):('')}
                                        {/* <!--JOB Info Card End---> */}


                                        {/* <!--Social Networks Card Start---> */}
                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Social Networks
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">WhatsApp Number : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="WhatsApp Number" value={user_whatsapp_number}  onChange={(e) => setUserWhatsappNo(e.target.value)}/>

                                                        {socialNetworksErrors.user_whatsapp_number && <p style={{ color: 'red' }}>{socialNetworksErrors.user_whatsapp_number}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Microsoft Team Link : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Team Link" value={user_team_link} onChange={(e) => setUserTeamLink(e.target.value)} />

                                                        {socialNetworksErrors.user_team_link && <p style={{ color: 'red' }}>{socialNetworksErrors.user_team_link}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">LinkedIn Link :</label>
                                                        <input type="text" className="form-control" placeholder="LinkedIn Link" value={user_linkedin_link} onChange={(e) => setUserLinkedinLink(e.target.value)} />

                                                        {socialNetworksErrors.user_linkedin_link && <p style={{ color: 'red' }}>{socialNetworksErrors.user_linkedin_link}</p>}
                                                    </div>

                                                </div>    

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">GitHub Link :</label>
                                                        <input type="text" className="form-control" placeholder="GitHub Link" value={user_github_link} onChange={(e) => setUserGithubLink(e.target.value)} />

                                                        {socialNetworksErrors.user_github_link && <p style={{ color: 'red' }}>{socialNetworksErrors.user_github_link}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Stack Overflow Link :</label>
                                                        <input type="text" className="form-control" placeholder="Stack Overflow Link" value={user_stackoverflow_link} onChange={(e) => setUserStackoverflowLink(e.target.value)} />

                                                        {socialNetworksErrors.user_stackoverflow_link && <p style={{ color: 'red' }}>{socialNetworksErrors.user_stackoverflow_link}</p>}
                                                    </div>

                                                </div>    
                                            </div> 

                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateSocialNetworks}>Update</button>
                                            </div>   
                                        </div>
                                        ):('')}
                                        {/* <!--Social Networks Card End---> */}


                                        {/* <!--Account Info Card Start---> */}
                                        {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Account Info
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label for="input-label" className="form-label">Role : <span className="text-danger">*</span></label>
                                                        <select className="form-control" onChange={(e) => setUserRoleId(e.target.value)}>
                                                            <option value="" hidden>- Select Role -</option>
                                                            {userRoles.map((role) => (
                                                                <option value={role._urole_id} selected={user_role_id === role._urole_id}>
                                                                    {role.urole_title}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        {accInfoErrors.user_role_id && <p style={{ color: 'red' }}>{accInfoErrors.user_role_id}</p>}
                                                    </div>

                                                </div>    

                                            </div>  
                                            {user_data.urole_permission==='*' ? (                   
                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateAccountInfo}>Update</button>
                                            </div>   
                                            ):('')}
                                        </div> 
                                        ):('')}
                                        {/* <!--Account Info Card End---> */}


                                        {/* <!--Change Password Card Start---> */}
                                        {user_data.user_unique_id===user_unique_id ? (
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Change Password
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-4">
                                                        <label className="form-label">Current Passsword : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Current Passsword" value={current_password} onChange={(e) => setCurrentPassword(e.target.value)} />

                                                        {changePasswordErrors.current_password && <p style={{ color: 'red' }}>{changePasswordErrors.current_password}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label className="form-label">New Passsword : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="New Passsword" value={new_password} onChange={(e) => setNewPassword(e.target.value)} />

                                                        {changePasswordErrors.new_password && <p style={{ color: 'red' }}>{changePasswordErrors.new_password}</p>}
                                                    </div>

                                                    <div className="col-xl-4">
                                                        <label className="form-label">Confirm Passsword : <span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control" placeholder="Confirm Passsword" value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} />

                                                        {changePasswordErrors.confirm_password && <p style={{ color: 'red' }}>{changePasswordErrors.confirm_password}</p>}
                                                    </div>

                                                </div>    

                                            </div>  

                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={changePassword}>Update</button>
                                            </div>   
                                        </div> 
                                        ):('')}
                                        {/* <!--Change Password Card End--->    */}
                                        
                                    </div>
                                    ):('')}
                                    {/* <!--Profile Tab Content End---> */}


                                    {/* <!--Bank Details Tab Content Start---> */}
                                    {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                    <div className="tab-pane fade p-0 border-0" id="bank-details-tab-pane" role="tabpanel" 
                                        aria-labelledby="bank-details-tab" tabindex="0">

                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    Bank Details
                                                </div>
                                            </div>
                                            <div className="card-body">

                                                <div className="row gy-3">

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Bank Name :</label>
                                                        <input type="text" className="form-control" placeholder="Bank Name" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_bank_name} onChange={(e) => setUserBankName(e.target.value)} />

                                                        {bankDetailsErrors.user_bank_name && <p style={{ color: 'red' }}>{bankDetailsErrors.user_bank_name}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Branch Name :</label>
                                                        <input type="text" className="form-control" placeholder="Branch Name" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_bank_branch_name} onChange={(e) => setUserBankBranchName(e.target.value)} />

                                                        {bankDetailsErrors.user_bank_branch_name && <p style={{ color: 'red' }}>{bankDetailsErrors.user_bank_branch_name}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">Account No :</label>
                                                        <input type="text" className="form-control" placeholder="Account No" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_bank_ac_no} onChange={(e) => setUserBankAcNo(e.target.value)} />

                                                        {bankDetailsErrors.user_bank_ac_no && <p style={{ color: 'red' }}>{bankDetailsErrors.user_bank_ac_no}</p>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <label className="form-label">IFSC  :</label>
                                                        <input type="text" className="form-control" placeholder="IFSC" readOnly={user_data.urole_permission !== '*' ? true : false}
                                                        value={user_bank_ifsc} onChange={(e) => setUserBankIfsc(e.target.value)} />

                                                        {bankDetailsErrors.user_bank_ifsc && <p style={{ color: 'red' }}>{bankDetailsErrors.user_bank_ifsc}</p>}
                                                    </div>

                                                </div>    

                                            </div>  
                                            {user_data.urole_permission==='*' ? ( 
                                            <div className="card-footer">
                                                <button className="btn btn-primary-light btn-wave ms-auto float-end" onClick={updateBankDetails}>Update</button>
                                            </div> 
                                            ):('')}  
                                        </div> 
                                        
                                    </div>
                                    ):('')}
                                    {/* <!--Bank Details Tab Content Start---> */}


                                    {/* <!--Leave Tab Content Start---> */}
                                    {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                    <div className="tab-pane fade p-0 border-0" id="leave-tab-pane" role="tabpanel" 
                                        aria-labelledby="leave-tab" tabindex="0">

                                        <h4>Leave</h4>

                                        {/* <!--Leave Applications Card Start---> */}
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    
                                                </div>
                                            </div>
                                            <div className="card-body">

                                            <div className="table-responsive">
                                                <LeaveApplicationTables
                                                    data={leaveApplicationData}
                                                    setData={setLeaveApplicationData}
                                                    loading={leaveApplicationLoading}
                                                    setLoading={setLeaveApplicationLoading}
                                                    totalRows={leaveApplicationTotalRows}
                                                    setTotalRows={setLeaveApplicationTotalRows}
                                                    page={leaveApplicationPage}
                                                    setPage={setLeaveApplicationPage}
                                                    limit={leaveApplicationLimit}
                                                    setLimit={setLeaveApplicationLimit}
                                                    fetchApplications={fetchApplications} 
                                                    user_unique_id={user_unique_id}
                                                    openViewApplicationModal={openViewApplicationModal}/>
                                            </div>

                                            </div>  


                                            {/* <!--View Leave Modal Start--> */}
                                            <ViewLeaveApplicationModal
                                                showModal={showViewLeaveModal}
                                                setShowModal={setShowViewLeaveModal}
                                                applicationData={applicationData}
                                                modalKey={modalKey}
                                                fetchApplications={fetchApplications}
                                                limit={leaveApplicationLimit}
                                                page={leaveApplicationPage} />
                                            {/* <!--View Leave Modal End--> */}

                                              
                                        </div> 
                                        {/* <!--Leave Applications Card End---> */}


                                        {/* <!--Leave Balances Card Start---> */}
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    
                                                </div>
                                            </div>
                                            <div className="card-body">
                                            <LeaveBalanceTables 
                                                data={leaveBalanceData}
                                                setData={setLeaveBalanceData}
                                                loading={leaveBalanceLoading}
                                                setLoading={setLeaveBalanceLoading}
                                                totalRows={leaveBalanceTotalRows}
                                                setTotalRows={setLeaveBalanceTotalRows}
                                                page={leaveBalancePage}
                                                setPage={setLeaveBalancePage}
                                                limit={leaveBalanceLimit}
                                                setLimit={setLeaveBalanceLimit}
                                                fetchBalances={fetchBalances}
                                                user_unique_id={user_unique_id} 
                                                openViewBalanceModal={openViewBalanceModal}/>
                                                   

                                            </div>  

                                            {/* <!--View Leave Balance Modal Start--> */}
                                            <ViewLeaveBalanceModal
                                                showModal={showViewBalanceModal}
                                                setShowModal={setShowViewBalanceModal}
                                                balanceData={balanceData}
                                                modalKey={modalKey}
                                                fetchBalances={fetchBalances}
                                                limit={leaveBalanceLimit}
                                                page={leaveBalancePage} />
                                            {/* <!--View Leave Balance Modal End--> */}

                                              
                                        </div> 
                                        {/* <!--Leave Balances Card End---> */}
                                        
                                    </div>
                                    ):('')}
                                    {/* <!--Leave Tab Content Start---> */}


                                    {/* <!--Attendance Tab Content Start---> */}
                                    {user_data.user_unique_id===user_unique_id || user_data.urole_permission==='*' ? (
                                    <div className="tab-pane fade p-0 border-0" id="attendance-tab-pane" role="tabpanel" 
                                        aria-labelledby="attendance-tab" tabindex="0">

                                        {/* <!--Attendance Card Start---> */}
                                        <div className="card custom-card">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    
                                                </div>
                                            </div>
                                            <div className="card-body">
                                            <AttendanceTables 
                                                data={attendances}
                                                setData={setAttendances}
                                                loading={attendanceLoading}
                                                setLoading={setAttendanceLoading}
                                                totalRows={attendanceTotalRows}
                                                setTotalRows={setAttendanceTotalRows}
                                                page={attendancePage}
                                                setPage={setAttendancePage}
                                                limit={attendanceLimit}
                                                setLimit={setAttendanceLimit}
                                                fetchAttendances={fetchAttendances}
                                                openViewAttendanceModal={openViewAttendanceModal}
                                                user_unique_id={user_unique_id} />
                                                   
                                            </div>  

                                              
                                        </div> 
                                        {/* <!--Attendance Card End---> */}


                                        {/* <!--View Attendance Modal Start--> */}
                                        <ViewAttendanceModal
                                        showModal={showViewAttendanceModal}
                                        setShowModal={setShowViewAttendanceModal}
                                        attendanceData={attendanceData}
                                        modalKey={modalKey}
                                        fetchAttendances={fetchAttendances}
                                        limit={attendanceLimit}
                                        page={attendancePage} />
                                        {/* <!--View Attendance Modal End--> */}
                                        
                                    </div>
                                    ):('')}
                                    {/* <!--Attendance Tab Content Start---> */}

                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
    )} 

</div>


</div>  

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Profile
