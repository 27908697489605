import React, { useState } from 'react';

const Submodules = ({ submodules, selectedModules, setSelectedModules }) => {
    const handleSubmoduleChange = (submoduleValue, isChecked) => {
      if (isChecked) {
        setSelectedModules([...selectedModules, submoduleValue]);
      } else {
        setSelectedModules(selectedModules.filter(module => module !== submoduleValue));
      }
    };
  
    return (
        <>
        {submodules.map(submodule => (
            <div className="form-check form-check-lg d-flex align-items-center" key={submodule.value}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    id={submodule.value}
                    checked={selectedModules.includes(submodule.value)}
                    onChange={(e) => handleSubmoduleChange(submodule.value, e.target.checked)}
                />
                <label className="form-check-label" htmlFor={submodule.value}>{submodule.label}</label>
            </div>
        ))}
        </>
    );
};

export default Submodules;