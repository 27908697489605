import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { getProjectFiles, getProjectLogs, getProjectMembers } from '../../../models/ProjectModel';
import ProjectFiles from '../../skeletons/ProjectFiles';
import DataTable, { createTheme } from 'react-data-table-component';
import ProjectMembers from '../../skeletons/ProjectMembers';
import { profilePic } from '../../../helpers/GeneralHelper';
import ProjectLogs from '../../skeletons/ProjectLogs';

const ProjectLogsTables = ({
    project_unique_id,
    callProjectLogs,
    task_unique_id
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;


    // Get Project Files Start
    const [projectLogs, setProjectLogs] = useState([]);
    const [projectLogsTotalRows, setProjectLogsTotalRows] = useState(0);
    const [projectLogsLoading, setProjectLogsLoading] = useState(false);
    const [projectLogsPage, setProjectLogsPage] = useState(1);
    const [projectLogsLimit, setProjectLogsLimit] = useState(10);

    const fetchProjectLogs = async (limit, page) => {

        if (callProjectLogs===true) {
            setProjectLogsLoading(true);
        
            try {
                let apiParam = {page: page, limit: limit};

                const response = await getProjectLogs(user_data.user_token, project_unique_id, task_unique_id, apiParam);

                //console.log(response);

                if (typeof response != typeof undefined) {
                    setProjectLogs(response.logs);
                    setProjectLogsTotalRows(response.pagination.total_rows);
                    setProjectLogsLimit(limit);
                    setProjectLogsPage(page);

                    setNotificationCount(localStorage.getItem('unread_notification_count'));
                }  
            
                setProjectLogsLoading(false);

            } catch (error) {
                //console.error(error);
            }
        }
    }

    const ProjectLogsCustomLoader = () => (
        <ProjectLogs/>
    );

    const ProjectLogsPageChange = async (page) => {
        fetchProjectLogs(projectLogsLimit, page);  
    };

    const ProjectLogsLimitChange = async (limit) => {
        fetchProjectLogs(limit, projectLogsPage); 
    };

    useEffect(()=>{
        fetchProjectLogs(projectLogsLimit, projectLogsPage); 
    }, [project_unique_id, callProjectLogs, task_unique_id]);
    // Get Project Files End

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    const ProjectLogsColumns = [
        {
            name: "#",
            selector: (row, index) => <div>{((projectLogsPage-1)*projectLogsLimit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.user_first_name, row.user_last_name, row.user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.user_first_name} {row.user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.user_email}</span>
                                        </div>
                                    </div>
                                </div>
        },
        {
            name: "Title",
            selector: (row) => row.activity_title
        },
        {
            name: "Time",
            selector: (row) => dateFormat(row.activity_create_time, "dd mmm yyyy")
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="View">
                              <i className='bi bi-eye'></i>
                            </button>
                        </div>
        }
    ];

    return (
        <div className="card custom-card shadow-none">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">
                    {task_unique_id!='' ? 'Task' : 'Project'} Logs
                </div>
            </div>
            <div className="card-body">
                <DataTable 
                    columns={ProjectLogsColumns} 
                    data={projectLogs}
                    pagination
                    paginationServer
                    paginationTotalRows={projectLogsTotalRows} 
                onChangeRowsPerPage={ProjectLogsLimitChange} 
                onChangePage={ProjectLogsPageChange} 
                progressPending={projectLogsLoading} 
                progressComponent={<ProjectLogsCustomLoader />}
                    fixedHeader 
                    fixedHeaderScrollHeight='550px'
                    highlightOnHover
                    theme={`${theme==='light' ? 'light' : 'dark'}`} />
            </div>
        </div> 
    );

};    

export default ProjectLogsTables;    