import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { getProjectFiles, getProjectMembers } from '../../../models/ProjectModel';
import ProjectFiles from '../../skeletons/ProjectFiles';
import DataTable, { createTheme } from 'react-data-table-component';
import ProjectMembers from '../../skeletons/ProjectMembers';
import { profilePic } from '../../../helpers/GeneralHelper';
import AddProjectMemberModal from '../../modals/Project/AddProjectMemberModal';
import DeleteProjectMemberModal from '../../modals/Project/DeleteProjectMemberModal';
import UpdateProjectMemberModal from '../../modals/Project/UpdateProjectMemberModal';

const ProjectMembersTables = ({
    project_unique_id,
    callProjectMembers,
    projectData
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const [modalKey, setModalKey] = useState(0);

    const [memberData, setMemberData] = useState({});

    //Add Project Member Start
    const [showAddProjectMemberModal, setShowAddProjectMemberModal] = useState(false);

    const openAddProjectMemberModal = () =>{
        setModalKey(prevKey => prevKey + 1); 
        setShowAddProjectMemberModal(true);
    } 
    //Add Project Member End

    //Update Project Member Start
    const [showUpdateProjectMemberModal, setShowUpdateProjectMemberModal] = useState(false);

    const openUpdateProjectMemberModal = (member_data) =>{
        setMemberData(member_data);
        setModalKey(prevKey => prevKey + 1); 
        setShowUpdateProjectMemberModal(true);
    }
    //Update Project Member End

    
    //Delete Project Member Start
    const [showDeleteProjectMemberModal, setShowDeleteProjectMemberModal] = useState(false);

    const openDeleteProjectMemberModal = (member_data) =>{
        setMemberData(member_data);
        setModalKey(prevKey => prevKey + 1); 
        setShowDeleteProjectMemberModal(true);
    } 
    //Delete Project Member End


    // Get Project Files Start
    const [projectMembers, setProjectMembers] = useState([]);
    const [projectMembersTotalRows, setProjectMembersTotalRows] = useState(0);
    const [projectMembersLoading, setProjectMembersLoading] = useState(false);
    const [projectMembersPage, setProjectMembersPage] = useState(1);
    const [projectMembersLimit, setProjectMembersLimit] = useState(10);

    const fetchProjectMembers = async (limit, page) => {

        if (callProjectMembers===true) {
            setProjectMembersLoading(true);

            try {
                let apiParam = {page: page, limit: limit};

                const response = await getProjectMembers(user_data.user_token, project_unique_id, apiParam);

                if (typeof response != typeof undefined) {
                    setProjectMembers(response.members);
                    setProjectMembersTotalRows(response.pagination.total_rows);
                    setProjectMembersLimit(limit);
                    setProjectMembersPage(page);

                    setNotificationCount(localStorage.getItem('unread_notification_count'));
                }  
            
                setProjectMembersLoading(false);
                
            } catch (error) {
                //console.error(error);
            }
        }

        
    }

    const ProjectMembersCustomLoader = () => (
        <ProjectMembers/>
    );

    const ProjectMembersPageChange = async (page) => {
        fetchProjectMembers(projectMembersLimit, page);  
    };

    const ProjectMembersLimitChange = async (limit) => {
        fetchProjectMembers(limit, projectMembersPage); 
    };  

    useEffect(()=>{
        fetchProjectMembers(projectMembersLimit, projectMembersPage); 
    }, [project_unique_id, callProjectMembers]);
    // Get Project Files End

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    const ProjectMembersColumns = [
        {
            name: "#",
            selector: (row, index) => <div>{((projectMembersPage-1)*projectMembersLimit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Name",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.user_first_name, row.user_last_name, row.user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.user_first_name} {row.user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.user_email}</span>
                                        </div>
                                    </div>
                                </div>
        },
        {
            name: "Site Visit",
            selector: (row) => 
            {
                if (row.member_site_visit_allowed==='1') {
                    return (
                        <>
                          <span className="badge bg-success-transparent">Yes</span>
                        </>
                      );
                }
                else{
                    return (
                        <>
                          <span className="badge bg-danger-transparent">No</span>
                        </>
                      );
                }    
            }
        },
        {
            name: "Type",
            selector: (row) => row.user_job_title
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">    
                            <button type="button" className={`btn btn-icon btn-sm btn-info ${projectData.project_site_visit==1 ? '' : 'd-none'}`} title="Edit" onClick={() => openUpdateProjectMemberModal(row)}>
                              <i className='bx bxs-edit'></i>
                            </button>

                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteProjectMemberModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    // Conditionally unset a specific column
    const filteredColumns = projectData.project_site_visit!='0'
        ? ProjectMembersColumns.filter(column => column.name !== "Site Visit")
        : ProjectMembersColumns;

    return (
        <div className="card custom-card shadow-none">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">
                    
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-primary btn-sm" onClick={() => openAddProjectMemberModal()}>
                        <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Member
                    </button>
                </div>
            </div>
            <div className="card-body">
                <DataTable 
                    title={`Manage Members`} 
                    columns={filteredColumns} 
                    data={projectMembers}
                    pagination
                    paginationServer
                    paginationTotalRows={projectMembersTotalRows} 
                onChangeRowsPerPage={ProjectMembersLimitChange} 
                onChangePage={ProjectMembersPageChange} 
                progressPending={projectMembersLoading} 
                progressComponent={<ProjectMembersCustomLoader />}
                    fixedHeader 
                    fixedHeaderScrollHeight='550px'
                    highlightOnHover
                    theme={`${theme==='light' ? 'light' : 'dark'}`} />
            </div>


            {/* <!--Add Project Member Modal Start--> */}
            <AddProjectMemberModal
                showModal={showAddProjectMemberModal}
                setShowModal={setShowAddProjectMemberModal}
                project_unique_id={project_unique_id}
                projectData={projectData}
                modalKey={modalKey}
                fetchProjectMembers={fetchProjectMembers}
                limit={projectMembersLimit}
                page={projectMembersPage} />
            {/* <!--Add Project Member Modal End--> */}


            {/* <!--Update Project Member Modal Start--> */}
            <UpdateProjectMemberModal
                showModal={showUpdateProjectMemberModal}
                setShowModal={setShowUpdateProjectMemberModal}
                project_unique_id={project_unique_id}
                memberData={memberData}
                modalKey={modalKey}
                fetchProjectMembers={fetchProjectMembers}
                limit={projectMembersLimit}
                page={projectMembersPage} />
            {/* <!--Update Project Member Modal End--> */}


            {/* <!--Delete Project Member Modal Start--> */}
            <DeleteProjectMemberModal
                showModal={showDeleteProjectMemberModal}
                setShowModal={setShowDeleteProjectMemberModal}
                project_unique_id={project_unique_id}
                memberData={memberData}
                modalKey={modalKey}
                fetchProjectMembers={fetchProjectMembers}
                limit={projectMembersLimit}
                page={projectMembersPage} />
            {/* <!--Delete Project Member Modal End--> */}

        </div>  
    );

};    

export default ProjectMembersTables;    