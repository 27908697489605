import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { apiError } from '../../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../../config/ApiConfig';
import { tableCustomLoader } from '../../../helpers/StyleHelper';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';


function WsJson() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');

    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Web Service Logs - Worktracker Anciletech </title>
                
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Web Service Log JSON</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">Logs</li>
                                    <li className="breadcrumb-item active" aria-current="page">Web Service</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-danger-light btn-sm">Delete Selected</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default WsJson
