import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { profilePic } from '../../../helpers/GeneralHelper';
import { utcToLocal } from '../../../helpers/DateHelper';

const ViewActivityModal = ({showModal, setShowModal, activityData, modalKey, fetchActivities, limit, page}) => {

  const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);
    
    const [_details_id, setDetailsId] = useState('');
    const [details_screenshot_path, setDetailScreenshotPath] = useState('');
    const [details_application_title, setDetailApplicationTitle] = useState('');
    const [details_application_name, setDetailApplicationName] = useState('');
    const [details_url, setDetailUrl] = useState('');
    const [details_application_path, setDetailApplicationPath] = useState('');
    const [details_create_time, setDetailCreateTime] = useState('');
    const [project_title, setProjectTitle] = useState('');
    const [client_name, setClientName] = useState('');
    const [task_title, setTaskTitle] = useState('');
    const [timesheet_other_task, setTimesheetOtherTask] = useState('');
    const [timesheet_task_detail, setTimesheetTaskDetail] = useState('');
    const [user_first_name, setUserFirstName] = useState('');
    const [user_last_name, setUserLastName] = useState('');
    const [user_image, setUserImage] = useState('');



    const closeModal = () =>{
        setDetailsId('');
        setDetailScreenshotPath('');
        setDetailApplicationTitle('');
        setDetailApplicationName('');
        setDetailUrl('');
        setDetailApplicationPath('');
        setDetailCreateTime('');
        setProjectTitle('');
        setClientName('');
        setTaskTitle('');
        setTimesheetOtherTask('');
        setTimesheetTaskDetail('');
        setUserFirstName('');
        setUserLastName('');
        setUserImage('');

        setShowModal(false);
    }
    
    
    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (activityData) {
            setDetailsId(activityData._details_id);
            setDetailScreenshotPath(activityData.details_screenshot_path);
            setDetailApplicationTitle(activityData.details_application_title);
            setDetailApplicationName(activityData.details_application_name);
            setDetailUrl(activityData.details_url);
            setDetailApplicationPath(activityData.details_application_path);
            setDetailCreateTime(activityData.details_create_time);
            setProjectTitle(activityData.project_title);
            setClientName(activityData.client_name);
            setTaskTitle(activityData.task_title);
            setTimesheetOtherTask(activityData.timesheet_other_task);
            setTimesheetTaskDetail(activityData.timesheet_task_detail);
            setUserFirstName(activityData.user_first_name);
            setUserLastName(activityData.user_last_name);
            setUserImage(activityData.user_image);
          
        }
    }, [activityData, modalKey]);
  

  return (
    <Modal show={showModal} onHide={closeModal} size='xl'>
        <Modal.Header closeButton>
            
            <h6 className='d-flex align-items-center'>Activity Details of &nbsp;
                <div className="d-flex align-items-center me-3">
                    <div className="avatar avatar-sm me-2 avatar-rounded">
                        {profilePic(user_first_name, user_last_name, user_image)}
                    </div>

                    <div>
                        <div className="lh-1">
                            <span>{user_first_name} {user_last_name}</span>
                        </div>
                    </div>
                </div>
                <i class="bi bi-clock"></i> <span className='mb-0'>&nbsp;Captured On {utcToLocal(details_create_time, "dd mmm yyyy HH:MM")}</span>
            </h6>
            
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">

                <div className="col-xl-4">
                    <label htmlFor="note_desc" className="form-label">Project </label>

                    <div className='form-control'>{project_title}</div>
                </div>

                <div className="col-xl-4">
                    <label htmlFor="note_desc" className="form-label">Task </label>


                    <div className='form-control'>{task_title ? task_title : timesheet_other_task}</div>
                </div>

                <div className="col-xl-4">
                    <label htmlFor="note_desc" className="form-label">Task Details</label>


                    <textarea className='form-control' value={timesheet_task_detail} disabled="true" rows="3"></textarea>
                </div>

                <div className="col-xl-12">
                    <img src={`${process.env.REACT_APP_S3_URL}/${details_screenshot_path}`} alt="image" style={{maxWidth: '100%'}} />
                </div>
                <div className="col-xl-6">
                    <label htmlFor="note_desc" className="form-label">Application Name </label>

                    <div className='form-control'>{details_application_name}</div>
                </div>

                <div className="col-xl-6">
                    <label htmlFor="note_desc" className="form-label">Application Title </label>


                    <textarea className='form-control' value={details_application_title} disabled="true" rows="4"></textarea>
                </div>

                <div className="col-xl-12">
                    <label htmlFor="note_desc" className="form-label">Application Installation Path </label>

                    <div className='form-control'>{details_application_path}</div>
                </div>
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Close</button>
        </Modal.Footer>
    </Modal>
  );
};

export default ViewActivityModal;
