import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../config/ApiConfig';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { useAuth } from '../../context/AuthContext';
import { invoiceAmount, invoiceStatus, prettyAmount } from '../../helpers/InvoiceHelper';
import { useTheme } from '../../context/ThemeContext';
import { getInvoiceMonthWiseChart, getInvoices, getInvoiceStats } from '../../models/InvoiceModel';
import InvoiceTables from '../tables/InvoiceTables';
import CreateInvoiceModal from '../modals/Invoice/CreateInvoiceModal';
import DeleteInvoiceModal from '../modals/Invoice/DeleteInvoiceModal';
import { useNotificationCount } from '../../context/NotificationCountContext';
import { checkPermission } from '../../helpers/GeneralHelper';
import Error403 from './Error403';
import { formatMonthYear } from '../../helpers/DateHelper';
import { Bar } from 'react-chartjs-2';
import AddClientModal from '../modals/Clients/AddClientModal';
import AddProjectModal from '../modals/Project/AddProjectModal';

function Invoices() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme } = useTheme();  

  const { state } = useAuth(); 

  let user_data = state.user_data;
  
  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading'); 

  const [modalKey, setModalKey] = useState(0);

  //Add Client Start
  const [showAddClientModal, setShowAddClientModal] = useState(false);
  //Add Client End

  //Add Project Start
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  //Add Project End


  //Selected Client Start
  const [_client_id, setClientId] = useState('');
  //Selected Client End

  //Add Invoice Start
  const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
  //Add Invoice End


  //Delete Invoice Start
  const [invoice_data, setInvoiceData] = useState({});

  const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);

  const openDeleteInvoiceModal = (invoice_data) =>{
    setInvoiceData(invoice_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteInvoiceModal(true);
  } 
  //Delete Invoice End

  //Flush Invoice Start
  const flushInvoice = async() => {
    localStorage.removeItem("invoices");
    fetchInvoices(limit, page); 
  }  
  //Flush Invoice End
  
  //Invoice List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchInvoices = async(newLimit, page) => {
    setLoading(true);

    try {
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getInvoices(apiParam);

        if (typeof response != typeof undefined) {
            setData(response.invoices);
            setTotalRows(response.pagination.total_rows);
            setLimit(newLimit);
            setPage(page);
            
            if (response.time) {
                setCacheTime(response.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
            
        
        setLoading(false);

    } catch (error) {
        //console.error(error);
    }

    
  };
  //Invoice List End



  //Invoice Stats Start
  const [stats, setStats] = useState([]);
  const [statLoading, setStatLoading] = useState(true);

  const fetchInvoiceStats = async() =>{
    setStatLoading(true);

    try {
        const response = await getInvoiceStats(user_data.user_token);

        if (response.status) {

            if (response.data.invoice_stats) {
                //console.log(response.data.invoice_stats);
                setStats(response.data.invoice_stats);
                
                setStatLoading(false);
            }
        }
        else{
            navigate('/re-login');
        }
        
    } catch (error) {
        //console.error(error);
    }
  }
  //Invoice Stats End


  //Invoice Month Wise Chart Start
  const options = {
    scales: {
      x: {
        type: 'category', // Define the x-axis as categorical
      },
    },
    maintainAspectRatio: false
  };

  const [chart, setChart] = useState({
    labels: [],
    datasets: [
      {
        label: 'Invoice Amount',
        data: [],
        backgroundColor: '#5f76e8',
        tension: 0.1,
      }
    ],
    });
  const [chartLoading, setChartLoading] = useState(true);

  const fetchInvoiceChart = async()=>{
    setChartLoading(true);

    try {
        const response = await getInvoiceMonthWiseChart(user_data.user_token);

        if (response.status) {

            if (response.data.invoice_month_wise_data) {
                //console.log(response.data.invoice_month_wise_data);

                const chartData = {
                    labels: response.data.invoice_month_wise_data.map(item => {
                        const [year, month] = item.month.split('-');

                        const dateString = month+'-'+year;

                        return `${formatMonthYear(dateString)}`;
                    }),
                    datasets: [
                        {
                            label: 'Invoice Amount',
                            data: response.data.invoice_month_wise_data.map(item => (parseInt(item.invoice_amount))),
                            backgroundColor: '#5f76e8',
                            tension: 0.1,
                        }
                    ],
                };


                setChart(chartData);
                
                setChartLoading(false);
            }
        }
        
        
    } catch (error) {
        //console.error(error);
    }
  }
  //Invoice Month Wise Chart End

  useEffect(() => {
    fetchInvoiceChart();
    fetchInvoiceStats();
}, []); 

  
    
  return (
    <>
        {checkPermission('menu_invoice')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Invoices - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Invoice List</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Invoice</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Invoice List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card custom-card">
                                <div className="card-header justify-content-between">
                                    <div className="card-title me-auto">
                                        Last Synced On {cacheTime}
                                    </div>

                                    <button className="btn btn-primary btn-sm me-1" onClick={() => setShowAddInvoiceModal(true)}>
                                            <i className="ri-add-line me-1 fw-semibold align-middle"></i>New Invoice
                                    </button>

                                    <button className="btn btn-success-light btn-sm" onClick={() => flushInvoice()}>
                                        <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                    </button>

                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <InvoiceTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchInvoices={fetchInvoices}
                                          openDeleteInvoiceModal={openDeleteInvoiceModal} />
                                    </div>
                                </div>
                                
                            </div>

                            {/* <!--Create Invoice Modal Start--> */}
                            <CreateInvoiceModal
                            showModal={showAddInvoiceModal}
                            setShowModal={setShowAddInvoiceModal}
                            fetchInvoices={fetchInvoices}
                            limit={limit}
                            page={page}
                            setShowAddClientModal={setShowAddClientModal}
                            setShowAddProjectModal={setShowAddProjectModal}
                            _client_id={_client_id} />
                            {/* <!--Create Invoice Modal End--> */}


                            {/* <!--Delete Invoice Modal Start--> */}
                            <DeleteInvoiceModal
                            showModal={showDeleteInvoiceModal}
                            setShowModal={setShowDeleteInvoiceModal}
                            invoiceData={invoice_data}
                            modalKey={modalKey}
                            fetchInvoices={fetchInvoices}
                            limit={limit}
                            page={page}  />
                            {/* <!--Delete Invoice Modal End--> */}

                            {/* <!--Add Client Modal Start--> */}
                            <AddClientModal 
                                showModal={showAddClientModal}
                                setShowModal={setShowAddClientModal}
                                fetchClients={() => []}
                                limit="10"
                                page="1"
                                openCreateInvoice={true}
                                setShowAddInvoiceModal={setShowAddInvoiceModal}
                                setClientId={setClientId}
                            />
                            {/* <!--Add Client Modal End--> */}

                            {/* <!--Add Project Modal Start--> */}
                            <AddProjectModal
                            showModal={showAddProjectModal}
                            setShowModal={setShowAddProjectModal}
                            fetchProjects={() => []}
                            limit="10"
                            page="1"
                            openCreateInvoice={true}
                            setShowAddInvoiceModal={setShowAddInvoiceModal} />
                            {/* <!--Add Project Modal End--> */}

                        </div>
                        <div className="col-xl-3">
                            <div className="card custom-card">
                                <div className="card-body p-0">
                                    <div  style={{height: '100%'}}>
                                    {statLoading ? 
                                        
                                        <div className='d-flex justify-content-center my-3'>
                                            <span class="loader-new"></span>
                                        </div>
                                    
                                    : (
                                    <>
                                        <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                                            <div className="svg-icon-background bg-primary-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewbox="0 0 24 24" className="svg-primary"><path d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Total Invoices Amount Domestic
                                                    <span className="badge bg-primary fw-semibold float-end">
                                                        {stats.total_invoice_count_domestic}
                                                    </span> 
                                                </h6> 
                                                <div className="pb-0 mt-0"> 
                                                    <div> 
                                                        <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('₹', stats.total_invoice_amount_domestic)}</h4> 
                                                    
                                                    </div> 
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                                            <div className="svg-icon-background bg-primary-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewbox="0 0 24 24" className="svg-primary"><path d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Total Invoices Amount International
                                                    <span className="badge bg-primary fw-semibold float-end">
                                                        {stats.total_invoice_count_international}
                                                    </span> 
                                                </h6> 
                                                <div className="pb-0 mt-0"> 
                                                    <div> 
                                                        <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('$', stats.total_invoice_amount_international)}</h4> 
                                                    
                                                    </div> 
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                                            <div className="svg-icon-background bg-success-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" className="svg-success"><path d="M11.5,20h-6a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h5V7a3,3,0,0,0,3,3h3v5a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.29.29,0,0,0-.1,0A1.1,1.1,0,0,0,11.56,2H5.5a3,3,0,0,0-3,3V19a3,3,0,0,0,3,3h6a1,1,0,0,0,0-2Zm1-14.59L15.09,8H13.5a1,1,0,0,1-1-1ZM7.5,14h6a1,1,0,0,0,0-2h-6a1,1,0,0,0,0,2Zm4,2h-4a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-4-6h1a1,1,0,0,0,0-2h-1a1,1,0,0,0,0,2Zm13.71,6.29a1,1,0,0,0-1.42,0l-3.29,3.3-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,21.21,16.29Z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Total Paid Invoices Domestic
                                                    <span className="badge bg-success fw-semibold float-end">
                                                        {stats.total_fullpaid_invoice_count_domestic}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2"> {prettyAmount('₹', stats.total_partlypaid_invoice_amount_domestic)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="p-4 border-bottom border-block-end-dashed d-flex align-items-top">
                                            <div className="svg-icon-background bg-success-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" className="svg-success"><path d="M11.5,20h-6a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h5V7a3,3,0,0,0,3,3h3v5a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.29.29,0,0,0-.1,0A1.1,1.1,0,0,0,11.56,2H5.5a3,3,0,0,0-3,3V19a3,3,0,0,0,3,3h6a1,1,0,0,0,0-2Zm1-14.59L15.09,8H13.5a1,1,0,0,1-1-1ZM7.5,14h6a1,1,0,0,0,0-2h-6a1,1,0,0,0,0,2Zm4,2h-4a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-4-6h1a1,1,0,0,0,0-2h-1a1,1,0,0,0,0,2Zm13.71,6.29a1,1,0,0,0-1.42,0l-3.29,3.3-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4A1,1,0,0,0,21.21,16.29Z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Total Paid Invoices International
                                                    <span className="badge bg-success fw-semibold float-end">
                                                        {stats.total_fullpaid_invoice_count_international}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2"> {prettyAmount('$', stats.total_partlypaid_invoice_amount_international)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                                            <div className="svg-icon-background bg-warning-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewbox="0 0 24 24" className="svg-warning"><path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Pending Invoices Domestic
                                                    <span className="badge bg-warning fw-semibold float-end">
                                                        {stats.total_partlypaid_invoice_count_domestic}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('₹', stats.total_partlypaid_invoice_amount_domestic)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                                            <div className="svg-icon-background bg-warning-transparent me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewbox="0 0 24 24" className="svg-warning"><path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Pending Invoices International
                                                    <span className="badge bg-warning fw-semibold float-end">
                                                        {stats.total_partlypaid_invoice_count_international}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('$', stats.total_partlypaid_invoice_amount_international)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                                            <div className="svg-icon-background bg-light me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewbox="0 0 24 24" className="svg-dark"><path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Overdue Invoices Domestic
                                                    <span className="badge bg-light text-default fw-semibold float-end">
                                                        {stats.total_overdue_invoice_count_domestic}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('₹', stats.total_overdue_invoice_amount_domestic)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-top p-4 border-bottom border-block-end-dashed">
                                            <div className="svg-icon-background bg-light me-4"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewbox="0 0 24 24" className="svg-dark"><path d="M19,12h-7V5c0-0.6-0.4-1-1-1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C20,12.4,19.6,12,19,12z M12,19.9c-3.8,0.6-7.4-2.1-7.9-5.9C3.5,10.2,6.2,6.6,10,6.1V13c0,0.6,0.4,1,1,1h6.9C17.5,17.1,15.1,19.5,12,19.9z M15,2c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1C22,5.1,18.9,2,15,2z M16,8V4.1C18,4.5,19.5,6,19.9,8H16z"></path></svg>
                                            </div> 
                                            <div className="flex-fill">
                                                <h6 className="mb-2 fs-12">Overdue Invoices International
                                                    <span className="badge bg-light text-default fw-semibold float-end">
                                                        {stats.total_overdue_invoice_count_international}
                                                    </span> 
                                                </h6> 
                                                <div> 
                                                    <h4 className="fs-18 fw-semibold mb-2">{prettyAmount('$', stats.total_overdue_invoice_amount_international)}</h4> 
                                                    
                                                </div> 
                                            </div>
                                        </div>
                                    </>     
                                    )}
                                    </div>

                                    <div className="p-4 d-none" style={{maxHeight: '350px'}}>
                                        <p className="fs-15 fw-semibold">Invoice Status <span className="text-muted fw-normal">(Last 6 months) :</span></p>
                                        
                                        <Bar data={chart} options={options} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                    
                    </div>
                    
                    {/* <!--End::row-1 --> */}

                </div>

                
            </div>  

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default Invoices
