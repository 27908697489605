import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { apiError } from '../../../helpers/ErrorHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { getProjectOverView } from '../../../models/ProjectModel';
import { utcToLocal } from '../../../helpers/DateHelper';
import { profilePic } from '../../../helpers/GeneralHelper';
import { Line } from 'react-chartjs-2';

const ProjectOverView = ({
    project_unique_id,
    callProjectOverView,
    projectData,
    setProjectData,
    projectOverViewLoading,
    setProjectOverViewLoading
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const editor = useReducer(null);

    const loader = document.getElementById('loading');

    const options = {
        scales: {
          x: {
            type: 'category', // Define the x-axis as categorical
          },
        },
        maintainAspectRatio: false
    };

    

    

    const [projectDaysLeft, setProjectDaysLeft] = useState(0);
    const [projectTasksOpen, setProjectTasksOpen] = useState(0);
    const [projectTasksClosed, setProjectTasksClosed] = useState(0);
    const [projectTotalMembers, setProjectTotalMembers] = useState(0);

    const [projectActivities, setProjectActivities] = useState([]);

    const [projectTimesheets, setProjectTimesheets] = useState({
        labels: [],
        datasets: [
          {
            label: 'Work Time',
            data: [],
            backgroundColor: '#5f76e8',
            tension: 0.1,
          }
        ],
    });


    // Get Project Overview Start
    const fetchProjectOverView = async() => {

        if (callProjectOverView===true ) {
            //console.log(Object.keys(projectData).length);
            setProjectOverViewLoading(true); 

            try {
                const response = await getProjectOverView(user_data.user_token, project_unique_id);

                if (response.status) {

                    if (response.data.project_data) {
                        setProjectData(response.data.project_data);
                    }

                    if (response.data.days_left) {
                        setProjectDaysLeft(response.data.days_left);
                    }

                    if (response.data.task_open) {
                        setProjectTasksOpen(response.data.task_open);
                    }

                    if (response.data.task_closed) {
                        setProjectTasksClosed(response.data.task_closed);
                    }

                    if (response.data.total_member) {
                        setProjectTotalMembers(response.data.total_member);
                    }

                    if (response.data.activities) {
                        setProjectActivities(response.data.activities);
                    }

                    if (response.data.timesheets) {
                        setProjectTimesheets({
                            labels: response.data.timesheets.map(item => {
                                return `${utcToLocal(item.timesheet_date, "dd mmm yyyy")}`;
                            }),
                            datasets: [
                            {
                                label: 'Work Time',
                                data: response.data.timesheets.map(item => (parseInt(item.work_seconds))),
                                backgroundColor: '#5f76e8',
                                tension: 0.1,
                            }
                            ],
                        });
                    }
                    
                }
                else{
                    if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                        navigate(`/re-login?redirect=${currentRoute}`);
                    }

                    toast.error(response.message);
                }  
                
                setProjectOverViewLoading(false);    
                
            } catch (error) {
                //console.log(error);
            }
        }
    }

    useEffect(() => {  
        fetchProjectOverView();
    }, []);
    // Get Project Overview End


    useEffect(() => {  
        console.log('Total Member: '+projectTotalMembers);
    }, [projectTotalMembers]);



    return (
        <>
            <div className="row mb-3">
                <div className="col-xl-3">
                    <div className="card custom-card card-bg-primary">
                        <div className="card-body">
                            <div className="d-flex align-items-center w-100">
                                <div className="me-2">
                                    <span className="avatar avatar-rounded">
                                        <h3 className='text-white'>{projectTasksOpen}</h3>
                                    </span>
                                </div>
                                <div className="">
                                    <div className="fs-15 fw-semibold">Open Tasks</div>
                                    <p className="mb-0 text-fixed-white op-7 fs-12"><br/></p>
                                </div>
                                <div className="ms-auto">
                                    <a href="javascript:void(0);" className="text-fixed-white"><i className="bi bi-lock"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card custom-card card-bg-secondary">
                        <div className="card-body">
                            <div className="d-flex align-items-center w-100">
                                <div className="me-2">
                                    <span className="avatar avatar-rounded">
                                        <h3 className='text-white'>{projectTasksClosed}</h3>
                                    </span>
                                </div>
                                <div className="">
                                    <div className="fs-15 fw-semibold">Closed Tasks</div>
                                    <p className="mb-0 text-fixed-white op-7 fs-12"><br/></p>
                                </div>
                                <div className="ms-auto">
                                    <a href="javascript:void(0);" className="text-fixed-white"><i className="bi bi-lock"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card custom-card card-bg-warning">
                        <div className="card-body">
                            <div className="d-flex align-items-center w-100">
                                <div className="me-2">
                                    <span className="avatar avatar-rounded">
                                        <h3 className='text-white'>{projectTotalMembers}</h3>
                                    </span>
                                </div>
                                <div className="">
                                    <div className="fs-15 fw-semibold">Members</div>
                                    <p className="mb-0 text-fixed-white op-7 fs-12"><br/></p>
                                </div>
                                <div className="ms-auto">
                                    <a href="javascript:void(0);" className="text-fixed-white"><i className="bi bi-people"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className={`card custom-card card-bg-${projectDaysLeft > 0 ? 'success' : 'danger'}`}>
                        <div className="card-body">
                            <div className="d-flex align-items-center w-100">
                                <div className="me-2">
                                    <span className="avatar avatar-rounded">
                                        <h3 className='text-white'>{projectDaysLeft < 0 ? (projectDaysLeft)*(-1) : projectDaysLeft}</h3>
                                    </span>
                                </div>
                                <div className="">
                                    <div className="fs-15 fw-semibold">Days {projectDaysLeft > 0 ? 'Left' : 'Overdue'}</div>
                                    <p className="mb-0 text-fixed-white op-7 fs-12"><br/></p>
                                </div>
                                <div className="ms-auto">
                                    <a href="javascript:void(0);" className="text-fixed-white"><i className="bi bi-calendar3"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-8">
                    <div className="card custom-card shadow">
                        <div className="card-header">
                            <div className="card-title">Work Hour Chart</div>
                        </div>
                        <div className="card-body" style={{height: '400px'}}>
                            <Line data={projectTimesheets} options={options}  />
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card custom-card shadow">
                        <div className="card-header justify-content-between">
                            <div className="card-title">
                                Timeline
                            </div>
                            <div>
                                <a className="btn btn-sm btn-primary-light btn-wave waves-effect waves-light">View All</a>
                            </div>
                        </div>
                        <div className="card-body"  style={{height: '400px'}}>
                            {projectOverViewLoading===true ? (
                                <div className='d-flex justify-content-center my-3'>
                                    <span class="loader-new"></span>
                                </div>
                            ) : projectActivities.length > 0 ? (
                            <ul className="list-unstyled timeline-widget mb-0 my-3">
                                {projectActivities.map((activity) => (
                                <li className="timeline-widget-list">
                                    
                                    <div className="d-flex align-items-top">
                                        <div className="me-5 text-center">
                                            <span className="d-block fs-20 fw-semibold">{utcToLocal(activity.activity_create_time, 'dd')}</span>
                                            <span className="d-block fs-12 text-muted">{utcToLocal(activity.activity_create_time, 'mmm')}</span>
                                        </div>

                                        <div className="lh-1">
                                            <span className="avatar avatar-md online me-3 avatar-rounded">
                                                {activity._user_id !== '' ? (
                                                    profilePic(activity.user_first_name, activity.user_last_name, activity.user_image)
                                                ) : (
                                                    <i className="ti ti-user-check fs-18"></i>
                                                )}
                                            </span>
                                        </div>

                                        <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between">
                                            <div>
                                                <p className="mb-1 text-truncate timeline-widget-content text-wrap">{activity.activity_title}</p>
                                                <p className="mb-0 fs-12 lh-1 text-muted">{utcToLocal(activity.activity_create_time, 'hh:MM TT')}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </li>
                                )) }  
                            </ul>
                            ) : 
                            <div className="p-5 empty-item1 d-nonea">
                                <div className="text-center">
                                    <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                        <i className="ri-notification-off-line fs-2"></i>
                                    </span>
                                    <h6 className="fw-semibold mt-3">No New Activies</h6>
                                </div>
                            </div>

                            } 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};    

export default ProjectOverView;    