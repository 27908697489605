import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { getNotes } from '../../models/NoteModel';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import NoteList from '../skeletons/NoteList';

const NoteTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchNotes,
    openUpdateNoteModal, 
    openDeleteNoteModal
}) => {

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
      
        <NoteList/>
       
    );


    const handlePageChange = async (page) => {
      fetchNotes(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
      if (newLimit!==10 && page!==1) {
        fetchNotes(newLimit, page); 
      }
      else if (localStorage.getItem('notes')) {
        fetchNotes(newLimit, page); 
      }
      
    };

    useEffect(()=>{
        fetchNotes(limit, page);  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Title",
            selector: (row) => row.note_title
        },
        {
            name: "Added On",
            selector: (row) => dateFormat(row.note_create_time, "dd mmm yyyy HH:MM")  
        },
        {
            name: "Last Edited On",
            selector: (row) => row.note_update_time ? dateFormat(row.note_update_time, "dd mmm yyyy HH:MM") : '--'
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit" onClick={() => openUpdateNoteModal(row)}>
                              <i className='bx bxs-edit'></i>
                            </button>
    
                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteNoteModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Notes (Private)`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default NoteTables;