import { decryptData } from "./EncryptHelper";
import he from 'he';

export const numberToOrdinal = (number) => {
    const suffixes = ['st', 'nd', 'rd'];
    const remainder10 = number % 10;
    const remainder100 = number % 100;

    if (remainder10 === 1 && remainder100 !== 11) {
        return `${number}st`;
    } else if (remainder10 === 2 && remainder100 !== 12) {
        return `${number}nd`;
    } else if (remainder10 === 3 && remainder100 !== 13) {
        return `${number}rd`;
    } else {
        return `${number}th`;
    }
};


export const getInitials = (name) => {
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')
      .toUpperCase();

    return initials;
};


export const profilePic = (firstName, lastName, pic, radius='30px', fontSize='10px', display='block') => {

    if (display!=='block') {
        return '';
    }
    else if (pic) {
        return <img src={`${process.env.REACT_APP_ASSETS_URL}/${pic}`} alt="img" style={{height: radius, width: radius}} />
    }
    else{
        let name = firstName+' '+lastName;

        return <div className="user-avatar" style={{height: radius, width: radius, fontSize: fontSize}}>
                    {getInitials(name)}
               </div>;
    }
}  

export const checkPermission = (action) => {

    const user_data = localStorage.getItem("user_data") ? decryptData(localStorage.getItem("user_data")) : {};

    if (user_data && user_data.urole_permission) {
        if (user_data.urole_permission==='*') {
            return true;
        }
        else{
            if (user_data.urole_permission.includes(action)) {
                return true;
            }    
        }
    }

    return false;
}


export const decodeHtmlEntities = (encodedString) => {
    const elem = document.createElement('textarea');
  elem.innerHTML = encodedString;
  return elem.value;
}    


export const paymentModeLabels = (payment_mode) => {

    if (payment_mode=='bank-transfer') {
        return 'Bank Transfer';
    }
    else if (payment_mode=='cash') {
        return 'Cash';
    }
    else if (payment_mode=='cheque') {
        return 'Cheque';
    }
    else{
        return '';
    }

}    