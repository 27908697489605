import React, { useEffect,  useRef,  useState }  from 'react'


function TeamWithMemberList() { 

    return (
        <>
            <li className="pb-0">
                <div className="d-flex justify-content-between align-items-center placeholder-glow">
                    <div className="fs-10 fw-semibold mb-2 text-muted placeholder col-3"></div>
                    <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Add Member"
                    className="placeholder col-1">
                        <button className="btn btn-icon btn-sm btn-success-light btn-wave" data-bs-toggle="modal" data-bs-target="#create-teamui-mem"></button>
                    </div>
                </div>
            </li>
                                                
            <li>
                <a href="javascript:void(0);">
                    <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-2 d-flex align-items-center">
                            <span className="avatar avatar-sm avatar-rounded online placeholder col-2">
                                
                            </span>
                        </div>
                        <div className="flex-fill">
                            <span className="placeholder col-6"></span>
                        </div>
                        <div className="placeholder col-2">
                            <span className="fs-10 fw-semibold text-muted"></span>
                        </div>
                    </div>
                </a>
            </li>

            <li>
                <a href="javascript:void(0);">
                    <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-2 d-flex align-items-center">
                            <span className="avatar avatar-sm avatar-rounded online placeholder col-2">
                                
                            </span>
                        </div>
                        <div className="flex-fill">
                            <span className="placeholder col-6"></span>
                        </div>
                        <div className="placeholder col-2">
                            <span className="fs-10 fw-semibold text-muted"></span>
                        </div>
                    </div>
                </a>
            </li>

            <li>
                <a href="javascript:void(0);">
                    <div className="d-flex align-items-center placeholder-glow">
                        <div className="me-2 d-flex align-items-center">
                            <span className="avatar avatar-sm avatar-rounded online placeholder col-2">
                                
                            </span>
                        </div>
                        <div className="flex-fill">
                            <span className="placeholder col-6"></span>
                        </div>
                        <div className="placeholder col-2">
                            <span className="fs-10 fw-semibold text-muted"></span>
                        </div>
                    </div>
                </a>
            </li>
        </>

    ); 

}


export default TeamWithMemberList