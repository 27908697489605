import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { editNote, saveNotesToStorage } from '../../../models/NoteModel';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import JoditEditor from 'jodit-react';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { useTheme } from '../../../context/ThemeContext';

const UpdateNoteModal = ({showModal, setShowModal, noteData, modalKey, fetchNotes, limit, page}) => {

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_note_id, setNoteID] = useState('');
    const [note_title, setNoteTitle] = useState('');
    const [note_desc, setNoteDesc] = useState('');

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300
        }),
        []
    );
    
    const [errors, setErrors] = useState({note_title:  '',  note_desc: '',});

    
    const closeModal = () =>{
        setNoteID('');
        setNoteTitle('');
        setNoteDesc('');
        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (noteData) {
          setNoteID(noteData._note_id);
          setNoteTitle(noteData.note_title);
          setNoteDesc(noteData.note_desc);
          setErrors({});
        }
      }, [noteData, modalKey]);


    const updateNote = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { note_title, note_desc };
    
          const response = await editNote(user_data.user_token, _note_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveNotesToStorage(response.data.notes, response.data.pagination);
    
            fetchNotes(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Update Note</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-12">
                        <label htmlFor="note_title" className="form-label">Title <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" id="note_title" placeholder="Note Title" 
                        value={note_title} onChange={(e) => setNoteTitle(e.target.value)}/>
    
                        
                        {errors.note_title && <p style={{ color: 'red' }}>{errors.note_title}</p>}
                    </div>
                    <div className="col-xl-12">
                        <label htmlFor="note_desc" className="form-label">Description <span className="text-danger">*</span></label>
                        {/* <textarea className="form-control" id="note_desc" cols="30" rows="5" placeholder='Note Description' 
                        value={note_desc} onChange={(e) => setNoteDesc(e.target.value)}></textarea> */}

                    <JoditEditor
                        ref={editor}
                        value={note_desc}
                        config={config}
                        onChange={newContent => setNoteDesc(newContent)}/>
    
                        {errors.note_desc && <p style={{ color: 'red' }}>{errors.note_desc}</p>}
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateNote}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UpdateNoteModal;