import { DEL, GET, POST, PUT, UploadPost, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createProject = async(user_token, formData) => {

    const endPoint = "/projects/add-project";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const removeProject = async(user_token, projectId) => {

    const endPoint = `/projects/delete-project/${projectId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const saveProjectsToStorage = (projects, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        projects: projects,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("projects", encryptData(dataToStore));
} 


export const getProjects = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    let _client_id = param._client_id ? param._client_id : 0;

    if (page==1 && limit==10 && localStorage.getItem('projects')) {
        const saved_projects_obj = decryptData(localStorage.getItem('projects'));

        return saved_projects_obj;
    }
    else{
        try {
            const endPoint = '/projects/project-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&_client_id=${_client_id}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveProjectsToStorage(response.data.projects, response.data.pagination);

                    const saved_projects_obj = decryptData(localStorage.getItem('projects'));

                    return saved_projects_obj;
                }
                else{
                    //localStorage.removeItem("projects");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};


export const saveProjectsDetailsToStorage = (project_unique_id, data={}) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    data.time = currentTime;

    localStorage.setItem("project-details-"+project_unique_id, encryptData(data));
} 


export const getProjectDetails = async(user_token, project_unique_id) => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = `/projects/project-details/${project_unique_id}`;
        const url = `${endPoint}`;

        setAuthToken(user_token);
        
        const response = await GET(url);
        
        return response;

    } catch (error) {
        apiError(error);
    }
}  


export const getProjectOverView = async(user_token, project_unique_id) =>{
    if (!user_token) {
        return [];
    }

    try {
        const endPoint = `/projects/overview/${project_unique_id}`;
        const url = `${endPoint}`;

        setAuthToken(user_token);
        
        const response = await GET(url);
        
        return response;

    } catch (error) {
        apiError(error);
    }
}


export const editProject = async(user_token, projectId, formData) => {

    const endPoint = `/projects/update-project/${projectId}`;

    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
}


export const createProjectFile = async(user_token, project_unique_id, task_unique_id='', formData) => {

    const endPoint = `/projects/add-file/${project_unique_id}/${task_unique_id}`;
    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
}


export const removeProjectFile = async(user_token, file_id, project_id='', task_id='') => {

    const endPoint = `/projects/delete-file/${file_id}`;
    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}


export const getProjectFiles = async(user_token, projectId, taskId='', param = {}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    const endPoint = `/projects/project-file-list/${projectId}/${taskId}`;
    const url = `${endPoint}?page=${page}&limit=${limit}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}


export const getProjectMembers = async(user_token, projectId, param = {}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    const endPoint = `/projects/project-member-list/${projectId}`;
    const url = `${endPoint}?page=${page}&limit=${limit}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}




export const createProjectMember = async(user_token, project_unique_id, formData) => {

    const endPoint = `/projects/add-member/${project_unique_id}`;
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const editProjectMember = async(user_token, project_unique_id, userId, formData) => {

    const endPoint = `/projects/update-member/${project_unique_id}/${userId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}



export const removeProjectMember = async(user_token, project_unique_id, userId) => {

    const endPoint = `/projects/delete-member/${project_unique_id}/${userId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 



export const getProjectNonMembers = async(user_token, projectId) => {

    if (!user_token) {
        return [];
    }

    const endPoint = `/projects/project-non-member-list/${projectId}`;
    const url = `${endPoint}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}


export const getProjectTasks = async(user_token, projectId='', param = {}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    const endPoint = `/projects/project-task-list/${projectId}`;
    const url = `${endPoint}?page=${page}&limit=${limit}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}


export const createProjectLocation = async(user_token, project_unique_id, formData) => {

    const endPoint = `/projects/add-project-location/${project_unique_id}`;
    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
}


export const editProjectLocation = async(user_token, _location_id, formData) => {

    const endPoint = `/projects/edit-project-location/${_location_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}


export const removeProjectLocation = async(user_token, project_unique_id, _location_id) => {

    const endPoint = `/projects/delete-project-location/${project_unique_id}/${_location_id}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const getProjectLocations = async(user_token, projectId, param = {}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    const endPoint = `/projects/project-location-list/${projectId}`;
    const url = `${endPoint}?page=${page}&limit=${limit}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}


export const getProjectLogs = async(user_token, projectId, taskId='', param = {}) => {

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    const endPoint = `/projects/project-log-list/${projectId}/${taskId}`;
    const url = `${endPoint}?page=${page}&limit=${limit}`;

    setAuthToken(user_token);

    const response = await GET(url);

    return response.data;
}


export const saveSiteVisitProjectsToStorage = (site_visit_projects) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        site_visit_projects: site_visit_projects,
        time:currentTime
    };

    localStorage.setItem("site_visit_projects", encryptData(dataToStore));
}


export const getSiteVisitProjects = async(user_token) => {

    if (!user_token) {
        return [];
    }

    if (localStorage.getItem('site_visit_projects')) {
        const saved_site_visit_projects_obj = decryptData(localStorage.getItem('site_visit_projects'));

        return saved_site_visit_projects_obj;
    }
    else{
        try {
            const endPoint = '/projects/site-visit-project-list';
            const url = `${endPoint}`;

            setAuthToken(user_token);

            const response = await GET(url);

            if (response.status) {
                saveSiteVisitProjectsToStorage(response.data.site_visit_projects);

                const saved_site_visit_projects_obj = decryptData(localStorage.getItem('site_visit_projects'));

                return saved_site_visit_projects_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }
}; 