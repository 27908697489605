import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import LoginVsWorkList from '../skeletons/LoginVsWorkList';
import { profilePic } from '../../helpers/GeneralHelper';
import { secondsToTime } from '../../helpers/DateHelper';

const LoginVsWorkTables = ({
    data,
    setData,
    loading,
    setLoading,
    fetchLoginVsWorkReport
}) => {

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;


    const CustomLoader = () => (
        <LoginVsWorkList/>
    );


    useEffect(()=>{
        fetchLoginVsWorkReport();  
    }, []);



    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className={`avatar avatar-xl me-2 avatar-rounded ${row.is_logged_in=='1' ? 'online' : 'offline'} `}>
                                        {profilePic(row.user_first_name, row.user_last_name, row.user_image, '40px', '20px')}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.user_first_name} {row.user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Status",
            selector: (row) => row.attendance_type ? row.attendance_type.toUpperCase() : '--'
        },
        {
            name: "Login Hours",
            selector: (row) => secondsToTime(row.login_seconds)
        },
        {
            name: "Work Hours",
            selector: (row) => secondsToTime(row.work_seconds)
        },
        {
            name: "Idle Time",
            selector: (row) => secondsToTime(row.timesheet_idle_time)
        },
        {
            name: "Active (%)",
            selector: (row) => "--"
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            columns={columns} 
            data={data} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default LoginVsWorkTables;