import { GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const saveCurrenciesToStorage = (currencies, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        currencies: currencies,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("currencies", encryptData(dataToStore));
};

export const getCurrencies = async() => {

    if (localStorage.getItem('currencies')) {
        const saved_currencies_obj = decryptData(localStorage.getItem('currencies'));

        return saved_currencies_obj;
    }
    else{
        try {
            const endPoint = '/master/currency-list';
            const url = `${endPoint}`;

            const response = await GET(url);

            if (response.status) {
                saveCurrenciesToStorage(response.data.currencies, response.data.pagination);

                const saved_currencies_obj = decryptData(localStorage.getItem('currencies'));

                return saved_currencies_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }
};   



export const saveCountriesToStorage = (countries, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        countries: countries,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("countries", encryptData(dataToStore));
} 


export const getCountries = async() => {

    if (localStorage.getItem('countries')) {
        const saved_countries_obj = decryptData(localStorage.getItem('countries'));

        return saved_countries_obj;
    }
    else{
        try {
            const endPoint = '/master/country-list';
            const url = `${endPoint}?page=1&limit=245`;

            const response = await GET(url);

            if (response.status) {
                saveCountriesToStorage(response.data.countries, response.data.pagination);

                const saved_countries_obj = decryptData(localStorage.getItem('countries'));

                return saved_countries_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }

}  


export const saveStatesToStorage = (states, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        states: states,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("states", encryptData(dataToStore));
} 


export const getStates = async() => {

    if (localStorage.getItem('states')) {
        const saved_states_obj = decryptData(localStorage.getItem('states'));

        return saved_states_obj;
    }
    else{
        try {
            const endPoint = '/master/state-list';
            const url = `${endPoint}?page=1&limit=36`;

            const response = await GET(url);

            if (response.status) {
                saveStatesToStorage(response.data.states, response.data.pagination);

                const saved_states_obj = decryptData(localStorage.getItem('states'));

                return saved_states_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }
}  


export const saveSkillsToStorage = (skills, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        skills: skills,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("skills", encryptData(dataToStore));
} 


export const getSkills = async() => {

    if (localStorage.getItem('skills')) {
        const saved_skills_obj = decryptData(localStorage.getItem('skills'));

        return saved_skills_obj;
    }
    else{
        try {
            const endPoint = '/master/skill-list';
            const url = `${endPoint}?page=1&limit=100`;

            const response = await GET(url);

            if (response.status) {
                saveSkillsToStorage(response.data.skills, response.data.pagination);

                const saved_skills_obj = decryptData(localStorage.getItem('skills'));

                return saved_skills_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }
}

export const getCurrentFinancialYear = () => {

   return localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year")) : 0;
}    


export const saveCurrentFinancialYearToStorage = (_fin_year_id, fin_year) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        _fin_year_id: _fin_year_id,
        fin_year:fin_year,
        time:currentTime
    };

    localStorage.setItem("current_fin_year", encryptData(dataToStore));
}



export const saveFinancialYearsToStorage = (financial_years) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        financial_years: financial_years,
        time:currentTime
    };

    localStorage.setItem("financial_years", encryptData(dataToStore));
} 



export const getFinancialYears = async() => {

    if (localStorage.getItem('financial_years')) {
        const saved_financial_years_obj = decryptData(localStorage.getItem('financial_years'));

        return saved_financial_years_obj;
    }
    else{
        try {
            const endPoint = '/master/financial-year-list';
            const url = `${endPoint}`;

            const response = await GET(url);

            if (response.status) {
                saveFinancialYearsToStorage(response.data.financial_years);

                const saved_financial_years_obj = decryptData(localStorage.getItem('financial_years'));

                return saved_financial_years_obj;
            }
            else{
                toast.error(response.message);
            }     
        } catch (error) {
            apiError(error);
        }
    }
}


export const createFinancialYear = async(user_token, formData) => {

    const endPoint = "/settings/add-fin-year";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editFinancialYear = async(user_token, _fin_year_id, formData) => {

    const endPoint = `/settings/update-fin-year/${_fin_year_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 