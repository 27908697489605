import {  GET, POST, UploadPost, setAuthToken } from "../config/ApiConfig";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';



export const getApplicationsSettings = async(param = {}) => {

    let user_token = param.user_token;

    try {
        const endPoint = `/settings/applications-settings`;
        const url = `${endPoint}`;
    
        setAuthToken(user_token);
        
        const response = await GET(url);

        if (response.status) {
            return response;
        }    
        else{
            toast.error(response.message);
        }
        
    } catch (error) {
        apiError(error);
    }
};


export const updateWindowsDesktopApplicationSettings = async(user_token, formData) => {

    const endPoint = "/settings/update-windows-desktop-application-settings";
    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
} 


export const updateLinuxDesktopApplicationSettings = async(user_token, formData) => {

    const endPoint = "/settings/update-linux-desktop-application-settings";
    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
} 


export const updateMacOsDesktopApplicationSettings = async(user_token, formData) => {

    const endPoint = "/settings/update-macos-desktop-application-settings";
    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
} 