import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import DataTable, { createTheme } from 'react-data-table-component';
import ProjectLocations from '../../skeletons/ProjectLocations';
import { getProjectLocations } from '../../../models/ProjectModel';
import AddProjectLocationModal from '../../modals/Project/AddProjectLocationModal';
import DeleteProjectLocationModal from '../../modals/Project/DeleteProjectLocationModal';


const ProjectLocationsTables = ({
    project_unique_id,
    callProjectLocations,
    projectData
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;
    
    const [modalKey, setModalKey] = useState(0);

    const [locationData, setLocationData] = useState({});

    //Add Project Location Start
    const [showAddProjectLocationModal, setShowAddProjectLocationModal] = useState(false);

    const openAddProjectLocationModal = () =>{
        setModalKey(prevKey => prevKey + 1); 
        setShowAddProjectLocationModal(true);
    } 
    //Add Project Location End
    


    //Delete Project Location Start
    const [showDeleteProjectLocationModal, setShowDeleteProjectLocationModal] = useState(false);

    const openDeleteProjectLocationModal = (location_data) =>{
        setLocationData(location_data);
        setModalKey(prevKey => prevKey + 1); 
        setShowDeleteProjectLocationModal(true);
    } 
    //Delete Project Location End


    // Get Project Locations Start
    const [projectLocations, setProjectLocations] = useState([]);
    const [projectLocationsTotalRows, setProjectLocationsTotalRows] = useState(0);
    const [projectLocationsLoading, setProjectLocationsLoading] = useState(true);
    const [projectLocationsPage, setProjectLocationsPage] = useState(1);
    const [projectLocationsLimit, setProjectLocationsLimit] = useState(10);

    const fetchProjectLocations = async (limit, page) => {

        //console.log('limit:'+limit+'&page='+page);

        if (callProjectLocations===true) {
            setProjectLocationsLoading(true);
        
            try {
                let apiParam = {page: page, limit: limit};

                const response = await getProjectLocations(user_data.user_token, project_unique_id, apiParam);

                //console.log(response);

                if (typeof response != typeof undefined) {
                    setProjectLocations(response.locations);
                    setProjectLocationsTotalRows(response.pagination.total_rows);
                    setProjectLocationsLimit(limit);
                    setProjectLocationsPage(page);

                    setNotificationCount(localStorage.getItem('unread_notification_count'));
                }  
            
                setProjectLocationsLoading(false);

            } catch (error) {
                //console.error(error);
            }
        }
    }

    const ProjectLocationsCustomLoader = () => (
        <ProjectLocations/>
    );

    const ProjectLocationsPageChange = async (page) => {
        fetchProjectLocations(projectLocationsLimit, page);  
    };

    const ProjectLocationsLimitChange = async (limit) => {
        fetchProjectLocations(limit, projectLocationsPage); 
    };  

    useEffect(()=>{
        fetchProjectLocations(projectLocationsLimit, projectLocationsPage); 
    }, [project_unique_id, callProjectLocations]);
    // Get Project Locations End

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    const ProjectLocationsColumns = [
        {
            name: "#",
            selector: (row, index) => <div>{((projectLocationsPage-1)*projectLocationsLimit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Location Name",
            selector: (row) => row.location_name
        },
        {
            name: "Address",
            selector: (row) => row.location_radius
        },
        {
            name: "Geo Location",
            selector: (row) => <>
                                Lat : {row.lat}<br/>
                                Lon : {row.lon}
                               </>
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info d-none" title="Edit">
                              <i className='bx bxs-edit'></i>
                            </button>
    
                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteProjectLocationModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    return (
        <div className="card custom-card shadow-none">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">
                    
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-primary btn-sm" onClick={() => openAddProjectLocationModal()}>
                        <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Location
                    </button>
                </div>
            </div>
            <div className="card-body">
                <DataTable 
                    title={`Manage Locations`} 
                    columns={ProjectLocationsColumns} 
                    data={projectLocations}
                    pagination
                    paginationServer
                    paginationTotalRows={projectLocationsTotalRows} 
                onChangeRowsPerPage={ProjectLocationsLimitChange} 
                onChangePage={ProjectLocationsPageChange} 
                progressPending={projectLocationsLoading} 
                progressComponent={<ProjectLocationsCustomLoader />}
                    fixedHeader 
                    fixedHeaderScrollHeight='550px'
                    highlightOnHover
                    theme={`${theme==='light' ? 'light' : 'dark'}`} />
            </div>


            {/* <!--Add Project Location Modal Start--> */}
            <AddProjectLocationModal
                showModal={showAddProjectLocationModal}
                setShowModal={setShowAddProjectLocationModal}
                project_unique_id={project_unique_id}
                projectData={projectData}
                modalKey={modalKey}
                fetchProjectLocations={fetchProjectLocations}
                limit={projectLocationsLimit}
                page={setProjectLocationsPage} />
            {/* <!--Add Project Location Modal End--> */}


            {/* <!--Delete Project Location Modal Start--> */}
            <DeleteProjectLocationModal
                showModal={showDeleteProjectLocationModal}
                setShowModal={setShowDeleteProjectLocationModal}
                project_unique_id={project_unique_id}
                locationData={locationData}
                modalKey={modalKey}
                fetchProjectLocations={fetchProjectLocations}
                limit={projectLocationsLimit}
                page={setProjectLocationsPage} />
            {/* <!--Delete Project Location Modal End--> */}

        </div>
    );

};    

export default ProjectLocationsTables;    