import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../helpers/EncryptHelper';



// Encode the credentials in base64
const credentials = btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`);

// const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_BASE_URL, // Set your API base URL
//     timeout: 30000, // Set a timeout for API requests 30 seconds
//     headers: {
//         'Authorization': `Basic ${credentials}`,
//         'Content-Type': 'application/json',
//         'API-KEY': process.env.REACT_APP_API_KEY,
//         '_fin_year_id': localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0,
//         'fcm_token': localStorage.getItem("fcm_token") ? decryptData(localStorage.getItem("fcm_token")) : ''
//     },
// });

const createAxiosInstance = () => {
    const fcmTokenData = localStorage.getItem("fcm_token");
    const headers = {
        'Authorization': `Basic ${credentials}`,
        'Content-Type': 'application/json',
        'API-KEY': process.env.REACT_APP_API_KEY,
        '_fin_year_id': localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0,
        'fcm_token': fcmTokenData ? decryptData(fcmTokenData) : ''
    };

    return axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: 30000, // 30 sec
        headers: headers
    });
};

// Usage:
const instance = createAxiosInstance();

// CancelToken source for cancelling requests
const cancelTokenSource = axios.CancelToken.source();

// Function to set the authentication token in the request headers
const setAuthToken = (token) => {
    if (token) {
        instance.defaults.headers.common['user_token'] = `${token}`;
    } else {
        delete instance.defaults.headers.common['user_token'];
    }
};

const Test = async() => {



}

// Function to make a GET request
const GET = async(url) => {

    let _fin_year_id = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0;

    try {
        const response = await instance.get(url, { headers: { '_fin_year_id': _fin_year_id } });

        if (response.data.status) {
            if (response.data.data.notification_count) {
                localStorage.setItem("unread_notification_count", response.data.data.notification_count);
            }
        }

        return response.data;
    } catch (error) {
        throw error;
        //console.log(error);
    }
};

// Function to make a POST request
const POST = async(url, data) => {

    let _fin_year_id = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0;

    try {
        const response = await instance.post(url, data, { headers: { '_fin_year_id': _fin_year_id } });

        if (response.data.status) {
            if (response.data.data.notification_count) {
                localStorage.setItem("unread_notification_count", response.data.data.notification_count);
            }
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Upload File Post
const UploadPost = async(url, formData) => {

    let _fin_year_id = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0;

    try {
        const response = await instance.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data', '_fin_year_id': _fin_year_id } });

        if (response.data.status) {
            if (response.data.data.notification_count) {
                localStorage.setItem("unread_notification_count", response.data.data.notification_count);
            }
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};


// Function to make a PUT request
const PUT = async(url, data) => {

    let _fin_year_id = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0;

    try {
        const response = await instance.put(url, data, { headers: { '_fin_year_id': _fin_year_id } });

        if (response.data.status) {
            if (response.data.data.notification_count) {
                localStorage.setItem("unread_notification_count", response.data.data.notification_count);
            }
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};



// Function to make a DELETE request
const DEL = async(url) => {

    let _fin_year_id = localStorage.getItem("current_fin_year") ? decryptData(localStorage.getItem("current_fin_year"))._fin_year_id : 0;

    try {
        const response = await instance.delete(url, { headers: { '_fin_year_id': _fin_year_id } });

        if (response.data.status) {
            if (response.data.data.notification_count) {
                localStorage.setItem("unread_notification_count", response.data.data.notification_count);
            }
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};




// Add more functions for other HTTP methods as needed

export { setAuthToken, GET, POST, UploadPost, PUT, DEL };