import { DEL, GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const createTask = async(user_token, formData) => {

    const endPoint = "/tasks/create-task";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const editTask = async(user_token, task_unique_id, formData) => {

    const endPoint = `/tasks/update-task/${task_unique_id}`;
    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}


export const removeTask = async(user_token, taskId) => {

    const endPoint = `/tasks/delete-task/${taskId}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
}


export const saveTasksToStorage = (tasks, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        tasks: tasks,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("tasks", encryptData(dataToStore));
}


export const getTasks = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page===1 && limit===10 && localStorage.getItem('tasks')) {
        const saved_tasks_obj = decryptData(localStorage.getItem('tasks'));

        return saved_tasks_obj;
    }
    else{
        try {
            const endPoint = '/tasks/task-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page===1 && limit===10) {
                    saveTasksToStorage(response.data.tasks, response.data.pagination);

                    const saved_tasks_obj = decryptData(localStorage.getItem('tasks'));

                    return saved_tasks_obj;
                }
                else{
                    //localStorage.removeItem("tasks");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
}; 



export const getTaskOverView = async(user_token, task_unique_id) =>{
    if (!user_token) {
        return [];
    }

    try {
        const endPoint = `/tasks/overview/${task_unique_id}`;
        const url = `${endPoint}`;

        setAuthToken(user_token);
        
        const response = await GET(url);
        
        return response;

    } catch (error) {
        apiError(error);
    }
}