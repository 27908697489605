import React, { createContext, useState, useContext, useEffect } from 'react';
import { decryptData } from '../helpers/EncryptHelper';

const FinancialYearContext = createContext();

export const FinancialYearProvider = ({ children }) => {
  // Initialize financialYear state with an empty object
  const [financialYear, setFinancialYear] = useState({});

  // Load financialYear data from localStorage when component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("current_fin_year");
    if (storedData) {
      // Decrypt and parse stored data
      const decryptedData = decryptData(storedData);
      setFinancialYear(decryptedData._fin_year_id);
    }
  }, []); // Run effect only once on component mount

  return (
    <FinancialYearContext.Provider value={{ financialYear, setFinancialYear }}>
      {children}
    </FinancialYearContext.Provider>
  );
};

export const useFinancialYear = () => useContext(FinancialYearContext);
