import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { apiError } from '../../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../../config/ApiConfig';
import { tableCustomLoader } from '../../../helpers/StyleHelper';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { getWsLogs } from '../../../models/LogModel';
import WsLogTables from '../../tables/WsLogTables';
import DeleteAllWsLogsModal from '../../modals/Logs/DeleteAllWsLogsModal';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { checkPermission } from '../../../helpers/GeneralHelper';
import Error403 from '../Error403';


function WsLogs() {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const loader = document.getElementById('loading');


  //Flush WS Logs Start
  const flushWSLogs = async() => {
    localStorage.removeItem("ws_logs");
    fetchLogs(limit, page); 
  }  
  //Flush WS Logs End


  //WS Logs Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);


  const fetchLogs = async(newLimit, page) => {
    setLoading(true);

    try {        
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getWsLogs(apiParam);

        if (typeof response != typeof undefined) {
          setData(response.ws_logs);
          setTotalRows(response.pagination.total_rows);
          setLimit(newLimit);
          setPage(page);

          if (response.time) {
            setCacheTime(response.time);
          }

          setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
          navigate('/re-login');
        }  
        
        setLoading(false);
    } catch (error) {
      //console.error(error);
    }

    
  };
  //WS Logs End


  //Delete All WS Logs Start
  const [showDeleteAllWsLogsModal, setShowDeleteAllWsLogsModal] = useState(false);
  //Delete All WS Logs End

    
  return (
    <>
        {checkPermission('logs_ws')===true ? (
        <AdminLayout>
        
            <Helmet>
                <title>Web Service Logs - Worktracker Anciletech </title>
                
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Web Service Logs</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item">Logs</li>
                                    <li className="breadcrumb-item active" aria-current="page">Web Service</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                      Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className={`btn btn-danger-light btn-sm ${checkPermission('logs_ws_delete_all')===false ? 'd-none' : ''}`} onClick={() => setShowDeleteAllWsLogsModal(true)}>
                                          Delete All Logs
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushWSLogs()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                      <WsLogTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchLogs={fetchLogs} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!--Delete Note Modal Start--> */}
                    <DeleteAllWsLogsModal
                      showModal={showDeleteAllWsLogsModal}
                      setShowModal={setShowDeleteAllWsLogsModal}
                      fetchLogs={fetchLogs}
                      limit={limit}
                      page={page}  />
                    {/* <!--Delete Note Modal End--> */}


                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
        ) : ( <Error403/>)}
    </>
  )
}

export default WsLogs
