import React, { createContext, useContext, useState } from 'react';

// Create the context
const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');


  // Define your theme object
  const selectBoxTheme = {
    container: (provided) => ({
      ...provided,
      color: theme==='light' ? '#333335' : '#FFFFFF',
      backgroundColor: theme==='light' ? '#FFFFFF' : '#232628',
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: theme==='light' ? '#FFFFFF' : '#444444',
      borderColor: theme==='light' ? '#e9edf6' : '#313335',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? (theme==='light' ? '#555' : 'lightblue') : (theme==='light' ? '#FFFFFF' : '#444444'),
      color: state.isSelected ? (theme==='light' ? 'black' : 'white') : 'inherit',
    }),
  };

  // Function to toggle between light and dark themes
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));

    const htmlElement = document.documentElement;

    htmlElement.setAttribute('data-theme-mode', theme === 'light' ? 'dark' : 'light');
    htmlElement.setAttribute('data-header-styles', theme === 'light' ? 'dark' : 'light');
  };

  // Provide the theme state and toggleTheme function to the components
  const contextValue = {
    theme,
    toggleTheme,
    selectBoxTheme
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useTheme = () => {
  return useContext(ThemeContext);
};
