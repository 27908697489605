import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable, { createTheme } from 'react-data-table-component';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { apiError } from '../../helpers/ErrorHelper';
import { get, put, post, setAuthToken, del } from '../../config/ApiConfig';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { useAuth } from '../../context/AuthContext';
import { attendanceStatus } from '../../helpers/AttendanceHelper';
import { profilePic } from '../../helpers/GeneralHelper';
import { useTheme } from '../../context/ThemeContext';
import { getAttendance } from '../../models/AttendanceModel';
import AttendanceTables from '../tables/AttendanceTables';
import ViewAttendanceModal from '../modals/Attendance/ViewAttendanceModal';
import { useNotificationCount } from '../../context/NotificationCountContext';
import AttendanceLoginModal from '../modals/Attendance/AttendanceLoginModal';
import AttendanceLogoutModal from '../modals/Attendance/AttendanceLogoutModal';
import { getUsers } from '../../models/UserModel';
import Select from 'react-select';


function Attendance() {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;
  
    const navigate = useNavigate();  
  
    const loader = document.getElementById('loading');

    const [modalKey, setModalKey] = useState(0);

    const [attendanceLoginStatus, setAttendanceLoginStatus] = useState('');

    const [_user_id, setUserId] = useState('');

     //Attendance Login Start
     const [showAttendanceLoginModal, setShowAttendanceLoginModal] = useState(false);
     //Attendance Login End
 
     //Attendance Logout Start
     const [showAttendanceLogoutModal, setShowAttendanceLogoutModal] = useState(false);
     //Attendance Logout End

    //View Attendance Start
    const [showViewAttendanceModal, setShowViewAttendanceModal] = useState(false);

    const [attendanceData, setAttendanceData] = useState({});

    const openViewAttendanceModal = (attendance_data) =>{
        setAttendanceData(attendance_data);
        setModalKey(prevKey => prevKey + 1);
        setShowViewAttendanceModal(true);
    }  
    //View Attendance End


    //Flush Attendance Start
    const flushAttendance = async() => {
        localStorage.removeItem("attendances");
        fetchAttendances(limit, page); 
    }  
    //Flush Attendance End
    
    //Attendance List Start
    const [data, setData] = useState([]);
    const [cacheTime, setCacheTime] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    

    const fetchAttendances = async(newLimit, page) => {
        setLoading(true);
    
        try {       
            let user_unique_id = _user_id ? _user_id.value : '';   
            let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token, user_unique_id };

            const response = await getAttendance(apiParam);

            if (typeof response != typeof undefined) {
                setData(response.attendances);
                setTotalRows(response.pagination.total_rows);
                setLimit(newLimit);
                setPage(page);

                setAttendanceLoginStatus(response.attendance_is_logged_in);

                if (response.time) {
                    setCacheTime(response.time);
                }

                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                navigate('/re-login');
            }    
            

            setLoading(false);
    
        } catch (error) {
            //console.error(error);
        }
    
        
    };
    //Attendance List End

    
 //Fetch Users Start
  
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

      const userOptions = result.users.map(user => ({
        label: user.user_first_name+' '+user.user_last_name,
        value: user.user_unique_id
      }));

      setNotificationCount(localStorage.getItem('unread_notification_count'));


      setUsers(userOptions);
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    fetchUsers();  
  }, []); 
  //Fetch Users End
    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Attendance - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Attendance</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Attendance</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between gap-3 flex-nowrap">
                                    <div className="card-title">
                                        Last Synced On {cacheTime}
                                    </div>

                                    <div className='d-flex gap-1'>
                                    
                                            <Select
                                                value={_user_id}
                                                onChange={setUserId}
                                                options={users}
                                                placeholder="- Select Member -"
                                                isSearchable="true"
                                                noOptionsMessage={() => "No member found"}
                                                styles={selectBoxTheme}
                                                theme={(selectBoxTheme) => ({
                                                ...selectBoxTheme,
                                                borderRadius: 0
                                                })} /> 
                                                <button className="btn btn-light" type="button" onClick={() => setUserId('')}
                                                    style={{height: '38px', background: '#ccc', borderRadius: '0 4px 4px 0', color: '#333' , display: _user_id !== '' ? 'block' : 'none'}}>
                                                    x
                                                </button>
                                        
                                    </div>    

                                    <div className="d-flex flex-nowrap gap-2 ">

                                        <button className="btn btn-primary btn-sm" onClick={() => setShowAttendanceLoginModal(true)} style={{display: attendanceLoginStatus===false ? 'block' : 'none'}}>
                                            <i className="bi bi-box-arrow-in-right me-1 fw-semibold align-middle"></i>Attendance Login
                                        </button>

                                        <button className="btn btn-primary btn-sm" onClick={() => setShowAttendanceLogoutModal(true)} style={{display: attendanceLoginStatus===true ? 'block' : 'none'}}>
                                            <i className="bi bi-box-arrow-in-right me-1 fw-semibold align-middle"></i>Attendance Logout
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushAttendance()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <AttendanceTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchAttendances={fetchAttendances}
                                          openViewAttendanceModal={openViewAttendanceModal}
                                          user_unique_id={_user_id} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}

                    {/* <!--Attendance Login Modal Start--> */}
                    <AttendanceLoginModal
                    showModal={showAttendanceLoginModal}
                    setShowModal={setShowAttendanceLoginModal}
                    fetchAttendances={fetchAttendances}
                    limit={limit}
                    page={page} />
                    {/* <!--Attendance Login Modal End--> */}

                    {/* <!--Attendance Logout Modal Start--> */}
                    <AttendanceLogoutModal
                    showModal={showAttendanceLogoutModal}
                    setShowModal={setShowAttendanceLogoutModal}
                    fetchAttendances={fetchAttendances}
                    limit={limit}
                    page={page} />
                    {/* <!--Attendance Logout Modal End--> */}

                    {/* <!--View Attendance Modal Start--> */}
                    <ViewAttendanceModal
                    showModal={showViewAttendanceModal}
                    setShowModal={setShowViewAttendanceModal}
                    attendanceData={attendanceData}
                    modalKey={modalKey}
                    fetchAttendances={fetchAttendances}
                    limit={limit}
                    page={page} />
                    {/* <!--View Attendance Modal End--> */}


                </div>


            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Attendance
