import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { utcToLocal } from '../../../helpers/DateHelper';
import TaskTabs from '../../skeletons/TaskTabs';
import TaskOverView from '../../widgets/tasks/TaskOverView';
import ProjectLogsTables from '../../tables/ProjectDetails/ProjectLogsTables';
import ProjectFilesTables from '../../tables/ProjectDetails/ProjectFilesTables';
import TaskDetailsForm from '../../widgets/tasks/TaskDetailsForm';

function TaskDetails() {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const location = useLocation();
    const currentRoute = location.pathname; 

    const { state, dispatch } = useAuth();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate();

    let user_data = state.user_data;

    const loader = document.getElementById('loading');

    const [modalKey, setModalKey] = useState(0);

    let { task_unique_id } = useParams();


    // Get Task Overview Start
    const [projectData, setProjectData] = useState({});

    const [taskData, setTaskData] = useState({});

    const [project_unique_id, setProjectUniqueId] = useState('');

    const [taskOverViewLoading, setTaskOverViewLoading] = useState(false);

    const [callTaskOverView, setCallTaskOverView] = useState(true);
    // Get Task Overview End


    // Get Task Details Start
    const [callTaskDetails, setCallTaskDetails] = useState(false);
    // Get Task Details End

    // Get Task Files Start
    const [callProjectFiles, setCallProjectFiles] = useState(false);
    // Get Task Files End

    // Get Task Log List on Page & Limit Change Start
    const [callProjectLogs, setCallProjectLogs] = useState(false);
    // Get Task Log List on Page & Limit Change End


    return (
        <>
        
    <AdminLayout>
    
        <Helmet>
            <title> Task Details - Worktracker Anciletech</title>
        </Helmet>

        <div className="main-content app-content">

            <div className="container-fluid">

                {/* <!-- Page Header --> */}
                <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    
                    <h1 className="page-title fw-semibold fs-18 mb-0 d-flex align-items-center"> 
                        {Object.keys(taskData).length > 0 ?  taskData.task_title : ''}
                    </h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Task Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                {/* <!-- Page Header Close --> */}
    
 
        
        <>
        {taskOverViewLoading ? (
            <TaskTabs />
        ) : (     
            <div className="row">
                <div className="col-xl-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#overview-center" aria-selected="true" onClick={() => setCallTaskOverView(true)}>Overview</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#details-center" aria-selected="false" tabindex="-1" onClick={() => setCallTaskDetails(true)}>Details</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#files-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectFiles(true)}>Files</a>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#logs-center" aria-selected="false" tabindex="-1" onClick={() => setCallProjectLogs(true)}>Logs</a>
                                </li>
                            </ul>
                            <div className="tab-content">

                                {/* <!---Overview Tab Content Start---> */}
                                <div className="tab-pane show active text-muted" id="overview-center" role="tabpanel">
                                    <TaskOverView 
                                        task_unique_id={task_unique_id} 
                                        callTaskOverView={callTaskOverView} 
                                        taskData={taskData}
                                        setTaskData={setTaskData}
                                        taskOverViewLoading={taskOverViewLoading} 
                                        setTaskOverViewLoading={setTaskOverViewLoading}
                                        setProjectUniqueId={setProjectUniqueId}
                                        setProjectData={setProjectData} />
                                </div>
                                {/* <!---Overview Tab Content End---> */}


                                {/* <!---Details Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="details-center" role="tabpanel">
                                    <TaskDetailsForm task_unique_id={task_unique_id} callTaskDetails={callTaskDetails} taskData={taskData} setTaskData={setTaskData} taskLoading={taskOverViewLoading} setTaskLoading={setTaskOverViewLoading}/>
                                </div>
                                {/* <!---Details Tab Content End---> */}


                                {/* <!---Files Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="files-center" role="tabpanel">
                                    <ProjectFilesTables project_unique_id={project_unique_id} callProjectFiles={callProjectFiles} projectData={projectData} task_unique_id={task_unique_id}/>
                                </div>
                                {/* <!---Files Tab Content End---> */}



                                 {/* <!---Logs Tab Content Start---> */}
                                <div className="tab-pane text-muted" id="logs-center" role="tabpanel">
                                    <ProjectLogsTables project_unique_id={project_unique_id}  callProjectLogs={callProjectLogs} task_unique_id={task_unique_id}/> 
                                </div>
                                {/* <!---Logs Tab Content End---> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )}
        </>
        
        
    
            </div>
    
    
        </div>  
    
                <Helmet>
                    
                </Helmet>
            
            </AdminLayout>
        </>
    )

}

export default TaskDetails