import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createUser, saveUsersToStorage } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";

const AddNewMemberModal = ({showModal, setShowModal, fetchUsers, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [user_first_name, setUserFirstName] = useState('');
    const [user_last_name, setUserLastName] = useState('');
    const [user_dob, setUserDob] = useState('');
    const [user_gender, setUserGender] = useState('');
    const [user_email, setUserEmail] = useState('');
    const [user_mobile, setUserMobile] = useState('');
    const [user_alternate_phone, setUserAlternatePhone] = useState('');
    const [user_address, setUserAddress] = useState('');
    const [user_alternate_address, setUserAlternateAddress] = useState('');

    const [user_job_title, setUserJobTitle] = useState('');
    const [user_date_of_joining, setUserDateOfJoining] = useState('');

    const [errors, setErrors] = useState({user_first_name:  '',  user_last_name: '', user_dob: '', user_gender: '', user_email: '',
    user_mobile: '', user_alternate_phone: '', user_address: '', user_alternate_address: '', user_job_title: '', user_date_of_joining: ''});

    const closeModal = () =>{

        setUserFirstName('');
        setUserLastName('');
        setUserDob('');
        setUserGender('');
        setUserEmail('');
        setUserMobile('');
        setUserAlternatePhone('');
        setUserAddress('');
        setUserAlternateAddress('');
        setUserJobTitle('');
        setUserDateOfJoining('');

        setErrors({});
    
        setShowModal(false);
    }


    const addUser = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { user_first_name, user_last_name, user_dob, user_gender, user_email, user_mobile, user_alternate_phone, user_address, user_alternate_address, user_job_title, user_date_of_joining: user_date_of_joining ? dateFormat(user_date_of_joining, "dd-mm-yyyy") : '' };
    
          const response = await createUser(user_data.user_token, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveUsersToStorage(response.data.users, response.data.pagination);
    
            fetchUsers(limit, page); 

            navigate(`/profile/${response.data.user_unique_id}`);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Add New Member</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-3">
                        <label className="form-label">First Name : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="First Name"  
                            value={user_first_name} onChange={(e) => setUserFirstName(e.target.value)} />

                        {errors.user_first_name && <p style={{ color: 'red' }}>{errors.user_first_name}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">Last Name : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Last Name"
                            value={user_last_name} onChange={(e) => setUserLastName(e.target.value)} />

                        {errors.user_last_name && <p style={{ color: 'red' }}>{errors.user_last_name}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">Date of Birth : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="DD-MM-YYYY"
                        value={user_dob} onChange={(e) => setUserDob(e.target.value)}/>

                        {errors.user_dob && <p style={{ color: 'red' }}>{errors.user_dob}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">Gender : <span className="text-danger">*</span></label>
                        <br/>

                        <label className="radio-inline">
                        <input type="radio" name="user_gender" value="male" checked={user_gender=='male' ? 'checked' : ''} onChange={(e) => setUserGender(e.target.value)}/>&nbsp;Male&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" name="user_gender" value="female" checked={user_gender=='female' ? 'checked' : ''} onChange={(e) => setUserGender(e.target.value)} />&nbsp;Female&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" name="user_gender" value="other" checked={user_gender=='other' ? 'checked' : ''} onChange={(e) => setUserGender(e.target.value)} />&nbsp;Other
                        </label>

                        {errors.user_gender && <p style={{ color: 'red' }}>{errors.user_gender}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label for="input-label" className="form-label">Email : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Email address"
                        value={user_email} onChange={(e) => setUserEmail(e.target.value)}/>

                        {errors.user_email && <p style={{ color: 'red' }}>{errors.user_email}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label for="input-label" className="form-label">Mobile : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Mobile number"
                        value={user_mobile} onChange={(e) => setUserMobile(e.target.value)}/>

                        {errors.user_mobile && <p style={{ color: 'red' }}>{errors.user_mobile}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label for="input-label" className="form-label">Alternate Mobile :</label>
                        <input type="text" className="form-control" placeholder="Alternate Mobile number"
                        value={user_alternate_phone} onChange={(e) => setUserAlternatePhone(e.target.value)} />

                        {errors.user_alternate_phone && <p style={{ color: 'red' }}>{errors.user_alternate_phone}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label for="input-label" className="form-label">Permanent Address : <span className="text-danger">*</span></label>
                        <textarea className="form-control" placeholder="Permanent Address" rows="5" value={user_address}
                        onChange={(e) => setUserAddress(e.target.value)}></textarea>

                        {errors.user_address && <p style={{ color: 'red' }}>{errors.user_address}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label for="input-label" className="form-label">Current Address : </label>
                        <textarea className="form-control" placeholder="Current Address" rows="5" value={user_alternate_address}
                        onChange={(e) => setUserAlternateAddress(e.target.value)}></textarea>

                        {errors.user_alternate_address && <p style={{ color: 'red' }}>{errors.user_alternate_address}</p>}
                    </div>

                    <div className="col-xl-6">
                        <label for="input-label" className="form-label">JOB Title : <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="JOB Title" value={user_job_title} readOnly={user_data.urole_permission !== '*' ? true : false}
                        onChange={(e) => setUserJobTitle(e.target.value)}/>

                        {errors.user_job_title && <p style={{ color: 'red' }}>{errors.user_job_title}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label for="input-label" className="form-label">Date of Joining : <span className="text-danger">*</span></label>

                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" readOnly={user_data.urole_permission !== '*' ? true : false}
                            selected={user_date_of_joining} onSelect={setUserDateOfJoining} onChange={setUserDateOfJoining} dateFormat="dd-MM-yyyy"/>

                        {errors.user_date_of_joining && <p style={{ color: 'red' }}>{errors.user_date_of_joining}</p>}
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={addUser}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
      );

};   


export default AddNewMemberModal;