import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useSubMenu } from '../../context/SubMenuContext';
import { useSideMenu } from '../../context/SideMenuContext';
import { checkPermission } from '../../helpers/GeneralHelper';

function LeftSideBar() {  
 
  const { subMenuOpen, setSubMenuOpen } = useSubMenu();

  const { sideMenu, toggleSideMenu} = useSideMenu();

  const handleCurrentMenu = (menuName, subMenuClick=false)=>{
    console.log('menuName='+menuName+'&subMenuOpen='+subMenuOpen);

    if (menuName!=subMenuOpen) {
        console.log('A');
        setSubMenuOpen(menuName);
    }
    else{
        if (subMenuClick===true) {
            console.log('B');
            setSubMenuOpen(menuName);
        }
        else{
            console.log('C');
            setSubMenuOpen(null);
        }
        
    }
    
    
    //console.log(menuName);
  }


  return (
    <>
      <aside className="app-sidebar sticky" id="sidebar">

        {/* <!-- Start::main-sidebar-header --> */}
        <div className="main-sidebar-header">
            <Link to="/dashboard" className="header-logo">
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-logo" />
                <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/icon.png`} alt="logo" className="toggle-logo" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-dark" />
                <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/icon.png`} alt="logo" className="toggle-dark" />
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/logo-header.png`} alt="logo" className="desktop-white" />
                <img src={`${process.env.REACT_APP_ASSETS_URL}/assets/images/icon.png`} alt="logo" className="toggle-white" />
            </Link>
        </div>
        {/*<!-- End::main-sidebar-header --> */}

        {/* <!-- Start::main-sidebar --> */}
        <div className="main-sidebar" id="sidebar-scroll">

            {/* <!-- Start::nav --> */}
            <nav className="main-menu-container nav nav-pills flex-column sub-open">
                <div className="slide-left" id="slide-left">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewbox="0 0 24 24"> <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path> </svg>
                </div>
                <ul className="main-menu">
                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Main</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className="slide" >
                        <Link to="/dashboard" className="side-menu__item" onClick={() => handleCurrentMenu('dashboard')}>
                            <i className="bx bx-home side-menu__icon"></i>
                            <span className="side-menu__label">Dashboard</span>
                        </Link>
                    </li>

                    <li className="slide" >
                        <Link to="/events" className="side-menu__item" onClick={() => handleCurrentMenu('events')}>
                            <i className="bx bxs-calendar side-menu__icon"></i>
                            <span className="side-menu__label">Events</span>
                        </Link>
                    </li>

                    <li className="slide" >
                        <Link to="/notes" className="side-menu__item" onClick={() => handleCurrentMenu('notes')}>
                            <i className="bx bxs-book-content side-menu__icon"></i>
                            <span className="side-menu__label">Notes</span>
                        </Link>
                    </li>

                    <li className="slide" >
                        <Link to="/team-members" className="side-menu__item" onClick={() => handleCurrentMenu('team-members')}>
                            <i className="bx bxs-group side-menu__icon"></i>
                            <span className="side-menu__label">Team Members</span>
                        </Link>
                    </li>


                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Clients & Projects</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className={`slide ${checkPermission('menu_clients')===false ? 'd-none' : ''}`} >
                        <Link to="/clients" className="side-menu__item" onClick={() => handleCurrentMenu('clients')}>
                            <i className="bx bx-user-plus side-menu__icon"></i>
                            <span className="side-menu__label">Clients</span>
                        </Link>
                    </li>


                    <li className={`slide has-sub ${subMenuOpen==='projects' ? 'open' : ''}`}>
                        <a href="javascript:void(0);" className="side-menu__item"  onClick={() => handleCurrentMenu('projects')}>
                            <i className="bx bx-file-blank side-menu__icon"></i>
                            <span className="side-menu__label">Projects</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'projects' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);">Projects</a>
                            </li>
                            <li className="slide" >
                                <Link to="/projects" className="side-menu__item" onClick={() => handleCurrentMenu('projects', true)}>All Projects</Link>
                            </li>
                            <li className="slide">
                                <Link to="/tasks" className="side-menu__item" onClick={() => handleCurrentMenu('projects', true)}>Tasks</Link>
                            </li>
                        </ul>
                    </li>

                    <li className={`slide ${checkPermission('menu_invoice')===false ? 'd-none' : ''}`} >
                        <Link to="/invoices" className="side-menu__item" onClick={() => handleCurrentMenu('invoices')}>
                            <i className="bx bxs-file-pdf side-menu__icon"></i>
                            <span className="side-menu__label">Invoices</span>
                        </Link>
                    </li>


                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Attendance, Timesheet & Leave</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className="slide" >
                        <Link to="/attendance" className="side-menu__item" onClick={() => handleCurrentMenu('attendance')}>
                            <i className="bx bxs-time side-menu__icon"></i>
                            <span className="side-menu__label">Attendance</span>
                        </Link>
                    </li>


                    <li className="slide" >
                        <Link to="/timesheets" className="side-menu__item" onClick={() => handleCurrentMenu('timesheets')}>
                            <i className="bi bi-pc-display-horizontal side-menu__icon"></i>
                            <span className="side-menu__label">Timesheets</span>
                        </Link>
                    </li>


                    <li className={`slide has-sub ${subMenuOpen==='leave' ? 'open' : subMenuOpen} ${checkPermission('menu_leave')===false ? 'd-none' : ''}`} >
                        <a href="javascript:void(0);" className="side-menu__item" onClick={() => handleCurrentMenu('leave')}>
                            <i className="bx bxs-arrow-from-left side-menu__icon"></i>
                            <span className="side-menu__label">Leave</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'leave' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);" >Leave</a>
                            </li>
                            <li className="slide">
                                <Link to="/leave-applications" className="side-menu__item" onClick={() => handleCurrentMenu('leave', true)}>Leave Applications</Link>
                            </li>
                            <li className="slide">
                                <Link to="/leave-balances" className="side-menu__item" onClick={() => handleCurrentMenu('leave', true)}>Leave Balances</Link>
                            </li>
                        </ul>
                    </li>


                    {/* <!-- Start::slide__category --> */}
                    <li className={`slide__category ${checkPermission('menu_reports')===false ? 'd-none' : ''}`}><span className="category-name">Reports</span></li>
                    {/* <!-- End::slide__category --> */}


                    <li className={`slide has-sub ${subMenuOpen==='reports' ? 'open' : subMenuOpen} ${checkPermission('menu_reports')===false ? 'd-none' : ''}`} >
                        <a href="javascript:void(0);" className="side-menu__item" onClick={() => handleCurrentMenu('reports')}>
                            <i className="bi bi-card-checklist side-menu__icon"></i>
                            <span className="side-menu__label">Reports</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'reports' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);">Reports</a>
                            </li>
                            <li className={`slide`}>
                                <Link to="/reports/login-vs-work" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Login vs Work</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_compare')===false ? 'd-none' : ''}`}>
                                <Link to="/reports/compare-report" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Compare Report</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_activities')===false ? 'd-none' : ''}`}>
                                <Link to="/reports/activities" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Activities</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_work')===false ? 'd-none' : 'd-none'}`}>
                                <Link to="/reports/work-report" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Work Report</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_leave')===false ? 'd-none' : ''}`}>
                                <Link to="/reports/leave-report" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Leave Report</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_performance')===false ? 'd-none' : ''}`}>
                                <Link to="/reports/performance-reports" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Performace Reports</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_performance_graphs')===false ? 'd-none' : ''}`}>
                                <Link to="/reports/performance-graphs" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Performace Graphs</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_project')===false ? 'd-none' : 'd-none'}`}>
                                <Link to="/reports/project-reports" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Project Reports</Link>
                            </li>
                            <li className={`slide ${checkPermission('reports_task')===false ? 'd-none' : 'd-none'}`}>
                                <Link to="/reports/task-reports" className="side-menu__item" onClick={() => handleCurrentMenu('reports', true)}>Task Reports</Link>
                            </li>
                        </ul>
                    </li>

                    

           

                    {/* <!-- Start::slide__category --> */}
                    <li className="slide__category"><span className="category-name">Logs & Settings</span></li>
                    {/* <!-- End::slide__category --> */}

                    <li className={`slide has-sub ${subMenuOpen==='logs' ? 'open' : subMenuOpen} ${checkPermission('menu_logs')===false ? 'd-none' : ''}`} >
                        <a href="javascript:void(0);" className="side-menu__item" onClick={() => handleCurrentMenu('logs')}>
                            <i className="bx bi-list-columns side-menu__icon"></i>
                            <span className="side-menu__label">Logs</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'logs' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);">Logs</a>
                            </li>
                            <li className={`slide ${checkPermission('logs_ws')===false ? 'd-none' : ''}`}>
                                <Link to="/ws-logs" className="side-menu__item" onClick={() => handleCurrentMenu('logs', true)}>Web Service</Link>
                            </li>
                            <li className={`slide ${checkPermission('logs_mail')===false ? 'd-none' : ''}`}>
                                <Link to="/mail-logs" className="side-menu__item" onClick={() => handleCurrentMenu('logs', true)}>Mail</Link>
                            </li>
                        </ul>
                    </li>

                    <li className={`slide has-sub ${subMenuOpen==='settings' ? 'open' : subMenuOpen} ${checkPermission('menu_settings')===false ? 'd-none' : ''}`} >
                        <a href="javascript:void(0);" className="side-menu__item" onClick={() => handleCurrentMenu('settings')}>
                            <i className="bx bx-cog side-menu__icon"></i>
                            <span className="side-menu__label">Settings</span>
                            <i className="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul className="slide-menu child1" style={{ display: `${subMenuOpen === 'settings' ? 'block' : 'none'}` }}>
                            <li className="slide side-menu__label1">
                                <a href="javascript:void(0);">Settings</a>
                            </li>
                            <li className="slide">
                                <Link to="/general-settings" className="side-menu__item" onClick={() => handleCurrentMenu('settings', true)}>General</Link>
                            </li>
                            <li className="slide">
                                <Link to="/company-settings" className="side-menu__item" onClick={() => handleCurrentMenu('settings', true)}>Company</Link>
                            </li>
                            <li className="slide d-none">
                                <Link to="/employee-settings" className="side-menu__item" onClick={() => handleCurrentMenu('settings', true)}>Employee</Link>
                            </li>
                        </ul>
                    </li>


                </ul>
                <div className="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewbox="0 0 24 24"> <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path> </svg></div>
            </nav>
            {/* <!-- End::nav --> */}

        </div>
        {/* <!-- End::main-sidebar --> */}

     </aside>
    </>
  )
}

export default LeftSideBar
