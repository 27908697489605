import React, { useEffect,  useRef,  useState }  from 'react'

function LoginVsWorkList() { 

    return (
        <table className="table text-nowrap placeholder-glow">
            <thead className="table-primary">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Team Member</th>
                    <th scope="col">Status</th>
                    <th scope="col">Login Hours</th>
                    <th scope="col">Work Hours</th>
                    <th scope="col">Idle Time</th>
                    <th scope="col">Active (%)</th>
                </tr>
            </thead>
            <tbody>
                {[1,2,3,4,5,6,7,8].map((n) => (
                <tr>
                    <td><span className="placeholder col-1"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                </tr>
                )) }

            </tbody>
        </table>
    );
}  

export default LoginVsWorkList