import React, {useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Login from './views/pages/Login';
import ReLogin from './views/pages/ReLogin';
import ForgotPassword from './views/pages/ForgotPassword';
import Dashboard from './views/pages/Dashboard';
import Error404 from './views/pages/Error404';
import Notes from './views/pages/Notes';
import TeamMembers from './views/pages/TeamMembers';
import Clients from './views/pages/Clients';
import Projects from './views/pages/Projects/Projects';
import Invoices from './views/pages/Invoices';
import Attendance from './views/pages/Attendance';
import LeaveApplications from './views/pages/Leave/LeaveApplications';
import Events from './views/pages/Events';

import LeaveBalances from './views/pages/Leave/LeaveBalances';

import Profile from './views/pages/Profile';
import WsLogs from './views/pages/Logs/WsLogs';
import WsJson from './views/pages/Logs/WsJson';
import Tasks from './views/pages/Projects/Tasks';
import ProjectDetails from './views/pages/Projects/ProjectDetails';
import TaskDetails from './views/pages/Projects/TaskDetails';
import EditInvoice from './views/pages/EditInvoice';
import Timesheets from './views/pages/Timesheets';
import Activities from './views/pages/Activities';
import Notifications from './views/pages/Notifications';
import GeneralSettings from './views/pages/Settings/GeneralSettings';
import CompanySettings from './views/pages/Settings/CompanySettings';
import EmployeeSettings from './views/pages/Settings/EmployeeSettings';
import ResetPassword from './views/pages/ResetPassword';
import MailLogs from './views/pages/Logs/MailLogs';
import { generateToken, messaging } from './notifications/firebase';
import { onMessage } from 'firebase/messaging';
import icon from './icon.ico'
import { encryptData } from './helpers/EncryptHelper';
import { useAuth } from './context/AuthContext';
import { editFCMToken } from './models/UserModel';
import { apiError } from './helpers/ErrorHelper';
import LoginVsWork from './views/pages/Reports/LoginVsWork';
import LeaveReport from './views/pages/Reports/LeaveReport';
import PerformanceReports from './views/pages/Reports/PerformanceReports';
import PerformanceGraphs from './views/pages/Reports/PerformanceGraphs';
import ProjectReports from './views/pages/Reports/ProjectReports';
import TaskReports from './views/pages/Reports/TaskReports';
import CompareReport from './views/pages/Reports/CompareReport';

function App() {  

    const [fcmToken, setFcmToken] = useState('');

    const { state, dispatch } = useAuth();
    
    useEffect(() => {
        //Fetch FCM token start
        const fetchFcmToken = async()=>{
            const token = await generateToken();
            setFcmToken(token);

            if (token) {
                localStorage.setItem("fcm_token", encryptData(fcmToken));

                if (state.isAuthenticated) {
                    try {
                        const formData = { fcm_token: token };
    
                        const response = await editFCMToken(state.user_data.user_token, formData);
    
                        //console.log(response);
    
                    } catch (error) {
                        apiError(error);
                    }
                }    
            }
        }

        fetchFcmToken();
        //Fetch FCM token end

        
        //Receive Foreground message start
        onMessage(messaging, (payload) => {
            //console.log("Foreground message received ", payload);

            const module = payload.data['gcm.notification.module'];
            const display = payload.data['gcm.notification.display'];
            const customData = payload.data['gcm.notification.data'];
            
            if (display == '1') {
                toast.success(payload.notification.title);

                const notificationTitle = 'foreground: '+payload.notification.title;
                const notificationOptions = {
                    body: payload.notification.body,
                    icon: icon,
                    expirationTime: Date.now() + 60000 // Set expiration time to 10 seconds from now
                };

                //new Notification(notificationTitle, notificationOptions); 
            }

        });
        //Receive Foreground message end

    }, [fcmToken, state]);    

    return ( 
    <>
        <BrowserRouter>
        
        <Routes>
            <Route exact index element={<Login  />} />
            <Route exact path="/login" element={<Login  />} />
            <Route exact path="/re-login" element={<ReLogin  />} />
            <Route exact path="/forgot-password" element={<ForgotPassword  />} />
            <Route exact path={`/reset-password/:token`} element={<ResetPassword  />} />


            <Route exact path="/notifications" element={<Notifications  />} />

            <Route exact path="/dashboard" element={<Dashboard  />} />
            <Route exact path="/profile" element={<Profile  />} />
            <Route path="/profile/:user_unique_id" element={<Profile  />} />
            <Route exact path="/events" element={<Events  />} />
            <Route exact path="/notes" element={<Notes  />} />
            <Route exact path="/team-members" element={<TeamMembers  />} />
            <Route exact path="/clients" element={<Clients  />} />
            <Route exact path="/projects" element={<Projects  />} />
            <Route exact path="/project-details/:project_unique_id" element={<ProjectDetails  />} />
            <Route exact path="/tasks" element={<Tasks  />} />
            <Route exact path="/task-details/:task_unique_id" element={<TaskDetails  />} />
            <Route exact path="/invoices" element={<Invoices  />} />
            <Route exact path="/edit-invoice/:invoice_unique_id" element={<EditInvoice  />} />
            <Route exact path="/attendance" element={<Attendance  />} />
            <Route exact path="/timesheets" element={<Timesheets  />} />
            
            <Route exact path="/leave-applications" element={<LeaveApplications  />} />
            <Route exact path="/leave-balances" element={<LeaveBalances />} />
            <Route exact path="/general-settings" element={<GeneralSettings />} />
            <Route exact path="/company-settings" element={<CompanySettings />} />
            <Route exact path="/employee-settings" element={<EmployeeSettings />} />

            <Route exact path="/ws-logs" element={<WsLogs />} />
            <Route path="/view-ws-json/:id" element={<WsJson  />} />

            <Route exact path="/mail-logs" element={<MailLogs />} />


            <Route exact path="/reports/login-vs-work" element={<LoginVsWork />} />
            <Route exact path="/reports/compare-report" element={<CompareReport />} />
            <Route exact path="/reports/activities" element={<Activities  />} />
            <Route exact path="/reports/leave-report" element={<LeaveReport />} />
            <Route exact path="/reports/performance-reports" element={<PerformanceReports />} />
            <Route exact path="/reports/performance-graphs" element={<PerformanceGraphs />} />
            <Route exact path="/reports/project-reports" element={<ProjectReports />} />
            <Route exact path="/reports/task-reports" element={<TaskReports />} />

            <Route path="*" element={<Error404  />} />
        </Routes>
        
        </BrowserRouter>

        <ToastContainer
            position="bottom-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            
        />
    
    </>
        
    );
}

export default App;