import {  GET, POST, PUT, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const loginAttendance = async(user_token, formData) => {

    const endPoint = "/attendance/attendance-login";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const logoutAttendance = async(user_token, formData) => {

    const endPoint = "/attendance/attendance-logout";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
}


export const saveAttendanceToStorage = (attendances, pagination, attendance_is_logged_in) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        attendances: attendances,
        pagination: pagination,
        attendance_is_logged_in: attendance_is_logged_in,
        time:currentTime
    };

    localStorage.setItem("attendances", encryptData(dataToStore));
}


export const getAttendance = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    let user_unique_id = param.user_unique_id ? param.user_unique_id : '';

    //For Own data which will be cached start
    if (user_unique_id==='') {
        if (page===1 && limit===10 && localStorage.getItem('attendances')) {
            const saved_attendances_obj = decryptData(localStorage.getItem('attendances'));
    
            return saved_attendances_obj;
        }
        else{
            try {
                const endPoint = '/attendance/attendance-list';
                const url = `${endPoint}?page=${page}&limit=${limit}`;
        
                setAuthToken(user_token);
                
                const response = await GET(url);
        
                if (response.status) {
                    if (page===1 && limit===10) {
                        saveAttendanceToStorage(response.data.attendances, response.data.pagination, response.data.attendance_is_logged_in);
    
                        const saved_attendances_obj = decryptData(localStorage.getItem('attendances'));
    
                        return saved_attendances_obj;
                    }
                    else{
                        //localStorage.removeItem("attendances");
                        return response.data;
                    }
                }
                else{
                    toast.error(response.message);
                }    
                
            } catch (error) {
                apiError(error);
            }
        }
    }    
    //For Own data which will be cached end

    //For Other data which will not be cached start
    else{
        try {
            const endPoint = '/attendance/attendance-list';
            const url = `${endPoint}?page=${page}&limit=${limit}&user_unique_id=${user_unique_id}`;

            setAuthToken(user_token);
                
            const response = await GET(url);

            if (response.status) {
                return response.data;
            }    
            else{
                toast.error(response.message);
            }
            
        } catch (error) {
            apiError(error);
        }
    }
    //For Other data which will not be cached end

    
};


export const editAttendance = async(user_token, attendanceId, formData) => {

    const endPoint = `/attendance/update-attendance-status/${attendanceId}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
} 