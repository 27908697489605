import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editLeaveBalance, saveLeaveBalanceToStorage } from '../../../models/LeaveModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const ViewLeaveBalanceModal = ({showModal, setShowModal, balanceData, modalKey, fetchBalances, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_lb_id, setLbID] = useState('');
    const [user_unique_id, setUserUniqueID] = useState('');
    const [user_first_name, setUserFirstName] = useState('');
    const [user_last_name, setUserLastName] = useState('');
    const [lb_leave_allocated, setLbLeaveAllocated] = useState('');
    const [lb_leave_balance, setLbLeaveBalance] = useState('');

    const [errors, setErrors] = useState({lb_leave_allocated: ''});

    const closeModal = () =>{
        setLbID('');
        setUserUniqueID('');
        setUserFirstName('');
        setUserLastName('');
        setLbLeaveAllocated('');
        setLbLeaveBalance('');

        setErrors({});
    
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (balanceData) {
          setLbID(balanceData._lb_id);
          setUserUniqueID(balanceData.leave_user_unique_id);
          setUserFirstName(balanceData.leave_user_first_name);
          setUserLastName(balanceData.leave_user_last_name);
          setLbLeaveAllocated(balanceData.lb_leave_allocated);
          setLbLeaveBalance(balanceData.lb_leave_balance);
          
          setErrors({});
        }
    }, [balanceData, modalKey]);


    const updateBalance = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { lb_leave_allocated };
    
          const response = await editLeaveBalance(user_data.user_token, user_unique_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();

            saveLeaveBalanceToStorage(response.data.balances, response.data.pagination);
    
            fetchBalances(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Allocate Leave</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-12" style={{display: user_data.urole_permission=='*' ? 'block' : 'none'}}>
                        <label className="form-label">Member </label>
                        <div className='form-control'>{user_first_name} {user_last_name}</div>
                    </div> 


                    <div className="col-xl-12">
                        <label className="form-label">Leave Allocated <span className="text-danger">*</span> </label>
                        
                        <input type="number" className="form-control text-center" value={lb_leave_allocated} 
                        onChange={(e) => setLbLeaveAllocated(e.target.value)} min={balanceData.lb_leave_allocated-balanceData.lb_leave_balance}/>

                        {errors.lb_leave_allocated && <p style={{ color: 'red' }}>{errors.lb_leave_allocated}</p>}
                    </div> 

                     
                    <div className="col-xl-12" >
                        <label className="form-label">Balance </label>

                        <input type="number" className="form-control text-center" value={lb_leave_balance} readOnly="true"/>
                    </div>

                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>

                
                <button type="button" className="btn btn-primary" onClick={updateBalance}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
              
            </Modal.Footer>
        </Modal>
    );
};    

export default ViewLeaveBalanceModal;