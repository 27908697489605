import React, { useEffect,  useRef,  useState }  from 'react'

function ProjectCards() { 

    return (

        <>
            <div className="col-xxl-4">
                <div className="card custom-card placeholder-glow">
                    <div className="card-header align-items-center">
                        <div className="me-2">
                            <span className="avatar avatar-rounded p-1 bg-secondary-transparent">
                                
                            </span>
                        </div>
                        <div className="flex-fill">
                            <a href="javascript:void(0);" className="fw-semibold fs-14 d-block text-truncate project-list-title placeholder col-3 mb-1"></a>
                            <span className="text-muted d-block fs-12 placeholder col-3">
                                                                
                            </span>
                        </div>
                        <div className="dropdown ">
                            <a aria-label="anchor" href="javascript:void(0);" className="btn btn-icon btn-sm btn-light placeholder col-3" data-bs-toggle="dropdown" aria-expanded="false">
                                
                            </a>
                            
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="col-4">
                                <div className="fw-semibold mb-1 placeholder col-12"></div>
                                <div className="avatar-list-stacked">
                                    <span className="py-1 px-2 rounded-pill bg-light placeholder col-3"></span>
                                    <span className="py-1 px-2 rounded-pill bg-light placeholder col-3"></span>
                                    <span className="py-1 px-2 rounded-pill bg-light placeholder col-3"></span>
                                    <span className="py-1 px-2 rounded-pill bg-light placeholder col-3"></span>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <div className="fw-semibold mb-1 placeholder col-12"></div>
                                <span className="placeholder col-4 badge bg-light py-1 px-4"></span>
                            </div>
                        </div>
                        <div className="fw-semibold mb-1 placeholder col-8"></div>
                        <p className="text-muted mb-3 placeholder col-8"></p>
                        <div className="fw-semibold mb-1 placeholder col-8"></div>
                        <div>
                            <div></div>
                            <div className="progress progress-xs progress-animate placeholder col-12" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar bg-primary placeholder col-12"></div>
                            </div>
                            <div className="mt-1"><span className="text-primary fw-semibold placeholder col-4"></span> </div>
                        </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                        <div className='col-4'>
                            <span className="text-muted fs-11 d-block placeholder col-12 mb-1"></span>
                            <span className="fw-semibold d-block placeholder col-12"></span>
                        </div>
                        <div className="col-4 text-end">
                            <span className="text-muted fs-11 d-block placeholder col-12 mb-1"></span>
                            <span className="fw-semibold d-block placeholder col-12"></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );    

}    

export default ProjectCards