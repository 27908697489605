import React, { useEffect, useRef, useState, useReducer, useMemo } from 'react';
import { useNavigate,  useLocation, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import dateFormat from "dateformat";
import { useAuth } from '../../../context/AuthContext';
import { useTheme } from '../../../context/ThemeContext';
import DataTable, { createTheme } from 'react-data-table-component';
import ProjectTasks from '../../skeletons/ProjectTasks';
import { getProjectTasks } from '../../../models/ProjectModel';
import { checkPermission, decodeHtmlEntities, profilePic } from '../../../helpers/GeneralHelper';
import DeleteTaskModal from '../../modals/Project/DeleteTaskModal';
import AddTaskModal from '../../modals/Project/AddTaskModal';


const ProjectTasksTables = ({
    project_unique_id,
    callProjectTasks
}) => {
    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    const navigate = useNavigate();

    const location = useLocation();
    const currentRoute = location.pathname; 

    let user_data = state.user_data;

    const [modalKey, setModalKey] = useState(0);

    //Add Task Start
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    //Add Task End


    //Delete Task Start
    const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

    const [taskData, setTaskData] = useState({});

    const openDeleteTaskModal = (task_data) =>{
        setTaskData(task_data);
        setModalKey(prevKey => prevKey + 1); 
        setShowDeleteTaskModal(true);
    }
    //Delete Task End


    // Get Project Files Start
    const [projectTasks, setProjectTasks] = useState([]);
    const [projectTasksTotalRows, setProjectTasksTotalRows] = useState(0);
    const [projectTasksLoading, setProjectTasksLoading] = useState(false);
    const [projectTasksPage, setProjectTasksPage] = useState(1);
    const [projectTasksLimit, setProjectTasksLimit] = useState(10);

    const fetchProjectTasks = async (limit, page) => {

        if (callProjectTasks===true) {
            setProjectTasksLoading(true);
        
            try {
                let apiParam = {page: page, limit: limit};

                const response = await getProjectTasks(user_data.user_token, project_unique_id, apiParam);

                //console.log(response);

                if (typeof response != typeof undefined) {
                    setProjectTasks(response.tasks);
                    setProjectTasksTotalRows(response.pagination.total_rows);
                    setProjectTasksLimit(limit);
                    setProjectTasksPage(page);

                    setNotificationCount(localStorage.getItem('unread_notification_count'));
                }  
            
                setProjectTasksLoading(false);

            } catch (error) {
                //console.error(error);
            }
        }
    }

    const ProjectTasksCustomLoader = () => (
        <ProjectTasks project_unique_id={project_unique_id}/>
    );

    const ProjectTasksPageChange = async (page) => {
        fetchProjectTasks(projectTasksLimit, page);  
    };

    const ProjectTasksLimitChange = async (limit) => {
        fetchProjectTasks(limit, projectTasksPage); 
    };  

    useEffect(()=>{
        fetchProjectTasks(projectTasksLimit, projectTasksPage); 
    }, [project_unique_id, callProjectTasks]);
    // Get Project Files End

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    const ProjectTasksColumns = [
        {
            name: "#",
            selector: (row, index) => <div>{((projectTasksPage-1)*projectTasksLimit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Project",
            selector: (row) =>  <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(row.project_title) }} />
        },
        {
            name: "Task",
            selector: (row) => row.task_title
        },
        {
            name: "Start Date",
            selector: (row) => dateFormat(row.task_start_date, "dd mmm yyyy")
        },
        {
            name: "Deadline",
            selector: (row) => dateFormat(row.task_deadline, "dd mmm yyyy")
        },
        {
            name: "Assigned To",
            selector: (row) => <div className="d-flex align-items-center">
                                <div className="avatar avatar-sm me-2 avatar-rounded">
                                    {profilePic(row._assign_user_first_name, row._assign_user_last_name, row._assign_user_image)}
                                </div>
                            </div>
        },
        {
            name: "Collaborators",
            selector: (row) => 'NA'
        },
        {
            name: "Status",
            selector: (row) => 'NA'
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`/task-details/${row.task_unique_id}`} className={`btn btn-icon btn-sm btn-info`} title="Edit">
                              <i className='bx bxs-edit'></i>
                            </Link>
    
                            <button type="button" className={`btn btn-icon btn-sm btn-danger ${checkPermission('project_edit')===false ? 'd-none' : ''}`} title="Delete" onClick={() => openDeleteTaskModal(row)}>
                              <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    // Conditionally unset a specific column
    const filteredColumns = project_unique_id!=''
        ? ProjectTasksColumns.filter(column => column.name !== "Project")
        : ProjectTasksColumns;

    return (
        <div className="card custom-card shadow-none">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                <div className="card-title">
                    
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <button className={`btn btn-primary btn-sm ${checkPermission('project_edit')===false ? 'd-none' : ''}`} onClick={() => setShowAddTaskModal(true)}>
                        <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Task
                    </button>
                </div>
            </div>
            <div className="card-body">
                <DataTable 
                    columns={filteredColumns} 
                    data={projectTasks}
                    pagination
                    paginationServer
                    paginationTotalRows={projectTasksTotalRows} 
                onChangeRowsPerPage={ProjectTasksLimitChange} 
                onChangePage={ProjectTasksPageChange} 
                progressPending={projectTasksLoading} 
                progressComponent={<ProjectTasksCustomLoader />}
                    fixedHeader 
                    fixedHeaderScrollHeight='550px'
                    highlightOnHover
                    theme={`${theme==='light' ? 'light' : 'dark'}`} />
            </div>

            {/* <!-- Start::add task modal --> */}
            <AddTaskModal
                showModal={showAddTaskModal}
                setShowModal={setShowAddTaskModal}
                project_unique_id={project_unique_id}
                modalKey={modalKey}
                fetchTasks={fetchProjectTasks}
                limit={projectTasksLimit}
                page={projectTasksPage} />
            {/* <!-- End::add task modal --> */}


            {/* <!--Delete Task Modal Start--> */}
            <DeleteTaskModal
                showModal={showDeleteTaskModal}
                setShowModal={setShowDeleteTaskModal}
                taskData={taskData}
                modalKey={modalKey}
                fetchTasks={fetchProjectTasks}
                limit={projectTasksLimit}
                page={projectTasksPage}  />
            {/* <!--Delete Task Modal End--> */}

        </div> 
    );

};    

export default ProjectTasksTables;    