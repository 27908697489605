import React, { useEffect,  useRef,  useState }  from 'react'

function ActivityCards() { 

    return (
        <div class="col-lg-3 col-md-3 col-sm-6 col-12">
            <div className="placeholder-glow">

                <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex align-items-center">
                        <div className="avatar avatar-sm me-2 avatar-rounded placeholder col-2">
                            
                        </div>

                        <div className='col-12'>
                            <div className="lh-1 placeholder col-12">
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <p className='mb-0 placeholder col-2'></p>
                </div>

                <a className="glightbox card mb-1 placeholder col-12" style={{height:'100px'}}> </a>

                
                <p className='mb-1 placeholder col-2'></p>
            </div>

        </div>
    );
}  

export default ActivityCards