import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import ClientList from '../skeletons/ClientList';


const ClientTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchClients,
    openUpdateClientModal, 
    openDeleteClientModal
}) => {

    const { theme } = useTheme();

    const { state } = useAuth(); 

    //let user_data = state.user_data;

    const CustomLoader = () => (
        <ClientList/>
    );

    const handlePageChange = async (page) => {
        fetchClients(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchClients(newLimit, page); 
        }
        else if (localStorage.getItem('clients')) {
            fetchClients(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchClients(limit, page);  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Company Name",
            selector: (row) => row.client_name
        },
        {
            name: "Mobile",
            selector: (row) => row.client_phone
        },
        {
            name: "Open Projects",
            selector: (row) => row.open_projects
        },
        {
            name: "Open Tasks",
            selector: (row) => row.open_tasks
        },
        {
            name: "Added On",
            selector: (row) => dateFormat(row.client_created, "dd mmm yyyy HH:MM") 
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit" onClick={() => openUpdateClientModal(row)}>
                                <i className='bx bxs-edit'></i>
                            </button>

                            <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete" onClick={() => openDeleteClientModal(row)}>
                                <i className='bx bxs-trash'></i>
                            </button>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });

    return (
        <DataTable 
            title={`Manage Clients`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );        

};    

export default ClientTables;    