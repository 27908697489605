import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editInvoiceShippingAddress, saveInvoicesToStorage } from '../../../models/InvoiceModel';
import { getCountries, getStates } from '../../../models/MasterModel';
import Select from 'react-select';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const UpdateShippingAddressModal = ({showModal, setShowModal, modalKey, invoiceData, setInvoiceData, shippingAddress, setShippingAddress, setInvoiceItems, setCompanySettings}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);
    
    const [invoice_unique_id, setInvoiceUniqueId] = useState('');

    const [invoice_company_name, setInvoiceCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [address_country, setAddressCountry] = useState('');
    const [address_state, setAddressState] = useState('');
    const [address_state_other, setAddressStateOther] = useState('');
    const [address_city, setAddressCity] = useState('');
    const [address_pincode, setAddressPincode] = useState('');


    const [errors, setErrors] = useState({invoice_company_name:  '',  address: '', address_country: '', address_state: '', address_city: '', address_pincode: ''});
    

    const closeModal = () =>{
        setInvoiceUniqueId('');

        setInvoiceCompanyName('');
        setAddress('');
        setAddressCountry('');
        setAddressState('');
        setAddressStateOther('');
        setAddressCity('');
        setAddressPincode('');
        setErrors({});
    
        setShowModal(false);
    }

    const [countries, setCountries] = useState([]);
    const fetchCountries = async () => {
        try {
          const result = await getCountries();

          const countriesOptions = result.countries.map(country => ({
            label: country.country_name,
            value: country._country_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setCountries(countriesOptions);
        } catch (error) {
          //console.error(error);
        }
    };

    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        // Start the loading state
        loader.style.display = 'block';

        try {
          const result = await getStates();

          const statesOptions = result.states.map(state => ({
            label: state.state_name,
            value: state._state_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setStates(statesOptions);
        } catch (error) {
          // Handle errors if necessary
          //console.error(error);
        }

        // Stop the loading state
        loader.style.display = 'none';
    };

    const countryChange = async(countryObj) => {
        setAddressCountry(countryObj);

        //console.log(countryObj.value);

        if (countryObj.value==99) {
            fetchStates();
            setAddressStateOther('');
        }
        else{
            setStates([]);
            setAddressState('');
        }
    }

    useEffect(() => {
        fetchCountries();

        if (shippingAddress && shippingAddress._country_id==99) {
            //console.log('India');
            fetchStates();
        }
        else{
            //console.log('Not India');
            setStates([]);
        }
    }, []);

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (invoiceData) {
            setInvoiceUniqueId(invoiceData.invoice_unique_id);
            
            setErrors({});
        }

        if (shippingAddress) {
            setInvoiceCompanyName(shippingAddress.invoice_company_name);
            setAddress(shippingAddress.address);
            setAddressCountry({'label': shippingAddress.country_name, 'value': shippingAddress._country_id});
            setAddressState({'label': shippingAddress.state_name, 'value': shippingAddress._state_id});
            setAddressStateOther(shippingAddress.address_state_other);
            setAddressCity(shippingAddress.address_city);
            setAddressPincode(shippingAddress.address_pincode);
        }

      }, [invoiceData, shippingAddress, modalKey]);
    
    
    const updateShippingAddress = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { invoice_company_name, address, address_country: address_country.value ? address_country.value : '', 
            address_state: address_state.value ? address_state.value : '', address_state_other, address_city, address_pincode  };
    
          const response = await editInvoiceShippingAddress(user_data.user_token, invoice_unique_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveInvoicesToStorage(response.data.invoices, response.data.pagination);
    
            setInvoiceData(response.data.invoice_data);
            setShippingAddress(response.data.invoice_shipping_address);
            setInvoiceItems(response.data.invoice_items);
            setCompanySettings(response.data.company_settings);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          //console.log(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
            <Modal.Title>Update Shipping Address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                <div className="col-xl-6">
                    <label className="form-label">Company Name <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Company Name" 
                    value={invoice_company_name} onChange={(e) => setInvoiceCompanyName(e.target.value)}/>

                    
                    {errors.invoice_company_name && <p style={{ color: 'red' }}>{errors.invoice_company_name}</p>}
                </div>

                <div className="col-xl-6">
                    <label className="form-label">Address <span className="text-danger">*</span></label>

                    <textarea className="form-control" rows="3" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>

                    {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
                </div>


                <div className="col-xl-6">
                    <label className="form-label">Country <span className="text-danger">*</span></label>

                    <Select
                        value={address_country}
                        onChange={countryChange}
                        options={countries}
                        placeholder="- Country -"
                        isSearchable="true"
                        noOptionsMessage={() => "No country found"} />

                    
                    {errors.address_country && <p style={{ color: 'red' }}>{errors.address_country}</p>}
                </div>

                <div className="col-xl-6" style={{display: address_country.value && address_country.value==99 ? 'block' : 'none'}}>
                    <label className="form-label">State </label>

                    <Select
                        value={address_state}
                        onChange={setAddressState}
                        options={states}
                        placeholder="- State -"
                        isSearchable="true"
                        noOptionsMessage={() => "No state found"} />

                    
                    {errors.address_state && <p style={{ color: 'red' }}>{errors.address_state}</p>}
                </div>


                <div className="col-xl-6" style={{display: address_country.value && address_country.value!=99 ? 'block' : 'none'}}>
                    <label className="form-label">State </label>

                    <input type="text" className="form-control" placeholder="State Name" 
                    value={address_state_other} onChange={(e) => setAddressStateOther(e.target.value)}/>

                    
                    {errors.address_state_other && <p style={{ color: 'red' }}>{errors.address_state_other}</p>}
                </div>


                <div className="col-xl-6">
                    <label className="form-label">City <span className="text-danger">*</span></label>

                    <input type="text" className="form-control" placeholder="City Name" 
                    value={address_city} onChange={(e) => setAddressCity(e.target.value)}/>

                    
                    {errors.address_city && <p style={{ color: 'red' }}>{errors.address_city}</p>}
                </div>

                <div className="col-xl-6">
                    <label className="form-label">Pincode <span className="text-danger">*</span></label>

                    <input type="text" className="form-control" placeholder="Pincode" 
                    value={address_pincode} onChange={(e) => setAddressPincode(e.target.value)}/>

                    
                    {errors.address_pincode && <p style={{ color: 'red' }}>{errors.address_pincode}</p>}
                </div>

                

                
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={updateShippingAddress}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Update'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default UpdateShippingAddressModal;
