import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import { useAuth } from '../../../context/AuthContext';
import { getUserRoles } from '../../../models/UserModel';
import UserRoleTables from '../../tables/UserRoleTables';
import AddUserRoleModal from '../../modals/Settings/AddUserRoleModal';
import UpdateUserRoleModal from '../../modals/Settings/UpdateUserRoleModal';
import DeleteUserRoleModal from '../../modals/Settings/DeleteUserRoleModal';
import UserRoleMembersModal from '../../modals/Settings/UserRoleMembersModal';
import { getApplicationsSettings, updateLinuxDesktopApplicationSettings, updateMacOsDesktopApplicationSettings, updateWindowsDesktopApplicationSettings } from '../../../models/SettingsModel';
import { apiError } from '../../../helpers/ErrorHelper';
import { toast } from 'react-toastify';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import Error403 from '../Error403';
import { checkPermission } from '../../../helpers/GeneralHelper';
import UpdateUserRolePermissionModal from '../../modals/Settings/UpdateUserRolePermissionModal';

const GeneralSettings = () => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  //Add Role Start
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  //Add Role End


  //Update Role Start
  const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);

  const [roleData, setRoleData] = useState({});

  const openUpdateRoleModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateRoleModal(true);
  }  
  //Update Role End



  //Update Members Start
  const [showUpdateMembersModal, setShowUpdateMembersModal] = useState(false);

  const openUpdateMembersModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateMembersModal(true);
  }
  //Update Members End


  //Update Permissions Start
  const [showUpdatePermissionsModal, setShowUpdatePermissionsModal] = useState(false);

  const openUpdatePermissionsModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdatePermissionsModal(true);
  }
  //Update Permissions End


  //Delete Role Start
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);

  const openDeleteRoleModal = (role_data) =>{
    setRoleData(role_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteRoleModal(true);
  } 
  //Delete Role End


  //Flush Role Start
  const flushRole = async() => {
    localStorage.removeItem("user_roles");
    fetchRoles(); 
  }  
  //Flush Role End


  //Role List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRoles = async() => {
    setLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token};

        const response = await getUserRoles(apiParam);

        if (typeof response != typeof undefined) {
            setData(response.user_roles);

            if (response.time) {
                setCacheTime(response.time);
            }

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else{
            navigate('/re-login');
        }        

        setLoading(false);

    } catch (error) {
      //console.error(error);
    }
    
  };
  //Role List End


  //Fetch Application Settings Start
  const [desktop_app_windows_current_version, setDesktopAppWindowsCurrentVersion] = useState('');
  const [desktop_app_windows_min_version, setDesktopAppWindowsMinVersion] = useState('');
  const [desktop_app_windows_file, setDesktopAppWindowsFile] = useState('');

  const [desktop_app_linux_current_version, setDesktopAppLinuxCurrentVersion] = useState('');
  const [desktop_app_linux_min_version, setDesktopAppLinuxMinVersion] = useState('');
  const [desktop_app_linux_file, setDesktopAppLinuxFile] = useState('');

  const [desktop_app_macos_current_version, setDesktopAppMacOsCurrentVersion] = useState('');
  const [desktop_app_macos_min_version, setDesktopAppMacOsMinVersion] = useState('');
  const [desktop_app_macos_file, setDesktopAppMacOsFile] = useState('');

  const [errors1, setErrors1] = useState({desktop_app_windows_current_version: '', desktop_app_windows_min_version: '', desktop_app_windows_file: ''});

  const [errors2, setErrors2] = useState({desktop_app_linux_current_version: '', desktop_app_linux_min_version: '', desktop_app_linux_file: ''});

  const [errors3, setErrors3] = useState({desktop_app_macos_current_version: '', desktop_app_macos_min_version: '', desktop_app_macos_file: ''});

  const fetchApplicationSettings = async() =>{
    loader.style.display = 'block';

    try {
      let apiParam = {user_token: user_data.user_token};
      const response = await getApplicationsSettings(apiParam);

      //console.log(response);

      if (response.status) {
        setDesktopAppWindowsCurrentVersion(response.data.apps_settings.desktop_app_windows_current_version);
        setDesktopAppWindowsMinVersion(response.data.apps_settings.desktop_app_windows_min_version);

        setDesktopAppLinuxCurrentVersion(response.data.apps_settings.desktop_app_linux_current_version);
        setDesktopAppLinuxMinVersion(response.data.apps_settings.desktop_app_linux_min_version);

        setDesktopAppMacOsCurrentVersion(response.data.apps_settings.desktop_app_macos_current_version);
        setDesktopAppMacOsMinVersion(response.data.apps_settings.desktop_app_macos_min_version);

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      }
      
    } catch (error) {
      //console.error(error);
    }


    loader.style.display = 'none';
  }
  useEffect(() => {  
    if (checkPermission('menu_settings')===true) {
      //fetchApplicationSettings();
    }  
    
  }, []);

  const handleWindowFileChange = (event)=>{
    const file = event.target.files[0];
    const fileName = file.name.toLowerCase();
    const extension = fileName.split('.').pop();

    // Check if the file extension is not .exe
    if (extension !== 'exe') {
      // Display an error message
      toast.error('Please select a .exe file.');
      return; // Exit the function
    }

    //console.log(file);
    setDesktopAppWindowsFile(file);
  }


  const handleLinuxFileChange = (event)=>{
    const file = event.target.files[0];
    const fileName = file.name.toLowerCase();
    const extension = fileName.split('.').pop();

    // Check if the file extension is not .exe
    if (extension !== 'deb') {
      // Display an error message
      toast.error('Please select a .deb file.');
      return; // Exit the function
    }

    //console.log(file);
    setDesktopAppLinuxFile(file);
  }


  const handleMacOsFileChange = (event)=>{
    const file = event.target.files[0];
    const fileName = file.name.toLowerCase();
    const extension = fileName.split('.').pop();

    // Check if the file extension is not .exe
    if (extension !== 'dmg') {
      // Display an error message
      toast.error('Please select a .dmg file.');
      return; // Exit the function
    }

    //console.log(file);
    setDesktopAppMacOsFile(file);
  }
  //Fetch Application Settings End


  //Update Windows Application Start
  const doUpdateWindowApp = async()=>{
    // Start the loading state
    loader.style.display = 'block';

    try {
      // Clear previous errors
      setErrors1({});

      const formData = new FormData();

      formData.append('desktop_app_windows_current_version', desktop_app_windows_current_version);
      formData.append('desktop_app_windows_min_version', desktop_app_windows_min_version);
      formData.append('desktop_app_windows_file', desktop_app_windows_file);

      const response = await updateWindowsDesktopApplicationSettings(user_data.user_token, formData);

      if (response.status) {
        toast.success(response.message);

        setDesktopAppWindowsFile('');

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      } 
      else{
        setErrors1(response.error);
        toast.error(response.message);
      } 
      
    } catch (error) {
      apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  }
  //Update Windows Application End


  //Update Linux Application Start
  const doUpdateLinuxApp = async()=>{
    // Start the loading state
    loader.style.display = 'block';

    try {
      // Clear previous errors
      setErrors2({});

      const formData = new FormData();

      formData.append('desktop_app_linux_current_version', desktop_app_linux_current_version);
      formData.append('desktop_app_linux_min_version', desktop_app_linux_min_version);
      formData.append('desktop_app_linux_file', desktop_app_linux_file);

      const response = await updateLinuxDesktopApplicationSettings(user_data.user_token, formData);

      if (response.status) {
        toast.success(response.message);

        setDesktopAppLinuxFile('');

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      } 
      else{
        setErrors2(response.error);
        toast.error(response.message);
      } 
      
    } catch (error) {
      apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  }
  //Update Linux Application End


  //Update MacOs Application Start
  const doUpdateMacOsApp = async()=>{
    // Start the loading state
    loader.style.display = 'block';

    try {
      // Clear previous errors
      setErrors3({});

      const formData = new FormData();

      formData.append('desktop_app_macos_current_version', desktop_app_macos_current_version);
      formData.append('desktop_app_macos_min_version', desktop_app_macos_min_version);
      formData.append('desktop_app_macos_file', desktop_app_macos_file);

      const response = await updateMacOsDesktopApplicationSettings(user_data.user_token, formData);

      if (response.status) {
        toast.success(response.message);

        setDesktopAppMacOsFile('');

        setNotificationCount(localStorage.getItem('unread_notification_count'));
      } 
      else{
        setErrors3(response.error);
        toast.error(response.message);
      } 
      
    } catch (error) {
      apiError(error);
    }

    // Stop the loading state
    loader.style.display = 'none';
  }
  //Update MacOs Application End

  return (
    <>
      {checkPermission('menu_settings')===true ? (
      <AdminLayout>
        
        <Helmet>
            <title>General Settings - Worktracker Anciletech</title>
        </Helmet>

        <div className="main-content app-content">

            
            <div className="container-fluid">
              <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">General Settings</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#role-center" aria-selected="false" tabindex="-1">User Role & Permissions</a>
                                    </li>
                                   
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#app-center" aria-selected="false" tabindex="-1" onClick={() => fetchApplicationSettings()}>APP Settings</a>
                                    </li>
                                </ul>

                                <div className="tab-content">

                                    {/* <!---User Role & Permissions Tab Content Start---> */}
                                    <div className="tab-pane show active text-muted" id="role-center" role="tabpanel">
                                      <div className="card custom-card">
                                          <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                              <div className="card-title">
                                                Last Synced On {cacheTime}
                                              </div>

                                              <div className="d-flex flex-wrap gap-2">
                                                <button className="btn btn-primary btn-sm"  onClick={() => setShowAddRoleModal(true)}>
                                                    <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Role
                                                </button>

                                                <button className="btn btn-success-light btn-sm" onClick={() => flushRole()}>
                                                    <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                                </button>
                                            </div>
                                          </div>

                                          <div className="card-body">

                                            {/* <!---User Role Table Start---> */}
                                            <UserRoleTables
                                              data={data}
                                              setData={setData}
                                              loading={loading}
                                              setLoading={setLoading}
                                              fetchRoles={fetchRoles} 
                                              openUpdateRoleModal={openUpdateRoleModal}
                                              openDeleteRoleModal={openDeleteRoleModal}
                                              openUpdateMembersModal={openUpdateMembersModal}
                                              openUpdatePermissionsModal={openUpdatePermissionsModal}/>
                                            {/* <!---User Role Table End---> */}

                                            {/* <!--Add User Role Modal Start--> */}
                                            <AddUserRoleModal
                                            showModal={showAddRoleModal}
                                            setShowModal={setShowAddRoleModal}
                                            fetchRoles={fetchRoles} />
                                            {/* <!--Add User Role Modal End--> */}


                                            {/* <!--Update User Role Modal Start--> */}
                                            <UpdateUserRoleModal
                                            showModal={showUpdateRoleModal}
                                            setShowModal={setShowUpdateRoleModal}
                                            roleData={roleData}
                                            modalKey={modalKey}
                                            fetchRoles={fetchRoles} />
                                            {/* <!--Update User Role Modal End--> */}


                                            {/* <!--Update Role Members Modal Start--> */}
                                            <UserRoleMembersModal
                                            showModal={showUpdateMembersModal}
                                            setShowModal={setShowUpdateMembersModal}
                                            roleData={roleData}
                                            modalKey={modalKey}
                                            fetchRoles={fetchRoles} />
                                            {/* <!--Update Role Members Modal End--> */}


                                            {/* <!--Update User Role Permission Modal Start--> */}
                                            <UpdateUserRolePermissionModal
                                            showModal={showUpdatePermissionsModal}
                                            setShowModal={setShowUpdatePermissionsModal}
                                            roleData={roleData}
                                            modalKey={modalKey}
                                            fetchRoles={fetchRoles} />
                                            {/* <!--Update User Role Permission Modal End--> */}


                                            {/* <!--Delete User Role Modal Start--> */}
                                            <DeleteUserRoleModal
                                            showModal={showDeleteRoleModal}
                                            setShowModal={setShowDeleteRoleModal}
                                            roleData={roleData}
                                            modalKey={modalKey}
                                            fetchRoles={fetchRoles} />
                                            {/* <!--Delete User Role Modal End--> */}


                                          </div>
                                      </div>
                                    </div>
                                    {/* <!---User Role & Permissions Tab Content End---> */}


                                    
                                    {/* <!---App Settings Tab Content Start---> */}
                                    <div className="tab-pane text-muted" id="app-center" role="tabpanel">

                                        {/* <!---Windows DeskTop Application Update Start---> */}
                                        <div className="row g-3">
                                          <div className="col-12">
                                            <div className="card custom-card p-3 border border-primary">
                                              <div className="card-title">
                                                <i class="bi bi-pc-display-horizontal"></i> Windows Desktop Application
                                              </div>
                                              <div className="card-body">
                                                  <div className="row g-3">

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Latest Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_windows_current_version} 
                                                      onChange={(e) => setDesktopAppWindowsCurrentVersion(e.target.value)} />

                                                      {errors1.desktop_app_windows_current_version && <p style={{ color: 'red' }}>{errors1.desktop_app_windows_current_version}</p>}
                                                    </div>

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Minimum Required Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_windows_min_version} 
                                                      onChange={(e) => setDesktopAppWindowsMinVersion(e.target.value)}  />

                                                      {errors1.desktop_app_windows_min_version && <p style={{ color: 'red' }}>{errors1.desktop_app_windows_min_version}</p>}
                                                    </div>


                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Application File </label>
                                                      <input type='file' className="form-control" accept=".exe" onChange={handleWindowFileChange} />

                                                      {errors1.desktop_app_windows_file && <p style={{ color: 'red' }}>{errors1.desktop_app_windows_file}</p>}
                                                    </div>
                                                    
                                                    
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label"></label><br/>
                                                      <button type='button' className="btn btn-lg btn-primary" onClick={doUpdateWindowApp}>
                                                          Update
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                        {/* <!---Windows DeskTop Application Update End---> */}


                                        {/* <!---Linux DeskTop Application Update Start---> */}
                                        <div className="row g-3">
                                          <div className="col-12">
                                            <div className="card custom-card p-3 border border-primary">
                                              <div className="card-title">
                                                <i class="bi bi-pc-display-horizontal"></i> Debian Linux Desktop Application
                                              </div>
                                              <div className="card-body">
                                                  <div className="row g-3">

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Latest Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_linux_current_version} 
                                                      onChange={(e) => setDesktopAppLinuxCurrentVersion(e.target.value)} />

                                                      {errors2.desktop_app_linux_current_version && <p style={{ color: 'red' }}>{errors2.desktop_app_linux_current_version}</p>}
                                                    </div>

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Minimum Required Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_linux_min_version} 
                                                      onChange={(e) => setDesktopAppLinuxMinVersion(e.target.value)}  />

                                                      {errors2.desktop_app_linux_min_version && <p style={{ color: 'red' }}>{errors2.desktop_app_linux_min_version}</p>}
                                                    </div>


                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Application File </label>
                                                      <input type='file' className="form-control" accept=".deb" onChange={handleLinuxFileChange} />

                                                      {errors2.desktop_app_linux_file && <p style={{ color: 'red' }}>{errors2.desktop_app_linux_file}</p>}
                                                    </div>
                                                    
                                                    
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label"></label><br/>
                                                      <button type='button' className="btn btn-lg btn-primary" onClick={doUpdateLinuxApp}>
                                                          Update
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                        {/* <!---Linux DeskTop Application Update End---> */}


                                        {/* <!---MAC OS DeskTop Application Update Start---> */}
                                        <div className="row g-3">
                                          <div className="col-12">
                                            <div className="card custom-card p-3 border border-primary">
                                              <div className="card-title">
                                                <i class="bi bi-pc-display-horizontal"></i> MAC OS Desktop Application
                                              </div>
                                              <div className="card-body">
                                                  <div className="row g-3">

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Latest Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_macos_current_version} 
                                                      onChange={(e) => setDesktopAppMacOsCurrentVersion(e.target.value)} />

                                                      {errors3.desktop_app_macos_current_version && <p style={{ color: 'red' }}>{errors3.desktop_app_macos_current_version}</p>}
                                                    </div>

                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Minimum Required Version <span className="text-danger">*</span></label>
                                                      <input type="text" className="form-control" value={desktop_app_macos_min_version} 
                                                      onChange={(e) => setDesktopAppMacOsMinVersion(e.target.value)}  />

                                                      {errors3.desktop_app_macos_min_version && <p style={{ color: 'red' }}>{errors3.desktop_app_macos_min_version}</p>}
                                                    </div>


                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label">Application File </label>
                                                      <input type='file' className="form-control" accept=".dmg" onChange={handleMacOsFileChange} />

                                                      {errors2.desktop_app_macos_file && <p style={{ color: 'red' }}>{errors2.desktop_app_macos_file}</p>}
                                                    </div>
                                                    
                                                    
                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                      <label className="form-label"></label><br/>
                                                      <button type='button' className="btn btn-lg btn-primary" onClick={doUpdateMacOsApp}>
                                                          Update
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                        {/* <!---MAC OS DeskTop Application Update End---> */}

                                    </div>
                                    {/* <!---App Settings Tab Content End---> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>  

        <Helmet>
            
        </Helmet>
    
      </AdminLayout>
      ) : ( <Error403/>)}
    </>
  )
}

export default GeneralSettings