import { DEL, GET, POST, PUT, UploadPost, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const userLogin = async(formData) => {

    const endPoint = "/general/login";

    const response = await POST(endPoint, formData);

    return response;
} 


export const userReLogin = async(user_token, formData) => {

    const endPoint = "/general/re-login";

    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 



export const userLogout = async(user_token) => {

    const endPoint = "/users/logout";

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}

export const clearStorageData = () => {
    const fcm_token_enc = localStorage.getItem("fcm_token");

    const remember = localStorage.getItem("remember");
    const user_credentials_enc = localStorage.getItem("user_credentials");

    localStorage.clear();

    if (fcm_token_enc) {
        localStorage.setItem("fcm_token", fcm_token_enc);
    }

    if (remember && user_credentials_enc) {
        localStorage.setItem("remember", remember);
        localStorage.setItem("user_credentials", user_credentials_enc);
    }
    
}

export const saveUserCredentialToStorage = (user_email, user_password) =>{

    const credentials = {user_email, user_password};

    localStorage.setItem('remember', true);
    localStorage.setItem("user_credentials", encryptData(credentials));
}


export const removeUserCredentialFromStorage = () =>{

    localStorage.removeItem('remember');
    localStorage.removeItem("user_credentials");
}

export const getUserCredentialsFromStorage = () => {
    let credentials;
 
    if (localStorage.getItem("user_credentials")) {
        credentials = decryptData(localStorage.getItem("user_credentials"));
    }
    else{
        credentials = {user_email: '', user_password: ''};
    }
 
    return credentials;
}


export const userForgotPassword = async(formData) => {

    const endPoint = "/general/forgot-password";

    const response = await POST(endPoint, formData);

    return response;
} 


export const userResetPassword = async(formData) => {

    const endPoint = "/general/reset-password";

    const response = await POST(endPoint, formData);

    return response;
} 


export const editFCMToken = async(user_token, formData) => {

    const endPoint = `/users/send-fcm-token`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 



export const saveUserDataToStorage = (user_data) => {

    localStorage.setItem("worktracker_login", true);
    localStorage.setItem("user_data", encryptData(user_data));
} 

export const saveUsersToStorage = (users, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        users: users,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("users", encryptData(dataToStore));
} 


export const createUser = async(user_token, formData) => {

    const endPoint = "/users/add-user";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const getUsers = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page===1 && limit===10 && localStorage.getItem('users')) {
        const saved_users_obj = decryptData(localStorage.getItem('users'));

        return saved_users_obj;
    }
    else{
        try {
            const endPoint = '/users/user-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page===1 && limit===10) {
                    saveUsersToStorage(response.data.users, response.data.pagination);

                    const saved_users_obj = decryptData(localStorage.getItem('users'));

                    return saved_users_obj;
                }
                else{
                    //localStorage.removeItem("users");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};



export const getUserProfile = async(param = {}) => {

    let user_token = param.user_token;
    let user_unique_id = param.user_unique_id;

    try {
        const endPoint = `/users/profile/${user_unique_id}`;
        const url = `${endPoint}`;
    
        setAuthToken(user_token);
        
        const response = await GET(url);

        if (response.status) {
            return response.data;
        }    
        else{
            toast.error(response.message);
        }
        
    } catch (error) {
        apiError(error);
    }
};


export const createRole = async(user_token, formData) => {

    const endPoint = "/users/add-user-role";
    setAuthToken(user_token);

    const response = await POST(endPoint, formData);

    return response;
} 


export const editRole = async(user_token, _urole_id, formData) => {

    const endPoint = `/users/update-user-role/${_urole_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 


export const editRolePermissions = async(user_token, _urole_id, formData) => {

    const endPoint = `/users/update-user-role-permissions/${_urole_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
};


export const editRoleMembers = async(user_token, _urole_id, formData) => {

    const endPoint = `/users/update-user-role-members/${_urole_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
};


export const removeRole = async(user_token, _urole_id) => {

    const endPoint = `/users/delete-user-role/${_urole_id}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 


export const saveRolesToStorage = (user_roles) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        user_roles: user_roles,
        time:currentTime
    };

    localStorage.setItem("user_roles", encryptData(dataToStore));
} 


export const getUserRoles = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    if (localStorage.getItem('user_roles')) {

        const saved_roles_obj = decryptData(localStorage.getItem('user_roles'));

        return saved_roles_obj;
    }    
    else{
        try {
            const endPoint = `/users/user-role-list`;
            const url = `${endPoint}`;
        
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {

                saveRolesToStorage(response.data.user_roles);

                const saved_roles_obj = decryptData(localStorage.getItem('user_roles'));

                return saved_roles_obj;

                //return response.data;
            }    
            else{
                toast.error(response.message);
            }
            
        } catch (error) {
            apiError(error);
        }
    }

    
};


export const updateUserGeneralInfo = async(user_token, user_unique_id, formData) => {

    const endPoint = `/users/update-general-info/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 


export const updateUserJobInfo = async(user_token, user_unique_id, formData) => {

    const endPoint = `/users/update-job-info/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 


export const updateUserSocialNetworks = async(user_token, user_unique_id, formData) => {

    const endPoint = `/users/update-social-networks/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 



export const updateUserAccountInfo = async(user_token, user_unique_id, formData) => {

    const endPoint = `/users/update-account-info/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 


export const updateUserBankDetails = async(user_token, user_unique_id, formData) => {

    const endPoint = `/users/update-bank-details/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 



export const updateMyPassword = async(user_token, formData) => {

    const endPoint = `/users/change-password`;

    setAuthToken(user_token);

    const response = await PUT(endPoint, formData);

    return response;
}; 


export const updateMyPicture = async(user_token, formData) => {

    const endPoint = `/users/update-profile-picture`;

    setAuthToken(user_token);

    const response = await UploadPost(endPoint, formData);

    return response;
};


export const removeUser = async(user_token, user_unique_id) => {

    const endPoint = `/users/delete-user/${user_unique_id}`;

    setAuthToken(user_token);

    const response = await DEL(endPoint);

    return response;
} 




export const saveUsersWithTeamsToStorage = (teams, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        teams: teams,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("users_with_teams", encryptData(dataToStore));
};


export const getTeamWithMembers = async(param = {}) => {

    let user_token = param.user_token;

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page===1 && limit===10 && localStorage.getItem('users_with_teams')) {
        const saved_users_with_teams_obj = decryptData(localStorage.getItem('users_with_teams'));

        return saved_users_with_teams_obj;
    }
    else{
        try {
            const endPoint = '/users/team-with-members-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page===1 && limit===10) {
                    saveUsersWithTeamsToStorage(response.data.teams, response.data.pagination);

                    const saved_users_with_teams_obj = decryptData(localStorage.getItem('users_with_teams'));

                    return saved_users_with_teams_obj;
                }
                else{
                    localStorage.removeItem("users_with_teams");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
}; 


export const getManagers = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page===1 && limit===10 && localStorage.getItem('managers')) {
        const saved_managers_obj = decryptData(localStorage.getItem('managers'));

        return saved_managers_obj;
    }
    else{
        try {
            const endPoint = '/users/manager-list';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page===1 && limit===10) {
                    saveManagersToStorage(response.data.managers, response.data.pagination);

                    const saved_managers_obj = decryptData(localStorage.getItem('managers'));

                    return saved_managers_obj;
                }
                else{
                    //localStorage.removeItem("users");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};

export const saveManagersToStorage = (managers, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        managers: managers,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("managers", encryptData(dataToStore));
} 