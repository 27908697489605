import React, { useEffect, useMemo, useRef, useState, useCallback  } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { createProjectFile } from '../../../models/ProjectModel';
import { useDropzone } from 'react-dropzone';

const AddProjectFileModal = ({showModal, setShowModal, project_unique_id, projectData, modalKey, fetchProjectFiles, limit, page, task_unique_id}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);


    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the accepted file
        const file = acceptedFiles[0];
        //console.log(file);

        setProjectFile(file);
    }, []);

    const { getRootProps, getInputProps, isDragActive } =  useDropzone({
        onDrop,
        multiple: false  // Set multiple to false to restrict selecting multiple files
    });


    const renderFilePreview = (file) => {
        // Render a preview or information based on the file type
        if (file.type.startsWith('image/')) {
          return <img src={URL.createObjectURL(file)} alt="Preview" style={previewStyle} />;
        } else if (file.type.startsWith('video/')) {
          return <video controls style={previewStyle}><source src={URL.createObjectURL(file)} type={file.type} /></video>;
        } else if (file.type === 'application/pdf') {
          return <embed src={URL.createObjectURL(file)} type="application/pdf" style={previewStyle} />;
        } else {
          return <p>File type not supported for preview</p>;
        }
    };


    const [_project_id, setProjectId] = useState('');
    const [_task_id, setTaskId] = useState('');
    const [project_file, setProjectFile] = useState('');


    const [errors, setErrors] = useState({project_file: ''});

    const closeModal = () =>{
        setProjectId('');
        setTaskId('');
        setProjectFile('');

        setErrors({});
    
        setShowModal(false);
    }

    useEffect(() => {
        if (projectData) {
            setProjectId(projectData._project_id);
        }
        
    }, [projectData]); 


    const uploadFile = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});

          const formData = new FormData();

          formData.append('project_file', project_file);

          const response = await createProjectFile(user_data.user_token, project_unique_id, task_unique_id, formData);

          if (response.status) {
            toast.success(response.message);

            fetchProjectFiles(limit, page);
    
            closeModal();

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 

    const dropzoneStyles = {
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer'
    };

    const previewStyle = {
        maxWidth: '100%',
        maxHeight: '200px',
        marginTop: '20px'
    };
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Upload File</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">

                    <div className="col-lg-12">
                        <label className="form-label">File <span className="text-danger">*</span></label>
                        <div {...getRootProps()} style={dropzoneStyles}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                            }
                        </div>

                        {project_file && (
                            <div>
                            <p>Selected File:</p>
                            <p>Name: {project_file.name}</p>
                            <p>Type: {project_file.type}</p>
                            <p>Size: {project_file.size} bytes</p>
                            {/* Additional logic to display preview for specific file types */}
                            {renderFilePreview(project_file)}
                            </div>
                        )}

                        {errors.project_file && <p style={{ color: 'red' }}>{errors.project_file}</p>}
                    </div>

                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={uploadFile}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};

export default AddProjectFileModal;    