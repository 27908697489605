import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editRole, saveRolesToStorage } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const UpdateUserRoleModal = ({showModal, setShowModal, roleData, modalKey, fetchRoles}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [_urole_id, setRoleID] = useState('');
    const [urole_title, setUroleTitle] = useState('');
    const [isSuperAdmin, setIsSuperAdmin] = useState(0);
    
    const [errors, setErrors] = useState({urole_title:  ''});

    const handleSuperAdmin = ()=>{

        if (isSuperAdmin==0) {
          setIsSuperAdmin(1);
        }
        else{
          setIsSuperAdmin(0);
        }
    }

    
    const closeModal = () =>{
        setRoleID('');
        setUroleTitle('');
        setIsSuperAdmin(0);

        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (roleData) {
            setRoleID(roleData._urole_id);
            setUroleTitle(roleData.urole_title);
            setIsSuperAdmin(roleData.urole_permission==='*' ? 1 : 0);

            setErrors({});
        }
      }, [roleData, modalKey]);


    const updateRole = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { urole_title, isSuperAdmin };
    
          const response = await editRole(user_data.user_token, _urole_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveRolesToStorage(response.data.user_roles);
    
            fetchRoles(); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='sm'>
            <Modal.Header closeButton>
                <Modal.Title>Update Role</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-12">
                        <label className="form-label">Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Role Name" 
                        value={urole_title} onChange={(e) => setUroleTitle(e.target.value)}/>

                        
                        {errors.urole_title && <p style={{ color: 'red' }}>{errors.urole_title}</p>}
                    </div>
                    <div className={`col-xl-12 ${roleData.urole_permission==='*' ? 'd-none' : ''}`}>
                        <label htmlFor="note_desc" className="form-label">All Permission <span className="text-danger">*</span></label>

                        <div className="form-check form-check-lg form-switch">
                            <input className="form-check-input" type="checkbox" role="switch"
                            value={isSuperAdmin} onChange={(e) => handleSuperAdmin(e.target.value)}   />
                        </div>
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateRole}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};


export default UpdateUserRoleModal;