import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { removeProjectFile } from '../../../models/ProjectModel';



const DeleteProjectFileModal = ({showModal, setShowModal, project_unique_id, projectData, fileData, modalKey, fetchProjectFiles, limit, page, task_unique_id}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate();

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);
    
    const [_file_id, setFileId] = useState('');
    const [_project_id, setProjectId] = useState('');
    const [_task_id, setTaskId] = useState('');

    const closeModal = () =>{
        setFileId('');
        setProjectId('');
        setTaskId('');

        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (fileData) {
            //console.log(fileData);
            setFileId(fileData._file_id);
        }

        if (projectData) {
            setProjectId(projectData._project_id);
        }

        //setTaskId(fileData._task_id);
    }, [project_unique_id, fileData, projectData, modalKey]);


    const deleteProjectFile = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
          const response = await removeProjectFile(user_data.user_token, _file_id);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            fetchProjectFiles(limit, page);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete File</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this file ?</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteProjectFile}>Delete</button>
            </Modal.Footer>
        </Modal>
    );  

}; 


export default DeleteProjectFileModal;