export const attendanceStatus = (status) => {
    if (status==='0') {
        return <span className="badge bg-warning-transparent">Pending</span>;
    }
    else if(status==='1'){
        return <span className="badge bg-success-transparent">Verified</span>; 
    }
    else if(status==='2'){
        return <span className="badge bg-danger-transparent">Not Verified</span>; 
    }
};