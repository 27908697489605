import React, { useEffect,  useRef,  useState }  from 'react'
import { useAuth } from '../../context/AuthContext';

function LeaveReportList() { 

    const { state } = useAuth();

    let user_data = state.user_data;

    return (
        <table className="table text-nowrap placeholder-glow">
            <thead className="table-primary">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Team Member</th>
                    <th scope="col">Leave Allocated</th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                    <th scope="col"><span className="placeholder col-3"></span></th>
                </tr>
            </thead>
            <tbody>
                {[1,2,3,4,5,6,7,8].map((n) => (
                <tr>
                    <td><span className="placeholder col-1"></span></td>

                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>

                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    
                </tr>
                )) }

            </tbody>
        </table>
    );
}  

export default LeaveReportList