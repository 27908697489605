import React, { useEffect } from 'react';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { Link } from 'react-router-dom';
import MailLogList from '../skeletons/MailLogList';


const MailLogTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchLogs
}) => {

    const { theme } = useTheme();


    const CustomLoader = () => (
        <MailLogList/>
    );


    const handlePageChange = async (page) => {
        fetchLogs(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {

        if (newLimit!==10 && page!==1) {
            fetchLogs(newLimit, page); 
        }
        else if (localStorage.getItem('mail_logs')) {
            fetchLogs(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchLogs(limit, page);  
    }, []);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Subject",
            selector: (row, index) => row.logs_subject,
            width: '300px'
        },
        {
            name: "To",
            selector: (row, index) => row.logs_to,
            width: '300px'
        },
        {
            name: "Status",
            selector: (row) => 
            {
                if (row.log_mail_is_sent==='1') {
                    return (
                        <>
                          <span className="badge bg-success-transparent">Sent</span>
                        </>
                      );
                }
                else{
                    return (
                        <>
                          <span className="badge bg-danger-transparent">Not Sent</span>
                        </>
                      );
                }    
            }
        },
        {
            name: "Sent Time",
            selector: (row) => dateFormat(row.logs_create_time, "dd mmm yyyy HH:MM")
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <Link to={`/view-mail/${row.log_unique_id}`} target='_blank' className="btn btn-icon btn-sm btn-info" title="View Mail">
                                <i className='bi bi-eye'></i>
                            </Link>
                        </div>
        }
    ];

    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    return (
        <DataTable 
            title={`Manage Mail Logs`} 
            columns={columns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default MailLogTables;    