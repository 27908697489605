import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import JoditEditor from 'jodit-react';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { checkPermission } from '../../../helpers/GeneralHelper';
import Error403 from '../Error403';
import { useTheme } from '../../../context/ThemeContext';
import { useAuth } from '../../../context/AuthContext';
import { getFinancialYears } from '../../../models/MasterModel';
import FinYearTables from '../../tables/FinYearTables';
import AddFinYearModal from '../../modals/Settings/AddFinYearModal';
import UpdateFinYearModal from '../../modals/Settings/UpdateFinYearModal';


const CompanySettings = () => {

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  //Add FinYear Start
  const [showAddFinYearModal, setShowAddFinYearModal] = useState(false);
  //Add FinYear End


  //Update FinYear Start
  const [showUpdateFinYearModal, setShowUpdateFinYearModal] = useState(false);

  const [finYearData, setFinYearData] = useState({});

  const openUpdateFinYearModal = (fin_year_data) =>{
    setFinYearData(fin_year_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateFinYearModal(true);
  }  
  //Update FinYear End

  //Flush FinYear Start
  const flushFinYear = async() => {
    localStorage.removeItem("financial_years");
    fetchFinancialYears(); 
  }  
  //Flush FinYear End


  //FinYear List Start
  const [finYears, setFinYears] = useState([]);
  const [finYearcacheTime, setFinYearCacheTime] = useState([]);
  const [finYearLoading, setFinYearLoading] = useState(true);

  const fetchFinancialYears = async() => {
      try {
        const response = await getFinancialYears();

        if (typeof response != typeof undefined) {
            setFinYears(response.financial_years);

            setNotificationCount(localStorage.getItem('unread_notification_count'));

            if (response.time) {
              setFinYearCacheTime(response.time);
            }
        }
        else{
            navigate('/re-login');
        }        

        setFinYearLoading(false);

    } catch (error) {
      //console.error(error);
    }
  }  
  //FinYear List End

  return (
    <>
     {checkPermission('menu_settings')===true ? (
      <AdminLayout>
        
        <Helmet>
            <title>Company Settings - Worktracker Anciletech</title>
            
        </Helmet>

        <div className="main-content app-content">

            
            <div className="container-fluid">

              {/* <!-- Page Header --> */}
              <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                  <h1 className="page-title fw-semibold fs-18 mb-0">Company Settings</h1>
                  <div className="ms-md-1 ms-0">
                      <nav>
                          <ol className="breadcrumb mb-0">
                              <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                              <li className="breadcrumb-item active" aria-current="page">Settings</li>
                          </ol>
                      </nav>
                  </div>
              </div>
              {/* <!-- Page Header Close--> */}

              {/* <!-- Start::row-1 --> */}
              <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Last Synced On {finYearcacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                      <button className="btn btn-primary btn-sm"  onClick={() => setShowAddFinYearModal(true)}>
                                          <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Financial Year
                                      </button>

                                      <button className="btn btn-success-light btn-sm" onClick={() => flushFinYear()}>
                                          <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                      </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <FinYearTables 
                                          finYears={finYears}
                                          setFinYears={setFinYears}
                                          finYearLoading={finYearLoading}
                                          setFinYearLoading={setFinYearLoading}
                                          fetchFinancialYears={fetchFinancialYears}
                                          openUpdateFinYearModal={openUpdateFinYearModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
              </div>
              {/* <!--End::row-1 --> */}  

              {/* <!--Add FinYear Modal Start--> */}
              <AddFinYearModal
                showModal={showAddFinYearModal}
                setShowModal={setShowAddFinYearModal}
                fetchFinancialYears={fetchFinancialYears} />
              {/* <!--Add FinYear Modal End--> */}

              {/* <!--Update FinYear Modal Start--> */}
              <UpdateFinYearModal
                showModal={showUpdateFinYearModal}
                setShowModal={setShowUpdateFinYearModal}
                finYearData={finYearData}
                modalKey={modalKey}
                fetchFinancialYears={fetchFinancialYears} />
              {/* <!--Update FinYear Modal End--> */}
            </div>


        </div>  

        <Helmet>
            
        </Helmet>
    
      </AdminLayout>
      ) : ( <Error403/>)}
    </>
  )
}

export default CompanySettings