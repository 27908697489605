import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { getClients } from '../../../models/ClientModel';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input-field';
import { getUsers } from '../../../models/UserModel';
import dateFormat from "dateformat";
import { createProject, saveProjectsToStorage } from '../../../models/ProjectModel';
import { useTheme } from '../../../context/ThemeContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const AddProjectModal = ({showModal, setShowModal, fetchProjects, limit, page, openCreateInvoice, setShowAddInvoiceModal}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const { state } = useAuth(); 

    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false, 
            height: 300,
            placeholder: 'Enter description...'
        }),
        []
    );

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [project_title, setProjectTitle] = useState('');
    const [project_description, setProjectDescription] = useState('');
    const [project_start_date, setProjectDate] = useState('');
    const [project_deadline, setProjectDeadline] = useState('');
    const [project_price, setProjectPrice] = useState('');
    const [_client_id, setClientID] = useState('');
    const [project_priority, setProjectPriority] = useState('');
    const [_user_ids, setUserIds] = useState([]);

    const [project_logo, setProjectLogo] = useState('');
    
    const inputFileRef = useRef(null);
    const handleImageClick = () => {
        inputFileRef.current.click();
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        //console.log(file);

        setProjectLogo(file);

        //console.log(URL.createObjectURL(file));
    }

    const [errors, setErrors] = useState({project_title: '', project_description: '', project_start_date: '', project_deadline: '',
    project_price: '', project_status: '', _client_id: '', _user_ids: '', project_priority: '', project_logo: ''});

    const closeModal = () =>{
        setProjectTitle('');
        setProjectDescription('');
        setProjectDate('');
        setProjectDeadline('');
        setProjectPrice('');
        setClientID('');
        setUserIds([]);
        setProjectPriority('');
        setProjectLogo('');
        setErrors({});
    
        setShowModal(false);
    }


    const [clients, setClients] = useState([]);
    const fetchClients = async () => {
        try {
          const result = await getClients({user_token: user_data.user_token, page:1, limit:200});
          
          //console.log(result);

          const clientOptions = result.clients.map(client => ({
            label: client.client_name,
            value: client._client_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));


          setClients(clientOptions);
        } catch (error) {
          console.error(error);
        }
    };

    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

          //console.log(result);

          const userOptions = result.users.map(user => ({
            label: user.user_first_name+' '+user.user_last_name,
            value: user._user_id
          }));
          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setUsers(userOptions);
        } catch (error) {
          console.error(error);
        }
    };

    const [priorities, setPriorities] = useState([]);

    useEffect(() => {
        fetchClients();
        fetchUsers();

        const priorityOptions = 
        [
            {label: 'Low', value: 'low'},
            {label: 'Medium', value: 'medium'},
            {label: 'High', value: 'high'}
        ];

        setPriorities(priorityOptions);

    }, []); 

    const addProject = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
          setErrors({});

          const selected_user_ids = _user_ids.map(_user_id => _user_id.value).join(',');
    
          const formData = { project_title, project_description, project_start_date: project_start_date ? dateFormat(project_start_date, "dd-mm-yyyy") : '', 
          project_deadline: project_deadline ? dateFormat(project_deadline, "dd-mm-yyyy") : '', project_price, _client_id: _client_id.value ? _client_id.value : '', 
          _user_ids: selected_user_ids, project_priority:  project_priority.value ? project_priority.value : ''};

          const response = await createProject(user_data.user_token, formData);

          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveProjectsToStorage(response.data.projects, response.data.pagination);
    
            fetchProjects(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));

            if (openCreateInvoice===true) {
                setShowAddInvoiceModal(true);                
            }
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }
            
        } catch (error) {
            apiError(error);
            
            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 
    
    
    return (
        <Modal show={showModal} onHide={closeModal} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Add Project</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-9">
                        <label htmlFor="task-name" className="form-label">Project Title <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Project Title"
                        value={project_title} onChange={(e) => setProjectTitle(e.target.value)} />

                        {errors.project_title && <p style={{ color: 'red' }}>{errors.project_title}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label htmlFor="task-id" className="form-label">Client <span className="text-danger">*</span></label>
                        
                        <Select
                            value={_client_id}
                            onChange={setClientID}
                            options={clients}
                            placeholder="- Client -"
                            isSearchable="true"
                            noOptionsMessage={() => "No client found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })} />

                        {errors._client_id && <p style={{ color: 'red' }}>{errors._client_id}</p>}    
                    </div>

                    <div className="col-xl-9">
                        <label className="form-label">Description <span className="text-danger">*</span></label>

                        <JoditEditor
                            ref={editor}
                            value={project_description}
                            config={config}
                            onChange={newContent => setProjectDescription(newContent)}/>

                        {errors.project_description && <p style={{ color: 'red' }}>{errors.project_description}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">Logo </label><br/>
                            <div className='d-flex justify-content-end' >
                                <button type='button' className='btn-close' title='Remove Logo' style={{display: project_logo!=='' ? 'block' : 'none'}}
                                onClick={(e) => setProjectLogo('')}></button>
                            </div>
                        
                            <div onClick={handleImageClick} style={{cursor: 'pointer'}}>
                                <img className='card-img-top' 
                                src={project_logo ? URL.createObjectURL(project_logo) : `${process.env.REACT_APP_BASE_URL}/assets/images/upload-icon.png`}/>
                                <input className='d-none' type='file' ref={inputFileRef} onChange={handleImageChange} 
                                accept="image/png, image/jpeg, image/jpg" />
                            </div>
                            
                            {errors.project_logo && <p style={{ color: 'red' }}>{errors.project_logo}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Start Date <span className="text-danger">*</span></label><br/>
                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                            selected={project_start_date} onSelect={setProjectDate} onChange={setProjectDate} dateFormat="dd-MM-yyyy"/>

                        {errors.project_start_date && <p style={{ color: 'red' }}>{errors.project_start_date}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Deadline <span className="text-danger">*</span></label><br/>
                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY"
                            selected={project_deadline} onSelect={setProjectDeadline} onChange={setProjectDeadline} dateFormat="dd-MM-yyyy"/>

                        {errors.project_deadline && <p style={{ color: 'red' }}>{errors.project_deadline}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label htmlFor="task-name" className="form-label">Price <span className="text-danger">*</span></label><br/>

                        <CurrencyInput
                            className="form-control"
                            placeholder="Enter Price"
                            prefix="₹"
                            defaultValue={project_price}
                            decimalsLimit={2}
                            allowNegativeValue="false"
                            onValueChange={(value, name, values) => setProjectPrice(value)}
                        />

                        {errors.project_price && <p style={{ color: 'red' }}>{errors.project_price}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Priority <span className="text-danger">*</span></label>
                        <Select
                            value={project_priority}
                            onChange={setProjectPriority}
                            options={priorities}
                            placeholder="- Priority -"
                            isSearchable="true"
                            noOptionsMessage={() => "No priority found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })} />

                        {errors.project_priority && <p style={{ color: 'red' }}>{errors.project_priority}</p>}  
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Assign Members <span className="text-danger">*</span></label>
                        <Select
                            value={_user_ids}
                            onChange={setUserIds}
                            options={users}
                            placeholder="- Members -"
                            isSearchable="true"
                            isMulti="true"
                            noOptionsMessage={() => "No member found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })} />

                        {errors._user_ids && <p style={{ color: 'red' }}>{errors._user_ids}</p>}  
                    </div>
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={addProject}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};

export default AddProjectModal;    