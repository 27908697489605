import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { createLeaveApplication, saveLeaveApplicationToStorage } from '../../../models/LeaveModel';
import { getUsers } from '../../../models/UserModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const ApplyLeaveModal = ({showModal, setShowModal, fetchApplications, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [user_unique_id, setUserUniqueId] = useState('');
    const [leave_type, setLeaveType] = useState('');
    const [leave_reason, setLeaveReason] = useState('');
    const [leave_duration, setLeaveDuration] = useState('');
    const [leave_half_day, setLeaveHalfDay] = useState('');
    // const [leave_start_date, setLeaveStartDate] = useState('');
    // const [leave_end_date, setLeaveEndDate] = useState('');
    const [leave_date, setLeaveDate] = useState('');

    const [dateRange, setDateRange] = useState([null, null]);
    const [leave_start_date, leave_end_date] = dateRange;

    const [errors, setErrors] = useState({user_unique_id:  '',  leave_type: '', leave_reason: '', leave_duration: '', leave_half_day: '',
    leave_start_date: '', leave_end_date: '', leave_date: ''});

    const closeModal = () =>{
        setUserUniqueId('');
        setLeaveType('');
        setLeaveReason('');
        setLeaveDuration('');
        setLeaveHalfDay('');
        // setLeaveStartDate('');
        // setLeaveEndDate('');
        setLeaveDate('');

        setErrors({});
    
        setShowModal(false);
    }



    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page: 1, limit: 50});
          setUsers(result.users);

          setNotificationCount(localStorage.getItem('unread_notification_count'));
        } catch (error) {
          //console.error(error);
        }
    };

    const handleLeaveDuration = (leave_duration) => {

        setLeaveDuration(leave_duration);

        if (leave_duration=='half') {
            setDateRange([null, null]);
        }
        else if (leave_duration=='single') {
            setLeaveHalfDay('');
            setDateRange([null, null]);
        }
        else if (leave_duration=='multiple') {
            setLeaveHalfDay('');
            setLeaveDate('');
        }
    }

    useEffect(() => {
        fetchUsers();

        if (user_data.urole_permission!='*') {
            setUserUniqueId(user_data.user_unique_id);
        }
        else{
            setUserUniqueId('');
        }
    }, []); 


    const applyLeave = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = {user_unique_id,  leave_type, leave_reason, leave_duration, leave_half_day,
          leave_start_date: leave_start_date ? dateFormat(leave_start_date, "dd-mm-yyyy") : '', 
          leave_end_date: leave_end_date ? dateFormat(leave_end_date, "dd-mm-yyyy") : '', 
          leave_date: leave_date ? dateFormat(leave_date, "dd-mm-yyyy") : ''};
    
          const response = await createLeaveApplication(user_data.user_token, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveLeaveApplicationToStorage(response.data.applications, response.data.pagination);
    
            fetchApplications(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Apply Leave</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-4" style={{display: user_data.urole_permission=='*' ? 'block' : 'none'}}>
                        <label htmlFor="client_pincode" className="form-label">Member <span className="text-danger">*</span></label>
                        <select className="form-control selectpicker" onChange={(e) => setUserUniqueId(e.target.value)}>
                            <option value=''>- Member -</option>
                            {users.map((user) => (
                                <option  value={user.user_unique_id} selected={user_unique_id === user.user_unique_id}>
                                    {user.user_first_name} {user.user_last_name}
                                </option>
                            ))}
                        </select>

                        {errors.user_unique_id && <p style={{ color: 'red' }}>{errors.user_unique_id}</p>}
                    </div> 


                    <div className={`col-xl-${user_data.urole_permission=='*' ? '4' : '6'}`}>
                        <label htmlFor="client_pincode" className="form-label">Leave Type <span className="text-danger">*</span></label>
                        <select className="form-control selectpicker" onChange={(e) => setLeaveType(e.target.value)}>
                            <option value='' hidden>- Type -</option>
                            <option value='casual' selected={leave_type === 'casual' ? true : false}>Casual</option>
                            <option value='planned' selected={leave_type === 'planned' ? true : false}>Planned</option>
                        </select>

                        {errors.leave_type && <p style={{ color: 'red' }}>{errors.leave_type}</p>}
                    </div>   

                    <div className={`col-xl-${user_data.urole_permission=='*' ? '4' : '6'}`}>
                        <label className="form-label">Duration <span className="text-danger">*</span></label>
                        
                        <br/>

                        <label className="radio-inline">
                        <input type="radio" value="half" checked={leave_duration=='half' ? 'checked' : ''}
                            onChange={(e) => handleLeaveDuration(e.target.value)}/>&nbsp;Half&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" value="single" checked={leave_duration=='single' ? 'checked' : ''}
                            onChange={(e) => handleLeaveDuration(e.target.value)} />&nbsp;Single&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" value="multiple" checked={leave_duration=='multiple' ? 'checked' : ''}
                            onChange={(e) => handleLeaveDuration(e.target.value)} />&nbsp;Multiple
                        </label>

                        {errors.leave_duration && <p style={{ color: 'red' }}>{errors.leave_duration}</p>}
                    </div>
                    

                    <div className="col-xl-6" style={{display: leave_duration=='half' ? 'block' : 'none'}}>
                        <label className="form-label">Which Half ? <span className="text-danger">*</span></label>
                        <br/>

                        <label className="radio-inline">
                        <input type="radio" value="1" checked={leave_half_day=='1' ? 'checked' : ''}
                            onChange={(e) => setLeaveHalfDay(e.target.value)}/>&nbsp;1st Half&nbsp;&nbsp;
                        </label>

                        <label className="radio-inline">
                        <input type="radio" value="2" checked={leave_half_day=='2' ? 'checked' : ''}
                            onChange={(e) => setLeaveHalfDay(e.target.value)} />&nbsp;2nd Half&nbsp;&nbsp;
                        </label>

                        {errors.leave_half_day && <p style={{ color: 'red' }}>{errors.leave_half_day}</p>}
                    </div>

                    <div className="col-xl-6" style={{display: leave_duration=='half' || leave_duration=='single' ? 'block' : 'none'}}>
                        <label className="form-label">Date <span className="text-danger">*</span></label><br/>

                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" 
                            selected={leave_date} onSelect={setLeaveDate} onChange={setLeaveDate} dateFormat="dd-MM-yyyy"/>

                        {errors.leave_date && <p style={{ color: 'red' }}>{errors.leave_date}</p>}
                    </div>

                    <div className="col-xl-12" style={{display: leave_duration=='multiple' ? 'block' : 'none'}}>
                        <label className="form-label">Start Date - End Date <span className="text-danger">*</span></label><br/>

                        <DatePicker
                            className="form-control"
                            selectsRange={true}
                            startDate={leave_start_date}
                            endDate={leave_end_date}
                            onChange={(update) => {setDateRange(update);}}
                            isClearable={true}
                            dateFormat="dd-MM-yyyy"/>

                        {errors.leave_start_date && <p style={{ color: 'red' }}>{errors.leave_start_date}</p>}
                        {errors.leave_end_date && <p style={{ color: 'red' }}>{errors.leave_end_date}</p>}
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Reason <span className="text-danger">*</span></label> 

                        <textarea className="form-control" placeholder="Reason" rows="3" value={leave_reason}
                        onChange={(e) => setLeaveReason(e.target.value)}></textarea>

                        {errors.leave_reason && <p style={{ color: 'red' }}>{errors.leave_reason}</p>}      
                    </div>

                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={applyLeave}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Submit'
                )}
                </button>
            </Modal.Footer>
        </Modal>
    );

};


export default ApplyLeaveModal;