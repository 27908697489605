import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { getNotes } from '../../models/NoteModel';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { profilePic } from '../../helpers/GeneralHelper';
import { leaveApplicationStatus, leaveDates } from '../../helpers/LeaveHelper';
import LeaveApplicationList from '../skeletons/LeaveApplicationList';
import { useFinancialYear } from '../../context/FinancialYearContext';

const LeaveApplicationTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchApplications,
    user_unique_id,
    openViewApplicationModal
}) => {

    const { financialYear } = useFinancialYear();

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;

    const CustomLoader = () => (
        <LeaveApplicationList/>
    );


    const handlePageChange = async (page) => {
        fetchApplications(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchApplications(newLimit, page); 
        }
        else if (localStorage.getItem('leave_applications')) {
            fetchApplications(newLimit, page); 
        }
    };

    useEffect(()=>{
        fetchApplications(limit, page);  
    }, [user_unique_id, financialYear]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.leave_user_first_name, row.leave_user_last_name, row.leave_user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.leave_user_first_name} {row.leave_user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.leave_user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Leave Type",
            selector: (row) =>  row.leave_type.toUpperCase() 
        },
        {
            name: "Date",
            selector: (row) => leaveDates(row) 
        },
        
        {
            name: "Days (s)",
            selector: (row) => row.leave_no_of_days 
        },
        {
            name: "Status",
            selector: (row) =>  leaveApplicationStatus(row.leave_status) 
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="View" onClick={() => openViewApplicationModal(row)}>
                                <i className='bi bi-eye'></i>
                            </button>
                        </div>
        }
    ];

    // Conditionally unset a specific column
    const filteredColumns = user_data.urole_permission!=='*'
    ? columns.filter(column => column.name !== "Team Member")
    : columns;


    createTheme('dark', {
        text: {
          primary: '#a2a3a4',
          secondary: '#2aa198',
        },
        background: {
          default: '#252729',
        },
        context: {
          background: '#cb4b16',
          text: '#a2a3a4',
        },
        divider: {
          default: '#073642',
        },
        action: {
          button: 'rgba(0,0,0,.54)',
          hover: 'rgba(0,0,0,.08)',
          disabled: 'rgba(0,0,0,.12)',
        },
    });


    return (
        <DataTable 
            title={`Manage Leave Applications`} 
            columns={filteredColumns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );
};    

export default LeaveApplicationTables;    