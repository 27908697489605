import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editAttendance, saveAttendanceToStorage } from '../../../models/AttendanceModel';
import { utcToLocal } from '../../../helpers/DateHelper';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const ViewAttendanceModal = ({showModal, setShowModal, attendanceData, modalKey, fetchAttendances, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [_attendance_id, setAttendanceID] = useState('');
    const [_user_id, setUserID] = useState('');
    const [user_first_name, setUserFirstName] = useState('');
    const [user_last_name, setUserLastName] = useState('');
    const [attendance_type, setAttendanceType] = useState('');
    const [attendance_date, setAttendanceDate] = useState('');
    const [attendance_in_time, setAttendanceInTime] = useState('');
    const [attendance_in_selfie, setAttendanceInSelfie] = useState('');
    const [attendance_in_ip_address, setAttendanceInIpAddress] = useState('');
    const [attendance_in_user_agent, setAttendanceInUserAgent] = useState('');
    const [in_lat, setInLat] = useState('');
    const [in_lon, setInLon] = useState('');
    const [attendance_out_time, setAttendanceOutTime] = useState('');
    const [attendance_out_selfie, setAttendanceOutSelfie] = useState('');
    const [attendance_out_ip_address, setAttendanceOutIpAddress] = useState('');
    const [attendance_out_user_agent, setAttendanceOutUserAgent] = useState('');
    const [out_lat, setOutLat] = useState('');
    const [out_lon, setOutLon] = useState('');
    const [attendance_login_hours, setAttendanceLoginHours] = useState('');
    const [attendance_logout_from, setAttendanceLogoutFrom] = useState('');
    const [attendance_is_verified, setAttendanceIsVerified] = useState('');

    const [attendance_in_address, setAttendanceInAddress] = useState('');
    const [attendance_out_address, setAttendanceOutAddress] = useState('');


    const [errors, setErrors] = useState({leave_status: ''});

    const closeModal = () =>{
        setAttendanceID('');
        setUserID('');
        setUserFirstName('');
        setUserLastName('');
        setAttendanceType('');
        setAttendanceDate('');
        setAttendanceInTime('');
        setAttendanceInSelfie('');
        setAttendanceInIpAddress('');
        setAttendanceInUserAgent('');
        setInLat('');
        setInLon('');
        setAttendanceOutTime('');
        setAttendanceOutSelfie('');
        setAttendanceOutIpAddress('');
        setAttendanceOutUserAgent('');
        setOutLat('');
        setOutLon('');
        setAttendanceLoginHours('');
        setAttendanceLogoutFrom('');
        setAttendanceIsVerified('');

        setAttendanceInAddress('');
        setAttendanceOutAddress('');

        setErrors({});
    
        setShowModal(false);
    }


    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (attendanceData) {
            setAttendanceID(attendanceData._attendance_id);
            setUserID(attendanceData._atten_user_id);
            setUserFirstName(attendanceData.atten_user_first_name);
            setUserLastName(attendanceData.atten_user_last_name);
            setAttendanceType(attendanceData.attendance_type);
            setAttendanceDate(attendanceData.attendance_date);
            setAttendanceInTime(attendanceData.attendance_in_time);
            setAttendanceInSelfie(attendanceData.attendance_in_selfie);
            setAttendanceInIpAddress(attendanceData.attendance_in_ip_address);
            setAttendanceInUserAgent(attendanceData.attendance_in_user_agent);
            setInLat(attendanceData.in_lat);
            setInLon(attendanceData.in_lon);
            setAttendanceOutTime(attendanceData.attendance_out_time);
            setAttendanceOutSelfie(attendanceData.attendance_out_selfie);
            setAttendanceOutIpAddress(attendanceData.attendance_out_ip_address);
            setAttendanceOutUserAgent(attendanceData.attendance_out_user_agent);
            setOutLat(attendanceData.out_lat);
            setOutLon(attendanceData.out_lon);
            setAttendanceLoginHours(attendanceData.attendance_login_hours);
            setAttendanceLogoutFrom(attendanceData.attendance_logout_from);
            setAttendanceIsVerified(attendanceData.attendance_is_verified);

            setAttendanceInAddress(attendanceData.attendance_in_address);
            setAttendanceOutAddress(attendanceData.attendance_out_address);
          
          setErrors({});
        }
    }, [attendanceData, modalKey]);


    const updateAttendance = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { attendance_is_verified: attendance_is_verified!==0 ? attendance_is_verified : ''  };
    
          const response = await editAttendance(user_data.user_token, _attendance_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveAttendanceToStorage(response.data.attendances, response.data.pagination);
    
            fetchAttendances(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Attendance Details</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    
                    <div className="col-xl-6" style={{display: user_data.urole_permission=='*' ? 'block' : 'none'}}>
                        <label htmlFor="client_pincode" className="form-label">Member </label>
                        <div className='form-control'>{user_first_name} {user_last_name}</div>
                    </div>

                    <div className="col-xl-6">
                        <label className="form-label">Date </label>
                        <div className='form-control'>{dateFormat(attendance_date, "dd mmm yyyy")}</div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">In Time </label>
                        <div className='form-control'>{utcToLocal(attendance_in_time, "HH:MM:ss")}</div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Out Time </label>
                        <div className='form-control'>{attendance_out_time ? utcToLocal(attendance_out_time, "HH:MM:ss") : '--:--:--'}</div>
                    </div> 

                    <div className="col-xl-6">
                        <label  className="form-label">Login Coordinates </label>
                        <div className='form-control'>
                            <a href={`https://maps.google.com/?q=${in_lat},${in_lon}`} target='_blank'>
                                {in_lat} ,{in_lon}
                            </a>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Logout Coordinates </label>
                        <div className='form-control'>
                            {out_lat && out_lon ? (
                            <a href={`https://maps.google.com/?q=${out_lat},${out_lon}`} target='_blank'>
                                {out_lat} ,{out_lon}
                            </a>
                            ) : ('--')}
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Login Address </label>
                        <div className='form-control' style={{height: '105px'}}>
                            {attendance_in_address}
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Logout Address </label>
                        <div className='form-control' style={{height: '105px'}}>
                            {attendance_out_address}
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Login IP Address </label>
                        <div className='form-control'>{attendance_in_ip_address}</div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Logout IP Address </label>
                        <div className='form-control'>{attendance_out_ip_address ? attendance_out_ip_address : '--'}</div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Login Selfie </label><br/>
                        <span class="avatar avatar-xl me-3">
                            <img src={process.env.REACT_APP_S3_URL+'/'+attendance_in_selfie} class="img-fluid" alt="..." />
                        </span>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Logout Selfie </label><br/>
                        
                            {attendance_out_selfie ? (
                                <span class="avatar avatar-xl me-3">
                                    <img src={process.env.REACT_APP_S3_URL+'/'+attendance_out_selfie} class="img-fluid" alt="..." />
                                </span>
                            ) : ('--')}
                        
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Login User Agent </label>
                        <div className="form-control" style={{minHeight: '100px', overflowY: 'auto'}}>{attendance_in_user_agent}</div>
                    </div>

                    <div className="col-xl-6">
                        <label  className="form-label">Logout User Agent </label>
                        <div className="form-control" style={{minHeight: '100px', overflowY: 'auto'}}>{attendance_out_user_agent}</div>
                    </div>

                    <div className="col-xl-4" style={{display: attendanceData.attendance_out_time ? 'block': 'none'}}>
                        <label className="form-label">Status <span className="text-danger">*</span></label>
                        <select className="form-control selectpicker" onChange={(e) => setAttendanceIsVerified(e.target.value)} disabled={attendanceData.attendance_is_verified !== '0' ? true : false}>
                            <option value='' hidden>- Status -</option>
                            <option value='1' selected={attendance_is_verified === '1' ? true : false} >Verified</option>
                            <option value='2' selected={attendance_is_verified === '2' ? true : false} >Not Verified</option>
                        </select>

                        {errors.attendance_is_verified && <p style={{ color: 'red' }}>{errors.attendance_is_verified}</p>}
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>

                {attendanceData.attendance_is_verified === '0' && attendanceData.attendance_out_time ? (
                <button type="button" className={`btn btn-primary ${user_data.urole_permission==='*' ? '' : 'd-none'}`} onClick={updateAttendance}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
                ):('')}
            </Modal.Footer>
        </Modal>
    );

};    


export default ViewAttendanceModal;