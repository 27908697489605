import { GET, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";


export const saveWsLogsToStorage = (ws_logs, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        ws_logs: ws_logs,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("ws_logs", encryptData(dataToStore));
};




export const getWsLogs = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('ws_logs')) {
        const saved_ws_logs_obj = decryptData(localStorage.getItem('ws_logs'));

        return saved_ws_logs_obj;
    }
    else{
        try {
            const endPoint = '/logs/ws-logs';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveWsLogsToStorage(response.data.ws_logs, response.data.pagination);

                    const saved_ws_logs_obj = decryptData(localStorage.getItem('ws_logs'));

                    return saved_ws_logs_obj;
                }
                else{
                    //localStorage.removeItem("ws_logs");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};


export const truncateWsLogs = async(user_token) => {

    const endPoint = `/logs/delete-all-ws-logs`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
};   


export const saveMailLogsToStorage = (mail_logs, pagination) => {

    let currentTime = dateFormat('', "dd mmm yyyy HH:MM:ss");

    const dataToStore = {
        mail_logs: mail_logs,
        pagination: pagination,
        time:currentTime
    };

    localStorage.setItem("mail_logs", encryptData(dataToStore));
};



export const getMailLogs = async(param = {}) => {

    let user_token = param.user_token;

    if (!user_token) {
        return [];
    }

    let page  = param.page ? param.page : 1;
    let limit = param.limit ? param.limit : 10;

    if (page==1 && limit==10 && localStorage.getItem('mail_logs')) {
        const saved_mail_logs_obj = decryptData(localStorage.getItem('mail_logs'));

        return saved_mail_logs_obj;
    }
    else{
        try {
            const endPoint = '/logs/mail-logs';
            const url = `${endPoint}?page=${page}&limit=${limit}`;
    
            setAuthToken(user_token);
            
            const response = await GET(url);
    
            if (response.status) {
                if (page==1 && limit==10) {
                    saveMailLogsToStorage(response.data.mail_logs, response.data.pagination);

                    const saved_mail_logs_obj = decryptData(localStorage.getItem('mail_logs'));

                    return saved_mail_logs_obj;
                }
                else{
                    //localStorage.removeItem("mail_logs");
                    return response.data;
                }
            }
            else{
                toast.error(response.message);
            }    
            
        } catch (error) {
            apiError(error);
        }
    }
};



export const truncateMailLogs = async(user_token) => {

    const endPoint = `/logs/delete-all-mail-logs`;

    setAuthToken(user_token);

    const response = await GET(endPoint);

    return response;
}; 