import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editInvoiceItem, saveInvoicesToStorage } from '../../../models/InvoiceModel';
import CurrencyInput from 'react-currency-input-field';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const UpdateItemModal = ({showModal, setShowModal, modalKey, invoiceData, itemData, setInvoiceData, setShippingAddress, setInvoiceItems, setCompanySettings}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate();

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);
    
    const [invoice_unique_id, setInvoiceUniqueId] = useState('');
    const [_item_id, setItemID] = useState('');
    const [item_title, setItemTitle] = useState('');
    const [item_description, setItemDescription] = useState('');
    const [item_sac, setItemSac] = useState('');
    const [item_amount, setItemAmount] = useState('');
    const [item_tax, setItemTax] = useState('');
    
    const [errors, setErrors] = useState({item_title:  '',  item_description: '', item_sac: '', item_amount: '', item_tax: ''});

    
    const closeModal = () =>{
        setInvoiceUniqueId('');
        setItemID('');
        setItemTitle('');
        setItemDescription('');
        setItemSac('');
        setItemAmount('');
        setItemTax('');
        setErrors({});

        setShowModal(false);
    }

    useEffect(() => {
        //console.log(itemData);
        // This effect will be called whenever noteData changes
        if (invoiceData && itemData) {
            setInvoiceUniqueId(invoiceData.invoice_unique_id);
            setItemID(itemData._item_id);
            setItemTitle(itemData.item_title);
            setItemDescription(itemData.item_description);
            setItemSac(itemData.item_sac);
            setItemAmount(itemData.item_amount);
            setItemTax(itemData.item_tax);
            setErrors({});
        }
      }, [invoiceData, itemData, modalKey]);


    const updateItem = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { item_title, item_description, item_sac, item_amount, item_tax };
    
          const response = await editInvoiceItem(user_data.user_token, invoice_unique_id, _item_id, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveInvoicesToStorage(response.data.invoices, response.data.pagination);
    
            setInvoiceData(response.data.invoice_data);
            setShippingAddress(response.data.invoice_shipping_address);
            setInvoiceItems(response.data.invoice_items);
            setCompanySettings(response.data.company_settings);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    } 


    return (
        <Modal show={showModal} onHide={closeModal} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Add Item</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-9">
                        <label className="form-label">Item <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Item Name" 
                        value={item_title} onChange={(e) => setItemTitle(e.target.value)}/>
    
                        
                        {errors.item_title && <p style={{ color: 'red' }}>{errors.item_title}</p>}
                    </div>
    
                    <div className="col-xl-3">
                        <label className="form-label">SAC </label>
                        <input type="text" className="form-control" placeholder="Services Accounting Code" 
                        value={item_sac} onChange={(e) => setItemSac(e.target.value)}/>
    
                        
                        {errors.item_sac && <p style={{ color: 'red' }}>{errors.item_sac}</p>}
                    </div>
    
                    <div className="col-xl-6">
                        <label className="form-label">GST </label>
                        <input type="text" className="form-control" placeholder="GST" 
                        value={item_tax} disabled="true"/>
    
                        
                        {errors.item_tax && <p style={{ color: 'red' }}>{errors.item_tax}</p>}
                    </div>
    
                    <div className="col-xl-6">
                        <label className="form-label">Amount <span className="text-danger">*</span></label>
                        <CurrencyInput
                            className="form-control"
                            placeholder="Enter Amount"
                            prefix={invoiceData.invoice_type==='domestic' ? '₹ ' : '$ '}
                            value={item_amount}
                            decimalsLimit={2}
                            allowNegativeValue="false"
                            onValueChange={(value, name, values) => setItemAmount(value)}
                        />
    
                        
                        {errors.item_amount && <p style={{ color: 'red' }}>{errors.item_amount}</p>}
                    </div>
    
                    <div className="col-xl-12">
                        <label className="form-label">Description </label>
    
                        <textarea className="form-control" rows="3" value={item_description} onChange={(e) => setItemDescription(e.target.value)}></textarea>
    
                        {errors.note_desc && <p style={{ color: 'red' }}>{errors.note_desc}</p>}
                    </div>
                    
                </div>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateItem}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>
      );
};


export default UpdateItemModal;