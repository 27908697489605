import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNote, saveNotesToStorage } from '../../../models/NoteModel';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import JoditEditor from 'jodit-react';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { useTheme } from '../../../context/ThemeContext';

const AddNoteModal = ({showModal, setShowModal, fetchNotes, limit, page}) => {

  const { theme, toggleTheme, selectBoxTheme } = useTheme();

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const navigate = useNavigate(); 

    const { state } = useAuth(); 

    const editor = useRef(null);

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    
    const [loading, setLoading] = useState(false);
    
    const [note_title, setNoteTitle] = useState('');
    const [note_desc, setNoteDesc] = useState('');

    const config = useMemo(
      () => ({
          readonly: false, 
          height: 300
      }),
      []
    );
    
    const [errors, setErrors] = useState({note_title:  '',  note_desc: '',});
    

    const closeModal = () =>{
        setNoteTitle('');
        setNoteDesc('');
        setErrors({});
    
        setShowModal(false);
    }
    
    
    const addNote = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);
    
        try {
          // Clear previous errors
          setErrors({});
    
          const formData = { note_title, note_desc };
    
          const response = await createNote(user_data.user_token, formData);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveNotesToStorage(response.data.notes, response.data.pagination);
    
            fetchNotes(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            setErrors(response.error);
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }   
  

  return (
    <Modal show={showModal} onHide={closeModal} size='xl'>
        <Modal.Header closeButton>
            <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row gy-2">
                <div className="col-xl-12">
                    <label htmlFor="note_title" className="form-label">Title <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" id="note_title" placeholder="Note Title" 
                    value={note_title} onChange={(e) => setNoteTitle(e.target.value)}/>

                    
                    {errors.note_title && <p style={{ color: 'red' }}>{errors.note_title}</p>}
                </div>
                <div className="col-xl-12">
                    <label htmlFor="note_desc" className="form-label">Description <span className="text-danger">*</span></label>

                    <JoditEditor
                        ref={editor}
                        value={note_desc}
                        config={config}
                        onChange={newContent => setNoteDesc(newContent)}
                        
                        />

                    {errors.note_desc && <p style={{ color: 'red' }}>{errors.note_desc}</p>}
                </div>
                
            </div>
        </Modal.Body>

        <Modal.Footer>
            <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={addNote}>
            {loading ? (
                    <>
                    <span className="spinner-border spinner-border-sm align-middle"></span>
                    Loading...
                    </>
                ) : (     
                    'Submit'
            )}
            </button>
        </Modal.Footer>
    </Modal>
  );
};

export default AddNoteModal;
