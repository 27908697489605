import React, { useEffect, useRef, useState } from 'react';
import { useNavigate , useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { getInvoiceDetails } from '../../models/InvoiceModel';
import InvoiceDetails from '../skeletons/InvoiceDetails';
import { invoiceAmount } from '../../helpers/InvoiceHelper';
import AddItemModal from '../modals/Invoice/AddItemModal';
import UpdateItemModal from '../modals/Invoice/UpdateItemModal';
import DeleteItemModal from '../modals/Invoice/DeleteItemModal';
import UpdateShippingAddressModal from '../modals/Invoice/UpdateShippingAddressModal';
import DeleteShippingAddressModal from '../modals/Invoice/DeleteShippingAddressModal';
import { useNotificationCount } from '../../context/NotificationCountContext';
import { decodeHtmlEntities, paymentModeLabels } from '../../helpers/GeneralHelper';
import { getPayments } from '../../models/PaymentModel';
import AddPaymentModal from '../modals/Invoice/AddPaymentModal';
import DeletePaymentModal from '../modals/Invoice/DeletePaymentModal';
import UpdatePaymentModal from '../modals/Invoice/UpdatePaymentModal';
import SendInvoiceModal from '../modals/Invoice/SendInvoiceModal';


function EditInvoice() {

  const { notificationCount, setNotificationCount } = useNotificationCount();  

  const { theme, toggleTheme } = useTheme();  

  const { state } = useAuth(); 

  let user_data = state.user_data;
  
  const navigate = useNavigate();  
  
  const loader = document.getElementById('loading'); 

  const [modalKey, setModalKey] = useState(0);

  //Update Shipping Address Start
  const [showUpdateShippingAddressModal, setShowUpdateShippingAddressModal] = useState(false);

  const openUpdateShippingAddressModal = () =>{
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateShippingAddressModal(true);
  } 
  //Update Shipping Address End


  //Delete Shipping Address Start
  const [showDeleteShippingAddressModal, setShowDeleteShippingAddressModal] = useState(false);

  const openDeleteShippingAddressModal = () =>{
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteShippingAddressModal(true);
  } 
  //Delete Shipping Address End


  //Add Item Start
  const [showAddItemModal, setShowAddItemModal] = useState(false);

  const openAddItemModal = () =>{
    setModalKey(prevKey => prevKey + 1); 
    setShowAddItemModal(true);
  } 
  //Add Item End


  //Update Item Start
  const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);

  const [itemData, setItemData] = useState({});

  const openUpdateItemModal = (item_data) =>{
    setItemData(item_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateItemModal(true);
  } 
  //Update Item End


  //Delete Item Start
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);

  const openDeleteItemModal = (item_data) =>{
    setItemData(item_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteItemModal(true);
  } 
  //Delete Item End

  const downloadInvoice = () =>{
    const fileUrl = process.env.REACT_APP_ASSETS_URL+'/pdf/invoice/'+invoice_unique_id+'?download=1';
    window.location.href = fileUrl; // Initiates the file download
  }


  // Get Invoice Details Start
  let { invoice_unique_id } = useParams();

  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [shippingAddress, setShippingAddress] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [companySettings, setCompanySettings] = useState({});

  const fetchInvoiceDetails = async (invoice_unique_id) => {
    setInvoiceLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token, invoice_unique_id};
        const response = await getInvoiceDetails(apiParam);

        if (response.status===true) {
            setInvoiceData(response.data.invoice_data);
            setShippingAddress(response.data.invoice_shipping_address);
            setInvoiceItems(response.data.invoice_items);
            setCompanySettings(response.data.company_settings);

            setInvoiceLoading(false);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else if(response.invalid_token===1){
            navigate('/re-login');
        }     
        
    } catch (error) {
        //console.error(error);
    }

  }  

  useEffect(() => {  
    fetchInvoiceDetails(invoice_unique_id);
  }, [invoice_unique_id]);
  // Get Invoice Details End


  // Get Payment List Start
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  const fetchPayments = async() => {
    setPaymentsLoading(true);

    try {
        let apiParam = {user_token: user_data.user_token, page: 1, limit: 100};
        const response = await getPayments(invoice_unique_id, apiParam);

        if (response.status===true) {

            if (response.data.payments) {
                setPayments(response.data.payments);
            }

            setPaymentsLoading(false);

            setNotificationCount(localStorage.getItem('unread_notification_count'));
        }
        else if(response.invalid_token===1){
            navigate('/re-login');
        }
        
    } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {  
    fetchPayments(invoice_unique_id);
  }, [invoice_unique_id]);
  // Get Payment List End


  //Send Invoice Start
  const [showSendInvoiceModal, setShowSendInvoiceModal] = useState(false);

  const openSendInvoiceModal = () =>{
    setModalKey(prevKey => prevKey + 1); 
    setShowSendInvoiceModal(true);
  } 
  //Send Invoice  End


   //Add Payment Start
   const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

   const openAddPaymentModal = () =>{
     setModalKey(prevKey => prevKey + 1); 
     setShowAddPaymentModal(true);
   } 
   //Add Payment End


   //Update Payment Start
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);

  const [paymentData, setPaymentData] = useState({});

  const openUpdatePaymentModal = (item_data) =>{
    setPaymentData(item_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdatePaymentModal(true);
  } 
  //Update Payment End


  //Delete Payment Start
  const [showDeletePaymentModal, setShowDeletePaymentModal] = useState(false);

  const openDeletePaymentModal = (item_data) =>{
    setPaymentData(item_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeletePaymentModal(true);
  } 
  //Delete Payment End
  
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Edit Invoice - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Edit Invoice</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Invoice</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Invoice</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {invoiceLoading ? (<InvoiceDetails />) : ( 

                    <>

                    {/* <!--Update Shipping Address Modal Start--> */}
                    <UpdateShippingAddressModal
                    showModal={showUpdateShippingAddressModal}
                    setShowModal={setShowUpdateShippingAddressModal}
                    modalKey={modalKey}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    shippingAddress={shippingAddress}
                    setShippingAddress={setShippingAddress}
                    setInvoiceItems={setInvoiceItems}
                    setCompanySettings={setCompanySettings} />
                    {/* <!--Update Shipping Address Modal End--> */}


                    {/* <!--Delete Shipping Address Modal Start--> */}
                    <DeleteShippingAddressModal
                    showModal={showDeleteShippingAddressModal}
                    setShowModal={setShowDeleteShippingAddressModal}
                    modalKey={modalKey}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    shippingAddress={shippingAddress}
                    setShippingAddress={setShippingAddress}
                    setInvoiceItems={setInvoiceItems}
                    setCompanySettings={setCompanySettings} />
                    {/* <!--Delete Shipping Address Modal End--> */}


                    {/* <!--Add Item Modal Start--> */}
                    <AddItemModal
                    showModal={showAddItemModal}
                    setShowModal={setShowAddItemModal}
                    modalKey={modalKey}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    setShippingAddress={setShippingAddress}
                    setInvoiceItems={setInvoiceItems}
                    setCompanySettings={setCompanySettings} />
                    {/* <!--Add Item Modal End--> */}


                    {/* <!--Update Item Modal Start--> */}
                    <UpdateItemModal
                    showModal={showUpdateItemModal}
                    setShowModal={setShowUpdateItemModal}
                    modalKey={modalKey}
                    invoiceData={invoiceData}
                    itemData={itemData}
                    setInvoiceData={setInvoiceData}
                    setShippingAddress={setShippingAddress}
                    setInvoiceItems={setInvoiceItems}
                    setCompanySettings={setCompanySettings} />
                    {/* <!--Update Item Modal End--> */}


                    {/* <!--Delete Item Modal Start--> */}
                    <DeleteItemModal
                    showModal={showDeleteItemModal}
                    setShowModal={setShowDeleteItemModal}
                    modalKey={modalKey}
                    invoiceData={invoiceData}
                    itemData={itemData}
                    setInvoiceData={setInvoiceData}
                    setShippingAddress={setShippingAddress}
                    setInvoiceItems={setInvoiceItems}
                    setCompanySettings={setCompanySettings} />
                    {/* <!--Delete Item Modal End--> */}


                    {/* <!--Add Payment Modal Start--> */}
                    <AddPaymentModal
                    showModal={showAddPaymentModal}
                    setShowModal={setShowAddPaymentModal}
                    modalKey={modalKey}
                    invoice_unique_id={invoice_unique_id}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    setPayments={setPayments} />
                    {/* <!--Add Payment Modal End--> */}


                    {/* <!--Update Payment Modal Start--> */}
                    <UpdatePaymentModal
                    showModal={showUpdatePaymentModal}
                    setShowModal={setShowUpdatePaymentModal}
                    modalKey={modalKey}
                    invoice_unique_id={invoice_unique_id}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    paymentData={paymentData}
                    setPayments={setPayments} />
                    {/* <!--Update Payment Modal End--> */}


                    {/* <!--Delete Payment Modal Start--> */}
                    <DeletePaymentModal
                    showModal={showDeletePaymentModal}
                    setShowModal={setShowDeletePaymentModal}
                    modalKey={modalKey}
                    invoice_unique_id={invoice_unique_id}
                    invoiceData={invoiceData}
                    setInvoiceData={setInvoiceData}
                    paymentData={paymentData}
                    setPayments={setPayments} />
                    {/* <!--Delete Payment Modal End--> */}


                     {/* <!--Send Invoice Modal Start--> */}
                     <SendInvoiceModal
                    showModal={showSendInvoiceModal}
                    setShowModal={setShowSendInvoiceModal}
                    modalKey={modalKey}
                    invoice_unique_id={invoice_unique_id}
                    invoiceData={invoiceData} />
                    {/* <!--SendInvoice Modal End--> */}
                    
                    
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-md-flex d-block">
                                    <div className="h5 mb-0 d-sm-flex d-block align-items-center">
                                        
                                        <div className="ms-sm-2 ms-0 mt-sm-0 mt-2 col-auto">
                                            <input type="text" className="form-control form-control-light form-control-sm" value={invoiceData.project_title} disabled="true" /> 
                                        </div>

                                        <div className="ms-sm-2 ms-0 mt-sm-0 mt-2 col-auto">
                                            <input type="text" className="form-control form-control-light form-control-sm" value={invoiceData.invoice_number} disabled="true" />
                                        </div> 
                                        
                                    </div>
                                    <div className="ms-auto mt-md-0 mt-2">
                                        <button className="btn btn-sm btn-icon btn-success-light me-2" onClick={downloadInvoice}>
                                            <i className="bi bi-download"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row gy-3">
                                        <div className="col-xl-12">
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <p className="dw-semibold mb-2" style={{fontWeight: 'bold'}}>
                                                        Billed to :
                                                    </p>
                                                    <div className="row gy-2">
                                                        <div className="col-xl-12">
                                                            {invoiceData.client_name}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`GSTIN :  ${invoiceData.client_gstin}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {invoiceData.client_address}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`${invoiceData.client_city+', '+(invoiceData.state_name ? invoiceData.state_name : invoiceData.client_state_other)+', '+invoiceData.country_name}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`Pincode : ${invoiceData.client_pincode}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {invoiceData.client_email}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <p className="dw-semibold mb-2">
                                                        <a href='javascript:void(0);' className='text-primary' onClick={() => openUpdateShippingAddressModal()} style={{fontWeight: 'bold'}}>Shipping To : </a>

                                                        <a href='javascript:void(0);' className='text-danger' title="Delete" onClick={() => openDeleteShippingAddressModal()} style={{display: shippingAddress ? 'block' : 'none'}}><i class="bi bi-trash3"></i></a>
                                                    </p>
                                                    <div className="row gy-2" style={{display: shippingAddress ? 'block' : 'none'}}>
                                                        <div className="col-xl-12">
                                                            {shippingAddress ? shippingAddress.invoice_company_name : ''}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {shippingAddress ? shippingAddress.address : ''}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`${shippingAddress ? shippingAddress.address_city+', '+(shippingAddress.state_name ? shippingAddress.state_name : shippingAddress.address_state_other)+', '+shippingAddress.country_name  : ''}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`${shippingAddress ? 'Pincode : ' +shippingAddress.address_pincode : ''}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 ms-auto mt-sm-0 mt-3">
                                                    <p className="dw-semibold mb-2" style={{fontWeight: 'bold'}}>
                                                        Billing From :
                                                    </p>
                                                    <div className="row gy-2">
                                                        <div className="col-xl-12">
                                                            {companySettings.company_name}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(companySettings.company_address) }} />
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`PAN : ${companySettings.company_pan}`} 
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`CIN : ${companySettings.company_cin}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`GSTIN : ${companySettings.company_gst}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {`Phone : ${companySettings.company_phone}`}
                                                        </div>
                                                        <div className="col-xl-12">
                                                            {companySettings.company_website}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3"> 
                                            <label htmlFor="invoice-number" className="form-label">Invoice ID</label>
                                            <input type="text" className="form-control form-control-light"  value={invoiceData.invoice_number} disabled="true" />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="invoice-date-issued" className="form-label">Date Issued</label>
                                            <input type="text" className="form-control form-control-light" value={dateFormat(invoiceData.invoice_bill_date, "dd mmm yyyy")} disabled="true" />
                                        </div>
                                        <div className="col-xl-3">
                                            <label htmlFor="invoice-date-due" className="form-label">Due Date</label>
                                            <input type="text" className="form-control form-control-light" value={dateFormat(invoiceData.invoice_due_date, "dd mmm yyyy")} disabled="true" />
                                        </div>
                                        <div className="col-xl-3"> 
                                            <label htmlFor="invoice-due-amount" className="form-label">Amount</label>
                                            <input type="text" className="form-control form-control-light" value={invoiceData.invoice_amount} />
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="table-responsive"> 
                                                <table className="table nowrap text-nowrap border mt-3"> 
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Item</th>
                                                            <th>SAC</th>
                                                            <th>Amount</th>
                                                            <th><i class="bi bi-gear"></i></th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody>
                                                        {invoiceItems && invoiceItems.map((item, index) => (
                                                        <tr> 
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <div className="form-control form-control-light">{item.item_title}</div>
                                                            </td>
                                                            <td>
                                                                <div className="form-control form-control-light">{item.item_sac}</div>
                                                            </td>
                                                            <td>
                                                                <div className="form-control form-control-light">{item.item_amount_str}</div>
                                                            </td>
                                                            
                                                            <td>
                                                            <div className="hstack gap-2 fs-15">
                                                                <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit Item" onClick={() => openUpdateItemModal(item)}>
                                                                    <i className='bx bxs-edit'></i>
                                                                </button>
                                        
                                                                <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete Item" onClick={() => openDeleteItemModal(item)}>
                                                                    <i className='bx bxs-trash'></i>
                                                                </button>
                                                            </div>
                                                            </td>
                                                        </tr> 
                                                        ))}
                                                        <tr>
                                                            <td colspan="5" class="border-bottom-0">
                                                                <a class="btn btn-light" href="javascript:void(0);" onClick={() => openAddItemModal()}><i class="bi bi-plus-lg"></i> Add Item</a>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="3"></td>
                                                            <td colspan="2">
                                                                <table class="table table-sm text-nowrap mb-0 table-borderless">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">
                                                                                <div class="fw-semibold">Sub Total :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input"  value={invoiceData.invoice_amount} />
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                        <tr className={!invoiceData.invoice_cgst_tax ? 'd-none' :''}>
                                                                            <th scope="row">
                                                                                <div class="fw-semibold">CGST :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input" value={invoiceData.invoice_cgst_tax} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr className={!invoiceData.invoice_sgst_tax ? 'd-none' :''}>
                                                                            <th scope="row">
                                                                                <div class="fw-semibold">SGST :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input" value={invoiceData.invoice_sgst_tax} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr className={!invoiceData.invoice_igst_tax==='0:00' ? 'd-none' :'d-none'}>
                                                                            <th scope="row">
                                                                                <div class="fw-semibold">IGST :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input" value={invoiceData.invoice_igst_tax} />
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                        <tr>
                                                                            <th scope="row">
                                                                                <div class="fs-14 fw-semibold">Total Payable Amount :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input"  value={invoiceData.invoice_total_amount} />
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row">
                                                                                <div class="fs-14 fw-semibold">Total Paid Amount :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input"  value={invoiceData.invoice_paid_amount} />
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <th scope="row">
                                                                                <div class="fs-14 fw-semibold">Balance Due :</div>
                                                                            </th>
                                                                            <td>
                                                                                <input type="text" class="form-control form-control-light invoice-amount-input"  value={invoiceData.invoice_due_amount} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="5" align='right'>
                                                                {invoiceData.invoice_total_amount_txt}
                                                            </td>
                                                        </tr>
                                                        
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <Link to={`https://docs.google.com/viewer?url=${encodeURIComponent(process.env.REACT_APP_ASSETS_URL+'/pdf/invoice/'+invoice_unique_id)}&embedded=true`} target='_blank' className="btn btn-light me-1"><i className="ri-eye-line me-1 align-middle d-inline-block"></i>Preview</Link>
                                    <button className="btn btn-primary" onClick={openSendInvoiceModal}>Send Invoice <i className="ri-send-plane-2-line ms-1 align-middle d-inline-block"></i></button>
                                </div>
                            </div>
                        </div>
                        

                    </div>

                    <div className='row'>
                    <div className="col-xl-12">
                                            <div className="table-responsive"> 
                                                <table className="table nowrap text-nowrap border mt-3"> 
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Payment Mode</th>
                                                            <th>Date</th>
                                                            <th>Amount</th>
                                                            <th><i class="bi bi-gear"></i></th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody>
                                                    {payments && payments.length > 0 ? (
                                                        payments.map((payment, paymentIndex) => (
                                                        <tr> 
                                                            <td>{paymentIndex+1}</td>
                                                            <td>
                                                                <div className="form-control form-control-light">{paymentModeLabels(payment.payment_mode)}</div>
                                                            </td>
                                                            <td>
                                                                <div className="form-control form-control-light">{dateFormat(payment.payment_date, "dd mmm yyyy")}</div>
                                                            </td>
                                                            <td>
                                                                <div className="form-control form-control-light">{payment.payment_amount_str}</div>
                                                            </td>
                                                            
                                                            <td>
                                                            <div className="hstack gap-2 fs-15">
                                                                <button type="button" className="btn btn-icon btn-sm btn-info" title="Edit Payment" onClick={() => openUpdatePaymentModal(payment)}>
                                                                    <i className='bx bxs-edit'></i>
                                                                </button>
                                        
                                                                <button type="button" className="btn btn-icon btn-sm btn-danger" title="Delete Payment" onClick={() => openDeletePaymentModal(payment)}>
                                                                    <i className='bx bxs-trash'></i>
                                                                </button>
                                                            </div>
                                                            </td>
                                                        </tr> 
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5" align='center'>No payments done</td>
                                                        </tr>
                                                    )}
                                                        <tr>
                                                            <td colspan="5" class="border-bottom-0">
                                                                <a class="btn btn-light" href="javascript:void(0);" onClick={() => openAddPaymentModal()}><i class="bi bi-plus-lg"></i> Add Payment</a>
                                                            </td>
                                                        </tr>

                                                        
                                                        
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                    </div>
                    </>             
                    )} 
                </div>  

            </div>
            

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default EditInvoice
