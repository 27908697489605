import React from 'react'
import { Helmet } from 'react-helmet';
import AdminLayout from '../../layout/AdminLayout';
import JoditEditor from 'jodit-react';
import { useNotificationCount } from '../../../context/NotificationCountContext';
import { checkPermission } from '../../../helpers/GeneralHelper';
import Error403 from '../Error403';


const EmployeeSettings = () => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

  return (
    <>
      {checkPermission('menu_settings')===true ? (
      <AdminLayout>
        
        <Helmet>
            <title>Employee Settings - Worktracker Anciletech</title>
            
        </Helmet>

        <div className="main-content app-content">

            
            <div className="container-fluid">
              <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                    <h1 className="page-title fw-semibold fs-18 mb-0">Employee Settings</h1>
                    <div className="ms-md-1 ms-0">
                        <nav>
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="card custom-card">
                            <div className="card-body">
                                <ul className="nav nav-pills justify-content-center nav-style-2 mb-3" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" data-bs-toggle="tab" role="tab" aria-current="page" href="#leave-center" aria-selected="true">Leave</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#attendance-center" aria-selected="false" tabindex="-1">Attendance</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#projectstatus-center" aria-selected="false" tabindex="-1">Project Status</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" data-bs-toggle="tab" role="tab" aria-current="page" href="#taskstatus-center" aria-selected="false" tabindex="-1">Task Status</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane show active text-muted" id="leave-center" role="tabpanel">
                                        <div className="row g-3">
                                          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Casual Leave Gap Days</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Planned Leave Gap Days</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Casual Leave/Month</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>

                                          <div className="col-12">
                                            <div className="d-flex justify-content-end">
                                              <button type="submit" className="btn btn-primary">Update</button>
                                            </div>
                                          </div>
                                          
                                        </div>
                                    </div>
                                    <div className="tab-pane text-muted" id="attendance-center" role="tabpanel">
                                        <div className="row g-3">
                                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="row g-3 align-items-center">
                                              <div className="col-md-2">
                                                  <label for="inputPassword6" className="form-label">Location</label>
                                              </div>
                                              <div className="col-md-8">
                                                  <input type="password" id="inputPassword6" className="form-control"
                                                      aria-describedby="passwordHelpInline" />
                                              </div>
                                              <div className="col-md-2">
                                                <div className="d-grid">
                                                  <button type="submit" className="btn btn-primary">Select</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Max Login Radius</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">App login allowed members</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Office Latitude</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                            <label for="input-text" className="form-label">Office Longitude</label>
                                            <input type="text" className="form-control" id="input-text" placeholder="Text" />
                                          </div>
                                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                              <div className="dynamic-map">
                                                <div className="card custom-card">
                                                  <div className="card-header">
                                                      <div className="card-title">Map</div>
                                                  </div>
                                                  <div className="card-body">
                                                      <div id="map-markers"></div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>

                                          <div className="col-12">
                                            <div className="d-flex justify-content-end">
                                              <button type="submit" className="btn btn-primary">Update</button>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane text-muted" id="projectstatus-center" role="tabpanel">
                                       <div className="row">

                                       </div>
                                    </div>
                                    <div className="tab-pane text-muted" id="taskstatus-center" role="tabpanel">
                                        <div className="row">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>  

        <Helmet>
            
        </Helmet>
    
      </AdminLayout>
      ) : ( <Error403/>)}
    </>
  )
}

export default EmployeeSettings