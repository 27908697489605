import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../helpers/ErrorHelper';
import { useAuth } from '../../context/AuthContext';
import { tableCustomLoader } from '../../helpers/StyleHelper';
import { getNotes } from '../../models/NoteModel';
import dateFormat from "dateformat";
import DataTable, { createTheme } from 'react-data-table-component';
import { useTheme } from '../../context/ThemeContext';
import { profilePic } from '../../helpers/GeneralHelper';
import { attendanceStatus } from '../../helpers/AttendanceHelper';
import AttendanceList from '../skeletons/AttendanceList';
import { utcToLocal } from '../../helpers/DateHelper';
import { useFinancialYear } from '../../context/FinancialYearContext';

const AttendanceTables = ({
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    page,
    setPage,
    limit,
    setLimit,
    fetchAttendances,
    openViewAttendanceModal,
    user_unique_id
}) => {

    const { financialYear } = useFinancialYear();

    const { theme, toggleTheme } = useTheme();

    const { state } = useAuth(); 

    let user_data = state.user_data;

    const CustomLoader = () => (
        <AttendanceList/>
    );

    const handlePageChange = async (page) => {
        fetchAttendances(limit, page);
    };
    
    const handleLimitChange = async (newLimit, page) => {
        if (newLimit!==10 && page!==1) {
            fetchAttendances(newLimit, page); 
        }
        else if (localStorage.getItem('attendances')) {
            fetchAttendances(newLimit, page); 
        }
    };

    useEffect(()=>{
        console.log(user_unique_id);
        fetchAttendances(limit, page);  
    }, [user_unique_id, financialYear]);


    const columns = [
        {
            name: "#",
            selector: (row, index) => <div>{((page-1)*limit)+index+1}.</div>,
            width: '50px'
        },
        {
            name: "Team Member",
            selector: (row) => <div className="d-flex align-items-center">
                                    <div className="avatar avatar-sm me-2 avatar-rounded">
                                        {profilePic(row.atten_user_first_name, row.atten_user_last_name, row.atten_user_image)}
                                    </div>
                                    <div>
                                        <div className="lh-1">
                                            <span>{row.atten_user_first_name} {row.atten_user_last_name}</span>
                                        </div>
                                        <div className="lh-1">
                                            <span className="fs-11 text-muted">{row.atten_user_email}</span>
                                        </div>
                                    </div>
                                </div>,
            width: '300px'                    
        },
        {
            name: "Date",
            selector: (row) => utcToLocal(row.attendance_in_time, "dd mmm yyyy")  
        },
        {
            name: "Work Type",
            selector: (row) =>  row.attendance_type.toUpperCase() 
        },
        {
            name: "In Time",
            selector: (row) => utcToLocal(row.attendance_in_time, "HH:MM:ss") 
        },
        {
            name: "Out Time",
            selector: (row) => row.attendance_out_time ? utcToLocal(row.attendance_out_time, "HH:MM:ss") : '--:--:--'
        },
        {
            name: "Login Hours",
            selector: (row) => row.attendance_login_hours  
        },
        {
            name: "Status",
            selector: (row) => attendanceStatus(row.attendance_is_verified)   
        },
        {
            name: "Action",
            cell: row => <div className="hstack gap-2 fs-15">
                            <button type="button" className="btn btn-icon btn-sm btn-info" title="View" onClick={() => openViewAttendanceModal(row)}>
                                <i className='bi bi-eye'></i>
                            </button>
                        </div>
        }
    ];

    // Conditionally unset a specific column
    const filteredColumns = user_data.urole_permission!=='*'
        ? columns.filter(column => column.name !== "Team Member")
        : columns;

    createTheme('dark', {
        text: {
            primary: '#a2a3a4',
            secondary: '#2aa198',
        },
        background: {
            default: '#252729',
        },
        context: {
            background: '#cb4b16',
            text: '#a2a3a4',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    });     

    return (
        <DataTable 
            title={`Manage Attendance`} 
            columns={filteredColumns} 
            data={data} 
            pagination 
            paginationServer 
            paginationTotalRows={totalRows} 
            onChangeRowsPerPage={handleLimitChange} 
            onChangePage={handlePageChange} 
            progressPending={loading} 
            progressComponent={<CustomLoader />}
            highlightOnHover
            theme={`${theme==='light' ? 'light' : 'dark'}`} />
    );

};    

export default AttendanceTables;    