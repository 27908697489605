import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { removeClient, saveClientsToStorage } from '../../../models/ClientModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const DeleteClientModal = ({showModal, setShowModal, clientData, modalKey, fetchClients, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);

    const [_client_id, setClientID] = useState('');

    const closeModal = () =>{
        setClientID('');
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever clientData changes
        if (clientData) {
            setClientID(clientData._client_id);
        }
    }, [clientData, modalKey]);


    const deleteClient = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
            const response = await removeClient(user_data.user_token, _client_id);

            if (response.status) {
              toast.success(response.message);
      
              closeModal();
      
              saveClientsToStorage(response.data.clients, response.data.pagination);
      
              fetchClients(limit, page); 

              setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
              toast.error(response.message);
            }
            
        } catch (error) {
            apiError(error);

            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    } 

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Client</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this client ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteClient}>Delete</button>
            </Modal.Footer>
        </Modal>
    );
};   


export default DeleteClientModal; 