import React, { useEffect,  useRef,  useState }  from 'react'

function ProjectFiles() { 

    return (
        <table className="table text-nowrap placeholder-glow">
            <thead className="table-primary">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Size</th>
                    <th scope="col">Added On</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {[1,2,3,4,5,6,7,8].map((n) => (
                <tr>
                    <td><span className="placeholder col-1"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td><span className="placeholder col-3"></span></td>
                    <td>
                        <div className="hstack gap-2 fs-15">
                            <button href="javascript:void(0);" className="btn btn-icon btn-sm rounded-pill">
                                <span className="placeholder col-12"></span>
                            </button>
                        </div>
                    </td>
                </tr>
                )) }

            </tbody>
        </table>
    );
}  

export default ProjectFiles