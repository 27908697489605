import { GET, POST, setAuthToken } from "../config/ApiConfig";
import { decryptData, encryptData } from "../helpers/EncryptHelper";
import { apiError } from "../helpers/ErrorHelper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";



export const getLoginVsWorkReport = async(user_token, date) => {

    if (!user_token) {
        return [];
    }

    if (!date) {
        const currentDate = new Date();
        
        date = new Date();
    }

    // Format date in DD-MM-YYYY format
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).replace(/\//g, '-');
    date = formattedDate;
    


    try {
        const endPoint = '/reports/login-hours';
        const url = `${endPoint}?date=${date}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }
};

export const getCompareReport = async(user_token, date_from, date_to) => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/reports/compare-report';
        const url = `${endPoint}?date_from=${date_from}&date_to=${date_to}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;  
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}


export const getPerformanceGraphs = async(user_token, _user_id='') => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/reports/performance-graph';
        const url = `${endPoint}?_user_id=${_user_id}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}



export const getPerformanceReports = async(user_token, _user_id='') => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/reports/performance-report';
        const url = `${endPoint}?_user_id=${_user_id}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}



export const getLeaveReport = async(user_token, _user_id='') => {

    if (!user_token) {
        return [];
    }

    try {
        const endPoint = '/reports/leave-report';
        const url = `${endPoint}?_user_id=${_user_id}`;

        setAuthToken(user_token);
        
        const response = await GET(url);

        return response;
        
    } catch (error) {
        apiError(error);

        return error; 
    }
}