import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import {  getNotes } from '../../models/NoteModel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import AddNoteModal from '../modals/Notes/AddNoteModal';
import UpdateNoteModal from '../modals/Notes/UpdateNoteModal';
import DeleteNoteModal from '../modals/Notes/DeleteNoteModal';
import NoteTables, { fetchNotes } from '../tables/NoteTables';
import { useNotificationCount } from '../../context/NotificationCountContext';

function Notes() {

  const { theme, toggleTheme } = useTheme();

  const { state } = useAuth(); 

  let user_data = state.user_data;

  const navigate = useNavigate();  

  const { notificationCount, setNotificationCount } = useNotificationCount();

  const loader = document.getElementById('loading');

  const [modalKey, setModalKey] = useState(0);

  //Add Note Start
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  //Add Note End


  //Update Note Start
  const [showUpdateNoteModal, setShowUpdateNoteModal] = useState(false);

  const [noteData, setNoteData] = useState({});

  const openUpdateNoteModal = (note_data) =>{
    setNoteData(note_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowUpdateNoteModal(true);
  }  
  //Update Note End


  //Delete Note Start
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false);

  const openDeleteNoteModal = (note_data) =>{
    setNoteData(note_data);
    setModalKey(prevKey => prevKey + 1); 
    setShowDeleteNoteModal(true);
  } 
  //Delete Note End


  //Flush Note Start
  const flushNote = async() => {
    localStorage.removeItem("notes");
    fetchNotes(limit, page); 
  }  
  //Flush Note End


  //Note List Start
  const [data, setData] = useState([]);
  const [cacheTime, setCacheTime] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchNotes = async(newLimit, page) => {
    setLoading(true);

    try {
        let apiParam = {page: page, limit: newLimit, user_token: user_data.user_token};

        const response = await getNotes(apiParam);

        if (typeof response != typeof undefined) {
            setData(response.notes);
            setTotalRows(response.pagination.total_rows);
            setLimit(newLimit);
            setPage(page);

            setNotificationCount(localStorage.getItem('unread_notification_count'));

            if (response.time) {
                setCacheTime(response.time);
            }
        }
        else{
            navigate('/re-login');
        }        

        setLoading(false);

    } catch (error) {
      //console.error(error);
    }

    
  };
  //Note List End





    
  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Notes - Worktracker Anciletech </title>
                
            </Helmet>

            <div className="main-content app-content">

                
                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Notes</h1>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Notes</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                    {/* <!-- Start::row-1 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className="card-title">
                                        Last Synced On {cacheTime}
                                    </div>

                                    <div className="d-flex flex-wrap gap-2">
                                        <button className="btn btn-primary btn-sm"  onClick={() => setShowAddNoteModal(true)}>
                                            <i className="ri-add-line me-1 fw-semibold align-middle"></i>Add Note
                                        </button>

                                        <button className="btn btn-success-light btn-sm" onClick={() => flushNote()}>
                                            <i className="ri-refresh-line me-1 fw-semibold align-middle"></i>Refresh
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <NoteTables 
                                          data={data}
                                          setData={setData}
                                          loading={loading}
                                          setLoading={setLoading}
                                          totalRows={totalRows}
                                          setTotalRows={setTotalRows}
                                          page={page}
                                          setPage={setPage}
                                          limit={limit}
                                          setLimit={setLimit}
                                          fetchNotes={fetchNotes}
                                          openUpdateNoteModal={openUpdateNoteModal} 
                                          openDeleteNoteModal={openDeleteNoteModal} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!--Add Note Modal Start--> */}
                    <AddNoteModal
                    showModal={showAddNoteModal}
                    setShowModal={setShowAddNoteModal}
                    fetchNotes={fetchNotes}
                    limit={limit}
                    page={page} />
                    {/* <!--Add Note Modal End--> */}


                    {/* <!--Update Note Modal Start--> */}
                    <UpdateNoteModal
                    showModal={showUpdateNoteModal}
                    setShowModal={setShowUpdateNoteModal}
                    noteData={noteData}
                    modalKey={modalKey}
                    fetchNotes={fetchNotes}
                    limit={limit}
                    page={page} />
                    {/* <!--Update Note Modal End--> */}


                    {/* <!--Delete Note Modal Start--> */}
                    <DeleteNoteModal
                    showModal={showDeleteNoteModal}
                    setShowModal={setShowDeleteNoteModal}
                    noteData={noteData}
                    modalKey={modalKey}
                    fetchNotes={fetchNotes}
                    limit={limit}
                    page={page}  />
                    {/* <!--Delete Note Modal End--> */}

                </div>


            </div>

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Notes
