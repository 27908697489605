export const invoiceStatus = (status) => {
    if (status=='draft') {
        return <span className="badge bg-primary-transparent">Draft</span>;
    }
    else if (status=='paid') {
        return <span className="badge bg-success-transparent">Paid</span>;
    }
    else if (status=='partly_paid') {
        return <span className="badge bg-warning-transparent">Partly Paid</span>;
    }
    else if (status=='cancelled') {
        return <span className="badge bg-danger-transparent">Cancelled</span>;
    }
    else{
        return <span className="badge bg-info-transparent">{status.toUpperCase()}</span>; 
    }
};


export const invoiceAmount = (invoice_type, invoice_amount) => {

    if (invoice_type=='international') {
        return <span>$ {invoice_amount}</span>;
    }
    else{
        return <span>₹ {invoice_amount}</span>;
    }
};


export const prettyAmount = (prefix, invoice_amount) => {

    let amount = '';

    if (invoice_amount < 1000) {
        amount = invoice_amount;
    }
    else if (invoice_amount >= 1000 && invoice_amount < 100000) {
        amount = (invoice_amount/1000).toFixed(2)+' K';
    }
    else{
        amount = (invoice_amount/100000).toFixed(2)+' L';
    }

    return <span>{prefix} {amount}</span>;
};