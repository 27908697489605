import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { removeProject, saveProjectsToStorage } from '../../../models/ProjectModel';
import { useNotificationCount } from '../../../context/NotificationCountContext';


const DeleteProjectModal = ({showModal, setShowModal, projectData, modalKey, fetchProjects, limit, page}) => {

  const { notificationCount, setNotificationCount } = useNotificationCount();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');

    const [loading, setLoading] = useState(false);
    
    const [_project_id, setProjectID] = useState('');

    const closeModal = () =>{
        setProjectID('');
        setShowModal(false);
    }

    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (projectData) {
            setProjectID(projectData._project_id);
        }
    }, [projectData, modalKey]);


    const deleteProject = async() => {
        // Start the loading state
        loader.style.display = 'block';
    
        try {
          const response = await removeProject(user_data.user_token, _project_id);
    
          if (response.status) {
            toast.success(response.message);
    
            closeModal();
    
            saveProjectsToStorage(response.data.projects, response.data.pagination);
    
            fetchProjects(limit, page); 

            setNotificationCount(localStorage.getItem('unread_notification_count'));
          }
          else{
            toast.error(response.message);
          }  
    
        } catch (error) {
          apiError(error);

          if (error.response.data.invalid_token==1) {
            navigate('/re-login');
          }
        }
    
        // Stop the loading state
        loader.style.display = 'none';
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Project</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p>Do you want to delete this project ? This process can't be undone</p>
            </Modal.Body>
    
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-danger" onClick={deleteProject}>Delete</button>
            </Modal.Footer>
        </Modal>
    );  

}; 


export default DeleteProjectModal;