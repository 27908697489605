import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiError } from '../../../helpers/ErrorHelper';
import { useAuth } from '../../../context/AuthContext';
import { editClient, saveClientsToStorage } from '../../../models/ClientModel';
import { getCountries, getCurrencies, getStates } from '../../../models/MasterModel';
import Select from 'react-select';
import { useTheme } from '../../../context/ThemeContext';
import { useNotificationCount } from '../../../context/NotificationCountContext';

const UpdateClientModal = ({showModal, setShowModal, clientData, modalKey, fetchClients, limit, page}) => {

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { theme, toggleTheme, selectBoxTheme } = useTheme();

    const navigate = useNavigate(); 

    const { state } = useAuth(); 

    let user_data = state.user_data;
    
    const loader = document.getElementById('loading');
    
    const [loading, setLoading] = useState(false);

    const [_client_id, setClientID] = useState('');
    const [client_name, setClientName] = useState('');
    const [client_email, setClientEmail] = useState('');
    const [client_phone, setClientPhone] = useState('');
    const [client_address, setClientAddress] = useState('');
    const [client_pincode, setClientPincode] = useState('');
    const [client_country, setClientCountry] = useState('');
    const [client_state, setClientState] = useState('');
    const [client_state_other, setClientStateOther] = useState('');
    const [client_state_code, setClientStateCode] = useState('');
    const [client_city, setClientCity] = useState('');
    const [client_website, setClientWebsite] = useState('');
    const [client_gstin, setClientGstin] = useState('');
    const [client_currency, setClientCurrency] = useState('');

    const [errors, setErrors] = useState({client_name:  '',  client_email: '', client_phone: '', client_address: '', client_pincode: '',
    client_country: '', client_state: '', client_state_other: '', client_state_code: '', client_city: '', client_website: '', client_gstin: '', client_currency: ''});

    const closeModal = () =>{
        setClientID('');
        setClientName('');
        setClientEmail('');
        setClientPhone('');
        setClientAddress('');
        setClientPincode('');
        setClientCountry('');
        setClientState('');
        setClientStateOther('');
        setClientStateCode('');
        setClientCity('');
        setClientWebsite('');
        setClientGstin('');
        setClientCurrency('');
        
        setErrors({});
    
        setShowModal(false);
    }

    const [countries, setCountries] = useState([]);
    const fetchCountries = async () => {
        try {
          const result = await getCountries();

          const countriesOptions = result.countries.map(country => ({
            label: country.country_name,
            value: country._country_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setCountries(countriesOptions);
        } catch (error) {
          //console.error(error);
        }
    };

    const [states, setStates] = useState([]);
    const fetchStates = async () => {
        // Start the loading state
        loader.style.display = 'block';

        try {
          const result = await getStates();

          const statesOptions = result.states.map(state => ({
            label: state.state_name,
            value: state._state_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setStates(statesOptions);
        } catch (error) {
          // Handle errors if necessary
          //console.error(error);
        }

        // Stop the loading state
        loader.style.display = 'none';
    };

    const [currencies, setCurrencies] = useState([]);
    const fetchCurrencies = async () => {
        try {
          const result = await getCurrencies();

          const currenciesOptions = result.currencies.map(currency => ({
            label: currency.currency_name,
            value: currency._currency_id
          }));

          setNotificationCount(localStorage.getItem('unread_notification_count'));

          setCurrencies(currenciesOptions);
        } catch (error) {
          // Handle errors if necessary
          //console.error(error);
        }
    };

    const countryChange = async(countryObj) => {
        setClientCountry(countryObj);

        //console.log(countryObj.value);

        if (countryObj.value==99) {
            fetchStates();
            setClientStateOther('');
        }
        else{
            setStates([]);
            setClientState('');
        }
    }

    useEffect(() => {
        if (showModal) {
            fetchCountries();
            fetchCurrencies();
            fetchStates();
        }
        

        

        if (clientData._country_id==99 && showModal) {
            //console.log('India');
            fetchStates();
        }
        else{
           // console.log('Not India');
            setStates([]);
        }
    }, [showModal]); 


    useEffect(() => {
        // This effect will be called whenever noteData changes
        if (clientData) {
            setClientID(clientData._client_id);
            setClientName(clientData.client_name);
            setClientEmail(clientData.client_email);
            setClientPhone(clientData.client_phone);
            setClientAddress(clientData.client_address);
            setClientPincode(clientData.client_pincode);
            setClientCountry({'label': clientData.country_name, 'value': clientData._country_id});
            setClientState({'label': clientData.state_name, 'value': clientData._state_id});
            setClientStateOther(clientData.client_state_other);
            setClientStateCode(clientData.client_state_code);
            setClientCity(clientData.client_city);
            setClientWebsite(clientData.client_website);
            setClientGstin(clientData.client_gstin);
            setClientCurrency({'label': clientData.currency_name, 'value': clientData.client_currency});
            
          setErrors({});
        }
    }, [clientData, modalKey]);


    const updateClient = async() => {
        // Start the loading state
        loader.style.display = 'block';
        setLoading(true);

        try {
            // Clear previous errors
            setErrors({});
      
            const formData = { client_name, client_email, client_phone, client_address, client_pincode, client_country: client_country.value ? client_country.value : '', 
            client_state: client_state.value ? client_state.value : '', client_state_other, client_state_code,
                client_city, client_website, client_gstin, client_currency: client_currency.value ? client_currency.value : '' };
      
      
            const response = await editClient(user_data.user_token, _client_id, formData);
      
      
            if (response.status) {
              toast.success(response.message);
      
              closeModal();

              saveClientsToStorage(response.data.clients, response.data.pagination);
      
              fetchClients(limit, page); 

              setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
              setErrors(response.error);
              toast.error(response.message);
            }
      
          } catch (error) {
            apiError(error);

            if (error.response.data.invalid_token==1) {
                navigate('/re-login');
            }
          }

        // Stop the loading state
        loader.style.display = 'none';
        setLoading(false);
    }  


    return (
        <Modal show={showModal} onHide={closeModal} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update Client</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-xl-4">
                        <label className="form-label">Client Name <span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Client Name" 
                        value={client_name} onChange={(e) => setClientName(e.target.value)}/>

                        
                        {errors.client_name && <p style={{ color: 'red' }}>{errors.client_name}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Client Email <span className="text-danger">*</span></label>
                        <input type="text" className="form-control"  placeholder="Client Email" 
                        value={client_email} onChange={(e) => setClientEmail(e.target.value)}/>

                        
                        {errors.client_email && <p style={{ color: 'red' }}>{errors.client_email}</p>}
                    </div>

                    <div className="col-xl-4">
                        <label className="form-label">Client Mobile </label>
                        <input type="text" className="form-control" placeholder="Client Mobile" 
                        value={client_phone} onChange={(e) => setClientPhone(e.target.value)}/>

                        
                        {errors.client_phone && <p style={{ color: 'red' }}>{errors.client_phone}</p>}
                    </div>

                    <div className="col-xl-12">
                        <label className="form-label">Address <span className="text-danger">*</span></label>
                        <textarea className="form-control" cols="30" rows="5" placeholder='Address' 
                        value={client_address} onChange={(e) => setClientAddress(e.target.value)}></textarea>

                        {errors.client_address && <p style={{ color: 'red' }}>{errors.client_address}</p>}
                    </div>


                    <div className="col-xl-3">
                        <label className="form-label">Pincode </label>
                        <input type="text" className="form-control" placeholder="Pincode" 
                        value={client_pincode} onChange={(e) => setClientPincode(e.target.value)}/>

                        
                        {errors.client_pincode && <p style={{ color: 'red' }}>{errors.client_pincode}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label htmlFor="client_pincode" className="form-label">Country <span className="text-danger">*</span></label>

                        <Select
                            value={client_country}
                            onChange={countryChange}
                            options={countries}
                            placeholder="- Country -"
                            isSearchable="true"
                            noOptionsMessage={() => "No country found"} 
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })}/>

                        
                        {errors.client_country && <p style={{ color: 'red' }}>{errors.client_country}</p>}
                    </div>

                    <div className="col-xl-3" style={{display: client_country.value && client_country.value==99 ? 'block' : 'none'}}>
                        <label htmlFor="client_pincode" className="form-label">State </label>

                        <Select
                            value={client_state}
                            onChange={setClientState}
                            options={states}
                            placeholder="- State -"
                            isSearchable="true"
                            noOptionsMessage={() => "No state found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })} />

                        
                        {errors.client_state && <p style={{ color: 'red' }}>{errors.client_state}</p>}
                    </div>

                    <div className="col-xl-3" style={{display: client_country.value && client_country.value!=99 ? 'block' : 'none'}}>
                        <label className="form-label">State </label>

                        <input type="text" className="form-control" placeholder="State Name" 
                        value={client_state_other} onChange={(e) => setClientStateOther(e.target.value)}/>

                        
                        {errors.client_state_other && <p style={{ color: 'red' }}>{errors.client_state_other}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">State Code </label>
                        <input type="text" className="form-control" placeholder="State Code" 
                        value={client_state_code} onChange={(e) => setClientStateCode(e.target.value)}/>

                        
                        {errors.client_state_code && <p style={{ color: 'red' }}>{errors.client_state_code}</p>}
                    </div>

                    <div className="col-xl-3">
                        <label className="form-label">City </label>
                        <input type="text" className="form-control" placeholder="City" 
                        value={client_city} onChange={(e) => setClientCity(e.target.value)}/>

                        
                        {errors.client_city && <p style={{ color: 'red' }}>{errors.client_city}</p>}
                    </div>


                    <div className="col-xl-3">
                        <label className="form-label">Website </label>
                        <input type="text" className="form-control"  placeholder="Website" 
                        value={client_website} onChange={(e) => setClientWebsite(e.target.value)}/>

                        
                        {errors.client_website && <p style={{ color: 'red' }}>{errors.client_website}</p>}
                    </div>


                    <div className="col-xl-3">
                        <label className="form-label">GSTN </label>
                        <input type="text" className="form-control" placeholder="GST No" 
                        value={client_gstin} onChange={(e) => setClientGstin(e.target.value)}/>

                        
                        {errors.client_gstin && <p style={{ color: 'red' }}>{errors.client_gstin}</p>}
                    </div>

                    <div className="col-xl-3 d-none">
                        <label className="form-label">Currency <span className="text-danger">*</span></label>

                        <Select
                            value={client_currency}
                            onChange={setClientCurrency}
                            options={currencies}
                            placeholder="- Currency -"
                            isSearchable="true"
                            noOptionsMessage={() => "No currency found"}
                            styles={selectBoxTheme}
                            theme={(selectBoxTheme) => ({
                            ...selectBoxTheme,
                            borderRadius: 0,
                            })}/>

                        
                        {errors.client_currency && <p style={{ color: 'red' }}>{errors.client_currency}</p>}
                    </div>
                                            
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={closeModal}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={updateClient}>
                {loading ? (
                        <>
                        <span className="spinner-border spinner-border-sm align-middle"></span>
                        Loading...
                        </>
                    ) : (     
                        'Update'
                )}
                </button>
            </Modal.Footer>
        </Modal>    


    );   

};

export default UpdateClientModal; 