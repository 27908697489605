import React, { useEffect, useState, Suspense   } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AdminLayout from '../layout/AdminLayout';
import { useAuth } from '../../context/AuthContext';
import { GoogleMap, LoadScript, OverlayView, InfoWindow } from '@react-google-maps/api';
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getUsers } from '../../models/UserModel';
import { useTheme } from '../../context/ThemeContext';
import { Line, Bar, Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { getIncomeVsExpensePieGraph, getInvoiceVsPaymentLineGraph, getLocationMap, getLoginVsWorkLineGraph } from '../../models/DashboardModel';
import { formatMonthYear, secondsToTime, utcToLocal } from '../../helpers/DateHelper';
import { useNotificationCount } from '../../context/NotificationCountContext';
import { getActivities } from '../../models/ActivityModel';


function Dashboard() {

    const currentRoute =  useLocation().pathname;  

    const navigate = useNavigate(); 

    const { notificationCount, setNotificationCount } = useNotificationCount();

    const { state } = useAuth(); 

    let user_data = state.user_data;  

    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const hour = new Date().getHours();
        let newGreeting = '';
    
        if (hour >= 5 && hour < 12) {
          newGreeting = 'Good Morning '+user_data.user_first_name;
        } else if (hour >= 12 && hour < 17) {
          newGreeting = 'Good Afternoon '+user_data.user_first_name;
        } else {
          newGreeting = 'Good Evening '+user_data.user_first_name;
        }
    
        setGreeting(newGreeting);
      }, []);

    

    const { theme, toggleTheme, selectBoxTheme } = useTheme();


    const mapContainerStyle = {
        width: '100%',
        height: '330px'
    };
    
    const center = {
        lat: 22.48,
        lng: 88.38
    };

    //Fetch User Working Locations Start
    const [work_date, setWorkDate] = useState(new Date());
    const [_map_user_ids, setMapUserIds] = useState([]);
    const [mapLoading, setMapLoading] = useState(true);

    const [todayWorkingHours, setTodayWorkingHours] = useState('00:00:00'); 
    const [todayWorkingHoursChange, setTodayWorkingHoursChange] = useState(''); 

    const [todayWfhHours, setTodayWfhHours]  = useState('00:00:00'); 
    const [todayWfhHoursChange, setTodayWfhHoursChange]  = useState('');
    
    const [todayOfficeHours, setTodayOfficeHours] = useState('00:00:00'); 
    const [todayOfficeHoursChange, setTodayOfficeHoursChange] = useState(''); 

    const [todaySiteHours, setTodaySiteHours] = useState('00:00:00'); 
    const [todaySiteHoursChange, setTodaySiteHoursChange] = useState(''); 

    const [locations, setLocations] = useState([]); 

    const fetchMapLocations = async() =>{
        setMapLoading(true);

        try {
            let apiParam = {user_token: user_data.user_token};

            const selected_user_ids = _map_user_ids.map(_user_id => _user_id.value).join(',');
    
            const response = await getLocationMap(user_data.user_token, work_date, selected_user_ids);
    
            if (response.status===true) {
            
                if (response.data.locations) {
                    setLocations(response.data.locations);
                }

                if (response.data.working_hours.time_work) {
                    setTodayWorkingHours(secondsToTime(response.data.working_hours.time_work));
                }
                if (response.data.working_hours.time_work_difference) {
                    setTodayWorkingHoursChange(response.data.working_hours.time_work_difference);
                }
                
                if (response.data.working_hours.time_wfh) {
                    setTodayWfhHours(secondsToTime(response.data.working_hours.time_wfh));
                }
                if (response.data.working_hours.time_wfh_difference) {
                    setTodayWfhHoursChange(response.data.working_hours.time_wfh_difference);
                }

                if (response.data.working_hours.time_office) {
                    setTodayOfficeHours(secondsToTime(response.data.working_hours.time_office));
                }
                if (response.data.working_hours.time_office_difference) {
                    setTodayOfficeHoursChange(response.data.working_hours.time_office_difference);
                }

                if (response.data.working_hours.todaySiteHours) {
                    setTodaySiteHours(secondsToTime(response.data.working_hours.todaySiteHours));
                }
                if (response.data.working_hours.time_site_difference) {
                    setTodaySiteHoursChange(response.data.working_hours.time_site_difference);
                }

                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }
        
                toast.error(response.message);
            }      
    
            setMapLoading(false);
    
        } catch (error) {
        
            //console.log(error);
        }
    }

    useEffect(()=>{
        fetchMapLocations();

      }, [work_date, _map_user_ids]);
    //Fetch User Working Locations End

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
    });

    const [selectedUser, setSelectedUser] = useState(null);

    const handleMarkerClick = (location) => {
        setSelectedUser(location);
    };

    const handleInfoWindowClose = () => {
        setSelectedUser(null);
    };

    
    const [users, setUsers] = useState([]);
    const fetchUsers = async () => {
        try {
          const result = await getUsers({user_token: user_data.user_token, page:1, limit:50});

          //console.log(result);

          const userOptions = result.users.map(user => ({
            label: user.user_first_name+' '+user.user_last_name,
            value: user._user_id
          }));


          setUsers(userOptions);


          setNotificationCount(localStorage.getItem('unread_notification_count'));

        } catch (error) {
          //console.error(error);
        }
    };
    

    useEffect(() => {
        fetchUsers();
    }, []); 

    const options = {
        scales: {
          x: {
            type: 'category', // Define the x-axis as categorical
          },
        },
        maintainAspectRatio: false
    };

    //Fetch Invoice vs Payment Line Graph Start
    const [invoiceVsPaymentLineGraphLoading, setInvoiceVsPaymentLineGraphLoading] = useState(true);

    const [invoiceVsPaymentLineGraphData, setInvoiceVsPaymentLineGraphData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Invoice',
            data: [],
            backgroundColor: '#5f76e8',
            tension: 0.1,
          },
          {
            label: 'Payment',
            data: [],
            backgroundColor: '#01caf1',
            tension: 0.1,
          },
        ],
    });

    const fetchInvoiceVsPaymentLineGraph = async() => {
        try {
            const response = await getInvoiceVsPaymentLineGraph(user_data.user_token);

            if (response.status===true) {

                if (response.data.invoice_vs_payment) {

                    const chartData = {
                        labels: response.data.invoice_vs_payment.map(item => {
                            const [year, month] = item.month.split('-');

                            const dateString = month+'-'+year;

                            return `${formatMonthYear(dateString)}`;
                        }),
                        datasets: [
                            {
                                label: 'Invoice',
                                data: response.data.invoice_vs_payment.map(item => (parseInt(item.invoice_amount))),
                                backgroundColor: '#5f76e8',
                                tension: 0.1,
                            },
                            {
                                label: 'Payment',
                                data: response.data.invoice_vs_payment.map(item => (parseInt(item.payment_amount))),
                                backgroundColor: '#01caf1',
                                tension: 0.1,
                            },
                        ],
                    };

                    setInvoiceVsPaymentLineGraphData(chartData);
                    setInvoiceVsPaymentLineGraphLoading(false);
                } 
                
                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }
        
                toast.error(response.message);
            }    
        } catch (error) {
            //console.error(error);
        }
    }

    useEffect(()=>{
        fetchInvoiceVsPaymentLineGraph();
    }, []);
    //Fetch Invoice vs Payment Line Graph End

    
    //Fetch Invoice vs Expense Pie Graph Start
    const [incomeVsExpensePieGraphMonth, setIncomeVsExpensePieGraphMonth] = useState('');
    const [incomeVsExpensePieGraphLoading, setIncomeVsExpensePieGraphLoading] = useState(true);

    const [incomeVsExpensePieGraphData, setIncomeVsExpensePieGraphData] = useState({
        labels: ['Income', 'Expense'],
        datasets: [
          {
            label: 'Income vs Expense',
            data: [],
            backgroundColor: ['#5f76e8', '#01caf1'],
            borderWidth: 1,
          },
        ],
    });
    
    const fetchIncomeVsExpensePieGraph = async() => {
        try {
            const month = incomeVsExpensePieGraphMonth ? dateFormat(incomeVsExpensePieGraphMonth, "mm-yyyy") : '';

            const response = await getIncomeVsExpensePieGraph(user_data.user_token, month);

            if (response.status===true) {

                if (response.data.invoice_vs_expense) {

                    const chartData = {
                        labels: ['Income', 'Expense'],
                        datasets: [
                          {
                            label: 'Income vs Expense',
                            data: [response.data.invoice_vs_expense.invoice_amount, response.data.invoice_vs_expense.expense_amount],
                            backgroundColor: ['#5f76e8', '#01caf1'],
                            borderWidth: 1,
                          },
                        ],
                    };

                    setIncomeVsExpensePieGraphData(chartData);
                    setIncomeVsExpensePieGraphLoading(false);
                } 
                
                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }
        
                toast.error(response.message);
            }    
        } catch (error) {
            //console.error(error);
        }
    }

    useEffect(()=>{
        fetchIncomeVsExpensePieGraph();
    }, [incomeVsExpensePieGraphMonth]);
    //Fetch Invoice vs Expense Pie Graph End

    
    
    
    //Login Hours vs Working Hours Start
    const [_graph_user_id, setGraphUserId] = useState('');
    const [loginWorkLineGraphLoading, setloginWorkLineGraphLoading] = useState(true);

    const [loginWorkLineGraphData, setLoginWorkLineGraphData] = useState({
        labels: [],
        datasets: [
          {
            label: 'Login Hours',
            data: [],
            backgroundColor: '#5f76e8',
            tension: 0.1,
          },
          {
            label: 'Working Hours',
            data: [],
            backgroundColor: '#01caf1',
            tension: 0.1,
          },
        ],
    });

    const fetchLoginVsWorkLineGraph = async() =>{
        try {
            
            const selected_user_id = _graph_user_id.value ? _graph_user_id.value : '';

            const response = await getLoginVsWorkLineGraph(user_data.user_token, selected_user_id);

            if (response.status===true) {

                if (response.data.login_vs_work) {
                    const chartData = {
                        labels: response.data.login_vs_work.map(item => {
                            // Format the date string into desired format
                            const date = new Date(item.date);
                            return `${date.getDate()}th ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
                        }),
                        datasets: [
                            {
                                label: 'Login Hours',
                                data: response.data.login_vs_work.map(item => (parseInt(item.login_seconds)/3600)),
                                backgroundColor: '#5f76e8',
                                tension: 0.1,
                            },
                            {
                                label: 'Working Hours',
                                data: response.data.login_vs_work.map(item => (parseInt(item.work_seconds)/3600)),
                                backgroundColor: '#01caf1',
                                tension: 0.1,
                            },
                        ],
                    };

                    setLoginWorkLineGraphData(chartData);
                    setloginWorkLineGraphLoading(false);
                }

                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }
        
                toast.error(response.message);
            }    
            
        } catch (error) {
            //console.error(error);
        }
    }    

    useEffect(()=>{
        fetchLoginVsWorkLineGraph();
    }, [_graph_user_id]);
    //Login Hours vs Working Hours End


    //Fetch Activities Start
    const [activitiesLoading, setActivitiesLoading] = useState(true);
    const [activities, setActivities] = useState([]); 

    const fetchActivities = async() => {
        try {
            let apiParam = {page: 1, limit: 3};

            const response = await getActivities(user_data.user_token, apiParam);

            if (response.status===true) {

                if (response.data.activities) {
                    setActivities(response.data.activities);
                }
    
                setNotificationCount(localStorage.getItem('unread_notification_count'));
            }
            else{
                if (typeof response.response.data.invalid_token != typeof undefined && response.response.data.invalid_token=='1') {
                    navigate(`/re-login?redirect=${currentRoute}`);
                }
        
                toast.error(response.message);
            } 
            
            setActivitiesLoading(false);
            
        } catch (error) {
           // console.log(error);
        }
    }

    useEffect(()=>{
        fetchActivities();
    }, []);
    //Fetch Activities End


  return (
    <>
    
        <AdminLayout>
        
            <Helmet>
                <title>Dashboard - Worktracker Anciletech</title>
            </Helmet>

            <div className="main-content app-content">

                <div className="container-fluid">

                    {/* <!-- Page Header --> */}
                    <div className="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
                        <h1 className="page-title fw-semibold fs-18 mb-0">Dashboard</h1>
                        <h6 >{greeting}</h6>
                        <div className="ms-md-1 ms-0">
                            <nav>
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active"><a href="javascript:void(0);">Dashboard</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* <!-- Page Header Close --> */}

                   {/* <!-- Start::row-1 --> */}
                   <div className="row">
                        <div className="col-xl-12">                                                                

                            {/* <!--Start::row-1 --> */}
                            <div className="row">

                                {/* <!--Map Start--> */}
                                <div className="col-xxl-9 col-xl-12">
                                    <div className="card custom-card">
                                        <div className="card-header justify-content-between">
                                            <div className="card-title d-flex justify-content-between w-100">
                                                
                                                    <div>
                                                        <i className="bi bi-map"></i> Working Locations
                                                    </div>

                                                    <div >
                                                        <DatePicker className="form-control" placeholderText="DD-MM-YYYY" maxDate={new Date()}
                                                            selected={work_date} onSelect={setWorkDate} onChange={setWorkDate} 
                                                            dateFormat="dd-MM-yyyy"/>
                                                    </div>

                                                    <div className={`${user_data.urole_permission!='*' ? 'd-none' : ''} w-25`}>

                                                        <div className="input-group d-block">
                                                            <Select
                                                                value={_map_user_ids}
                                                                onChange={setMapUserIds}
                                                                options={users}
                                                                placeholder="- Members -"
                                                                isSearchable="true"
                                                                isMulti="true"
                                                                noOptionsMessage={() => "No member found"}
                                                                styles={selectBoxTheme}
                                                                theme={(selectBoxTheme) => ({
                                                                ...selectBoxTheme,
                                                                borderRadius: 0
                                                                })} /> 

                                                                
                                                        </div>
                                                        
                                                    </div>
                                                
                                                   
                                            </div>
                                        </div>
                                        <div className="card-body" style={{height: '365px'}}>
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                                            <GoogleMap
                                                mapContainerStyle={mapContainerStyle}
                                                zoom={8}
                                                center={center}>


                                                {locations.map((location, index) => (
                                                    <OverlayView
                                                        key={index}
                                                        position={{ lat: location.latitude, lng: location.longitude }}
                                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                        getPixelPositionOffset={getPixelPositionOffset}>
                                                        
                                                        <div style={{ width: '25px', height: '25px', borderRadius: '50%', overflow: 'hidden', border: '2px solid #007bff', padding: '2px' }} onMouseOver={() => setSelectedUser(location)} onMouseOut={() => setSelectedUser(null)}>
                                                        <div style={{ width: '100%', height: '100%', borderRadius: '50%', overflow: 'hidden', backgroundColor: '#c5cc33' }}>
                                                            {location.user_image ? (
                                                            <img src={`${process.env.REACT_APP_ASSETS_URL}/${location.user_image}`} alt={`User ${index + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                            ) : (
                                                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '10px', color: '#007cff' }}>
                                                                    {location.user_first_name.toUpperCase().charAt(0)}{location.user_last_name.toUpperCase().charAt(0)}
                                                                </div>
                                                            )}
                                                        </div>
                                                        </div>
                                                    </OverlayView>
                                                ))}

                                                {selectedUser && (
                                                    <InfoWindow
                                                        position={{ lat: selectedUser.latitude, lng: selectedUser.longitude }}
                                                        onCloseClick={handleInfoWindowClose} >
                                                        <div>
                                                            <h3>{selectedUser.user_first_name} {selectedUser.user_last_name}</h3>
                                                            
                                                            <p>Coordinates: {selectedUser.latitude}, {selectedUser.longitude}</p>
                                                            <p>Address: {selectedUser.attendance_in_address}</p>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                            </GoogleMap>
                                            </LoadScript>
                                        </Suspense>    
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Map End--> */}

                                {/* <!--Timer Box Start--> */}
                                <div className="col-xxl-3 col-xl-12">
                                    {/* <!---Today Working Hours Start---> */}
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                            <span className="rounded p-3 bg-primary-transparent">
                                                                <i className="bi bi-pc-display"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                            <div className="mb-2">Working Hours</div>
                                                            <div className="text-muted mb-1 fs-12">
                                                                <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                                    {todayWorkingHours}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-12 mb-0">
                                                                    {todayWorkingHoursChange ? ( 
                                                                        <>
                                                                            <span className={`badge bg-success-transparent text-${todayWorkingHoursChange < 0 ? 'danger' : 'success'} mx-1`}>
                                                                                {todayWorkingHoursChange >= 0 ? '+' : ''}{todayWorkingHoursChange}%
                                                                            </span> from yesterday
                                                                        </>
                                                                    ) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!---Today Working Hours End---> */}


                                    {/* <!---Today WFH Hours Start---> */}
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                            <span className="rounded p-3 bg-primary-transparent">
                                                                <i className="bi bi-house"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                            <div className="mb-2">WFH Hours</div>
                                                            <div className="text-muted mb-1 fs-12">
                                                                <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                                    {todayWfhHours}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-12 mb-0">
                                                                    {todayWfhHoursChange ? ( 
                                                                        <>
                                                                            <span className={`badge bg-success-transparent text-${todayWfhHoursChange < 0 ? 'danger' : 'success'} mx-1`}>
                                                                                {todayWfhHoursChange >= 0 ? '+' : ''}{todayWfhHoursChange}%
                                                                            </span> from yesterday
                                                                        </>
                                                                    ) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!---Today WFH Hours End---> */}

                                    {/* <!---Today Office Hours Start---> */}
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                            <span className="rounded p-3 bg-primary-transparent">
                                                                <i className="bi bi-building"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                            <div className="mb-2">Office Hours</div>
                                                            <div className="text-muted mb-1 fs-12">
                                                                <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                                    {todayOfficeHours}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-12 mb-0">
                                                                    {todayOfficeHoursChange ? ( 
                                                                        <>
                                                                            <span className={`badge bg-success-transparent text-${todayOfficeHoursChange < 0 ? 'danger' : 'success'} mx-1`}>
                                                                                {todayOfficeHoursChange >= 0 ? '+' : ''}{todayOfficeHoursChange}%
                                                                            </span> from yesterday
                                                                        </>
                                                                    ) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!---Today Office Hours End---> */}

                                    {/* <!---Today Site Visit Hours Start---> */}
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-md-12 col-xl-12">
                                            <div className="card custom-card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-4 d-flex align-items-center justify-content-center ecommerce-icon px-0">
                                                            <span className="rounded p-3 bg-primary-transparent">
                                                                <i className="bi bi-geo-alt"></i>
                                                            </span>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-10 col-lg-9 col-md-9 col-sm-8 col-8 px-0">
                                                            <div className="mb-2">Site Visit Hours</div>
                                                            <div className="text-muted mb-1 fs-12">
                                                                <span className="text-dark fw-semibold fs-20 lh-1 vertical-bottom">
                                                                    {todaySiteHours}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="fs-12 mb-0">
                                                                    {todaySiteHoursChange ? ( 
                                                                        <>
                                                                            <span className={`badge bg-success-transparent text-${todaySiteHoursChange < 0 ? 'danger' : 'success'} mx-1`}>
                                                                                {todaySiteHoursChange >= 0 ? '+' : ''}{todaySiteHoursChange}%
                                                                            </span> from yesterday
                                                                        </>
                                                                    ) : ''}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!---Today Site Visit Hours End---> */}
                                </div>
                                {/* <!--Timer Box End--> */}
                            </div>
                            {/* <!--End::row-1 --> */}

                        </div>
                    </div>
                    {/* <!--End::row-1 --> */}


                    {/* <!-- Start::row-2 --> */}
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card custom-card">
                                <div className="card-header justify-content-between">
                                    <div className="card-title">
                                        Invoice Statistics
                                    </div>
                                </div>

                                <div className="card-body" style={{height: '300px'}}>
                                    <Line data={invoiceVsPaymentLineGraphData} options={options}  />
                                </div>
                            </div>     
                        </div>

                        <div className="col-xl-3">
                            <div className="card custom-card">
                                <div className="card-header justify-content-between">
                                    <div className="card-title d-flex justify-content-between">
                                        <div className='col-md-8'>
                                            Income vs Expense
                                        </div>


                                        
                                        <div className='col-md-4'>
                                            <DatePicker className="form-control" placeholderText="MM-YYYY" showMonthYearPicker isClearable
                                                selected={incomeVsExpensePieGraphMonth} onSelect={setIncomeVsExpensePieGraphMonth} onChange={setIncomeVsExpensePieGraphMonth} dateFormat="MM-yyyy"/>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="card-body" style={{height: '278px', display: 'flex', justifyContent: 'center'}}>
                               
                                    <Pie data={incomeVsExpensePieGraphData} />
                                </div>
                            </div>                                
                        </div>

                        <div className="col-xl-4">
                            <div className="card custom-card">
                                <div className="card-header justify-content-between">
                                    <div className="card-title">
                                        Recent Activity
                                    </div>
                                </div>

                                <div className="card-body" style={{height: '300px'}}>
                                    {activitiesLoading===true ? (
                                        <div className='d-flex justify-content-center my-3'>
                                            <span class="loader-new"></span>
                                        </div>
                                    ) : activities.length > 0 ? (
                                    <ul className="list-unstyled timeline-widget mb-0 my-3">
                                        {activities.map((activity) => (
                                        <li className="timeline-widget-list">
                                            <div className="d-flex align-items-top">
                                                <div className="me-5 text-center">
                                                    <span className="d-block fs-20 fw-semibold">{utcToLocal(activity.activity_create_time, 'dd')}</span>
                                                    <span className="d-block fs-12 text-muted">{utcToLocal(activity.activity_create_time, 'mmm')}</span>
                                                </div>
                                                <div className="d-flex flex-wrap flex-fill align-items-center justify-content-between">
                                                    <div>
                                                        <p className="mb-1 text-truncate timeline-widget-content text-wrap">{activity.activity_title}</p>
                                                        <p className="mb-0 fs-12 lh-1 text-muted">{utcToLocal(activity.activity_create_time, 'hh:MM TT')}</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </li>
                                        )) }                
                                    </ul>
                                    ) : 
                                    <div className="p-5 empty-item1 d-nonea">
                                        <div className="text-center">
                                            <span className="avatar avatar-xl avatar-rounded bg-secondary-transparent">
                                                <i className="ri-notification-off-line fs-2"></i>
                                            </span>
                                            <h6 className="fw-semibold mt-3">No New Activies</h6>
                                        </div>
                                    </div>

                                    } 
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End::row-2 --> */}


                    {/* <!-- Start::row-3 --> */}
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card custom-card">
                                <div className="card-header justify-content-between">
                                    <div className="card-title d-flex justify-content-between w-100">
                                        
                                            <div>
                                                Login Hours vs Working Hours
                                            </div>
                                            

                                            <div className={` ${user_data.urole_permission!='*' ? 'd-none' : ''} w-25`}>
                                                
                                                <div className="input-group d-block">
                                                    <Select
                                                        value={_graph_user_id}
                                                        onChange={setGraphUserId}
                                                        options={users}
                                                        placeholder="- Members -"
                                                        isSearchable="true"
                                                        noOptionsMessage={() => "No member found"}
                                                        styles={selectBoxTheme}
                                                        theme={(selectBoxTheme) => ({
                                                        ...selectBoxTheme,
                                                        borderRadius: 0
                                                        })} />  

                                                    <button className="btn btn-light border rounded-sm" type="button" onClick={() => setGraphUserId('')}
                                                            style={{height: '38px', background: '#ccc',  borderRadius: '0 4px 4px 0', color: '#333' ,display: _graph_user_id !== '' ? 'block' : 'none'}}>
                                                            x
                                                    </button>                         
                                                </div>
                                                
                                            </div>
                                        
                                        
                                    </div>
                                </div>

                                <div className="card-body" style={{minHeight: '300px'}}>
                                    {loginWorkLineGraphLoading ? (
                                        "Loading..."
                                    ) :(
                                        <Bar data={loginWorkLineGraphData} options={options} />
                                    )}
                                    
                                </div>
                            </div>     
                        </div>
                    </div>
                    {/* <!-- End::row-3 --> */}


                </div>

            </div> 

            <Helmet>
                
            </Helmet>
        
        </AdminLayout>
    </>
  )
}

export default Dashboard
